import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { NavItem, Nav, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { AppStateContext } from "../../appContext";
import './SideBar.scss';
import { arrowDownIcon, arrowUpIcon, callIcon, closeIcon, communicateIcon, newsIcon, portfolioIcon, riskIcon, supportIcon, tradingIcon } from '../../assets/icons';
import { useTranslation } from 'react-i18next';
import { TooltipItem } from '../TootlipItem';
import ChatDialog from '../common/ChatDialog/ChatDialog';
import CallDialog from '../common/CallDialog/CallDialog';

interface SidebarMenuItem {
  url: string;
  name: string;
  icon: JSX.Element;
}

const sidebarMenu: SidebarMenuItem[] = [
  {
    url: '/main',
    name: 'menu.trading',
    icon: tradingIcon,
  },
  {
    url: '/portfolio',
    name: 'menu.portfolio',
    icon: portfolioIcon,
  },
  {
    url: '/risk',
    name: 'menu.var',
    icon: riskIcon,
  },
  {
    url: '/news',
    name: 'menu.news',
    icon: newsIcon,
  },
  // {
  //   url: '/mock-trading',
  //   name: 'Mock',
  //   icon: newsIcon,
  // },
  // {
  //   url: '/secure-page',
  //   name: 'Secure Page',
  //   icon: portfolioIcon,
  // },
];

const SideBar = observer(({ showMenu, showInstruments, showSupport }: { showMenu?: boolean, showInstruments?: boolean, showSupport?: boolean }) => {
  const context = useContext(AppStateContext);
  const [isToggle, setToggle] = useState(false);
  const [isChatDialogOpen, setChatDialogOpen] = useState(false);
  const [isCallDialogOpen, setCallDialogOpen] = useState(false);
  const { t } = useTranslation();
  if (showMenu === undefined) showMenu = true;
  if (showInstruments === undefined) showInstruments = true;
  if (showSupport === undefined) showSupport = true;

  const toggle = function () {
    setToggle(!isToggle);
  };

  const toggleMarket = (event: any, pairId: string) => {
    event.preventDefault();
    event.stopPropagation();
    context.toggleMarket(pairId);
  }

  const renderPair = (pairId: string) => {
    const pairIdParts = pairId.split(':');
    if (pairIdParts.length > 1) {
      return pairIdParts[1];
    }
    return pairId;
  }

  return <>
    <>
      <ChatDialog isOpen={isChatDialogOpen} onClose={() => setChatDialogOpen(false)} />
      <CallDialog isOpen={isCallDialogOpen} onClose={() => setCallDialogOpen(false)} />
      {showMenu && <Nav className="main-sidebar">
        {sidebarMenu.map(item =>
          <NavItem key={item.name}>
            <NavLink to={item.url}>
              <span className='icon'>{item.icon}</span>
              <span className='name'>{t(item.name)}</span>
            </NavLink>
          </NavItem>)}
      </Nav>}

      {showInstruments && (context.markets.length > 0) && <div className='instruments-section'>
        <div className='my-instruments'>{t('menu.myInstruments')}</div>
        <Nav>
          {context.markets.map(pairId => pairId && <NavItem key={pairId}>
            <TooltipItem text={pairId} position="right">
              <NavLink to={"/trading/" + encodeURIComponent(pairId)}>
                <span>{renderPair(pairId)}</span>
                <span className='close-icon' onClick={(event) => toggleMarket(event, pairId)}>{closeIcon}</span>
              </NavLink>
            </TooltipItem>
          </NavItem>)}
        </Nav>
      </div>}

      {showSupport && <Dropdown isOpen={isToggle} toggle={toggle} className='support-dropdown'>
        <DropdownToggle>
          <span className='support-icon'>{supportIcon}</span>
          <span className='arrow-icon'>
            {!isToggle && arrowDownIcon}
            {isToggle && arrowUpIcon}
          </span>
        </DropdownToggle>
        <DropdownMenu className='support-menu'>
          <DropdownItem className='call-link nav-item btn-icon btn' onClick={() => setCallDialogOpen(true)}>
            {callIcon}
            <span>{t('menu.call')}</span>
          </DropdownItem>
          <DropdownItem className='chat-link nav-item btn-icon btn' onClick={() => setChatDialogOpen(true)}>
            {communicateIcon}
            <span>{t('menu.chat')}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>}
    </>
  </>
});

export default SideBar;