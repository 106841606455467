import authService from "../components/api-authorization/AuthorizeService";
import { DrawdownData, RateItem, VaRMethod } from "../models/var";

export class ApiService {
    static get instance() { return apiService }

    async downloadStatement(accountToken: string, year?: number, month?: number) {
        const token = await authService.getAccessToken();

        const params = new URLSearchParams({
            token: accountToken
        });
        if (year) {
            params.append('year', year.toString());
        }
        if (month) {
            params.append('month', month.toString());
        }

        fetch('/api/statement?' + params, {
            headers: token ? { 'Authorization': `Bearer ${token}` } : {}
        })
            .then(response => {
                const dispositionHeader = response.headers.get('Content-Disposition');
                var filename = "filename.ext";
                if (dispositionHeader) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const match = filenameRegex.exec(dispositionHeader);
                    if (match != null && match[1]) {
                        filename = match[1].replace(/['"]/g, '');
                    }
                }

                response.blob().then(blob => {
                    console.log(blob);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    link.click();
                    window.URL.revokeObjectURL(url);
                });
            })
            .catch(error => {
                console.error('File download error:', error);
            });
    }

    async getVaR(method: VaRMethod, window: number, confidence: number, accountId: number) {
        const token = await authService.getAccessToken();

        const params = new URLSearchParams({
            window: window.toString(),
            confidence: confidence.toString()
        });

        return fetch(`/api/useractions/${accountId}/var/${method}?` + params, {
            headers: token ? { 'Authorization': `Bearer ${token}` } : {}
        })
            .then(response => response.text().then(text => parseFloat(text)))
            .catch(error => {
                console.error('Error:', error);
            }) as Promise<number>;
    }

    async GetRiskFreeRates() {
        const token = await authService.getAccessToken();

        const params = new URLSearchParams({
        });

        return fetch(`/api/useractions/riskfreerates` + params, {
            headers: token ? { 'Authorization': `Bearer ${token}` } : {}
        })
            .then(response => response.json())
            .catch(error => {
                console.error('Error:', error);
            }) as Promise<RateItem[]>;
    }

    async getDrawdown(params: any, accountId: number) {
        const token = await authService.getAccessToken();

        return fetch(`/api/useractions/${accountId}/drawdown`, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: token ? {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            } : {}
        })
            .then(response => response.json().then(data => data.drawdown))
            .catch(error => {
                console.error('Error:', error);
            }) as Promise<DrawdownData>;
    }

    async updateUserProfile(params: any) {
        const token = await authService.getAccessToken();

        return fetch(`/api/Profile/UserDetails`, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: token ? {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            } : {}
        })
            .catch(error => {
                console.error('Error:', error);
            });
    }
}

export const apiService = new ApiService();