import { useEffect, useState } from 'react';
import { showErrorNotification } from '../../common/notifications';

export const DEFAULT_PAGE_SIZE = 10;

export interface Pagination {
    page: number;
    pageSize: number;
}

type HookResponse<T> = {
    data: T | null;
    totalCount: number;
    loading: boolean;
    error: string | null;
    pagination: Pagination;
    setPage: (page: number) => void;
};

export function usePaginationData<T>(fetchData: (pagination: Pagination) => Promise<{ data: T, totalCount: number }>, pageSize?: number): HookResponse<T> {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
    const [pagination, setPagination] = useState<Pagination>({
        page: 0,
        pageSize: pageSize !== undefined ? pageSize : DEFAULT_PAGE_SIZE,
    });

    const setPage = (page: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page,
        }));
    };

    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                const { data, totalCount } = await fetchData(pagination);
                setTotalCount(totalCount);
                setData(data);
            } catch (error) {
                setError(error.message);
                showErrorNotification(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDataAsync();
    }, [fetchData, pagination.page, pagination.pageSize]);

    return { data, loading, error, pagination, setPage, totalCount };
}

export default usePaginationData;