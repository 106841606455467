import { Triggerable } from "./Triggerable";

export enum OrderType {
    Unknown = 'Unknown',
    Market = 'Market',
    Limit = 'Limit',
    Stop = 'Stop',
    FOK = 'FOK',
    IOC = 'IOC'
}

export enum QtyType {
    Qty = 'qty',
    Lots = 'lots'
}

export class Order extends Triggerable {

    marginRatioShort: number;
    marginRatioLong: number;
    priceTolerance: number;
    qty: number;
    quotedPrice: number;
    referencePositionId: number;
    referenceType: string;
    reservedAmount: number;
    stopLossTotal: number;
    stopLossTrigger: number;
    takeProfitTotal: number;
    takeProfitTrigger: number;
    triggerPrice: number;
    type: OrderType;
    immediacy: number;
    marginRatio = 0;

    constructor(data: Object, accountSymbol: string, accountDigits: number) {
        super(accountSymbol, accountDigits, data);
        Object.assign(this, data);
        this.init();

        // if (data['bridgePairId'] === 'EUR/USD') {
        //     this.bridgeRate = 1.1; // TOOD: remove this
        // }
    }

    get ExecutionPrice() {
        return {
            main: { value: this.executionPrice, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.executionPrice * this.BridgeRate, unit: this.bridgeSymbol, digits: this.bridgePairDigits },
        }
    }

    get TriggerPrice() {
        return {
            main: { value: this.triggerPrice, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.triggerPrice * this.BridgeRate, unit: this.bridgeSymbol, digits: this.accountDigits },
        }
    }

    get QuotedPrice() {
        return {
            main: { value: this.quotedPrice, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.quotedPrice * this.BridgeRate, unit: this.bridgeSymbol, digits: this.accountDigits },
        }
    }

    get TotalStopLoss() {
        return {
            main: { value: this.stopLossTotal, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.stopLossTotal / this.BridgeCurrentRate, unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get TotalTakeProfit() {
        return {
            main: { value: this.takeProfitTotal, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.takeProfitTotal / this.BridgeCurrentRate, unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get TypeName() {
        return `enum.order_type.${this.type}`;
    }
}
