import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import { AppStateContext } from '../../../appContext';
import { saveIcon } from '../../../assets/icons';
import { apiService } from '../../../services';
import SelectField from '../../fields/SelectField';
import './StatementDialog.css';


interface StatementDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const StatementDialog: React.FC<StatementDialogProps> = ({ isOpen, onClose }) => {
    const context = useContext(AppStateContext);
    const { t } = useTranslation();
    const handleDownload = async (values: any) => {
        await apiService.downloadStatement(context.account.token, values.year, values.month);
    }

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    
    const yearsOptions = Array.from({ length: currentYear - 2023 }, (_, index) => currentYear - index).map(year => ({ value: year, label: year.toString() }));
    const monthOptions = Array.from({ length: 12 }, (_, index) => index + 1).map(month => ({ value: month, label: moment().month(month - 1).format('MMMM') }));

    return (
        <Formik initialValues={{ month: currentMonth, year: currentYear }} onSubmit={(values) => handleDownload(values)}>
            {props => (
                <Form>
                    <Dialog
                        className='no-scroll'
                        style={{ width: '400px' }}
                        header={t('portfolio.statement.title')}
                        visible={isOpen}
                        onHide={() => onClose()}
                        footer={<Button color="primary" className="btn-icon" type="submit" onClick={() => props.submitForm()}>{saveIcon} {t('portfolio.statement.buttons.download')}</Button>}
                    >
                        <Container className='statement-download' >
                            <Row>
                                <Col sm={6}>
                                    <Field component={SelectField} classNamePrefix='select' name="month" menuPlacement="bottom" options={monthOptions} placeholder={t('portfolio.statement.month')} />
                                </Col>
                                <Col sm={6}>
                                    <Field component={SelectField} classNamePrefix='select' name="year" menuPlacement="bottom" options={yearsOptions} placeholder={t('portfolio.statement.year')} />
                                </Col>
                            </Row>
                        </Container>
                    </Dialog >
                </Form>)}
        </Formik>

    );
};

export default StatementDialog;