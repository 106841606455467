import { useCallback, useContext } from 'react';
import { PortfolioRequestDataParams, SortableProperty, assetsService } from '..';
import { AppStateContext } from '../../appContext';
import { Position } from '../../models';
import { Pagination, usePaginationData } from './usePaginationData';


export const useOpenPositionsData = ({ pairId, filters, sort, sortAsc }: PortfolioRequestDataParams) => {
    const context = useContext(AppStateContext);

    const filterFunction = (position: Position) => {
        if (pairId && position.pairId !== pairId) {
            return false;
        }
        if (filters && filters.side && position.side !== filters.side) {
            return false;
        }
        return true;
    };

    const sortIndex = sortAsc ? 1 : -1;

    const sortFunction = (a: Position, b: Position) => {
        if (sort === SortableProperty.PairId || !sort) {
            return a.pairId > b.pairId ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.PositionUnrealizedPnl) {
            return a.unrealizedPnL > b.unrealizedPnL ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.PositionOpeningValue) {
            return a.entranceCost > b.entranceCost ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.PositionCurrentValue) {
            return a.currentValue > b.currentValue ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.PositionMargin) {
            return a.currentMargin > b.currentMargin ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.CreatedAt) {
            return a.createdAt! > b.createdAt! ? sortIndex : -sortIndex;
        }
        return 0;
    }

    const loadingFunction = useCallback(async (pagination: Pagination) => {
        const positions = context.balance.positions;
        const thisPairPositions = positions.filter(filterFunction);
        thisPairPositions.sort(sortFunction);
        if (pagination.pageSize) {
            const start = (pagination.page - 1) * pagination.pageSize;
            const end = start + pagination.pageSize;
            thisPairPositions.slice(start, end);
        }
        const extendedPositions = await assetsService.ExtendPairInfo(thisPairPositions);
        return { data: extendedPositions, totalCount: positions.length };
    }, [pairId, context.balance.positions, context.balance.sentAtUtc, JSON.stringify(filters), sort, sortAsc, context.updateOrdersAt]);

    const result = usePaginationData<Position[]>(loadingFunction);

    return result;
};