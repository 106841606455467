import { useCallback } from 'react';
import { Pagination, usePaginationData } from './usePaginationData';
import { assetsService } from '../AssetService';
import { Transaction } from '../../models';
import { PortfolioRequestDataParams, orderService } from '../OrderService';

interface TransactionsRequestDataParams extends PortfolioRequestDataParams {
    orderId?: number;
    positionId?: number;
    pageSize?: number;
}

export const useTransactionsData = (params: TransactionsRequestDataParams) => {
    const loadingFunction = useCallback(async (pagination: Pagination) => {
        const request = { skip: pagination.page * pagination.pageSize, take: pagination.pageSize } as any;
        if (params.dateRange) {
            request.dateFrom = params.dateRange[0];
            request.dateTo = params.dateRange[1];
        }
        if (params.pairId) {
            request.pairId = params.pairId;
        }
        if (params.orderId) {
            request.orderId = params.orderId;
        }
        if (params.positionId) {
            request.positionId = params.positionId;
        }
        const [result, total] = await orderService.GetTransactionHistory(request);
        const extendedItems = await assetsService.ExtendPairInfo(result);
        return {data: extendedItems, totalCount: total};
    }, [params.dateRange, params.pairId, params.orderId, params.positionId]);

    const result = usePaginationData<Transaction[]>(loadingFunction, params.pageSize);

    return result;
};