import { formatCurrency, formatNumber } from "../utils";
import { AssetClassesEnum } from "./Asset";
import { ExtendedPairInfo } from "./ExtendedPairInfo";


export interface Quote {
    ask: number;
    bid: number;
    mid: number;
    at: Date;
}

export class PairInfo {
    id: string;
    quoteId: string;
    assetClassId: AssetClassesEnum;
    baseId: string;
    dispName: string;
    pairInfo?: ExtendedPairInfo;

    exchangeCountry: string;
    exchangeDescription: string;
    exchangeMic: string;
    exchangeName: string;
    quoteSymbol: string;
    baseSymbol: string;
    typeDescription: string;

    ask: number;
    mid: number;
    open: number;
    bid: number;
    change: number | null;

    lastBuyChange: number;
    lastAskChange: number;
    lastBidChange: number;

    lotDecimals: number;
    lotSize: number;
    pairDecimals: number;
    costDecimals: number;

    orderMin: number;
    orderMax: number;
    updatedAt: Date;

    constructor(pair: Object) {
        // if (pair['id'] === 'EUR/USD') {
        //     pair['open'] = pair['ask'] = pair['bid'] = pair['close'] = 1.1; // TOOD: remove this
        // }

        Object.assign(this, pair);
        this.mid = (this.ask + this.bid) / 2;
        if (this.open !== null && this.open !== 0) {
            this.change = this.open - this.mid;
        } else {
            this.change = null;
        }
    }

    updateQuote(quote: Quote) {
        if (!quote || quote.at === this.updatedAt) {
            return;
        }
        this.updatedAt = quote.at;
        this.lastAskChange = quote.ask - this.ask;
        this.lastBidChange = quote.bid - this.bid;

        this.ask = quote.ask;
        this.bid = quote.bid;
        this.mid = (this.ask + this.bid) / 2;
        if (this.open !== null) {
            this.change = this.open - this.mid;
        }
    }

    diffClass(diff: number | null) {
        if (diff === null || diff === undefined) {
            return 'Same';
        }

        if (diff > 0) {
            return 'Rising';
        } else if (diff < 0) {
            return 'Falling';
        } else {
            return 'Same';
        }
    }

    get isFX() {
        return this.assetClassId & AssetClassesEnum.FX;
    }

    get changePercent() {
        if (this.change === null || this.change === undefined) {
            return '---';
        }

        if (!this.mid) {
            return 0;
        }
        return formatNumber(100 * this.change / this.mid, 2);
    }

    get changeClass() {
        return this.diffClass(this.change);
    }

    get changeDisplay() {
        return this.displayValue(this.change)
    }

    get lastBidChangeClass() {
        return this.diffClass(this.lastBidChange);
    }

    get lastAskChangeClass() {
        return this.diffClass(this.lastAskChange);
    }

    get bidClass() {
        return this.diffClass(this.bid - this.open);
    }

    get bidDisplay() {
        return this.displayValue(this.bid);
    }

    get bidDisplayCurrency() {
        return this.displayCurrencyValue(this.bid, this.quoteSymbol);
    }

    get askClass() {
        return this.diffClass(this.ask - this.open);
    }

    get askDisplay() {
        return this.displayValue(this.ask);
    }

    get askDisplayCurrency() {
        return this.displayCurrencyValue(this.ask, this.quoteSymbol);
    }

    get midClass() {
        return this.diffClass(this.mid - this.open);
    }

    get midDisplay() {
        return this.displayValue(this.mid);
    }

    get midDisplayCurrency() {
        return this.displayCurrencyValue(this.mid, this.quoteSymbol);
    }


    get pip() {
        if (this.isFX) {
            return 1 / Math.pow(10, this.pairDecimals);
        } else {
            return this.ask / 100 / 100;
        }
    }

    get spread(): string {
        let value = this.ask - this.bid;
        let decimals: number;
        if (this.isFX) {
            value = Math.pow(10, this.pairDecimals - 1) * value;
            decimals = 1;
        } else {
            value = value / this.ask * 100 * 100;
            decimals = 2;
        }

        return formatNumber(value, decimals);
    }

    get spreadUnit() {
        return this.isFX ? 'pips' : 'bp';
    }

    displayCurrencyValue(value: number | null | undefined, symbol: string) {
        return formatCurrency(value, symbol, this.pairDecimals);
    }

    displayValue(value: any) {
        return formatNumber(value, this.pairDecimals);
    }
}