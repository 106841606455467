import { ROC } from "technicalindicators";
import { OhlcVolumeData } from "../Chart";
import { LineIndicator } from "./BaseIndicator";
import { ROCInput } from "technicalindicators/declarations/momentum/ROC";


export class ROCIndicator extends LineIndicator {
    color = '#00FF00';
    symbol = null;
    updateData(data: OhlcVolumeData[]): void {

        const input: ROCInput = {
            values: data.map((item: OhlcVolumeData) => item.close),
            period: 14
        }

        const indicator = new ROC(input);

        this.data = data.map((item: OhlcVolumeData) => {
            const value = indicator.nextValue(item.close);

            return {
                time: item.time,
                value: value
            };
        });

        if (this.data && this.series) {
            this.series.setData(this.data);
        }
    }
}

