import React from "react";
import { formatNumber, formatNumberTrim } from "../../utils";
import classNames from "classnames";
import { Skeleton } from "antd";

type UnitValueFieldProps = {
    position?: string | null,
    value: number | string | undefined | null,
    unit?: string,
    precision?: number,
    className?: string;
    hideUnit?: boolean;
    loading?: boolean;
};

type UnitValueTitleProps = {
    title: string;
}


export const UnitValueField = ({ position = null, value, unit, precision, className, hideUnit, loading }: UnitValueFieldProps) => {

    if (loading) {
        return <Skeleton.Input active={true} />
    }

    if (precision !== undefined && precision !== null) {
        value = formatNumber(value as number, precision);
    } else if (typeof value === 'number') {
        value = formatNumberTrim(value as number);
    }
    return <div className={classNames("text-number value suffix-value unit-field", position, className)}>
        <span className='val'>{value}</span>
        {unit && !hideUnit && <span className='suffix'>{unit}</span>}
    </div>
}

export const UnitValueHeader = ({ title }: UnitValueTitleProps) => {
    return <div className='unit-field'><span>{title}</span><span></span></div>
}
