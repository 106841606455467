import { makeAutoObservable } from "mobx";
import React from 'react';
import { PairInfoTab } from './components/pages/Main/components/PairInfoView/PairInfoView';
import { Account, Order, OrderSide, PairInfo } from './models';
import { Balance } from './models/Balance';
import { GenericNotification, IGenericNotification } from './models/Notifications';
import { Quotes } from './models/Quote';
import { AvailableMetrics } from './models/var';
import { accountService, apiService, notificationService, orderService } from './services';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import i18n, { supportedLanguages } from "./i18n";

dayjs.extend(timezone);


class LyraUser {
    name = 'Anonymous';
    role: string | null = null;
    culture: string = 'en-US';
    id: number = 0;
    tradeAccessPermitted: boolean = false;
    timezone: string;
    constructor(obj?: any) {
        this.timezone = dayjs.tz.guess();
        if (obj) {
            this.role = obj['role'];
            this.name = obj['aud'];
            this.culture = obj['Culture'];
            this.timezone = obj['Timezone'];
            this.id = parseInt(obj['LyraUID']);
            this.tradeAccessPermitted = obj['TradeAccessPermitted'] === 'true';
        }
        makeAutoObservable(this);
    }
}

export interface MenuItem {
    title: string;
    key: string;
    icon?: string;
    action?: () => void;
    path: string;
}

export type AccountCurrencyType = 'account' | 'quote';

export class AppState {
    isAuthenticated = false;
    connectionReady = false;
    isMenuOpen = false;
    error?: Error;
    clientReady = false;
    sideBarOpen: PairInfoTab | boolean = false;
    direction?: OrderSide;
    chartOpen = true;
    markets: string[] = [];
    metrics: AvailableMetrics[] = [AvailableMetrics.UnrealizedPnl, AvailableMetrics.VaR];
    hiddenBalanceItems = new Set<string>();
    balance = new Balance();
    account = new Account();
    notifications: GenericNotification[] = [];
    user = new LyraUser();
    quotes = new Quotes();
    updateOrdersAt = new Date();
    updateAlertsAt = new Date();
    updateNotificationsAt = new Date();
    bottomMenu: MenuItem[];
    isMobile: boolean = false;
    isTablet: boolean = false;
    isAdaptive: boolean = false;
    currency: AccountCurrencyType = 'account';
    activeAccount: string | null = null;
    language: string;
    timezone: string = 'UTC';

    constructor() {
        makeAutoObservable(this);
        const markets = JSON.parse(window.localStorage.getItem('markets') ?? "[]") as string[];
        const hiddenBalanceItems = JSON.parse(localStorage.getItem('hiddenBalanceItems') ?? "[]");
        this.activeAccount = localStorage.getItem('activeAccount') ?? null;
        this.chartOpen = localStorage.getItem('chartOpen') !== 'false';
        const sideBarOpen = localStorage.getItem('sideBarOpen') ?? false;
        this.currency = localStorage.getItem('currency') === 'quote' ? 'quote' : 'account';
        const clearDate = dayjs().subtract(1, 'week');
        this.timezone = dayjs.tz.guess();

        this.notifications = JSON.parse(localStorage.getItem('notifications') ?? "[]").map((obj: object) => new GenericNotification(obj, this.account.symbol, this.account.displayDecimals, false)).filter((obj: GenericNotification) => !obj.seenAt || dayjs(obj.seenAt).isAfter(clearDate))
        if (sideBarOpen === 'false') {
            this.sideBarOpen = false;
        } else {
            this.sideBarOpen = sideBarOpen as PairInfoTab;
        }

        if (hiddenBalanceItems) {
            this.hiddenBalanceItems = new Set(hiddenBalanceItems);
        }
        if (markets) {
            this.markets.push(...markets)
        }
    }

    switchLanguage(language: string, save=true) {
        if (!Object.keys(supportedLanguages).includes(language)) {
            let [lang] = language.split('-');
            if (Object.keys(supportedLanguages).includes(lang)) {
                language = lang;
            } else {
                language = 'en-US';
            }
        }

        this.language = language;
        i18n.changeLanguage(language);
        dayjs.locale(language);
        if (save) {
            localStorage.setItem('i18nextLng', language);
            apiService.updateUserProfile({culture: language});
        }
    }

    updateConnectionState(newState: boolean) {
        this.connectionReady = newState;
    }

    setError(error: Error | undefined) {
        this.error = error;
    }

    updateUser(newUser: any) {
        this.isAuthenticated = !!newUser;
        const user = new LyraUser(newUser);
        this.user = user;
        this.timezone = user.timezone;
        this.switchLanguage(localStorage.getItem('i18nextLng') || window.navigator.language || this.user.culture, false);
    }

    async switchAccount(newAccountToken: string) {
        const ret = await accountService.SwitchAccount(newAccountToken);
        this.activeAccount = newAccountToken;
        window.localStorage.setItem('activeAccount', newAccountToken);

        if (ret && ret.success) {
            const [newAccount, newBalance] = await Promise.all([accountService.GetAccountInfo(), accountService.GetPortfolioInfo()]);
            this.updateAccount(newAccount);
            this.updateBalance(newBalance);
        }
    }

    updateBalance(newBalance: Object) {
        // console.log('Updating balance: ', newBalance)
        this.balance.update(newBalance, this.account);
    }

    updatePendingOrders(orders: Order[]) {
        console.log('updatePendingOrders', orders);
        this.updateOrdersAt = new Date();
    }

    onGenericNotification(gn: IGenericNotification) {
        console.log('onGenericNotification', gn);

        if (gn.order) {
            this.updateOrdersAt = new Date();
        }
        if (gn.alert) {
            this.updateAlertsAt = new Date();
        }

        const notification = new GenericNotification(gn, this.account.symbol, this.account.displayDecimals);
        this.notifications.push(notification);
        this.updateNotificationsAt = new Date();
        notificationService.showNotification(notification);
        this.saveNotifications();
    }

    markAsRead() {
        this.notifications.forEach((notification: GenericNotification) => {
            notification.seenAt = new Date();
        });
        this.saveNotifications();
    }

    protected saveNotifications() {
        window.localStorage.setItem('notifications', JSON.stringify(this.notifications));
    }

    updateAccount(newAccountData: any) {
        console.log('Updating account data: ', newAccountData);
        this.account.update(newAccountData);
        this.balance.updateAccount(this.account);
        this.clientReady = !!newAccountData;
    }

    toggleFavorite(pair: PairInfo) {
        if (this.account.favoritesSet.has(pair.id)) {
            this.account.favoritesSet.delete(pair.id)
            orderService.RemoveAccountFavorites([pair.id]);
        } else {
            this.account.favoritesSet.add(pair.id);
            orderService.AddAccountFavorites([pair.id]);
        }
    }

    toggleMarket(pairId: string) {
        const idx = this.markets.indexOf(pairId);
        if (idx !== -1) {
            this.markets.splice(idx, 1);
        } else {
            this.markets.push(pairId);
        }
        window.localStorage.setItem('markets', JSON.stringify(this.markets));
    }

    toggleHiddenBalanceItems(key: string) {
        if (this.hiddenBalanceItems.has(key)) {
            this.hiddenBalanceItems.delete(key)
        } else {
            this.hiddenBalanceItems.add(key);
        }
        window.localStorage.setItem('hiddenBalanceItems', JSON.stringify(this.hiddenBalanceItems));
    }

    setDirection(direction: OrderSide) {
        this.direction = direction;
    }

    setSideBar(sidebar: PairInfoTab | boolean) {
        this.sideBarOpen = sidebar;
        localStorage.setItem('sideBarOpen', this.sideBarOpen as string);
    }

    setMetrics(newMetrics: AvailableMetrics[]) {
        this.metrics = newMetrics;
    }

    toggleChart() {
        this.chartOpen = !this.chartOpen;
        localStorage.setItem('chartOpen', JSON.stringify(this.chartOpen));
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }

    closeMenu() {
        this.isMenuOpen = false;
    }

    setBottomMenu(menu: any) {
        this.bottomMenu = menu;
    }

    setIsMobile(isMobile: boolean) {
        this.isMobile = isMobile;
        this.isAdaptive = this.isMobile || this.isTablet;
    }

    setIsTablet(isTablet: boolean) {
        this.isTablet = isTablet;
        this.isAdaptive = this.isMobile || this.isTablet;
    }

    setCurrency(currency: AccountCurrencyType) {
        this.currency = currency;
        localStorage.setItem('currency', currency);
    }

    showToast = () => { }

    setTitle(title: string, subtitle?: string) {
        const parts = [title];
        if (subtitle) {
            parts.push(subtitle);
        }
        document.title = `${parts.join(' - ')} | Lyra`;
    }
}

export const appState: AppState = new AppState();
window.appState = appState; // TODO: debug purpose only
export const AppStateContext = React.createContext(appState);