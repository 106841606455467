import React from 'react';

import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { Alert } from '../../../models';
import { ColumnDefinition, DataTable } from '../../DataTable';
import useAlertsViewData, { AlertsViewInternalProps } from './useAlertsViewData';
import classNames from 'classnames';


export const AlertsViewTable: React.FC<AlertsViewInternalProps> = observer(({ currency, alerts, refreshAlerts, symbol }) => {
    const { t } = useTranslation();
    const renderer = useAlertsViewData({ symbol, refreshAlerts, currency });
    const columns = [
        {
            name: 'logo',
            className: 'cell-logo',
            render: (alert: Alert) => renderer.logo.render(alert)
        },
        {
            name: 'tiker',
            className: 'cell-ticker',
            title: renderer.ticker.title,
            render: (alert: Alert) => renderer.cell_ticker.render(alert)
        },
        {
            name: 'trigger_price',
            className: 'text-end',
            title: renderer.trigger_price.title,
            render: (alert: Alert) => renderer.trigger_price.render(alert)
        },
        // {
        //     name: 'current_price',
        //     className: 'text-end combined-left',
        //     title: renderer.current_price.title,
        //     render: (alert: Alert) => renderer.current_price.render(alert, { hideUnit: true })
        // },
        // {
        //     name: 'currency_unit',
        //     className: 'cell-unit',
        //     render: (alert: Alert) => renderer.currency_unit.render(alert)
        // },
        {
            name: 'created_at',
            className: '',
            title: renderer.created_at.title,
            render: (alert: Alert) => renderer.created_at.render(alert)
        },
        {
            name: 'good_until',
            className: '',
            title: renderer.good_until.title,
            render: (alert: Alert) => renderer.good_until.render(alert)
        },
        {
            name: 'actions',
            render: (alert: Alert) => renderer.actions.render(alert)
        },
    ] as ColumnDefinition<Alert>[];

    const itemProps = (alert: Alert, index: number) => {
        return {
            className: classNames('item', alert.SideClass),
        }
    }
    return <>
        <DataTable itemProps={itemProps} data={alerts} columns={columns} className='table-grid alerts data-table table-auto' />
    </>
});
