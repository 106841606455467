import { useCallback, useContext } from 'react';
import { OrderService, PortfolioRequestDataParams, SortableProperty, assetsService, orderService } from '..';
import { AppStateContext } from '../../appContext';
import { Order } from '../../models';
import { Pagination, usePaginationData } from './usePaginationData';


export const usePendingOrdersData = (params: PortfolioRequestDataParams) => {
    const context = useContext(AppStateContext);
    const { sort } = params;

    const filterFunction = (order: Order) => {
        if (params.pairId && order.pairId !== params.pairId) {
            return false;
        }
        if (params.filters?.side && order.side !== params.filters?.side) {
            return false;
        }
        if (params.filters?.type && order.type !== params.filters?.type) {
            return false;
        }
        return true;
    };

    const sortIndex = params.sortAsc ? 1 : -1;

    const sortFunction = (a: Order, b: Order) => {
        if (sort === SortableProperty.PairId || !sort) {
            return a.pairId > b.pairId ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.OrderType) {
            return a.type > b.type ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.Side) {
            return a.side > b.side ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.OrderTriggerPrice) {
            return a.triggerPrice > b.triggerPrice ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.CreatedAt) {
            return a.createdAt > b.createdAt ? sortIndex : -sortIndex;
        }
        else if (sort === SortableProperty.OrderValidity) {
            return a.goodUntil! > b.goodUntil! ? sortIndex : -sortIndex;
        }
        return 0;
    }

    const loadingFunction = useCallback(async (pagination: Pagination) => {
        const request = OrderService.createRequest({ ...params, pagination });
        const [result, total] = await orderService.GetPendingOrders(context.account, request);
        const extendedItems = await assetsService.ExtendPairInfo(result);

        const thisResult = extendedItems.filter(filterFunction);
        thisResult.sort(sortFunction);
        return { data: thisResult, totalCount: total };
    }, [params.pairId, context.updateOrdersAt, params.updateAt, params.sort, params.sortAsc, JSON.stringify(params.filters), params.dateRange]);

    const result = usePaginationData<Order[]>(loadingFunction);

    return result;
};