import React, { useEffect, useState } from 'react';
import { assetsService } from '../../../../../services';
import OrderForm from '../OrderForm/OrderForm';
import AlertForm from '../AlertForm/AlertForm';
import { PairNews } from '../PairNews/PairNews';
import { PairDetails } from '../PairDetails/PairDetails';
import { ExtendedPairInfo, OrderSide, PairInfo } from '../../../../../models';

import './PairInfoView.scss'
import { useSearchParams } from 'react-router-dom';

export enum PairInfoTab {
    News = 'news',
    Info = 'info',
    Order = 'order',
    Alert = 'alert',
}

export interface PairInfoViewProps {
    pair: PairInfo | null;
    tab: PairInfoTab | boolean;
    direction?: OrderSide;
}

export interface PairDetailsProps {
    pair: PairInfo;
    direction?: OrderSide;
    extendedInfo?: ExtendedPairInfo;
}

const PairInfoView: React.FC<PairInfoViewProps> = ({ pair, tab, direction }) => {
    const [ pairInfo, setPairInfo ] = useState<ExtendedPairInfo|null>();

    const [searchParams] = useSearchParams();
    if (searchParams.get('direction')) {
        direction = searchParams.get('direction') as OrderSide;
    }

    useEffect(() => {
        async function fetchData() {
            const result = await assetsService.GetPairExtendedInfo(pair!.id);
            await assetsService.ExtendPairInfo([result], pair!);
            // TODO: subscribe to bridge pair info updates.
            setPairInfo(result);
        }
        setPairInfo(null);

        if (pair) {
            fetchData();
        }
    }, [pair]);

    if (!tab || !pair) {
        return <></>
    }

    const components = {
        news: PairNews,
        info: PairDetails,
        order: OrderForm,
        alert: AlertForm,
    }

    const component = React.createElement(components[tab as keyof typeof components], {
        pair: pair,
        extendedInfo: pairInfo!,
        direction: direction,
    });

    return (
        <div className="PairInfo">
            {component}
        </div>
    );


};

export default PairInfoView;