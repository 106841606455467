import React, { ElementType, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface TranslateProps {
  i18nKey: string;
  isHtml?: boolean;
  isMulty?: boolean;
  component?: ElementType;
  [key: string]: any;
}

const Translate = ({ i18nKey, isHtml = true, isMulty = false, component: Component = React.Fragment, ...props }: TranslateProps) => {
  const { t } = useTranslation();

  if (isMulty) {
    const items: string[] = t(i18nKey, { returnObjects: true });
    return <>{items.map(item => <Component key={item}>{item}</Component>)}</>;
  }

  return isHtml ? (
    <Trans i18nKey={i18nKey} {...props} />
  ) : (
    <>{t(i18nKey)}</>
  );
};

export default Translate;
