import React from 'react';

import { PositionRowProps, PositionsViewInternalProps, PositionsViewProps } from '../usePositionViewData';
import { useShowRelatedOrderDialog } from '../useShowRelatedOrderDialog';
import { useShowTransactionsDialog } from '../useShowTransactionsDialog';
import { ClosedPositionsViewBlocks } from './ClosedPositionsViewBlocks';
import { ClosedPositionsViewTable } from './ClosedPositionsViewTable';


export interface ClosedPositionRowProps extends PositionRowProps {
    showTransaction: CallableFunction;
    showOrder: CallableFunction;
}

export interface ClosedPositionsViewInternalProps extends PositionsViewInternalProps {
    showTransaction: CallableFunction;
    showOrder: CallableFunction;
}

export const ClosedPositionsView: React.FC<PositionsViewProps> = ({ currency, positions, fullView }) => {

    const { transactionDialog, showPositionTransactions } = useShowTransactionsDialog();
    const { orderDialog, showOrder, } = useShowRelatedOrderDialog();

    const view = fullView ?
        <ClosedPositionsViewTable positions={positions} currency={currency} showTransaction={showPositionTransactions} showOrder={showOrder} /> :
        <ClosedPositionsViewBlocks positions={positions} currency={currency} showTransaction={showPositionTransactions} showOrder={showOrder} />

    return <>
        {transactionDialog}
        {orderDialog}
        {view}
    </>
};