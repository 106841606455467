import classNames from 'classnames';
import React, { useContext } from 'react';

import { observer } from "mobx-react-lite";
import { AppStateContext } from '../../../../appContext';
import { transactionIcon } from '../../../../assets/icons';
import usePositionViewData from '../usePositionViewData';
import { ClosedPositionRowProps, ClosedPositionsViewInternalProps } from './ClosedPositionsView';


const ClosedPositionRowBlock: React.FC<ClosedPositionRowProps> = observer(({ currency, position, showOrder, showTransaction }) => {
    const { side } = position;
    const context = useContext(AppStateContext);
    const currencyAccount = context.account.denominatedIn;
    const isDirect = currency === currencyAccount;
    const sideClass = classNames('item inline-form', `side-${side}`);

    const columns = usePositionViewData({ isDirect, showOrder, showTransaction });

    return (
        <div className={sideClass}>
            <div className='ticker left'>
                {columns.ticker.render(position)}
            </div>
            <div className='data'>
                <div className='data-2 data-item'>
                    <div className='cell'>
                        <div className="header">{columns.realized.title}</div>
                        {columns.realized.render(position)}
                    </div>
                </div>
                <div className='data-2 data-item'>
                    <div className='combined'>
                        <div className='cell left'>
                            <div className="header with-divider">{columns.opening_value.title}</div>
                            <div className="with-divider">{columns.opening_value.render(position)}</div>
                        </div>
                        <div className='cell right'>
                            <div className="header">{columns.opening_price.title}</div>
                            {columns.opening_price.render(position)}
                        </div>
                    </div>
                </div>
                <div className='data-3 data-item'>
                    <div className='combined'>
                        <div className='cell left'>
                            <div className="header with-divider">{columns.closing_value.title}</div>
                            <div className="with-divider">{columns.closing_value.render(position)}</div>
                        </div>
                        <div className='cell right'>
                            <div className="header">{columns.closing_price.title}</div>
                            {columns.closing_price.render(position)}
                        </div>
                    </div>
                </div>
                <div className='data-3 data-item'>
                    <div className='cell'>
                        <div className="header">{columns.closed_at.title}</div>
                        {columns.closed_at.render(position)}
                    </div>
                </div>
            </div>
            <div className='actions'>
                <button className="icon" onClick={() => showTransaction(position)}>{transactionIcon}</button>
            </div>
        </div>
    )
});


export const ClosedPositionsViewBlocks: React.FC<ClosedPositionsViewInternalProps> = observer((props) => {
    const { positions, ...rest } = props;
    return <>
        <div className='positions responsive-data-table data-table portfolio-table'>
            {positions.map(position => <ClosedPositionRowBlock position={position} key={position.id} {...rest} />)}
        </div>
    </>
});
