import { makeAutoObservable } from "mobx";
import { PairInfo } from "./PairInfo";


export interface AccountInterface {
    id: number;
    token: string;
    symbol: string;
    displayDecimals: number;
}

export class Account implements AccountInterface {
    id = 0;
    availableBalance = 0;
    displayDecimals = 0;
    denominatedIn = ''
    favoritesSet = new Set();
    isDefault = false;
    otherAccounts = [];
    token = '';
    symbol = '';
    constructor() {
        makeAutoObservable(this);
    }

    update(newData: any) {
        Object.assign(this, newData);
        this.favoritesSet = new Set(newData.favorites.map((item: PairInfo) => item.id));
    }
}
