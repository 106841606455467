import { orderService } from "../services";
import { confirmDialog } from 'primereact/confirmdialog';
import moment from "moment";
import { GoodUntilTypes, Order } from "../models";
import { deleteIcon } from "../assets/icons";
import { showErrorNotification, showNotification } from "./notifications";
import i18n from "../i18n";

export const cancelOrderProcess = async (order: Order, refreshOrders: CallableFunction) => {
    try {
        const ret = await orderService.CancelOrder(order);
        if (ret.cancelledSuccessfully) {
            refreshOrders();
        }
        showNotification(i18n.t("notifications.order.Cancelled", { pair: order.pairId, reason: i18n.t("notifications.reason.user")}));
    } catch (error) {
        showErrorNotification(error.message);
    }
}

export const cancelOrder = async (order: Order, refreshOrders: CallableFunction) => {
    confirmDialog({
        message: i18n.t("dialogs.cancel_order.promt"),
        acceptLabel: i18n.t("dialogs.cancel_order.accept"),
        rejectLabel: i18n.t("dialogs.cancel_order.reject"),
        icon: deleteIcon,
        accept: () => cancelOrderProcess(order, refreshOrders),
    });
}

export type OrderFormValues = {
    qty: number;
    triggerPrice: number;
    stopLossTotal: number;
    takeProfitTotal: number;
    goodUntilType: GoodUntilTypes;
    goodUntil: Date | null,
}

export const submitChanges = async (order: Order, setEditing: CallableFunction, refreshOrders: CallableFunction, values: OrderFormValues) => {
    try {
        const data = {
            qty: values.qty,
            totalTakeProfit: values.takeProfitTotal,
            totalStopLoss: values.stopLossTotal,
            triggerPrice: values.triggerPrice,
            goodUntil: getGoodUntil({goodUntilType: values.goodUntilType, goodUntilDate: values.goodUntil}),
        }
        const result = await orderService.UpdateOrder(order, data);
        setEditing(undefined);
        refreshOrders();
        showNotification(i18n.t("notifications.order.updated"));
    } catch (error) {
        showErrorNotification(error.message);
    }
}

interface getGoodUntilProps {
    goodUntilType: number;
    goodUntilDate: Date | null;
    goodUntilTime?: Date | string | null;
}

export const getGoodUntil = (data: getGoodUntilProps) => {
    let { goodUntilType, goodUntilDate, goodUntilTime } = data;
    if (goodUntilType === GoodUntilTypes.GTD) {
        return moment().endOf('day').toISOString();
    }
    if (goodUntilType === GoodUntilTypes.GTC) {
        return moment('2100-01-01 00:00Z').toISOString();
    }
    const retDate = goodUntilDate!;
    if (!goodUntilTime) {
        goodUntilTime = goodUntilDate!;
    }
    if (typeof goodUntilTime === 'object') {
        retDate.setHours(goodUntilTime.getHours(), goodUntilTime.getMinutes(), 0);
    } else {
        const [hours, minutes] = goodUntilTime.split(':').map((val) => parseInt(val));
        retDate.setHours(hours, minutes, 0);
    }
    return retDate.toISOString();
}