import React, { useState } from 'react';
import { newsSmallIcon } from "../../../../../assets/icons";
import { logoImageService, newsService } from "../../../../../services";
import Loading from "../../../../Loading";
import "./PairNews.css"
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { FullNewsItem, NewsItemView } from '../../../NewsPage/NewsPage';
import { useTranslation } from 'react-i18next';
import { NewsItem } from '../../../../../models';
import { PairDetailsProps } from '../PairInfoView/PairInfoView';


export const NewsDialog = ({ item }: { item?: NewsItem }) => {
    if (!item) {
        return <></>
    }

    return <FullNewsItem baseClassName='NewsDialog' item={item} />;
};


export const PairNews: React.FC<PairDetailsProps> = ({ pair }) => {
    const iconUrl = logoImageService.getPairLogoUrl(pair.id);
    const { t } = useTranslation();

    const [newsList, setNewsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [displayNewsItem, setDisplayNewsItem] = useState<NewsItem|undefined>(undefined);


    useEffect(() => {
        async function fetchData() {
            const result = await newsService.GetGetMarketNews([pair.id]);
            console.log(result);
            setNewsList(result);
            setIsLoading(false);
        }
        setIsLoading(true);
        fetchData();
    }, [pair]);

    const showNewsDialog = (newsItem: NewsItem) => {
        setDisplayNewsItem(newsItem);
    }

    return (
        <>
            <Dialog style={{ width: '70vw' }} headerClassName="ivisibelHeader" visible={!!displayNewsItem} onHide={() => setDisplayNewsItem(undefined)} contentClassName="NewsDialog" dismissableMask={true}>
                <NewsDialog item={displayNewsItem} />
            </Dialog>
            <div className='PairNews'>
                <h2 className=''>{newsSmallIcon} <span>{t('news.news')}</span></h2>
                <h3><span className='logo-wrapper'><img className="pair-logo" src={iconUrl}></img></span> <span>{pair.id}</span></h3>
                <div className='news-list'>
                    {isLoading && <Loading></Loading>}
                    {!isLoading && newsList.map((item: NewsItem) => <NewsItemView key={item.id} item={item} isActive={displayNewsItem === item} showNews={showNewsDialog} />)}
                </div>
            </div>
        </>
    );
};
