import { HistogramSeriesPartialOptions, IChartApi, ISeriesApi, MouseEventParams, SingleValueData } from "lightweight-charts";
import { formatNumber } from "src/utils";
import { MACD } from "technicalindicators";
import { MACDInput } from "technicalindicators/declarations/moving_averages/MACD";
import { OhlcVolumeData } from "../Chart";
import { BaseIndicator, TooltipOptions } from "./BaseIndicator";


export class MACDIndicator extends BaseIndicator {
    series: ISeriesApi<"Line">;
    histogram: ISeriesApi<"Histogram">;
    priceScaleId = 'left';
    color = '#00FF00';
    updateData(data: OhlcVolumeData[]): void {

        const input: MACDInput = {
            values: data.map((item: OhlcVolumeData) => item.close),
            fastPeriod: 12,
            slowPeriod: 26,
            signalPeriod: 9,
            SimpleMAOscillator: true,
            SimpleMASignal: true
        }

        const indicator = new MACD(input);

        this.data = data.map((item: OhlcVolumeData) => {
            const value = indicator.nextValue(item.close);

            return {
                time: item.time,
                value: value?.MACD
            };
        });
        const histogrmData = data.map((item: OhlcVolumeData) => {
            const value = indicator.nextValue(item.close);
            return {
                time: item.time,
                color: 'silver',
                value: value?.histogram
            };
        });

        if (this.data && this.series && this.histogram) {
            this.series.setData(this.data);
            this.histogram.setData(histogrmData);
        }
    }


    attach(chart: IChartApi, mainSeries: ISeriesApi<"Candlestick">): void {
        super.attach(chart, mainSeries);

        this.series = this.chart.addLineSeries({ priceScaleId: this.priceScaleId, baseLineVisible: false, color: this.color, lastValueVisible: false });
        if (this.priceScaleId === 'left') {
            this.chart.applyOptions({
                leftPriceScale: {
                    visible: true,
                },
            });
            this.series.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.6,
                    bottom: 0.2,
                },
            });
        }

        this.histogram = this.chart.addHistogramSeries({
            color: 'red',
            priceFormat: {
                type: 'volume',
            },
            lastValueVisible: false,
            priceScaleId: '', // set as an overlay by setting a blank priceScaleId

        } as HistogramSeriesPartialOptions);
        this.histogram.priceScale().applyOptions({
            scaleMargins: {
                top: 0.8,
                bottom: 0.0,
            },
        });

        if (this.data) {
            this.series.setData(this.data);
        }
    }

    detach() {
        this.chart?.removeSeries(this.series);
        this.chart?.removeSeries(this.histogram);
        this.chart.applyOptions({
            leftPriceScale: {
                visible: false,
            },
        });
    }

    getTooltip(param: MouseEventParams, options: TooltipOptions): string {
        const volumeData = param.seriesData.get(this.series!) as SingleValueData;
        const value = formatNumber(volumeData?.value, undefined);
        const symbol = '';
        return `<span class="value suffix-value"><span class="val number text-number">${value}</span><span class="suffix">${symbol}</span>`;
    }
}

