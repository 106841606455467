import type { Dayjs } from 'dayjs';
import { useCallback, useContext, useState } from 'react';
import { AppStateContext } from '../../appContext';
import { AggregationType, AvailableMetrics, CalculateVarData, MetricPeriodicity, PositionSide, VaRMethod } from '../../models';
import { accountService } from '../AccountService';
import useData from './useData';


export const useVARGetSSRatiosData = (window: number, riskFreeRate: number | undefined, includeRRA: boolean) => {
    return useData(useCallback(() => accountService.GetAccountSSRatios(window, riskFreeRate, includeRRA), [window, riskFreeRate, includeRRA]));
};

export const useVaRData = (method: VaRMethod, window: number, confidence: number, numberOfPaths: number, horizon: number, includeMCTR: boolean) => {
    const { balance } = useContext(AppStateContext);
    const [prevTotal, setPrevTotal] = useState<number>(0);
    const change = Math.abs(balance.totalValue - prevTotal) / balance.totalValue * 100;
    if (change > 0.5) {
        setPrevTotal(balance.totalValue);
    }
    return useData<CalculateVarData>(useCallback(() => accountService.CalculateAccountVar(method, window, confidence, numberOfPaths, horizon, includeMCTR), [prevTotal, method, window, confidence, numberOfPaths, horizon, includeMCTR]));
};

export const useVaRHistoricalData = (dateRange: Dayjs[], metrics: AvailableMetrics[], periodicity: number) => {
    const metricsFlags = metrics.map(item => item).reduce((a, b) => a | b, 0);
    return useData(useCallback(() => accountService.GetAccountMetricsHistory(dateRange[0].toDate(), dateRange[1].toDate(), metricsFlags, periodicity), [dateRange[0].toISOString(), dateRange[1].toISOString(), metricsFlags, periodicity]));
};

export const useDrawdownData = (dateRange: Dayjs[], periodicity: number, threshold: number) => {
    return useData(useCallback(() => accountService.GetAccountDrawdown(dateRange[0].toDate(), dateRange[1].toDate(), periodicity, threshold), [dateRange[0].toISOString(), dateRange[1].toISOString(), threshold, periodicity]));
};

export const useExposureData = (aggType: AggregationType) => {
    return useData(useCallback(() => accountService.GetAccountExposure(aggType), [aggType]));
};

export const useExposureHistoryData = (goingBackTo: Date, mostRecent: Date, periodicity: MetricPeriodicity, aggType: AggregationType) => {
    return useData(useCallback(() => accountService.GetAccountExposureHistory(goingBackTo, mostRecent, periodicity, aggType), [goingBackTo.toISOString(), mostRecent.toISOString(), periodicity, aggType]));
};

export const useRiskFreeRatesData = () => {
    return useData(useCallback(() => accountService.GetRiskFreeRates(), []));
};

export const usePerformScenarioAnalysisData = (pairId: string | undefined, side: PositionSide, notional: number, vaR: any, sharpeAndSortino: any) => {
    return useData(useCallback(() => accountService.PerformScenarioAnalysis(pairId, side, notional, vaR, sharpeAndSortino), [pairId, side, notional, JSON.stringify(vaR), JSON.stringify(sharpeAndSortino)]));
};