import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AppStateContext } from '../appContext';
import { alertIcon, favIcon, infoIcon, marketAddIcon, marketRemoveIcon, newsSmallIcon, orderIcon } from '../assets/icons';
import { PairInfo } from '../models';
import { PairInfoTab } from './pages/Main/components/PairInfoView/PairInfoView';
import { TooltipItem } from './TootlipItem';
import { useTranslation } from 'react-i18next';

interface PairActionsProps {
    pair: PairInfo;
    tab: PairInfoTab | boolean;
    isSelected: boolean;
    onInfo: (tab: string) => void;
    layout?: "horizontal" | "vertical";
    showOrder?: boolean;
}

const PairActions: React.FC<PairActionsProps> = observer(({ pair, tab, isSelected, layout, onInfo, showOrder }) => {
    const context = useContext(AppStateContext);
    const { t } = useTranslation();
    const favorites = context.account.favoritesSet;
    const markets = context.markets;
    const isMarket = markets.includes(pair.id);
    const isFav = favorites.has(pair.id);
    const marketIcon = isMarket ? marketRemoveIcon : marketAddIcon;
    const marketText = isMarket ? 'actions.del_market' : 'actions.add_market';
    if (layout === undefined) {
        layout = "horizontal";
    }

    const tooltopPosition = layout === "horizontal" ? "bottomLeft" : "right";

    const favText = isFav ? 'actions.del_favs' : 'actions.add_favs';

    const favClass = classNames('info action-fav', { active: isFav });
    const marketClass = classNames('info action-market');
    
    const orderClass = classNames('info action-info', { active: tab === 'order' && isSelected });
    const infoClass = classNames('info action-info', { active: tab === 'info' && isSelected });
    const newsClass = classNames('info action-news', { active: tab === 'news' && isSelected });
    const alertClass = classNames('info action-alert', { active: tab === 'alert' && isSelected });

    const onAction = (pair: PairInfo, action: string) => {
        if (action === 'fav') {
            context.toggleFavorite(pair);
        } else if (action === 'plus') {
            context.toggleMarket(pair.id);
        }
    }

    return (
        <div className={classNames('actions', 'layout-'+layout)}>
            {showOrder && <button className={orderClass} onClick={() => onInfo('order')}>
                <TooltipItem text={t('actions.order')} position={tooltopPosition}>
                    {orderIcon}
                </TooltipItem>
            </button>}
            <button className={newsClass} onClick={() => onInfo('news')}>
                <TooltipItem text={t('actions.news')} position={tooltopPosition}>
                    {newsSmallIcon}
                </TooltipItem>
            </button>
            <button className={infoClass} onClick={() => onInfo('info')}>
                <TooltipItem text={t('actions.info')} position={tooltopPosition}>
                    {infoIcon}
                </TooltipItem>
            </button>
            <button className={marketClass} onClick={() => onAction(pair, 'plus')}>
                <TooltipItem text={t(marketText)} position={tooltopPosition}>
                    {marketIcon}
                </TooltipItem>
            </button>
            <button className={favClass} onClick={() => onAction(pair, 'fav')}>
                <TooltipItem text={t(favText)}  position={tooltopPosition}>
                    {favIcon}
                </TooltipItem>
            </button>
            <button className={alertClass} onClick={() => onInfo('alert')}>
                <TooltipItem text={t('actions.alert')} position={tooltopPosition}>
                    {alertIcon}
                </TooltipItem>
            </button>
        </div>
    );
});

export default PairActions;