import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { AccountCurrencyType, AppStateContext, MenuItem } from '../../../appContext';
import { showErrorNotification } from '../../../common/notifications';
import { PairInfo } from '../../../models';
import { assetsService } from '../../../services';
import { withParams } from '../../../utils';
import Loading from '../../Loading';
import PairActions from '../../PairActions';
import PairInfoView, { PairInfoTab } from '../Main/components/PairInfoView/PairInfoView';
import Trading from '../Main/components/Trading/Trading';
import ClosedOrders from './Orders/ClosedOrders';
import OpenOrders from './Orders/OpenOrders';
import ClosedPositions from './Positions/ClosedPositions';
import OpenPositions from './Positions/OpenPositions';
import './TradingPage.css';


interface TradingPageProps extends WithTranslation {
    params: any;
}

interface TradingPageState {
    pair?: PairInfo,
    clientReady: boolean,
    isLoading: boolean,
    tab: PairInfoTab,
    activePositionsTab: string,
    activeOrdersTab: string,
    currency: AccountCurrencyType,
}

export const TradingPage = observer(class TradingPage extends Component<TradingPageProps, TradingPageState> {
    static displayName = TradingPage.name;
    pairId: string;
    context!: ContextType<typeof AppStateContext>;

    constructor(props: TradingPageProps) {
        super(props);
        this.pairId = props.params.pairId;
        this.state = {
            pair: undefined,
            clientReady: false,
            isLoading: true,
            tab: PairInfoTab.Order,
            activePositionsTab: '1',
            activeOrdersTab: '1',
            currency: 'account',
        };
    }

    componentDidMount() {
        if (this.context.clientReady) {
            this.loadData();
        }
        this.context.setBottomMenu(this.getNav());
    }

    componentDidUpdate(prevProps: TradingPageProps, prevState: TradingPageState) {
        if (this.context.clientReady && !this.state.clientReady) {
            this.setState({ clientReady: true }, () => {
                this.loadData();
            });
        } else if (this.context.clientReady && prevProps.params !== this.props.params) {
            this.pairId = this.props.params.pairId;
            this.loadData();
        }

        if (this.props.params !== prevProps.params) {
            this.context.setTitle(this.pairId, this.props.t('menu.myInstruments'));
            this.context.setBottomMenu(this.getNav());
        }
    }

    async loadData() {
        try {
            const pair = await assetsService.GetPairInfo(this.pairId);
            const newState = { pair, isLoading: false } as TradingPageState;
            if (!this.state.currency) {
                newState['currency'] = 'quote';
            }
            this.setState(newState);
            this.context.quotes.changeSubscription([pair.id], []);
            if (this.context.markets.indexOf(pair.id) === -1) {
                this.context.markets.push(pair.id);
            }
        } catch (error) {
            showErrorNotification(error.message);
        }
    }

    componentWillUnmount() {
        if (this.state.pair) {
            this.context.quotes.changeSubscription([], [this.state.pair.id]);
        }
        this.context.setBottomMenu(null);
    }

    getNav(): MenuItem[] {
        return this.context.markets.map((pairId) => {
            return {
                path: "/trading/" + encodeURIComponent(pairId),
                title: pairId,
                key: pairId
            };
        });
    }

    render() {
        const { isLoading, pair } = this.state;
        const isReady = this.context.clientReady;
        const quotes = this.context.quotes.values;
        const quote = quotes.get(this.pairId);

        if (!isReady || isLoading) {
            return <Loading />
        }

        pair?.updateQuote(quote);

        return (
            <Container fluid className='TradingPage'>
                <Row>
                    <Col>
                        <div className="main">
                            <div className='graph-pane'>
                                {this.graphPaneContent()}
                            </div>
                            <div className='positions-pane'>
                                {this.positionsConent()}
                            </div>
                            <div className='orders-pane'>
                                {this.ordersConent()}
                            </div>
                        </div>
                    </Col>
                    <Col className="sidebar">
                        <div className='content full-height pair'>
                            {this.sideBarConent()}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    toggleCurrency(usePairCurrency: boolean) {
        this.setState({ currency: usePairCurrency ? 'quote' : 'account' });
    }

    positionsConent() {
        const { pair, activePositionsTab, currency } = this.state;
        const isAcc = currency === 'account';
        const { account } = this.context;
        if (!pair) {
            return <></>
        }

        return <div className='content'>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classNames({ active: activePositionsTab === '1' })}
                        onClick={() => this.setState({ activePositionsTab: '1' })}
                    >
                        {this.props.t('portfolio.tabs.open_positions')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classNames({ active: activePositionsTab === '2' })}
                        onClick={() => this.setState({ activePositionsTab: '2' })}
                    >
                        {this.props.t('portfolio.tabs.closed_positions')}
                    </NavLink>
                </NavItem>
                <NavItem className='ms-auto'>
                    {pair.quoteId !== account.denominatedIn &&
                        <div className="form-check form-switch nav-link">
                            <label htmlFor='currencyToggler' className={classNames({ active: isAcc })}>{account.denominatedIn}</label>
                            <input onChange={(e) => this.toggleCurrency(e.target.checked)} className="form-check-input" id="currencyToggler" name="currencyToggler" type="checkbox" checked={!isAcc} />
                            <label htmlFor='currencyToggler' className={classNames({ active: !isAcc })}>{pair.quoteId}</label>
                        </div>}
                </NavItem>
            </Nav>
            <TabContent activeTab={activePositionsTab}>
                <TabPane tabId="1">
                    <OpenPositions pairId={pair.id} currency={this.state.currency!}></OpenPositions>
                </TabPane>
                <TabPane tabId="2">
                    <ClosedPositions pairId={pair.id} currency={this.state.currency!}></ClosedPositions>
                </TabPane>
            </TabContent>
        </div>
    }

    ordersConent() {
        const { pair, activeOrdersTab } = this.state;
        if (!pair) {
            return <></>
        }
        return <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classNames({ active: activeOrdersTab === '1' })}
                        onClick={() => this.setState({ activeOrdersTab: '1' })}
                    >
                        {this.props.t('portfolio.tabs.orders')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classNames({ active: activeOrdersTab === '2' })}
                        onClick={() => this.setState({ activeOrdersTab: '2' })}
                    >
                        {this.props.t('portfolio.tabs.order_history')}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeOrdersTab}>
                <TabPane tabId="1">
                    <OpenOrders pairId={pair.id} currency={this.state.currency}></OpenOrders>
                </TabPane>
                <TabPane tabId="2">
                    <ClosedOrders pairId={pair.id} currency={this.state.currency}></ClosedOrders>
                </TabPane>
            </TabContent>
        </div>
    }

    graphPaneContent() {
        return <div className='content'>
            <Trading pair={this.state.pair!} barCount={this.context.isMobile ? 30 : 120} ></Trading>
        </div>
    }

    onInfo(tab: PairInfoTab) {
        this.setState({ tab });
    }

    sideBarConent() {
        const { pair, tab } = this.state;
        return <>
            <PairActions showOrder={true} layout='vertical' pair={pair!} tab={tab} isSelected={true} onInfo={(tab: PairInfoTab) => this.onInfo(tab)}></PairActions>
            <PairInfoView pair={pair!} tab={tab}></PairInfoView>
        </>
    }
})

TradingPage.contextType = AppStateContext;
export default withTranslation()(withParams(TradingPage));