import { MarginCallPolicy, Position, LyraSequential, Account } from "./";


export enum MarginCallStatus {
    Unknown = 'Unknown',
    Pending = 'Pending',
    ResolvedMarket = 'ResolvedMarket',
    ResolvedFunding = 'ResolvedFunding',
    ResolvedIgnored = 'ResolvedIgnored',
    ResolvedManualCloseout = 'ResolvedManualCloseout',
    ResolvedAutoCloseout = 'ResolvedAutoCloseout',
    NotificationIssued = 'NotificationIssued'
}


export enum MarginCallKind {
    Unknown = 'Unknown',
    Warning = 'Warning',
    Critical = 'Critical',
    Liquidation = 'Liquidation',
}

export class MarginCall implements LyraSequential {

    constructor(data: Object, public accountSymbol: string, public accountDigits: number) {
        Object.assign(this, data);
    }
    id: number;
    token: string;
    accountId: number;
    account: Account;
    policyId: number;
    policy: MarginCallPolicy;
    cash: number;
    status: MarginCallStatus;
    kind: MarginCallKind;
    positionCount: number;
    unrealizedPnl: number;
    totalMargin: number;
    totalValue: number;
    netEquity: number;
    availableToTrade: number;
    marginIndicator: number;
    issuedOn: Date;
    userNotifiedOn: Date;
    positions: Position[];
}
