import { observer } from "mobx-react-lite";
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import Select, { Options } from 'react-select';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { AppStateContext } from '../../../../appContext';
import { PositionSide } from "../../../../models";
import { SortableProperty } from "../../../../services";
import { useOpenPositionsData } from "../../../../services/hooks";
import Loading from '../../../Loading';
import { OpenPositionsView } from '../../../common/Positions/Open/OpenPositionsView';
import { PorfolioTabPropsContextType } from "../PortfolioPage";
import { useOutletContext } from "react-router";

export const OpenPositionsSortColumns = [
    {
        key: SortableProperty.PairId,
        label: 'portfolio.positions.ticker'
    },
    {
        key: SortableProperty.PositionUnrealizedPnl,
        label: 'portfolio.positions.unrealized'
    },
    {
        key: SortableProperty.PositionOpeningValue,
        label: 'portfolio.positions.opening_value'
    },
    {
        key: SortableProperty.PositionCurrentValue,
        label: 'portfolio.positions.current_value'
    },
    {
        key: SortableProperty.PositionMargin,
        label: 'portfolio.positions.margin'
    },
    {
        key: SortableProperty.CreatedAt,
        label: 'portfolio.positions.created_at'
    },
];

export const OpenPositionsTabFilters: React.FC = ({ handleFilters }: { handleFilters: CallableFunction }) => {
    const [side, setSide] = React.useState<any>(undefined);
    const { t } = useTranslation();
    const sideOptions: Options<{ value: string, label: string }> = [{ value: PositionSide.Long, label: 'Long' }, { value: PositionSide.Short, label: 'Short' }];
    const handleSide = (selectedOption: any) => {
        setSide(selectedOption);
    }

    React.useEffect(() => {
        handleFilters({ side: side?.value });
    }, [side]);

    return <div style={{ minWidth: '110px' }}>
        <Select classNamePrefix='select' value={side} options={sideOptions} isClearable={true} placeholder={t('portfolio.common.select_side')} onChange={handleSide} />
    </div>
}

export const OpenPositionsTab: React.FC<{}> = observer(() => {
    const {currency, ...rest} = useOutletContext<PorfolioTabPropsContextType>();

    const context = useContext(AppStateContext);
    const { data: positions, loading } = useOpenPositionsData(rest);
    if (loading || !positions) {
        return <Loading />
    }

    return <OpenPositionsView positions={positions} currency={currency} fullView={!context.isAdaptive} />
});
