export class NewsItem {
    constructor(data: Object) {
        Object.assign(this, data);
    }
    id: string;
    snippet: string;
    text: string;
    url: string;
    title: string;
    tags: string[];
    imageUrl: string;
    language: string;
    source: string;
    publishedAt : Date;
    industry : string;
}