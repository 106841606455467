import classNames from 'classnames';
import React, { useContext, useState } from 'react';

import { observer } from "mobx-react-lite";
import { AppStateContext } from '../../../../appContext';
import usePositionViewData from '../usePositionViewData';
import { OpenPositionRowProps, OpenPositionsViewInternalProps } from './OpenPositionsView';


const OpenPositionRowBlock: React.FC<OpenPositionRowProps> = observer(({ currency, position, showTransaction, showOrder }) => {
    const [isEditing, setEditing] = useState(false);
    const { side, totalStopLoss, totalTakeProfit } = position;
    const context = useContext(AppStateContext);
    const currencyAccount = context.account.denominatedIn;
    const symbolAccount = context.account.symbol;
    const isDirect = currency === currencyAccount;
    const sideClass = classNames('item inline-form', `side-${side}`);

    // TODO: convert to current currency
    const [totalStopLossValue, setTotalStopLossValue] = useState<number>(totalStopLoss);
    const [totalTakeProfitValue, setTotalTakeProfitValue] = useState<number>(totalTakeProfit);

    const columns = usePositionViewData({ isDirect, showTransaction, showOrder });

    return (
        <div className={sideClass}>
            <div className='ticker left'>
                {columns.ticker.render(position)}
            </div>
            <div className='data'>
                <div className='data-2 data-item'>
                    <div className='cell'>
                        <div className="header">{columns.unrealized.title}</div>
                        {columns.unrealized.render(position)}
                    </div>
                    <div className='cell'>
                        <div className="header">{columns.margin.title}</div>
                        {columns.margin.render(position)}
                    </div>
                </div>
                <div className='data-3 data-item'>
                    <div className='combined'>
                        <div className='cell left'>
                            <div className="header with-divider">{columns.opening_value.title}</div>
                            <div className="with-divider">{columns.opening_value.render(position)}</div>
                        </div>
                        <div className='cell right'>
                            <div className="header">{columns.opening_price.title}</div>
                            {columns.opening_price.render(position)}
                        </div>
                    </div>
                </div>
                <div className='data-4 data-item'>
                    <div className='combined'>
                        <div className='cell left'>
                            <div className="header with-divider">{columns.current_value.title}</div>
                            <div className="with-divider">{columns.current_value.render(position)}</div>
                        </div>
                        <div className='cell right'>
                            <div className="header">{columns.current_price.title}</div>
                            {columns.current_price.render(position)}
                        </div>
                    </div>
                </div>
                <div className='data-5 data-item'>
                    <div className='cell'>
                        <div className="header">{columns.total_stop_loss.title}</div>
                        {!isEditing && totalStopLoss && columns.total_stop_loss.render(position)}
                        {isEditing && <div className='inline-form-group'>{<input name="totalStopLoss" className="form-control" defaultValue={totalStopLossValue} onChange={(e: any) => setTotalStopLossValue(e.target.value)} type="number" />} {symbolAccount}</div>}
                    </div>
                    <div className='cell'>
                        <div className="header">{columns.total_take_profit.title}</div>
                        {!isEditing && totalTakeProfit && columns.total_take_profit.render(position)}
                        {isEditing && <div className='inline-form-group'>{<input name="totalTakeProfit" className="form-control" defaultValue={totalTakeProfitValue} onChange={(e: any) => setTotalTakeProfitValue(e.target.value)} type="number" />} {symbolAccount}</div>}
                    </div>
                </div>
            </div>
            {columns.edit_actions.render(position)}
        </div>
    )
});


export const OpenPositionsViewBlocks: React.FC<OpenPositionsViewInternalProps> = observer((props) => {
    const { positions, ...rest } = props;
    return <div className='positions responsive-data-table data-table portfolio-table'>
        {props.positions.map(position => <OpenPositionRowBlock position={position} key={position.id} {...rest} />)}
    </div>
});
