import { useCallback, useContext } from 'react';
import { assetsService, orderService } from '..';
import { AppStateContext } from '../../appContext';
import { Pagination, usePaginationData } from './usePaginationData';
import { Alert } from '../../models';

type AlertsRequestDataParams = {
    pairId?: string;
    updateAt?: Date;
}

export const useAlertsData = (params: AlertsRequestDataParams) => {
    const context = useContext(AppStateContext);
    const loadingFunction = useCallback(async (pagination: Pagination) => {
        const request = {  } as any;
        if (params.pairId) {
            request.pairId = params.pairId;
        }
        const [result, total] = await orderService.AlertList(context.account, request);
        const extendedItems = await assetsService.ExtendPairInfo(result);
        return {data: extendedItems, totalCount: total};
    }, [params.pairId, params.updateAt]);

    const result = usePaginationData<Alert[]>(loadingFunction);

    return result;
};