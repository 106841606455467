import { Button, Col, Layout, Row } from 'antd';
import cx from "classnames";
import { observer } from "mobx-react-lite";
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";

import { AppStateContext } from "src/appContext";
import { UnitValueField } from "src/components/fields";
import { VaRMethod, VarSubvalue } from "src/models";
import { useVaRData } from "src/services/hooks";
import { useConfidence, useMethod, useWindow } from "../../filters";
import { useMonteCarloOptions } from "../../filters/mcOptions";

import { arrowDownIcon, arrowUpIcon } from 'src/assets/icons';
import styles from './VaRTab.module.scss';
import { CVaRDescription } from './description/cvar';
import { VaRDescription } from './description/var';
import { MCTR } from './mctr';

const { Content } = Layout;

const SCORE_GOOD_THRESHOLD = 10;
const SCORE_MEDIUM_THRESHOLD = 20;


export const SummaryTab = observer(({ subvalue }: { subvalue: VarSubvalue }) => {

    const { account, balance } = useContext(AppStateContext);
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = React.useState(false);

    const [windowComponent, window] = useWindow();
    const [methodComponent, method] = useMethod();
    const [confidenceComponent, confidence] = useConfidence();
    const [monteCarloComponent, numberOfPaths, horizon] = useMonteCarloOptions();
    const symbol = account.symbol;

    const { loading, data } = useVaRData(method, window, confidence, numberOfPaths, horizon, showDetails);


    const varValue: number = data === undefined || data[subvalue] === undefined ? 0 : data[subvalue] ?? 0;

    const percent = Math.abs(varValue) / balance.totalValue * 100;

    const scoreClassName = cx({
        [styles.scoreGood]: percent < SCORE_GOOD_THRESHOLD,
        [styles.scoreMedium]: percent >= SCORE_GOOD_THRESHOLD && percent < SCORE_MEDIUM_THRESHOLD,
        [styles.scoreBad]: percent >= SCORE_MEDIUM_THRESHOLD,
    });

    return (
        <Content className={cx(styles.base, styles.container)}>
            <Row justify="start">
                <Col className={styles.filtersContainer}>
                    <div>{methodComponent}</div>
                    <div>{windowComponent}</div>
                    <div>{confidenceComponent}</div>
                    {method === VaRMethod.MonteCarlo && monteCarloComponent}
                </Col>
                <Col className="extra-column" flex="auto">
                    <div>
                        <Button color="secondary" className="btn-icon" onClick={() => setShowDetails(!showDetails)}>{t('var.fields.mctr')}
                            {showDetails ? arrowUpIcon : arrowDownIcon}
                        </Button>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={styles.scoreContainer}>
                    <label className={styles.scoreLabel}>{t('var.fields.score')}:</label>
                    <div className={styles.score}>
                        <UnitValueField
                            className={scoreClassName}
                            value={varValue}
                            unit={symbol}
                            precision={account.displayDecimals}
                            loading={loading}
                        />
                        (<UnitValueField
                            value={percent}
                            unit="%"
                            precision={2}
                            loading={loading}
                        />)
                        <sup>*</sup>
                    </div>
                    <span className={styles.smallInfo}><sup>*</sup> - {t('var.fields.fromTotal')}</span>
                </Col>
            </Row>
            {showDetails && data && <div>
                <MCTR subvalue={subvalue} data={data} />
            </div >}

            <div className={styles.typography}>
                {subvalue === VarSubvalue.VaR && <VaRDescription />}
                {subvalue === VarSubvalue.CVaR && <CVaRDescription />}
            </div>
        </Content>
    )
});
