import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, InputRef, Select, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RateItem } from '../../../../models';
import { useRiskFreeRatesData } from '../../../../services/hooks';


type RiskOptionType = {
    value: number,
    data: RateItem,
    label: JSX.Element
}

export const useRisk = (): [JSX.Element, number | undefined] => {
    const { t } = useTranslation();
    const [riskOptions, setRiskOptions] = useState<RiskOptionType[]>([]);
    const [risk, setRisk] = useState<number | undefined>();

    const [newValue, setNewValue] = useState('');
    const inputRef = useRef<InputRef>(null);
    const [open, setOpen] = useState(false);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewValue(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        const value = parseFloat(newValue);
        if (isNaN(value) || riskOptions.filter((item: any) => item.value === value / 100).length > 0) {
            setRisk(value / 100);
            setOpen(false);
            setNewValue('');
            return;
        }

        const newItem: RiskOptionType = {
            value: parseFloat(newValue) / 100,
            label: <><span className="text-number">{(parseFloat(newValue)).toFixed(4)}%</span></>,
            data: { yield: parseFloat(newValue) / 100, country: '', symbol: '' } as RateItem,
        };

        // e.preventDefault();
        setRiskOptions([...riskOptions, newItem]);
        setNewValue('');
        setRisk(newItem.value);
        setOpen(false);
        // setTimeout(() => {
        //     inputRef.current?.focus();
        // }, 0);
    };


    const { data: riskRatesData } = useRiskFreeRatesData();
    useEffect(() => {
        if (riskRatesData && riskRatesData.length > 0) {
            const riskOptions: Array<{ value: number, data: RateItem }> = riskRatesData?.map(item => ({ value: item.yield, data: item, label: <><span className="text-number">{(item.yield * 100).toFixed(4)}%</span> {item.country} ({item.symbol})</> }));
            setRiskOptions(riskOptions as never[]);
            setRisk(riskOptions[0].value);
        }
    }, [riskRatesData]);

    const component = <>
        <label htmlFor="risk">{t('var.fields.riskFreeRate')}</label>
        <Select
            // className='number-select'
            popupClassName='number-select-fixed'
            style={{ width: '100%' }}
            options={riskOptions}
            value={risk}
            open={open}
            onDropdownVisibleChange={(visible) => setOpen(visible)}
            onChange={setRisk}
            optionRender={(option) => {
                return (
                    <><span className="text-number">{(option.data.data.yield * 100).toFixed(4)}%</span> {option.data.data.country} {option.data.data.symbol && <>({option.data.data.symbol})</>}</>
                )
            }}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                        <Input
                            placeholder={t('var.fields.customValue')}
                            ref={inputRef}
                            value={newValue}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={addItem} />
                    </Space>
                </>
            )}
        />
    </>

    return [component, risk];
};