import React from 'react';
import Select from 'react-select';
import { useField } from 'formik';

function SelectField(props: any) {
  const [field, state, { setValue, setTouched }] = useField(props.field.name);

  const onChange = ({ value }: any) => {
    setValue(value);
  };

  return (
    <Select
      {...props}
      onChange={onChange}
      onBlur={setTouched}
      value={props.options ? props.options.find((option: any) => option.value === field.value) : ''}
    />
  );
}

export default SelectField;