import dayjs from 'dayjs';
import { BarsInfo, CandlestickData, CandlestickSeriesPartialOptions, ColorType, createChart, IChartApi, ISeriesApi, LineStyle, MouseEventParams, OhlcData, Time } from 'lightweight-charts';
import React from 'react';
import { PairInfo, TechIndicator, TechIndicatorsMap, TechIndicatorsNames } from '../../models';
import { quotesService } from '../../services';
import { formatNumber, getFontSize } from '../../utils';
import Loading from '../Loading';

import { WithTranslation } from 'react-i18next';
import "./Chart.scss";
import { ADXIndicator, BandsIndicator, EMAIndicator, ITechIndicator, MACDIndicator, ROCIndicator, RSIIndicator, RVolIndicator, SMAIndicator, StochIndicator, VolumeIndicator } from './Indicators';


export interface CandlestickVolumeData extends CandlestickData {
    volume: number;
}

export interface OhlcVolumeData extends OhlcData {
    value: number;
};

const minute = 60;
const hour = 60 * 60;
const day = 60 * 60 * 24;

export const HistoryFreq = {
    Min1: 1 * minute,
    Min2: 2 * minute,
    Min3: 3 * minute,
    Min5: 5 * minute,
    Min10: 10 * minute,
    Min15: 15 * minute,
    Min30: 30 * minute,
    Hr1: 1 * hour,
    Hr2: 2 * hour,
    Hr4: 4 * hour,
    Hr8: 8 * hour,
    Day1: 1 * day,
    Week1: 7 * day,
    Month1: 30 * day,
}

const FrequencyDateFormat = {
    Min1: 'HH:mm',
    Min2: 'HH:mm',
    Min3: 'HH:mm',
    Min5: 'HH:mm',
    Min10: 'HH:mm',
    Min15: 'HH:mm',
    Min30: 'HH:mm',
    Hr1: 'MMM DD HH:00',
    Hr2: 'MMM DD HH:00',
    Hr4: 'MMM DD HH:00',
    Hr8: 'MMM DD HH:00',
    Day1: 'YYYY MMM DD',
    Week1: 'YYYY MMM DD',
    Month1: 'YYYY MMM DD',
}

interface QuotesChartProps extends WithTranslation {
    pair: PairInfo;
    isLoading: boolean;
    indicators: TechIndicator;
    frequency: keyof typeof HistoryFreq;
    onTimeRangeChanged: (bars: BarsInfo<Time> | null) => void;
}

export class QuotesChartComponent extends React.Component<QuotesChartProps> {
    chartContainerRef: React.RefObject<HTMLDivElement>;
    tooltipRef: React.RefObject<HTMLDivElement>;
    chart: IChartApi | null;
    series: ISeriesApi<"Candlestick"> | null;
    indicators: Map<TechIndicator, ITechIndicator>;
    data: CandlestickVolumeData[] = [];

    constructor(props: QuotesChartProps) {
        super(props);
        this.chartContainerRef = React.createRef();
        this.tooltipRef = React.createRef();
        this.chart = null;
        this.series = null;
        this.handleResize = this.handleResize.bind(this);
        this.indicators = new Map<TechIndicator, ITechIndicator>();
    }

    componentDidUpdate(prevProps: Readonly<QuotesChartProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.frequency !== this.props.frequency) {
            const timeScale = this.chart!.timeScale();
            const timeVisisble = this.props.frequency.startsWith('Min');
            timeScale.applyOptions({ timeVisible: timeVisisble, secondsVisible: false });
        }

        if (prevProps.indicators !== this.props.indicators) {
            this.applyIndicators();
        }
    }

    getIndicator(key: TechIndicator): any | null {
        const allIndicators = {
            [TechIndicator.ADX]: [ADXIndicator, {}],
            [TechIndicator.SMA]: [SMAIndicator, {}],
            [TechIndicator.EMA]: [EMAIndicator, {}],
            [TechIndicator.MACD]: [MACDIndicator, {}],
            [TechIndicator.BBands]: [BandsIndicator, {}],
            [TechIndicator.ROC]: [ROCIndicator, {}],
            [TechIndicator.RSI]: [RSIIndicator, {}],
            [TechIndicator.Volume]: [VolumeIndicator, {}],
            [TechIndicator.RVOL]: [RVolIndicator, {}],
            [TechIndicator.Stoch]: [StochIndicator, {}],
            // [TechIndicator.EMA]: [ServerIndicator, {extractor: fieldExtractor('ema')}],
        }
        if (Object.keys(allIndicators).indexOf(key.toString()) === -1) {
            return null;
        }

        return allIndicators[key as keyof typeof allIndicators];
    }

    applyIndicators() {
        TechIndicatorsMap.forEach(([key, value]) => {
            const current = this.indicators.get(value);
            if (current && !(this.props.indicators & value)) {
                current.detach();

                this.indicators.delete(value);
            } else if (!current && (this.props.indicators & value)) {
                const indicator = this.getIndicator(value);
                if (!indicator) {
                    return;
                }

                const indicatorInstance = new indicator[0](indicator[1]);
                indicatorInstance.attach(this.chart!, this.series!);
                indicatorInstance.updateData(this.data);

                this.indicators.set(value, indicatorInstance);
            }
        });
        this.chart!.timeScale().fitContent();
    }

    componentDidMount() {
        const bgColor = '#202024';
        const textColor = '#908ca3';
        const pxSize = getFontSize();
        this.chart = createChart(this.chartContainerRef.current!, {
            layout: {
                background: { type: ColorType.Solid, color: bgColor },
                textColor: textColor,
                attributionLogo: false,
                fontSize: pxSize,
            },
            grid: {
                vertLines: {
                    color: '#908ca3',
                },
                horzLines: {
                    color: '#908ca3',
                },
            },
            autoSize: true,
            crosshair: {
                horzLine: {
                    visible: true,
                    labelVisible: true,
                    color: 'white',
                    width: 1,
                },
                vertLine: {
                    labelVisible: true,
                    style: LineStyle.Solid,
                    color: 'white',
                    width: 1,
                },
            },
            // width: this.chartContainerRef.current.clientWidth,
            // height: this.chartContainerRef.current.clientHeight,
        });
        this.series = this.chart.addCandlestickSeries({
            upColor: '#26a69a', downColor: '#ef5350', borderVisible: false,
            wickUpColor: '#26a69a', wickDownColor: '#ef5350',
        });
        this.series.applyOptions({
            priceFormat: {
                type: 'custom',
                // precision: this.props.pair.pairDecimals
                formatter: (price: number) => formatNumber(price, this.props.pair.pairDecimals),
            },
        } as CandlestickSeriesPartialOptions);
        this.series.priceScale().applyOptions({
            scaleMargins: {
                // positioning the price scale for the area series
                top: 0.1,
                bottom: 0.25,
            }
        });

        const timeScale = this.chart.timeScale();
        const timeVisisble = this.props.frequency.startsWith('Min') || this.props.frequency.startsWith('Hr');
        timeScale.applyOptions({ timeVisible: timeVisisble, secondsVisible: false });

        this.applyIndicators();

        timeScale.subscribeVisibleLogicalRangeChange(() => {
            const visibleRange = this.chart!.timeScale().getVisibleLogicalRange();
            const bars = this.series!.barsInLogicalRange(visibleRange!);

            if (this.props.onTimeRangeChanged) {
                this.props.onTimeRangeChanged(bars);
            }
        });

        const toolTip = this.tooltipRef.current!;
        const container = this.chartContainerRef.current!;
        const toolTipMargin = 35;

        this.chart.subscribeCrosshairMove((param: MouseEventParams) => {
            if (
                param.point === undefined ||
                !param.time ||
                param.point.x < 0 ||
                param.point.x > container.clientWidth ||
                param.point.y < 0 ||
                param.point.y > container.clientHeight
            ) {
                toolTip.style.display = 'none';
            } else {
                const dt = new Date(((param.time as number) + quotesService.timeZoneOffset) * 1000);
                const dateStr = dayjs(dt).format(FrequencyDateFormat[this.props.frequency]);
                toolTip.style.display = 'block';
                const data = param.seriesData.get(this.series!) as OhlcData;
                // const volumeData = param.seriesData.get(this.volumeSeries!) as SingleValueData;

                const quoteSymbol = this.props.pair.quoteSymbol;
                const digits = this.props.pair.pairDecimals;
                const baseSymbol = this.props.pair.baseSymbol;

                // const volume = formatNumber(volumeData?.value, undefined);
                const open = formatNumber(data.open, digits);
                const high = formatNumber(data.high, digits);
                const close = formatNumber(data.close, digits);
                const low = formatNumber(data.low, digits);
                const diff = formatNumber(data.close - data.open, digits);
                const directionClass = data.open > data.close ? 'Rising' : 'Falling';

                let tooltip = '';
                this.indicators.forEach((indicator, key) => {
                    const value = indicator.getTooltip(param, { quoteSymbol, digits, baseSymbol });
                    if (value) {
                        const label = this.props.t('enum.TechIndicator.' + TechIndicatorsNames.get(key));
                        tooltip += `<div class="item"><b style="color: ${indicator.color}">${label}:</b> ${value}</div>`
                    }
                });

                toolTip.innerHTML = `
                    <div class="items ${directionClass}">
                        <div class="item date text-date">${dateStr}</div>
                        <div class="item"><b>Open:</b> <span class="value suffix-value"><span class="val number text-number">${open}</span><span class="suffix">${quoteSymbol}</span></div>
                        <div class="item"><b>High:</b> <span class="value suffix-value"><span class="val number text-number">${high}</span><span class="suffix">${quoteSymbol}</span></div>
                        <div class="item"><b>Close:</b> <span class="value suffix-value"><span class="val number text-number">${close}</span><span class="suffix">${quoteSymbol}</span></div>
                        <div class="item"><b>Low:</b> <span class="value suffix-value"><span class="val number text-number">${low}</span><span class="suffix">${quoteSymbol}</span></div>
                        <div class="item"><b>Change:</b> <span class="value suffix-value"><span class="val number text-number">${diff}</span><span class="suffix">${quoteSymbol}</span></div>
                        ${tooltip}
                    </div>`;
                let left = param.point.x + toolTipMargin;
                if (left > container.clientWidth - toolTip.clientWidth) {
                    left = param.point.x - toolTip.clientWidth;
                }

                let top = param.point.y + toolTipMargin;
                if (top > container.clientHeight - toolTip.clientHeight) {
                    top = param.point.y - toolTip.clientHeight;
                }
                toolTip.style.left = left + 'px';
                toolTip.style.top = top + 'px';
            }
        });

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        if (this.chart) {
            this.chart.remove();
            this.chart = null;
        }
    }

    handleResize() {
        this.chart!.applyOptions({
            width: this.chartContainerRef.current!.clientWidth,
            height: this.chartContainerRef.current!.clientHeight
        });
    }

    setData(data: CandlestickVolumeData[]) {
        this.data = data;
        this.series!.setData(data);
        this.indicators.forEach((indicator, key) => {
            indicator.updateData(data);
        });
        this.chart!.timeScale().fitContent();
    }

    fitContent() {
        this.chart!.timeScale().fitContent();
    }

    updateData(data: CandlestickVolumeData) {
        this.series!.update(data);
        if (data.volume) {
            this.indicators.forEach((indicator, key) => {
                indicator.update(data);
            });
        }
    }

    render() {
        return <div className='chart-wrapper'>
            <div className='chart-tooltip' ref={this.tooltipRef}></div>
            {this.props.isLoading && <Loading />}
            <div className="chart-container" ref={this.chartContainerRef} /></div>;
    }
}