import { observer } from "mobx-react-lite";
import React, { useContext } from 'react';
import { AppStateContext } from '../../../../appContext';
import { useClosedPositionsData } from '../../../../services/hooks';
import Loading from '../../../Loading';
import { TablePaginator } from '../../../TablePaginator';
import { ClosedPositionsView } from '../../../common/Positions/Closed/ClosedPositionsView';
import { SortableProperty } from "../../../../services";
import { PorfolioTabPropsContextType } from "../PortfolioPage";
import { useOutletContext } from "react-router";

export const ClosedPositionsSortColumns = [
    {
        key: SortableProperty.PairId,
        label: 'portfolio.positions.ticker'
    },
    {
        key: SortableProperty.PositionRealizedPnL,
        label: 'portfolio.positions.realized'
    },
    {
        key: SortableProperty.PositionOpeningValue,
        label: 'portfolio.positions.opening_value'
    },
    {
        key: SortableProperty.PositionCurrentValue,
        label: 'portfolio.positions.current_value'
    },
    {
        key: SortableProperty.PositionMargin,
        label: 'portfolio.positions.margin'
    },
    {
        key: SortableProperty.CreatedAt,
        label: 'portfolio.positions.created_at'
    },
];

const ClosedPositionsTab: React.FC<{}> = observer(() => {
    const context = useContext(AppStateContext);
    const {currency, ...rest} = useOutletContext<PorfolioTabPropsContextType>();

    const { data: positions, loading, pagination, setPage, totalCount } = useClosedPositionsData(rest);

    if (loading || !positions) {
        return <Loading />
    }

    return (
        <>
            <ClosedPositionsView positions={positions} currency={currency} fullView={!context.isAdaptive} />
            <TablePaginator onPage={setPage} page={pagination.page} itemsOnPage={pagination.pageSize} totalCount={totalCount} />
        </>
    );
});

export default ClosedPositionsTab;
