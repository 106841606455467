import { useCallback, useContext } from 'react';
import { AppStateContext } from '../../appContext';
import { Position } from '../../models';
import { assetsService } from '../AssetService';
import { OrderService, PortfolioRequestDataParams, orderService } from '../OrderService';
import { Pagination, usePaginationData } from './usePaginationData';


export const useClosedPositionsData = (params: PortfolioRequestDataParams) => {
    const context = useContext(AppStateContext);
    const loadingFunction = useCallback(async (pagination: Pagination) => {
        const request = OrderService.createRequest({ ...params, pagination });
        const [result, total] = await orderService.GetClosedPositions(context.account, request);
        const extendedItems = await assetsService.ExtendPairInfo(result);
        return { data: extendedItems, totalCount: total };
    }, [params.pairId, params.sort, params.sortAsc, params.dateRange, params.filters]);

    const result = usePaginationData<Position[]>(loadingFunction);

    return result;
};