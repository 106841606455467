import classNames from 'classnames';
import React from 'react';

import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../models';
import useOrderViewData, { OrdersViewInternalProps } from '../useOrderViewData';


interface OrderRowProps {
    order: Order;
    currency: string;
    refreshOrders: CallableFunction;
}

const PendingOrderRowBlock: React.FC<OrderRowProps> = observer(({ currency, order, refreshOrders }) => {
    const { t } = useTranslation();
    const sideClass = classNames('item side inline-form', order.SideClass);
    const { renderer } = useOrderViewData({ currency, refreshOrders });

    return (
        <div className={sideClass}>
            <div className='ticker left'>
                {renderer.ticker.render(order)}
            </div>
            <div className='data'>
                <div className='data-2 data-item'>
                    <div className='cell qty-content'>
                        <div className="header">{renderer.qty.title}</div>
                        {renderer.qty.render(order)}
                    </div>
                    <div className='cell price-content'>
                        <div className="header">{renderer.trigger_price.title}</div>
                        {renderer.trigger_price.render(order)}
                    </div>
                </div>
                <div className='data-3 data-item'>
                    <div className='cell total_stop_loss-content'>
                        <div className="header">{renderer.total_stop_loss.title}</div>
                        {renderer.total_stop_loss.render(order)}
                    </div>
                    <div className='cell total_take_profit-content'>
                        <div className="header">{renderer.total_take_profit.title}</div>
                        {renderer.total_take_profit.render(order)}
                    </div>
                </div>
                <div className='data-4 data-item'>
                    <div className='cell validity-content'>
                        <div className="header">{t('portfolio.orders.validity')}</div>
                        {renderer.good_until.render(order)}
                    </div>
                </div>
            </div>
            {renderer.actions.render(order)}
        </div>
    )
})


export const PendingOrdersViewBlocks: React.FC<OrdersViewInternalProps> = observer(({ currency, orders, refreshOrders }) => {
    return <div className='orders pending-orders responsive-data-table data-table portfolio-table'>
        {orders.map(order => <PendingOrderRowBlock order={order} key={order.id} currency={currency} refreshOrders={refreshOrders!} />)}
    </div>
});
