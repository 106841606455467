import React, { useState, useRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { closeIcon, searchIcon } from '../../../../../assets/icons';
import { AssetCategory } from '../../../../../services';
import SearchResults from './SearchResults';
import './SearchWidget.css';

interface SearchWidgetProps extends WithTranslation {
    categories: AssetCategory[];
}

const SearchWidget: React.FC<SearchWidgetProps> = ({ categories }) => {
    const [search, setSearch] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleSearch = (search: string) => {
        setSearch(search);
        setIsOpen(search.length > 0);
    };

    const handleClear = () => {
        setSearch('');
        setIsOpen(false);
    };

    return (
        <div className='search-widget' id="search-widget" >
            <div className='dropdown-container' ref={containerRef}>
                <Dropdown isOpen={isOpen} toggle={() => { }} >
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="div"
                    >
                        <div className='form-control-group'>
                            <input type="text" spellCheck="false" className="form-control" id="search-input" key="child-input" value={search} onChange={(event) => handleSearch(event.target.value)} autoComplete='off' />
                            {search.length > 0 && <button className="inner-btn" onClick={handleClear}>{closeIcon}</button>}
                            {search.length === 0 && <span className='inner-icon'>{searchIcon}</span>}
                        </div>
                    </DropdownToggle>
                    <DropdownMenu container={containerRef}>
                        {isOpen && <SearchResults search={search} categories={categories} />}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

export default withTranslation()(SearchWidget);
