import { Button, Input, Modal, Tree } from "antd";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrayToTechIndicators, TechIndicator, TechIndicatorsNames, TechIndicatorsToArray } from "src/models";
import "./IndicatorsDialog.scss";


const { Search } = Input;

// UI groups of indicators
const IndicatorsGroups = {
    trend: [TechIndicator.SMA, TechIndicator.EMA, TechIndicator.MACD, TechIndicator.ADX],
    momentum: [TechIndicator.ROC, TechIndicator.RSI],
    volatility: [TechIndicator.BBands],
    volume: [TechIndicator.Volume, TechIndicator.RVOL],
    other: [TechIndicator.Stoch]
};

interface IndicatorsDialogProps {
    indicators: TechIndicator;
    onChange: (indicators: TechIndicator) => void;
}

/**
 * Dialog component for selecting indicators to show on the chart.
 *
 * @param {IndicatorsDialogProps} props - Component props.
 * @param {TechIndicator} props.indicators - Initial set of selected indicators.
 * @param {(indicators: TechIndicator) => void} props.onChange - Callback to be called when the selected indicators change.
 * @returns {React.ReactElement} Component element.
 */
export const IndicatorsDialog: React.FC<IndicatorsDialogProps> = memo(({ indicators, onChange }) => {
    const [selectedIndicators, setSelectedIndicators] = useState<TechIndicator[]>(TechIndicatorsToArray(indicators));
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const { t } = useTranslation();

    const onExpand = (newExpandedKeys: React.Key[]) => {
        setExpandedKeys(newExpandedKeys);
    };

    useEffect(() => {
        setSelectedIndicators(TechIndicatorsToArray(indicators));
    }, [indicators]);

    const handleCancel = () => {
        setOpen(false);
        setSearchValue('');
    };

    const handleCheck = (checkedKeys: number[]) => {
        const indicators = ArrayToTechIndicators(checkedKeys);
        setSelectedIndicators(checkedKeys);
        onChange(indicators);
    };


    const onSearchChange = (value: string) => {
        setSearchValue(value);
    };

    const treeData = useMemo(() => {
        return Object.entries(IndicatorsGroups).map(([key, value]) => ({
            title: t(`trading.chart.groups.${key}`),
            key: key,
            value: value,
            children: value.map(indicator => ({
                title: t(`enum.TechIndicator.${TechIndicatorsNames.get(indicator)}`),
                key: indicator,
            }))
        }))
    }, [t]);

    useEffect(() => {
        const expanded: string[] = [];
        treeData.forEach(item => {
            item.children?.forEach(child => {
                if (searchValue.length && child.title.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                    expanded.push(item.key);
                }
            });
            if (searchValue.length && item.title.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                expanded.push(item.key);
            }
            setExpandedKeys(expanded);
        });
    }, [searchValue, treeData]);

    return (
        <>
            <Button onClick={() => setOpen(true)}>{t('trading.chart.indicators')}</Button>
            <Modal
                className="TradingIndicatorsDialog"
                title={t('trading.chart.indicators')}
                onCancel={handleCancel}
                open={open}
                footer={
                    <Button type="primary" onClick={handleCancel}>
                        {t('buttons.close')}
                    </Button>
                }
            >
                <Search onChange={(e) => onSearchChange(e.target.value)} value={searchValue} style={{ marginBottom: 8 }} />
                <Tree
                    checkable
                    selectable={false}
                    checkedKeys={selectedIndicators}
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    filterTreeNode={(treeNode) => {
                        return searchValue.length > 0 && treeNode.title.toLowerCase().includes(searchValue.toLowerCase());
                    }}
                    onCheck={handleCheck}
                    treeData={treeData}
                />
            </Modal>
        </>
    );
});
