import React from 'react';
import i18n from 'src/i18n';

type EnumFiledProps = {
    enum: string;
    translate: CallableFunction;
    value?: number | string | null | undefined;
}

export const EnumField: React.FC<EnumFiledProps> = (props) => {
    if (!props.value) {
        return <></>
    }

    return (
        <>{props.translate(`enum.${props.enum}.${props.value}`)}</>
    );
};
