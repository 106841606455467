import { BaselineSeriesPartialOptions, IChartApi, ISeriesApi, LineSeriesPartialOptions, MouseEventParams, SingleValueData } from "lightweight-charts";
import { formatNumber } from "src/utils";
import { CandlestickVolumeData } from "../Chart";

export type TooltipOptions = {
    baseSymbol: string;
    digits: number;
    quoteSymbol: string;
}

export interface ITechIndicator {
    updateData(data: CandlestickVolumeData[]): void;
    update(data: CandlestickVolumeData): void;
    detach(): void;
    attach(chart: IChartApi, mainSeries: ISeriesApi<"Candlestick">): void;
    getTooltip(param: MouseEventParams, options: TooltipOptions): string | null;
    color: string;
}


export abstract class BaseIndicator implements ITechIndicator {
    data: any;
    chart: IChartApi;
    mainSeries: ISeriesApi<"Candlestick">;
    priceScaleId = 'left';
    color = '#ff0000';
    symbol: 'baseSymbol' | 'quoteSymbol' | null = 'quoteSymbol';

    attach(chart: IChartApi, mainSeries: ISeriesApi<"Candlestick">): void {
        this.chart = chart;
        this.mainSeries = mainSeries;
    }

    detach() {
        throw new Error("Method not implemented.");
    }

    updateData(data: any) {
        this.data = data;
    }
    update(data: CandlestickVolumeData): void {
        throw new Error("Method not implemented.");
    }
    getTooltip(param: MouseEventParams, options: TooltipOptions): string | null {
        return null;
    }
}


export class LineIndicator extends BaseIndicator {
    data: any;
    series: ISeriesApi<"Line">;

    attach(chart: IChartApi, mainSeries: ISeriesApi<"Candlestick">): void {
        super.attach(chart, mainSeries);

        this.series = this.chart.addLineSeries({ priceScaleId: this.priceScaleId, baseLineVisible: false, color: this.color, lastValueVisible: false });
        if (this.priceScaleId === 'left') {
            this.chart.applyOptions({
                leftPriceScale: {
                    visible: true,
                    ticksVisible: false
                },
            });
            this.series.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.6,
                    bottom: 0.2,
                },
            });
        }

        if (this.data) {
            this.series.setData(this.data);
        }
    }

    detach() {
        this.chart?.removeSeries(this.series);
        this.chart.applyOptions({
            leftPriceScale: {
                visible: false,
            },
        });
    }

    getTooltip(param: MouseEventParams, options: TooltipOptions): string {
        const volumeData = param.seriesData.get(this.series!) as SingleValueData;
        const value = formatNumber(volumeData?.value, options.digits);
        const symbol = this.symbol ? options[this.symbol as keyof TooltipOptions] : '&nbsp;';
        return `<span class="value suffix-value"><span class="val number text-number">${value}</span><span class="suffix">${symbol}</span>`;
    }
}

export class BaseLineIndicator extends BaseIndicator {
    data: any;
    series: ISeriesApi<"Baseline">;
    baseValue = 1;

    attach(chart: IChartApi, mainSeries: ISeriesApi<"Candlestick">): void {
        super.attach(chart, mainSeries);

        const options: BaselineSeriesPartialOptions = {
            priceScaleId: this.priceScaleId,
            baseLineVisible: false,
            lastValueVisible: false,
            baseValue: {
                type: 'price',
                price: this.baseValue,
            },
            topLineColor: '#198754',
            bottomLineColor: '#dc3545',
        };

        this.series = this.chart.addBaselineSeries(options);
        if (this.priceScaleId === 'left') {
            this.chart.applyOptions({
                leftPriceScale: {
                    visible: true,
                    ticksVisible: false
                },
            });
            this.series.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.6,
                    bottom: 0.2,
                },
            });
        }

        if (this.data) {
            this.series.setData(this.data);
        }
    }

    detach() {
        this.chart?.removeSeries(this.series);
        this.chart.applyOptions({
            leftPriceScale: {
                visible: false,
            },
        });
    }

    getTooltip(param: MouseEventParams, options: TooltipOptions): string {
        const volumeData = param.seriesData.get(this.series!) as SingleValueData;
        const value = formatNumber(volumeData?.value, options.digits);
        const symbol = this.symbol ? options[this.symbol as keyof TooltipOptions] : '&nbsp;';
        return `<span class="value suffix-value"><span class="val number text-number">${value}</span><span class="suffix">${symbol}</span>`;
    }
}


export class MultyLineIndicator extends BaseIndicator {
    data: any;
    series: ISeriesApi<"Line">[] = [];
    lines = 2;
    colors = ['#ff0000', '#ffFF00'];
    labels= ['1', '2'];
    symbol = null;

    attach(chart: IChartApi, mainSeries: ISeriesApi<"Candlestick">): void {
        super.attach(chart, mainSeries);

        const options: LineSeriesPartialOptions = { 
            priceScaleId: 
            this.priceScaleId, 
            baseLineVisible: false, 
            color: this.color, 
            lineWidth: 1,
            lastValueVisible: false 
        };

        for (let i = 0; i < this.lines; i++) {
            options.color = this.colors[i];
            this.series.push(this.chart.addLineSeries(options));
        }

        if (this.priceScaleId === 'left') {
            this.chart.applyOptions({
                leftPriceScale: {
                    visible: true,
                    ticksVisible: false
                },
            });
            this.series.map(s => s.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.6,
                    bottom: 0.2,
                },
            }));
        }

        if (this.data) {
            this.series.map((s, i) => s.setData(this.data[i]));
        }
    }

    detach() {
        this.series.map(s => this.chart?.removeSeries(s));
        this.chart.applyOptions({
            leftPriceScale: {
                visible: false,
            },
        });
    }

    getTooltip(param: MouseEventParams, options: TooltipOptions): string {
        const symbol = this.symbol ? options[this.symbol as keyof TooltipOptions] : '&nbsp;';
        const values = this.labels.map((l, index) => {
            const volumeData = param.seriesData.get(this.series[0]) as SingleValueData;
            const value = formatNumber(volumeData?.value, options.digits);
            return `<span class="val number text-number">${l}: ${value}</span>`
        });
        return `<span class="value suffix-value">
                ${values.join(' / ')}
            <span class="suffix">${symbol}</span>`;
        // const volumeData = param.seriesData.get(this.series!) as SingleValueData;
        // const value = formatNumber(volumeData?.value, options.digits);
        // const symbol = this.symbol ? options[this.symbol as keyof TooltipOptions] : '&nbsp;';
        // return `<span class="value suffix-value"><span class="val number text-number">${value}</span><span class="suffix">${symbol}</span>`;
    }
}