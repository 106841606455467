import { confirmDialog } from 'primereact/confirmdialog';
import { orderService } from '../services';
import { deleteIcon } from '../assets/icons';
import { showErrorNotification, showNotification } from './notifications';
import { Position } from '../models';
import i18n from "../i18n";


export const cancelOpenPositionProcess = async (position: Position) => {
    try {
        const ret = await orderService.ClosePosition(position);
        showNotification(i18n.t("notifications.position.closed"));
    } catch (error) {
        showErrorNotification(error.message);
    }
}

export const cancelOpenPosition = async (position: Position) => {
    confirmDialog({
        message: i18n.t("dialogs.close_position.promt"),
        acceptLabel: i18n.t("dialogs.close_position.accept"),
        rejectLabel: i18n.t("dialogs.close_position.reject"),
        icon: deleteIcon,
        accept: () => cancelOpenPositionProcess(position),
    });
}

export const submitPositionChange = async (position: Position, totalStopLossValue: string, totalTakeProfitValue: string, setEditing: CallableFunction) => {
    try {
        const data = {
            totalStopLoss: totalStopLossValue ? parseFloat(totalStopLossValue) : null,
            totalTakeProfit: totalTakeProfitValue ? parseFloat(totalTakeProfitValue) : null,
        }
        const result = await orderService.UpdatePosition(position, data);
        position.update({totalTakeProfit: data.totalTakeProfit, totalStopLoss: data.totalStopLoss});
        setEditing(false);
        showNotification(i18n.t("notifications.position.updated"));
    } catch (error) {
        showErrorNotification(error.message);
    }
}