import React from 'react';

import { observer } from "mobx-react-lite";
import useTransactionViewData, { TransactionsViewInternalProps } from './useTransactionsViewData';
import { Transaction } from '../../../models';


interface TransactionRowProps {
    transaction: Transaction;
    symbol: string;
    showPosition: CallableFunction;
    showOrder: CallableFunction;
}

const TransactionRowBlock: React.FC<TransactionRowProps> = observer(({ transaction, symbol, showOrder, showPosition }) => {
    const sideClass = 'item side inline-form';
    const renderer = useTransactionViewData({ symbol, showOrder, showPosition });

    return (
        <div className={sideClass}>
            <div className='ticker left text-nowrap'>
                {renderer.ticker.render(transaction)}
            </div>
            <div className='data'>
                <div className='data-2 data-item'>
                    <div className='cell'>
                        <div className="header">{renderer.effective_date.title}</div>
                        <div className='value text-nowrap'>{renderer.effective_date.render(transaction)}</div>
                    </div>
                </div>
                <div className='data-2 data-item'>
                    <div className='cell'>
                        <div className="header">{renderer.change.title}</div>
                        <div className='value'>{renderer.change.render(transaction)}</div>
                    </div>
                    <div className='cell'>
                        <div className="header">{renderer.reserve.title}</div>
                        <div className='value'>{renderer.reserve.render(transaction)}</div>
                    </div>
                </div>
                <div className='data-3 data-item'>
                    <div className='cell'>
                        <div className="header">{renderer.type.title}</div>
                        <div className='value'>{renderer.type.render(transaction)}</div>
                    </div>
                </div>
            </div>
            <div className='actions'>
                {renderer.order.render(transaction)}
                {renderer.position.render(transaction)}
            </div>
        </div>
    )
})

export const TransactionsViewBlocks: React.FC<TransactionsViewInternalProps> = observer(({ transactions, symbol, showOrder, showPosition }) => {
    return <>
        <div className='positions responsive-data-table data-table portfolio-table'>
            {transactions.map(transaction => <TransactionRowBlock transaction={transaction} key={transaction.id} symbol={symbol} showOrder={showOrder} showPosition={showPosition} />)}
        </div>
    </>
});
