import React from 'react';
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import * as Flags from 'country-flag-icons/react/3x2'

export const supportedLanguages = {
  'en-GB': {
    code: 'en',
    flag: <Flags.GB height={16} />,
    name: 'English (GB)',
  },
  'en-US': {
    code: 'en',
    flag: <Flags.US height={16} />,
    name: 'English (US)',
  },
  'es': {
    code: 'es',
    flag: <Flags.ES height={16} />,
    name: 'Español',
  },
  'de': {
    code: 'de',
    flag: <Flags.DE height={16} />,
    name: 'Deutsch',
  },
  'ru': {
    code: 'ru',
    flag: <Flags.RU height={16} />,
    name: 'Русский',
  },
};

const userLanguage = window.navigator.language;

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('i18nextLng') || userLanguage || 'en-US',
    supportedLngs: false,
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    interpolation: { escapeValue: false },
    debug: true,
    backend: {
      loadPath: function (lngs: string[], amespaces: string[]) {
        const [lng] = lngs[0].split('-');
        return `/locales/${lng}.json`;
      },
    },
    fallbackLng: {
      'en': ['en-US'],
      'default': ['en-GB']
    }
  });

window.i18n = i18n;

export default i18n;
