import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useMemo, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { CategoryExposure, CategoryExposureTypes, ExposureType } from "../../../../models";
import { formatNumberFix, formatNumberTrim } from "../../../../utils";
import { MAX_CHART_ITEMS } from "./exposureCard";

type ExposureChartViewProps = {
    serie: any,
    othersData: CategoryExposure[],
    labels: Array<string>,
    name: string,
    activeStat: ExposureType,
    onSelectItem: CallableFunction,
    selection: string,
    displayDecimals: number,
    denominatedInAssetId: string
};

export const ExposureChartView: React.FC<ExposureChartViewProps> = React.memo(({ selection, displayDecimals, denominatedInAssetId, serie, labels, name, activeStat, onSelectItem, othersData }) => {
    const { t } = useTranslation();
    const currency = denominatedInAssetId;
    const digits = displayDecimals;
    const fixedDecimals = 0;
    const otherName = t('exposure.fields.others');

    // const tooltipFormatters = {
    //     totalValue: function (value: number, percentValue: string, opts: any) {
    //         return percentValue + '%';
    //     },
    //     openPositionCount: function (value: number, percentValue: string, opts: any) { return percentValue + '%'; },
    //     cumulativeUnrealizedPnl: function (value: number, percentValue: string, opts: any) { return percentValue + '%'; },
    // }

    const dataFormatters = {
        totalValue: function (dataValue: number) { return formatNumberFix(dataValue / 1000, fixedDecimals) + "K " + currency },
        openPositionCount: function (dataValue: number) { return formatNumberTrim(dataValue, digits); },
        cumulativeUnrealizedPnl: function (dataValue: number) { return formatNumberFix(dataValue / 1000, fixedDecimals) + "K " + currency },
    }
    const formatter = dataFormatters[activeStat as keyof typeof dataFormatters];

    const options: ApexOptions = {
        labels: labels,
        legend: {
            position: 'bottom',
        },
        theme: { mode: "dark", palette: "palette6" },
        tooltip: {
            style: {
                fontSize: '1rem',
            },
            y: {
                formatter: ((val: number, opts?: any) => {
                    // const percentValue = formatNumberTrim(val / serie.total * 100, 2);
                    if (opts?.seriesIndex === MAX_CHART_ITEMS && othersData.length) {
                        return '<h3>&nbsp;</h3>' + othersData.map(row => `<span class="">${formatter(row[name as CategoryExposureTypes][activeStat])}</span>`).join('');
                    }

                    // tooltipFormatters[activeStat as keyof typeof tooltipFormatters]
                    const orignal = serie.original[opts.dataPointIndex];
                    const total = formatter(serie.total);
                    return '<span>' + formatter(orignal) + '</span><span>' + total + '</span>';
                }),
                title: {
                    formatter: (seriesName: string) => {
                        if (seriesName === otherName) {
                            return '<h3>' + otherName + ':</h3>' + othersData.map(i => `<span>${i.categoryId}</span>`).join('');
                        }
                        return '<span>' + seriesName + ':</span><b class="">' + t('common.total') + ':</b>';
                    }
                }
            },
        },
        dataLabels: {
            formatter: function (value, { seriesIndex, w }) {
                const dataValue = serie.original[seriesIndex];
                return dataFormatters[activeStat as keyof typeof dataFormatters](dataValue);
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            color: 'white',
                            fontSize: '1.5rem',
                            showAlways: true,
                            label: t('common.total'),
                            formatter: function (w) {
                                return dataFormatters[activeStat as keyof typeof dataFormatters](serie.total);
                            }
                        },
                        value: {
                            show: true,
                            fontSize: '1.5rem',
                            color: 'white',
                            formatter: function (val: string) {
                                return dataFormatters[activeStat as keyof typeof dataFormatters](val as never);
                            }
                        },
                    }
                }
            }
        }
    }

    const prevSelection = useRef('');

    const chartOptions = useMemo(() => {
        const opts = {
            ...options, chart: {
                // group: "realtime",
                id: `${name}-${activeStat}`,
                background: '#202024',
                events: {
                    // dataPointMouseEnter: (e: any, chart?: any, options?: any) => { onSelectItem(options.w.config.chart.id, options.dataPointIndex); },
                    // dataPointMouseLeave: (e: any, chart?: any, options?: any) => { onSelectItem(options.w.config.chart.id, null); },
                    // dataPointSelection: (e: any, chart?: any, options?: any) => {
                    //     onSelectItem(options.w.config.chart.id, options.selectedDataPoints[0].length ? options.dataPointIndex : null);
                    // },
                },

            }
        };
        return opts;
    }, [name, serie, labels, activeStat]);

    const chartRef = useRef<{ chart: any }>(null);
    if (chartRef.current) {
        if (prevSelection.current !== '') {
            (chartRef.current.chart as ApexCharts).hideSeries(prevSelection.current);
            (chartRef.current.chart as ApexCharts).showSeries(prevSelection.current);
        }
        if (selection) {
            (chartRef.current.chart as ApexCharts).hideSeries(selection);
        }
        prevSelection.current = selection || '';
    }

    return (
        <ReactApexChart
            ref={chartRef as never}
            options={chartOptions}
            series={serie.data}
            type="donut"
            height={336}
        />
    )
});