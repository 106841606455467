import { Col, Layout, Row } from 'antd';
import dayjs from 'dayjs';
import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { MetricPeriodicity } from "../../../../models";
import { useVaRHistoricalData } from "../../../../services/hooks";
import Loading from "../../../Loading";
import MetricsTable from "../MetricsTable";
import { useDateRange, useMetrics, usePeriodicity } from '../filters';

const { Content } = Layout;

export const HistoricalTab: React.FC<{}> = observer((params) => {

    const [periodicityComponent, periodicity] = usePeriodicity();
    const [metricsComponent, metrics] = useMetrics();
    const [selectComponent, dateRange, setDateRange] = useDateRange();

    useEffect(() => {
        if (periodicity === MetricPeriodicity.Intraday) {
            setDateRange([dayjs().startOf('day'), dayjs().endOf('day')]);
        } else if (periodicity === MetricPeriodicity.Daily) {
            setDateRange([dayjs().subtract(7, 'day').startOf('day'), dayjs().endOf('day')]);
        } else if (periodicity === MetricPeriodicity.Monthly) {
            setDateRange([dayjs().subtract(1, 'month').startOf('day'), dayjs().endOf('day')]);
        }
    }, [periodicity, setDateRange]);

    const { loading, data } = useVaRHistoricalData(dateRange, metrics, periodicity);

    return (
        <Layout className='SummaryTab container-fixed'>
            <Content>
                <Row gutter={16} className='mb-24'>
                    <Col sm={24} md={4}>
                        {periodicityComponent}
                    </Col>
                    <Col sm={24} md={14}>
                        {metricsComponent}
                    </Col>
                    <Col sm={26} md={6}>
                        {selectComponent}
                    </Col>
                </Row>

                {loading && <Loading />}
                {data && data.length > 0 && <Row>
                    <Col className="text-center score-container" md={24}>
                        <MetricsTable data={data} selectedMetrics={metrics} />
                    </Col>
                </Row>}
            </Content>
        </Layout>)
});