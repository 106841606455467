import { observer } from "mobx-react-lite";
import React, { useState } from 'react';

// import './Orders.css';
import { Formik } from 'formik';
import { usePendingOrdersData } from '../../../../services/hooks';
import Loading from '../../../Loading';
import { PendingOrdersViewBlocks } from '../../../common/Orders/Pending/PendingOrdersViewBlocks';
import { PorfolioTabPropsContextType } from "../../PortfolioPage/PortfolioPage";


const OpenOrders: React.FC<PorfolioTabPropsContextType> = observer((params) => {
    const [lastUpdate, setLastUpdate] = useState(new Date());
    const { currency, ...rest } = params;

    const { data: orders, loading } = usePendingOrdersData({ ...rest, updateAt: lastUpdate });
    const refreshOrders = () => {
        setLastUpdate(new Date());
    }

    if (loading || !orders) {
        return <Loading />
    }

    return (
        <>
            <Formik onSubmit={() => { }} initialValues={{}}>
                <PendingOrdersViewBlocks orders={orders} currency={currency!} refreshOrders={refreshOrders} />
            </Formik >
        </>
    );
});

export default OpenOrders;
