import React, { useContext } from 'react';

import { Form, Formik } from 'formik';
import { AppStateContext } from '../../../appContext';
import { Alert } from '../../../models';
import { AlertsViewBlocks } from './AlertsViewBlocks';
import { AlertsViewTable } from './AlertsViewTable';


export interface AlertsViewProps {
    alerts: Alert[];
    refreshAlerts: CallableFunction;
    currency: string;
}

export const AlertsView: React.FC<AlertsViewProps> = (props) => {
    const context = useContext(AppStateContext);
    const fullView = !context.isAdaptive;

    const view = fullView ?
        <AlertsViewTable symbol={context.account.symbol} {...props} /> :
        <AlertsViewBlocks symbol={context.account.symbol} {...props} />

    return <>
        <Formik initialValues={{}} onSubmit={() => { }}>
            <Form>
                {view}
            </Form>
        </Formik>
    </>
};