import { Skeleton } from "antd";
import React from "react";
import { appState } from "src/appContext";

function toLocaleStringSupportsOptions() {
  return !!(
    typeof Intl == "object" &&
    Intl &&
    typeof Intl.NumberFormat == "function"
  );
}

type NumberFieldProps = {
  value: number | string | undefined | null,
  locale?: string,
  digits?: number,
  options?: Intl.NumberFormatOptions,
  className?: string,
  loading?: boolean,
}

export const NumberField = ({
  value,
  locale,
  options,
  digits,
  loading,
  ...rest
}: NumberFieldProps) => {

  if (loading) {
    return <Skeleton.Input active={true} />
  }

  if (locale === undefined) {
    locale = appState.language;
  }

  if (value === null || value === undefined) {
    return <></>;
  }

  let number = Number(value);
  rest.className = rest.className ? rest.className + " text-number" : "text-number";
  if (digits !== undefined) {
    options = { maximumFractionDigits: digits, minimumFractionDigits: digits };
  }

  return (
    <span {...rest}>
      {toLocaleStringSupportsOptions()
        ? number.toLocaleString(locale, options)
        : number}
    </span>
  );
};
