import { Button, Card, Col, DatePicker, Row, Space, Spin, TimeRangePickerProps } from "antd";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AggregationType, CategoryExposure, ExposureReport, ExposureType, MetricPeriodicity } from "../../../../models";
import { useExposureHistoryData } from "../../../../services/hooks";
import { CategoryLabelFormatters, formatNumberFix, rangePresets } from "../../../../utils";
import MyApexChart from "../../../ApexChart";

type ExposureHistoryPaneProps = {
  activeStat: ExposureType,
  assetType: AggregationType,
  displayDecimals: number,
  denominatedInAssetId: string,
};

const nowTime = dayjs().endOf('day');
const backTime = dayjs().subtract(1, 'hour');

const ExposureHistoryPane: React.FC<ExposureHistoryPaneProps> = ({ assetType, displayDecimals, denominatedInAssetId, activeStat }) => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([backTime, nowTime]);
  const [periodicy, setPeriodicy] = useState<MetricPeriodicity>(MetricPeriodicity.Intraday);

  useEffect(() => {
    if (dateRange[1].diff(dateRange[0], 'days') > 1) {
      setPeriodicy(MetricPeriodicity.Daily);
    } else {
      setPeriodicy(MetricPeriodicity.Intraday);
    }
  }, [[...dateRange]]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateRange(dateRange => [dateRange[0].add(1, 'm'), dateRange[1].add(1, 'm')]);
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [activeStat, assetType, dateRange]);

  const { data: exposureHistoryData, loading: isLoading } = useExposureHistoryData(dateRange[0].toDate(), dateRange[1].toDate(), periodicy, assetType);

  const decimals = displayDecimals;

  const { RangePicker } = DatePicker;

  const { series, labels } = useMemo(() => {
    const retLabels = new Array<string>();

    const seriesData = new Map<string, any>();

    const allCategories = exposureHistoryData?.reduce((acc, report) => {
      report.categories.forEach(category => acc.add(category.categoryId));
      return acc;
    }, new Set<string>());

    allCategories?.forEach(categortId => {
      seriesData.set(categortId, {
        long: Array.from({ length: exposureHistoryData?.length || 0 }, (_) => null),
        short: Array.from({ length: exposureHistoryData?.length || 0 }, (_) => null),
        netted: Array.from({ length: exposureHistoryData?.length || 0 }, (_) => null),
      })
    });

    exposureHistoryData?.forEach((report: ExposureReport, index: number) => {
      retLabels.push(report.preparedOn);
      report.categories.forEach((category: CategoryExposure) => {
        const data = seriesData.get(category.categoryId);
        data.long[index] = category.long[activeStat];
        data.short[index] = category.short[activeStat];
        data.netted[index] = category.netted[activeStat];
      });
    });

    const retSeries = {
      long: new Array<any>(),
      short: new Array<any>(),
      netted: new Array<any>(),
    }

    seriesData.forEach((value, key) => {
      const categoryName = CategoryLabelFormatters[assetType](key);
      retSeries.long.push({ name: categoryName, data: [...value.long] })
      retSeries.short.push({ name: categoryName, data: [...value.short] })
      retSeries.netted.push({ name: categoryName, data: [...value.netted] })
    });

    ApexCharts.exec('history-long', 'updateSeries', retSeries.long);
    ApexCharts.exec('history-short', 'updateSeries', retSeries.short);
    ApexCharts.exec('history-netted', 'updateSeries', retSeries.netted);

    return { series: retSeries, labels: retLabels };
  }, [exposureHistoryData, activeStat]);

  const options: ApexOptions = {
    chart: {
      background: '#202024',
      group: `history`,
      toolbar: {
        show: false
      },
    },
    grid: {
      borderColor: '#121214',
    },
    theme: { mode: "dark", palette: "palette6" },
    legend: {
      show: true,
    },
    yaxis: {
      labels: {
        formatter: (val: number, opts?: any) => {
          return formatNumberFix(val, decimals);
        },
        style: {
          fontSize: '1rem',
        }
      }
    },
    stroke: {
      width: 2,
    },
    labels: labels,
    xaxis: {
      categories: labels,
      type: 'datetime',
      labels: {
        formatter: function (value) {
          return value ? dayjs(value).local().format('HH:mm MMM DD') : '';
        },
        style: {
          fontSize: '1rem',
        }
      }
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const c = w.globals;
        const values = w.config.series.map((serie: any, index: Number) => {
          return [serie.data[dataPointIndex], serie.name, index]
        }).sort((a: any, b: any) => b[0] - a[0]);

        const ss = values.map((val: any, index: number) => {
          let value = val[0];
          value = c.yLabelFormatters[0](value, w);
          const color = c.colors[val[2]];
          return `<div class="apexcharts-tooltip-series-group custom-tooltip" style="order: ${index}; display: flex;">
      <div class="apexcharts-tooltip-text">
          <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label"><span
          class="apexcharts-tooltip-marker" style="background-color: ${color};"></span> ${val[1]}: </span><span
                  class="apexcharts-tooltip-text-y-value">${value}</span></div>
      </div>
  </div>
  `;
        });
        const dt = c.ttKeyFormatter(labels[dataPointIndex]);
        return `<div class="apexcharts-tooltip-title">${dt}</div>` + ss.join('');
      },
      style: {
        fontSize: '1rem',
      }
    }
  };
  const nettedOptions = { ...options, chart: { ...options.chart, id: `history-netted` } }
  const longOptions = { ...options, chart: { ...options.chart, id: `history-long` } }
  const shortOptions = { ...options, chart: { ...options.chart, id: `history-short` } }


  return (
    <>
      <Row justify="space-between" className="mb-8" align="middle">
        <Col>
          <Card size="small">
            <Space>
              {rangePresets?.map((range, index) => <Button key={index} onClick={() => setDateRange(range.value as never)}>{range.label}</Button>)}
              <RangePicker presets={rangePresets} showTime value={dateRange} onChange={(a1, a2) => a1 && setDateRange([a1[0]!, a1[1]!])} />
            </Space>
          </Card>
        </Col>
      </Row>
      <Row justify="space-between" gutter={[16, 24]} className="mb-24">
        <Col sm={24} md={12} lg={8}>
          <Card size="small" title={t('exposure.fields.total')}>
            <Spin spinning={isLoading}>
              {exposureHistoryData && <MyApexChart options={nettedOptions} series={series.netted} type="line" width="100%" height={"250px"} />}
            </Spin>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <Card size="small" title={t('exposure.fields.long')}>
            <Spin spinning={isLoading}>
              {exposureHistoryData && <MyApexChart options={longOptions} series={series.long} type="line" width="100%" height={"250px"} />}
            </Spin>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <Card size="small" title={t('exposure.fields.short')}>
            <Spin spinning={isLoading}>
              {exposureHistoryData && <MyApexChart options={shortOptions} series={series.short} type="line" width="100%" height={"250px"} />}
            </Spin>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ExposureHistoryPane;