import { Dialog } from 'primereact/dialog';
import React, { useContext, useState } from 'react';
import { AppStateContext } from '../../../appContext';
import { Order, OrderPositionAssociation, Position } from '../../../models';
import { orderService } from '../../../services';
import { OrderInfoDialog } from '../../pages/PortfolioPage/OrderInfoDialog';

export const useShowRelatedOrderDialog = () => {
    const [displayOrder, setDisplayOrder] = useState<Order | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { account } = useContext(AppStateContext);

    const showOrder = (position: Position, referenceType?: OrderPositionAssociation) => {
        setIsLoading(true);
        orderService.GetOrdersForPosition(position.id, account, referenceType).then(([orders, totalCount]) => {
            setIsLoading(false);
            setDisplayOrder(orders.length ? orders[0] : null);
        });
    }

    const hideDialog = () => {
        setDisplayOrder(undefined);
    }

    const orderDialog = <>
        {(isLoading || displayOrder) && <Dialog style={{ width: '70vw' }} visible={true} header="Order" onHide={() => hideDialog()}>
            <OrderInfoDialog order={displayOrder} />
        </Dialog>}
    </>

    return {
        orderDialog,
        showOrder,
    };
};