import React, { useContext } from 'react';
import { NavLink, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import { AppStateContext } from '../../../appContext';
import { addAccountIcon, signOutIcon } from '../../../assets/icons';
import { useTranslation } from 'react-i18next';

import './LoginMenu.css';


const LoginMenu: React.FC<{}> = () => {
  const { isAuthenticated } = useContext(AppStateContext);
  const { t } = useTranslation();

  const authenticatedView = (addAccountPath: string, logoutPath: string, logoutState: { local: boolean }) => {
    const addAccountLink = t('userMenu.addAccount');
    const signOutLink = t('userMenu.signOut');
    return (
      <div className='login-menu-wrapper'>
        <DropdownItem>
          <NavLink replace tag={Link} to={addAccountPath}>{addAccountIcon} {addAccountLink}</NavLink>
        </DropdownItem>
        <DropdownItem>
          <NavLink replace tag={Link} to={logoutPath} state={logoutState}>{signOutIcon} {signOutLink}</NavLink>
        </DropdownItem>
      </div>
    );
  };

  const anonymousView = (registerPath: string, loginPath: string) => {
    const registerLink = t('userMenu.register');
    const loginLink = t('userMenu.login');
    return (<div className='logout-menu-wrapper'>
      <DropdownItem>
        <NavLink tag={Link} to={registerPath}>{registerLink}</NavLink>
      </DropdownItem>
      <DropdownItem>
        <NavLink tag={Link} to={loginPath}>{loginLink}</NavLink>
      </DropdownItem>
    </div>);
  };

  if (!isAuthenticated) {
    const registerPath = `${ApplicationPaths.Register}`;
    const loginPath = `${ApplicationPaths.Login}`;
    return anonymousView(registerPath, loginPath);
  } else {
    //todo: add path for adding a new account
    const addAccountPath = '';
    const logoutPath = `${ApplicationPaths.LogOut}`;
    const logoutState = { local: true };
    return authenticatedView(addAccountPath, logoutPath, logoutState);
  }
}

export default LoginMenu;