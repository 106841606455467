import { Dialog } from 'primereact/dialog';
import React from 'react';
import './FundingDialog.css';


interface CallDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const FundingDialog: React.FC<CallDialogProps> = ({ isOpen, onClose }) => {
    return (
        <Dialog style={{ width: '50vw', height: '50vh' }} header="Funding" visible={isOpen} onHide={() => onClose()}>
            <div className={`funding-container ${isOpen ? 'open' : ''}`}>
                <div className="funding-body">
                    Fund your account.
                </div>
            </div>
        </Dialog>
    );
};

export default FundingDialog;