import { Stochastic } from "technicalindicators";
import { StochasticInput } from "technicalindicators/declarations/momentum/Stochastic";
import { OhlcVolumeData } from "../Chart";
import { MultyLineIndicator } from "./BaseIndicator";


export class StochIndicator extends MultyLineIndicator {
    priceScaleId = 'left';
    labels = ['K', 'D'];
    updateData(data: OhlcVolumeData[]): void {

        const input: StochasticInput = {
            low: new Array<number>(),
            high: new Array<number>(),
            close: new Array<number>(),
            signalPeriod: 14,
            period: 14,
        }

        data.forEach((item: OhlcVolumeData) => {
            input.low.push(item.low);
            input.high.push(item.high);
            input.close.push(item.close);
        });

        const sma = new Stochastic(input);

        this.data = [[], []];

        data.forEach((item: OhlcVolumeData) => {
            const value = sma.nextValue({ low: item.low, high: item.high, close: item.close } as any);
            
            this.data[0].push({
                time: item.time,
                value: value.k
            });

            this.data[1].push({
                time: item.time,
                value: value.d
            });
        });

        if (this.data && this.series) {
            this.series[0].setData(this.data[0]);
            this.series[1].setData(this.data[1]);
        }
    }
}

