import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import './ChatDialog.css';


interface ChatProps {
    isOpen: boolean;
    onClose: () => void;
}

const ChatDialog: React.FC<ChatProps> = ({ isOpen, onClose }) => {
    const [message, setMessage] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Handle submitting the message
        // You can use the 'message' state variable here
        setMessage('');
    };

    return (
        <Dialog style={{ width: '50vw', height: '50vh' }} header="Chat with support" visible={isOpen} onHide={() => onClose()}>
            <div className={`chat-container ${isOpen ? 'open' : ''}`}>
                <div className="chat-body">
                    {/* Render chat messages here */}
                </div>
                <Form onSubmit={(event) => handleSubmit(event)}>
                    <Row className="row-cols-lg-auto align-items-center">
                        <Col>
                            <Label
                                className="visually-hidden"
                                for="exampleEmail"
                            >
                                Message
                            </Label>
                            <Input
                                id="message"
                                name="message"
                                placeholder="Write ypur message"
                                type="textarea"
                                value={message}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Col>
                        <Col>
                            <Button>
                                Send
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Dialog>

    );
};

export default ChatDialog;