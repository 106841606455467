import React from 'react';
import { useTranslation } from "react-i18next";
import Translate from "src/components/common/Translate/Translate";

export const VaRDescription: React.FC = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <h2>{t('valueAtRisk.title')}</h2>
            <p><Translate i18nKey="valueAtRisk.description" /></p>
            <h3>{t('valueAtRisk.methodsOfCalculation')}</h3>
            <ol>
                <li><Translate i18nKey="valueAtRisk.methods.varianceCovarianceMethod" /></li>
                <li><Translate i18nKey="valueAtRisk.methods.historicalSimulationMethod" /></li>
                <li><Translate i18nKey="valueAtRisk.methods.monteCarloSimulationMethod" /></li>
            </ol>
            <h3>{t('valueAtRisk.parameters')}</h3>
            <ul>
                <li><Translate i18nKey="valueAtRisk.parameterList.window" /></li>
                <li>
                    <Translate i18nKey="valueAtRisk.parameterList.confidenceLevel" />
                    <ul>
                        <li><Translate i18nKey="valueAtRisk.parameterList.higherConfidence" /></li>
                        <li><Translate i18nKey="valueAtRisk.parameterList.halfConfidence" /></li>
                    </ul>
                </li>
                <li><Translate i18nKey="valueAtRisk.parameterList.numberOfPaths" /></li>
                <li><Translate i18nKey="valueAtRisk.parameterList.timeHorizon" /></li>
            </ul>
            <p>{t('valueAtRisk.adjustSettings')}</p>
            <p><Translate i18nKey="valueAtRisk.example" /></p>

            <h2>{t('valueAtRisk.mctr.title')}</h2>
            <Translate i18nKey="valueAtRisk.mctr.description" component="p" isMulty={true} />
        </>
    )
};
