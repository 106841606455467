import { Alert, MarginCall, MarginCallPolicy, Order, Position } from "./";


export enum NotificationType {
    Unknown = 'Unknown',
    AlertFired = 'AlertFired',
    AlertExpired = 'AlertExpired',
    OrderSubmitted = 'OrderSubmitted',
    OrderExecutedPositionOpened = 'OrderExecutedPositionOpened',
    OrderExecutedPositionClosed = 'OrderExecutedPositionClosed',
    OrderExecutedPositionClosedSLTP = 'OrderExecutedPositionClosedSLTP',
    OrderExpired = 'OrderExpired',
    OrderCancelled = 'OrderCancelled',
    MarginCallIssued = 'MarginCallIssued',
    CapitalTransaction = 'CapitalTransaction',
    AdjustmentTransaction = 'AdjustmentTransaction',
    CustomerServiceMessage = 'CustomerServiceMessage',
    AlertSubmitted = 'AlertSubmitted',
    AlertCancelled = 'AlertCancelled'
}

export interface IGenericNotification {
    sentAtUtc: Date;
    type: NotificationType;

    alert?: Alert;
    order?: Order;
    position?: Position;
    marginCall?: MarginCall;
    marginCallPolicy?: MarginCallPolicy;
    customerServiceMessage?: string;
}

export class GenericNotification implements IGenericNotification {
    public sentAtUtc: Date;
    public seenAt?: Date = undefined;
    public type: NotificationType;
    public alert?: Alert;
    public order?: Order;
    public position?: Position;
    public marginCall?: MarginCall;
    public marginCallPolicy?: MarginCallPolicy;
    public customerServiceMessage?: string;

    constructor(notification: any, public symbol: string, public digits: number, build: boolean = true) {
        Object.assign(this, notification);

        if (build) {
            if (this.alert) {
                this.alert = new Alert(notification.alert, symbol, digits);
            }
            if (this.order) {
                this.order = new Order(notification.order, symbol, digits);
            }
            if (this.position) {
                this.position = new Position(notification.position, symbol, digits);
            }
            if (this.marginCall) {
                this.marginCall = new MarginCall(notification.marginCall, symbol, digits);
            }
            if (this.marginCallPolicy) {
                this.marginCallPolicy = new MarginCallPolicy(notification.marginCallPolicy, symbol, digits);
            }
        }
    }
}

