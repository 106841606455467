import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { DropdownItem } from 'reactstrap';
import { AppStateContext } from '../../../appContext';
import { Account } from '../../../models';
import { formatCurrency } from '../../../utils';
import "./AccountsMenu.scss";

export const AccountsMenu: React.FC<{}> = observer(() => {
    const context = useContext(AppStateContext);
    const { account } = context;

    const switchAccount = async (token: string) => {
        context.switchAccount(token);
    }

    return <div className='accounts-wrapper'>
        {account.otherAccounts.map((item: Account) => <DropdownItem key={item.token} onClick={() => switchAccount(item.token)} className={classNames({ active: item.token === account.token })}>
            <div className='account'>
                <div className='name'>
                    {item.denominatedIn}: {item.token}
                </div>
                <div className='info'>
                    {formatCurrency(item.availableBalance, item.symbol)}
                </div>
            </div>
        </DropdownItem>)}
    </div>
});