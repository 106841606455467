import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../../../appContext';
import { AccountMetric } from '../../../../models';
import { formatNumber } from '../../../../utils';
import MyApexChart from '../../../ApexChart';

interface SimpleMetricsProps {
    data: AccountMetric[];
    selectedMetrics: string[];
    zeroScale: boolean;
}

type MetricOptionsType = {
    [key: string]: {
        formatter: (value: number) => string;
        color: string;
        label: string;
        step?: number;
    };
};

const defaultStyle = {
    fontFamily: "Neue Haas Grotesk Display Pro",
    fontWeight: 500,
    fontSize: '1rem',
}

const defaultChartOptions: ApexOptions = {
    chart: {
        id: "simple-metrics-chart",
        toolbar: {
            show: false
        },
        group: 'metrics',
    },
    stroke: {
        curve: 'smooth',
        width: 2,
    },
    theme: {
        mode: 'dark',
    },
    xaxis: {
        type: 'datetime',
    },
    tooltip: {
        style: {
            fontSize: '1rem',
        },
    }
}

const SimpleMetrics: React.FC<SimpleMetricsProps> = ({ data, selectedMetrics, zeroScale }) => {
    const { account } = useContext(AppStateContext);
    const { t } = useTranslation();
    const symbol = account.symbol;
    const [options, setOptions] = useState<ApexOptions>(defaultChartOptions);
    const [series, setSeries] = useState<ApexAxisChartSeries>([]);


    const currencyFormatter = (value: number) => formatNumber(value, 0) + ' ' + symbol;
    const percentFormatter = (value: number) => formatNumber(value * 100, 2) + '%';
    const decimalFormatter = (value: number) => formatNumber(value, 2);

    const MetricSimpleOptions: MetricOptionsType = {
        'AvailableToTrade': {
            formatter: currencyFormatter,
            color: '#fd7f6f',
            label: t('enum.AvailableMetrics.AvailableToTrade'),
        },
        'NetEquity': {
            formatter: currencyFormatter,
            color: '#7eb0d5',
            label: t('enum.AvailableMetrics.NetEquity'),
        },
        'Cash': {
            formatter: currencyFormatter,
            color: '#b2e061',
            label: t('enum.AvailableMetrics.Cash'),
        },
        'UnrealizedPnl': {
            formatter: currencyFormatter,
            color: '#bd7ebe',
            label: t('enum.AvailableMetrics.UnrealizedPnl'),
        },
        'TotalMargin': {
            formatter: currencyFormatter,
            color: '#ffb55a',
            label: t('enum.AvailableMetrics.TotalMargin'),
        },
        'MarginIndicator': {
            formatter: percentFormatter,
            color: '#ffee65',
            label: t('enum.AvailableMetrics.MarginIndicator'),
        },
        'TotalValue': {
            formatter: currencyFormatter,
            color: '#beb9db',
            label: t('enum.AvailableMetrics.TotalValue'),
        },
        'VaR': {
            formatter: currencyFormatter,
            color: '#fdcce5',
            label: t('enum.AvailableMetrics.VaR'),
        },
        'CVaR': {
            formatter: currencyFormatter,
            color: '#fdcce5',
            label: t('enum.AvailableMetrics.CVaR'),
        },
        'PositionCount': {
            formatter: (value: number) => value ? value.toFixed(0) : '0',
            color: '#8bd3c7',
            label: t('enum.AvailableMetrics.PositionCount'),
            step: 1,
        },
        // position metrics

        // 'IndvidualPositions': {
        //     formatter: (value: number) => 'IndvidualPositions',
        //     color: "#b3d4ff",
        //     label: t('enum.AvailableMetrics.IndvidualPositions'),
        // },
        'IPMarginRatio': {
            formatter: percentFormatter,
            color: "#7c1158",
            label: t('enum.AvailableMetrics.IPMarginRatio'),
        },
        'IPCurrentMargin': {
            formatter: currencyFormatter,
            color: "#eb44e8",
            label: t('enum.AvailableMetrics.IPCurrentMargin'),
        },
        'IPCurrentValue': {
            formatter: currencyFormatter,
            color: "#00ffff",
            label: t('enum.AvailableMetrics.IPCurrentValue'),
        },
        'IPUnrealizedPnL': {
            formatter: currencyFormatter,
            color: "#f4f04c",
            label: t('enum.AvailableMetrics.IPUnrealizedPnL'),
        },
        'IPQuoteMain': {
            formatter: currencyFormatter,
            color: "#ff0000",
            label: t('enum.AvailableMetrics.IPQuoteMain'),
        },
        'IPQuoteBridge': {
            formatter: currencyFormatter,
            color: "#0000ff",
            label: t('enum.AvailableMetrics.IPQuoteBridge'),
        },

        // Not implemented yet
        ROI: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        SharpeRatio: {
            formatter: decimalFormatter,
            color: '#1bd3c7',
            label: t('enum.AvailableMetrics.SharpeRatio'),
        },
        SortinoRatio: {
            formatter: decimalFormatter,
            color: '#8b13c7',
            label: t('enum.AvailableMetrics.SortinoRatio'),
        },
        ExposureReport: {
            formatter: decimalFormatter,
            color: '#c3c387',
            label: t('enum.AvailableMetrics.ExposureReport'),
        },
        MaximumDrawdown: {
            formatter: decimalFormatter,
            color: '#8bd317',
            label: t('enum.AvailableMetrics.MaximumDrawdown'),
        },
        Volatility: {
            formatter: decimalFormatter,
            color: '#8b2217',
            label: t('enum.AvailableMetrics.Volatility'),
        },
        WinRate: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        AvgWinToAvgLossRatio: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        EquityCurve: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        CAGR: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
    }

    useEffect(() => {
        const yaxis: ApexYAxis[] = selectedMetrics.map((metric, idx): ApexYAxis => {
            return {
                min: zeroScale ? 0 : undefined,
                seriesName: MetricSimpleOptions[metric].label,
                opposite: idx % 2 !== 0,
                stepSize: MetricSimpleOptions[metric].step,
                title: {
                    text: MetricSimpleOptions[metric].label,
                    style: {
                        color: MetricSimpleOptions[metric].color,
                        ...defaultStyle
                    }
                },
                labels: {
                    formatter: MetricSimpleOptions[metric].formatter,
                    style: defaultStyle
                },
            }
        });
        const xaxis: ApexXAxis = {
            type: 'datetime',
            categories: data.map(item => moment(item.AlignedTime).toDate().getTime()),
            labels: {
                style: defaultStyle
            }
        };

        // Create series for simple metrics
        const allSeries: ApexAxisChartSeries = selectedMetrics.map(metric => {
            return {
                color: MetricSimpleOptions[metric].color,
                name: MetricSimpleOptions[metric].label,
                data: data.map(item => item[metric as never] || null)
            }
        });

        const opts = { ...options };
        opts.xaxis = xaxis;
        opts.yaxis = yaxis;

        setOptions(opts);
        setSeries(allSeries);
    }, [data, zeroScale, selectedMetrics]);


    return (
        <div>
            <MyApexChart
                options={options}
                series={series}
                type="line"
                height="350"
                width="100%"
            />
        </div>
    );
};

export default SimpleMetrics;