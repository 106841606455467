import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface AreaActionProps extends WithTranslation {
  link: string;
}

const AreaAction: React.FC<AreaActionProps> = ({ link, t }) => {
  useEffect(() => {
    window.location.replace(link);
  }, [link]);

  return <div>{t('auth.redirecting')}</div>;
}

export default withTranslation()(AreaAction);