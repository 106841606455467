import { Select } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableMetrics, ChartMetrics, MetricAccessor } from 'src/models';
import { NumericOption, useEnumNumericOptions } from 'src/services/hooks';
import { AppStateContext } from 'src/appContext';

export const useMetrics = (): [JSX.Element, AvailableMetrics[]] => {
    const { t } = useTranslation();
    const appState = useContext(AppStateContext);
    const metricsOptions = useEnumNumericOptions(AvailableMetrics, 'AvailableMetrics');
    
    metricsOptions.forEach(item => {
        item.disabled = !(item.value & ChartMetrics);
    });
    const allOptions = metricsOptions.filter(item => item.value && !MetricAccessor[item.value].startsWith('IP'));;
    const positionsOptions = metricsOptions.filter(item => item.value && MetricAccessor[item.value].startsWith('IP'));
    allOptions.push({
        label: 'Positions',
        options: positionsOptions,
    } as NumericOption);

    const component = <>
        <label htmlFor="metrics">{t('var.fields.metrics')}</label>
        <Select
            mode="multiple"
            allowClear={false}
            style={{ width: '100%' }}
            placeholder={t('portfolio.common.select_please')}
            onChange={(value) => appState.setMetrics(value)}
            options={allOptions}
            value={appState.metrics}
        />
    </>

    return [component, appState.metrics];
};