import { observer } from "mobx-react-lite";
import React, { useState } from 'react';
import { usePendingOrdersData } from '../../../../services/hooks/usePendingOrdersData';
import Loading from '../../../Loading';
import { PendingOrdersView } from '../../../common/Orders/Pending/PendingOrdersView';
import { useTranslation } from "react-i18next";
import Select, { Options } from 'react-select'
import { OrderSide, OrderType } from "../../../../models";
import { SortableProperty } from "../../../../services";
import { useOutletContext } from "react-router";
import { PorfolioTabPropsContextType } from "../PortfolioPage";

export const PendingOrdersSortColumns = [
    {
        key: SortableProperty.PairId,
        label: 'portfolio.orders.ticker'
    },
    {
        key: SortableProperty.OrderType,
        label: 'portfolio.orders.type'
    },
    {
        key: SortableProperty.Side,
        label: 'portfolio.orders.side'
    },
    {
        key: SortableProperty.OrderTriggerPrice,
        label: 'portfolio.orders.trigger_price'
    },
    {
        key: SortableProperty.CreatedAt,
        label: 'portfolio.orders.created_at'
    },
    {
        key: SortableProperty.OrderValidity,
        label: 'portfolio.orders.good_until'
    },
];


export const PendingOrdersFilters: React.FC = ({ handleFilters }: { handleFilters: CallableFunction }) => {
    const [side, setSide] = React.useState<any>(undefined);
    const [type, setType] = React.useState<any>(undefined);
    const { t } = useTranslation();
    const typeOptions: Options<{ value: string, label: string }> = [
        { value: OrderType.Market, label: OrderType.Market }, 
        { value: OrderType.Limit, label: OrderType.Limit }, 
        { value: OrderType.Stop, label: OrderType.Stop }, 
    ];
    const sideOptions: Options<{ value: string, label: string }> = [
        { value: OrderSide.Buy, label: OrderSide.Buy }, 
        { value: OrderSide.Sell, label: OrderSide.Sell }, 
    ];
    const handleSide = (selectedOption: any) => {
        setSide(selectedOption);
    }
    const handleType = (selectedOption: any) => {
        setType(selectedOption);
    }

    React.useEffect(() => {
        handleFilters({ side: side?.value, type: type?.value });
    }, [handleFilters, side, type]);

    return <>
        <Select classNamePrefix='select' value={type} options={typeOptions} isClearable={true} placeholder={t('portfolio.common.select_type')} onChange={handleType} />
        <Select classNamePrefix='select' value={side} options={sideOptions} isClearable={true} placeholder={t('portfolio.common.select_side')} onChange={handleSide} />
    </>
}

const PendingOrdersTab: React.FC<{}> = observer(() => {
    const {currency, ...rest} = useOutletContext<PorfolioTabPropsContextType>();


    const [lastUpdate, setLastUpdate] = useState(new Date());
    const { data: orders, loading } = usePendingOrdersData({...rest, updateAt: lastUpdate});

    // TODO: move to invalidating resources
    const refreshOrders = () => {
        setLastUpdate(new Date());
    }

    if (loading || !orders) {
        return <Loading />
    }

    return <PendingOrdersView currency={currency} orders={orders} refreshOrders={refreshOrders} />
});

export default PendingOrdersTab;
