import { confirmDialog } from "primereact/confirmdialog";
import { showErrorNotification, showNotification } from "../../../../../common/notifications";
import { getGoodUntil } from "../../../../../common/order-utils";
import { ExtendedPairInfo, OrderSide, OrderType, PairInfo, QtyType, Quote, TriggerStatus } from "../../../../../models";
import { orderService } from "../../../../../services";
import { formatNumber } from "../../../../../utils";
import i18n from "../../../../../i18n";

export class OrderFormModel {
    pair: PairInfo;
    quote?: Quote;
    extendedInfo?: ExtendedPairInfo;
    values: any;
    t: CallableFunction;
    previousRequest: any = null;

    constructor(pair: PairInfo, values: any, t: CallableFunction, pairInfo: ExtendedPairInfo | undefined, quote: Quote | undefined) {
        this.pair = pair;
        this.extendedInfo = pairInfo;
        this.quote = quote;
        this.values = values;
        this.t = t;
    }

    getPriceTolerance(pair: PairInfo, values: any, price: number) {
        const { priceTolerance, } = values;
        if (priceTolerance === null || priceTolerance === '') {
            return null;
        }

        return pair.pip * priceTolerance;
    }

    get goodUntil() {
        return getGoodUntil(this.values);
    }

    async submit(values: any) {

        this.values = values;
        const isMarket = values.orderType === OrderType.Market;
        let price;
        if (isMarket) {
            price = values.side === OrderSide.Buy ? this.pair.ask : this.pair.bid;
        } else {
            price = values.price;
        }
        const orderReq = {
            orderDraft: {
                PairId: this.pair.id,
                type: values.orderType,
                side: values.side,
                persistentStatus: TriggerStatus.Ticket,
                referenceType: "OpenPosition",
                qty: this.totalQty,
                goodUntil: this.goodUntil,
                triggerPrice: !isMarket ? values.price : 0,
                takeProfitTotal: values.takeProfit ? values.takeProfitTotal : null,
                takeProfitTrigger: parseFloat(values.takeProfit) && !isMarket ? price : null,
                stopLossTotal: values.stopLoss ? parseFloat(values.stopLossTotal) : null,
                stopLossTrigger: values.stopLoss && !isMarket ? price : null,
                quotedPrice: price,
                priceTolerance: this.getPriceTolerance(this.pair, values, price)
            }
        };

        Object.keys(orderReq.orderDraft).forEach((key: keyof typeof orderReq.orderDraft) => {
            if (orderReq.orderDraft[key] === null) {
                delete orderReq.orderDraft[key];
            }
        });

        console.log(JSON.stringify(orderReq));
        if (JSON.stringify(this.previousRequest) === JSON.stringify(orderReq)) {
            confirmDialog({
                message: i18n.t("dialogs.duplicate_order.promt"),
                acceptLabel: i18n.t("dialogs.duplicate_order.accept"),
                rejectLabel: i18n.t("dialogs.duplicate_order.reject"),
                accept: () => {this.processRequest(orderReq)},
            });
        } else {
            await this.processRequest(orderReq);
            this.previousRequest = orderReq;
        }
    }

    async processRequest(orderReq: any) {
        try {
            const response = await orderService.SubmitOrder(orderReq);
            console.table(response);
            showNotification(this.t("notifications.order.created", { pair: this.pair.id }));
            window.lastOrderId = response.orderToken;
        } catch (error) {
            showErrorNotification(error.message);
        }
    }

    get isMarket() {
        return this.values.orderType === OrderType.Market;
    }

    get askPrice() {
        if (this.isMarket) {
            return this.pair.ask;
        }
        return parseFloat(this.values.price);
    }

    get askPriceDisplay() {
        return formatNumber(this.askPrice, this.pair.pairDecimals);
    }

    get bidPrice() {
        if (this.isMarket) {
            return this.pair.bid;
        }
        return parseFloat(this.values.price);
    }

    get totalQty() {
        return this.values.qtyType === QtyType.Qty ? this.values.qty : this.values.qty * this.pair.lotSize;
    }

    get bidPriceDisplay() {
        return formatNumber(this.bidPrice, this.pair.pairDecimals);
    }

    get actionPrice() {
        if (this.isMarket) {
            if (!this.values.side) {
                return this.pair.mid;
            }
            return this.values.side === OrderSide.Buy ? this.pair.ask : this.pair.bid;
        }
        return this.values.price;
    }

    get marginLeverage() {
        if (!this.extendedInfo) {
            return undefined;
        }
        return this.values.side === OrderSide.Buy ? this.extendedInfo.marginLeverageShort : this.extendedInfo.marginLeverageLong;
    }

    get marginRequired() {
        if (!this.extendedInfo) {
            return 0;
        }
        return 1 / this.marginLeverage! * 100;
    }

    get bridgeRate() {
        if (!this.extendedInfo) {
            return undefined;
        }
        if (this.extendedInfo.isBridge) {
            const val = this.values.side === OrderSide.Buy ? this.extendedInfo.bridgePairInfo.ask : this.extendedInfo.bridgePairInfo.bid;
            return this.extendedInfo.bridgeForward ? val : 1 / val;
        }
        return 1;
    }

    get marginRequiredTotal() {
        if (!this.extendedInfo) {
            return undefined;
        }
        return this.bridgeRate! * this.marginRequired! * this.totalQty * this.actionPrice / 100;
    }

    get orderFeeFixed() {
        if (!this.extendedInfo) {
            return undefined;
        }
        return (this.values.side === OrderSide.Buy ? this.extendedInfo.effOverride.orderFeeFixedBuy : this.extendedInfo.effOverride.orderFeeFixedSell);
    }

    get orderFeeFloating() {
        if (!this.extendedInfo) {
            return undefined;
        }
        return (this.values.side === OrderSide.Buy ? this.extendedInfo.effOverride.orderFeeFloatingBuy : this.extendedInfo.effOverride.orderFeeFloatingSell) * 100;
    }

    get orderFeeFloatingTotal() {
        if (!this.extendedInfo) {
            return undefined;
        }
        return this.bridgeRate! * this.orderFeeFloating! * this.totalQty * this.actionPrice / 100;
    }

    get chargeTiming() {
        if (!this.extendedInfo) {
            return undefined;
        }
        return this.t(`enum.fee_charge.${this.extendedInfo.effOverride.chargeTiming}`)
    }

    getMaxQty(balance: number) {
        if (!this.extendedInfo) {
            return 0;
        }
        const totalQty = balance / this.actionPrice * this.marginLeverage! / this.bridgeRate!;
        return Math.max(0, totalQty);
        
        // const totalFee = this.bridgeRate! * this.orderFeeFloating! / 100 * totalQty * this.actionPrice + this.orderFeeFixed!;
        // const totalAmount = (balance - totalFee) / this.actionPrice * this.marginLeverage!;
        // console.log(totalAmount);
        
        // return totalAmount;
    }
}