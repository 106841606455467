import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import Select, { Options } from 'react-select';
import { Col, Row, Container, Nav, NavItem, TabContent } from 'reactstrap';
import Loading from '../../Loading';
import { OpenPositionsSortColumns, OpenPositionsTab, OpenPositionsTabFilters } from './tabs/OpenPositionsTab';

import { Dayjs } from 'dayjs';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AccountCurrencyType, AppStateContext, MenuItem } from '../../../appContext';
import { rangePresets, withParams } from '../../../utils';
import AlertsTable from './tabs/AlertsTab';
import ClosedOrdersTable, { ClosedOrdersSortColumns } from './tabs/ClosedOrdersTab';
import ClosedPositionsTab, { ClosedPositionsSortColumns } from './tabs/ClosedPositionsTab';
import PendingOrdersTab, { PendingOrdersFilters, PendingOrdersSortColumns } from './tabs/PendingOrdersTab';
import TransactionsTable from './tabs/TransactionsTab';

import {DatePicker } from 'antd';
import { Navigate, NavLink, Outlet } from 'react-router-dom';
import { PairInfo } from '../../../models';
import { SortableProperty, assetsService } from '../../../services';
import "./PortfolioPage.scss";

const { RangePicker } = DatePicker;

interface PortfolioPageProps extends WithTranslation {
    params: any;
    navigate: any;
}

interface PortfolioPageState {
    dateRange: any;
    pairId: any;
    isLoading: boolean;
    pairOptions: Options<{ value: string, label: string }>;
    filters: any;
    sort: any;
    sortAsc: boolean;
    subpage: string;
}

type SortColumn = {
    key: SortableProperty;
    label: string;
};

interface TabItem {
    name: string;
    comp: React.FC<any>;
    filters?: React.FC<any>;
    sortColumns?: SortColumn[];
    quoute: boolean;
    calendar: boolean;
    pair: boolean;
}

export interface PorfolioTabPropsContextType {
    currency: AccountCurrencyType;
    pairId?: string;
    filters?: any;
    sort?: SortableProperty;
    sortAsc?: boolean;
    dateRange?: Dayjs[];
}

export const PortfolioPage = observer(class PortfolioPage extends Component<PortfolioPageProps, PortfolioPageState> {
    static displayName = PortfolioPage.name;
    context!: ContextType<typeof AppStateContext>;
    tabs: { [key: string]: TabItem };

    constructor(props: PortfolioPageProps) {
        super(props);
        this.state = {
            dateRange: null,
            pairId: '',
            isLoading: false,
            pairOptions: [],
            filters: null,
            sort: '',
            subpage: 'positions',
            sortAsc: true
        };
        const t = props.t;

        this.handleFilters = this.handleFilters.bind(this);

        this.tabs = {
            'positions': {
                name: t('portfolio.tabs.positions'),
                comp: OpenPositionsTab,
                filters: OpenPositionsTabFilters,
                sortColumns: OpenPositionsSortColumns,
                quoute: true,
                calendar: false,
                pair: true
            },
            'orders': {
                name: t('portfolio.tabs.orders'),
                comp: PendingOrdersTab,
                filters: PendingOrdersFilters,
                sortColumns: PendingOrdersSortColumns,
                quoute: true,
                calendar: false,
                pair: true
            },
            'closed_positions': {
                name: t('portfolio.tabs.closed_positions'),
                comp: ClosedPositionsTab,
                quoute: true,
                sortColumns: ClosedPositionsSortColumns,
                calendar: true,
                pair: true
            },
            'closed_orders': {
                name: t('portfolio.tabs.order_history'),
                comp: ClosedOrdersTable,
                sortColumns: ClosedOrdersSortColumns,
                quoute: false,
                calendar: true,
                pair: true
            },
            'transactions': {
                name: t('portfolio.tabs.transactions'),
                comp: TransactionsTable,
                quoute: false,
                calendar: true,
                pair: false
            },
            'alerts': {
                name: t('portfolio.tabs.alerts'),
                comp: AlertsTable,
                quoute: true,
                calendar: false,
                pair: true
            },
        }
    }

    handleFilters(filters: any) {
        this.setState({ filters: filters });
    }

    toggleCurrency(useQuoteCurrency: boolean) {
        this.context.setCurrency(useQuoteCurrency ? 'quote' : 'account');
    }

    onRangeChange(newValue: any) {
        this.setState({ dateRange: newValue });
    }

    async loadData() {
        if (this.state.pairOptions.length > 0 || this.state.isLoading) {
            return;
        }
        this.setState({ isLoading: true });

        // TODO: Load real user pairs.
        try {
            const pairs = (await assetsService.GetPairListInfo(-1)).items;
            const options = pairs.map((item: PairInfo) => { return { value: item.id, label: item.id } });

            this.setState({
                isLoading: false, pairOptions: [
                    { value: '', label: 'All' },
                    ...options]
            });
        } catch (e) {
            // this.setState({ isLoading: false });
        }
    }

    componentDidMount() {
        if (this.context.clientReady) {
            this.loadData();
        }
        this.context.setBottomMenu(this.getNav());
    }

    componentWillUnmount(): void {
        this.context.setBottomMenu(null);
    }

    componentDidUpdate(prevProps: Readonly<PortfolioPageProps>, prevState: Readonly<PortfolioPageState>) {
        if (this.context.clientReady) {
            this.loadData();
        }

        const activeTab = window.location.pathname.split('/')[2];

        if (prevState.subpage !== activeTab) {
            this.setState({ sort: null, sortAsc: true, subpage: activeTab });
        }
        this.context.setBottomMenu(this.getNav());
    }

    getNav(): MenuItem[] {
        return Object.entries(this.tabs).map(([key, value]) => {
            return {
                path: `/portfolio/${key}`,
                title: value.name,
                key: key
            };
        });
    }

    renderTab(tab: string) {
        const tabInfo = this.tabs[tab];
        const sortColumns = tabInfo.sortColumns ? tabInfo.sortColumns.map((item) => { return { label: this.props.t(item.label), value: item.key } }) : null

        const filterComponent = tabInfo.filters ? React.createElement(tabInfo.filters, { handleFilters: this.handleFilters }) : null;
        this.context.setTitle(this.props.t('menu.portfolio'), tabInfo['name']);
        return {
            showQuote: tabInfo.quoute,
            showCalendar: tabInfo.calendar,
            showPair: tabInfo.pair,
            tabFilters: filterComponent,
            sortColumns: sortColumns,
            currency: tabInfo.quoute ? this.context.currency : 'account',
        };
    }

    render() {
        const activeTab = window.location.pathname.split('/')[2];
        const tabInfo = this.tabs[activeTab];
        if (!tabInfo) {
            return <Navigate to="positions" />;
        }

        const { dateRange, pairId, pairOptions, isLoading, sort, sortAsc } = this.state;
        
        const { showQuote, showCalendar, showPair, tabFilters, sortColumns } = this.renderTab(activeTab);

        const { account, connectionReady, isAdaptive, currency, isMobile } = this.context;

        if (!connectionReady) {
            return <Loading />
        }
        const handlePair = (selectedOption: any) => {
            this.setState({ pairId: selectedOption });
        }
        const handleSort = (selectedOption: any) => {
            this.setState({ sort: selectedOption });
        }

        const sortComponent = sortColumns && <div className="sort-container">
            <label className='label'>{this.props.t('portfolio.common.sort_by')}</label><Select classNamePrefix='select' value={sort ? sort : sortColumns[0]} options={sortColumns} onChange={handleSort} />
            <button className={classNames('icon', { active: sortAsc })} onClick={() => this.setState({ sortAsc: !sortAsc })}>{sortAsc ? '↑' : '↓'}</button>
        </div>
        const isDirect = currency === 'account';

        const tabContext: PorfolioTabPropsContextType = {
            currency: tabInfo.quoute ? this.context.currency : 'account',
            dateRange: this.state.dateRange,
            pairId: this.state.pairId?.value,
            filters: this.state.filters,
            sort: this.state.sort ? this.state.sort.value : tabInfo.sortColumns ? tabInfo.sortColumns[0].key : null,
            sortAsc: this.state.sortAsc
        }

        return (
            <Container fluid className='PorfolioPage'>
                <Row>
                    <Col>
                        <Nav tabs>
                            {!isAdaptive && Object.entries(this.tabs).map(([key, value]) => <NavItem key={key}>
                                <NavLink className={'nav-link'}
                                    to={'/portfolio/' + key}
                                >
                                    {value.name}
                                </NavLink>
                            </NavItem>)}
                            {!isMobile && <NavItem className='ms-auto'>
                                <div className="d-flex inline-form filter-container">
                                    {sortComponent}
                                    {tabFilters}
                                    {showCalendar && <div><label className='label'>{this.props.t('portfolio.common.date_range')}</label><RangePicker presets={rangePresets} size='small' value={dateRange} onChange={(a1, a2) => a1 && this.onRangeChange([a1[0]!, a1[1]!])} /></div>}
                                    {showPair && <div style={{ minWidth: '110px' }}><Select isLoading={isLoading} classNamePrefix='select' value={pairId} options={pairOptions} isClearable={true} placeholder={this.props.t('portfolio.common.select_pair')} onChange={handlePair} /></div>}
                                    {showQuote && !isAdaptive && <div className="form-check form-switch nav-link">
                                        <label htmlFor='currencyToggler' className={classNames({ active: isDirect })}>{account.denominatedIn}</label>
                                        <input onChange={(e) => this.toggleCurrency(e.target.checked)} className="form-check-input" id="currencyToggler" name="currencyToggler" type="checkbox" checked={!isDirect} />
                                        <label htmlFor='currencyToggler' className={classNames({ active: !isDirect })}>{this.props.t('portfolio.common.quote_currency')}</label>
                                    </div>}
                                </div>
                            </NavItem>}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <Outlet context={tabContext} />
                        </TabContent>
                    </Col>
                </Row>
            </Container>
        );
    }
});

PortfolioPage.contextType = AppStateContext;
export default withTranslation()(withParams(PortfolioPage));