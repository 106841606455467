import { AssetCategory } from "./AssetService";

export class LogoImageService {
    static get instance() { return logoImageService }
    
    getPairLogoUrl(pairId: string) {
        return `/api/logoimage/pair?pairId=${pairId}`;
    }

    getCategoryLogoUrl(category: AssetCategory) {
        return `/api/logoimage/category?type=${category.type}&token=${category.token}`;
    }
}

export const logoImageService = new LogoImageService();