import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import Loading from '../../../../Loading';
import { TooltipItem } from '../../../../TootlipItem';
import { useTranslation } from 'react-i18next';
import { AssetCategory, SearchFilter, logoImageService } from '../../../../../services';
import useSearchData from './useSearchData';
import useSearchMetaData from './useSearchMetaData';


interface SearchResultProps {
    search: string;
    categories: AssetCategory[];
}

const SearchResults: React.FC<SearchResultProps> = ({ search, categories }) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<SearchFilter>({});

    const { results, error, isLoading } = useSearchData({ search, filters });
    const { options, handleFilter } = useSearchMetaData({ results, categories, setFilters, filters });

    return (
        <div>
            <div className='filter'>
                <div>{t('search.results')}</div>
                <div>
                    <Select isClearable={true} classNamePrefix='select' menuPlacement="bottom" onChange={(event) => handleFilter('assetClassId', event)} options={options.assetClassOptions} placeholder={t('search.all_assets')} />
                </div>
                <div>
                    <Select isClearable={true} classNamePrefix='select' menuPlacement="bottom" onChange={(event) => handleFilter('sector', event)} options={options.sectorOptions} placeholder={t('search.sector')} />
                </div>
                <div>
                    <Select isClearable={true} classNamePrefix='select' menuPlacement="bottom" onChange={(event) => handleFilter('industry', event)} options={options.industryOptions} placeholder={t('search.industry')} noOptionsMessage={() => t('search.select_sector')} />
                </div>
                <div>
                    <Select isClearable={true} classNamePrefix='select' menuPlacement="bottom" onChange={(event) => handleFilter('exchange', event)} options={options.exchangeOptions} placeholder={t('search.exchange')} />
                </div>
                <div>
                    <Select isClearable={true} classNamePrefix='select' menuPlacement="bottom" onChange={(event) => handleFilter('subtype', event)} options={options.subtypeOptions} placeholder={t('search.subtype')} />
                </div>
            </div>
            <div className='results'>
                {error != null && <div className="error">{error}</div>}
                {(results && results.length) === 0 && <div className="error">
                    {search.length >= 3 && <span>{t('search.not_found')}</span>}
                    {search.length < 3 && <span>{t('search.min_length')}</span>}
                </div>}
                {(results && results.length > 0) && <div className='table-container'>
                    {results.slice(0, 15).map(item =>
                        <Link className="table-row" to={`/trading/${encodeURIComponent(item.id)}`} key={item.id} >
                            <div className='table-cell cell-pair'>
                                <div className='pair-name'>
                                    {<img src={logoImageService.getPairLogoUrl(item.id)} className='pair-logo'></img>}
                                    {item.id}
                                </div>
                            </div>
                            <div className='table-cell cell-name'>
                                {item.dispName}
                            </div>
                            <div className='table-cell cell-type'>
                                {item.type}
                            </div>
                            <div className='table-cell cell-market'>
                                <TooltipItem text={`${item.exchangeDescription} \n${item.exchangeCountry}`} id={item.id.replace('/', '-').replace(':', '-')}>
                                    {item.typeDescription}
                                </TooltipItem>
                            </div>
                        </Link>
                    )}
                </div>}
                {isLoading && <Loading />}
            </div>
        </div>
    );
};

export default SearchResults;