import i18n from "src/i18n";
import { LyraNamed } from "./Base";
import { ExposureLimit } from "./ExposureLimits";

export enum AssetClassesEnum {
    Favorites = -1, // Client only

    Unknown = 0,

    FX          = 1 << 1,
    Crypto      = 1 << 2,   
    Equity      = 1 << 3,
    Index       = 1 << 4,   
    Commodity   = 1 << 5,
    ETF         = 1 << 6,
    MutualFund  = 1 << 7,
    Bond        = 1 << 8,


    Option      = 1 << 17,
    Future      = 1 << 18,
    Forward     = 1 << 19,
    CFD         = 1 << 20,
    Swap        = 1 << 21,
    CDS         = 1 << 22,

    VanillaAm   = 1 << 24,
    VanullaEu   = 1 << 25,
    Binary      = 1 << 26,
    Exotic      = 1 << 27,
};

export const AssetClassesEnumMask = {
    MaskDerivative: AssetClassesEnum.Option | AssetClassesEnum.Future | AssetClassesEnum.Forward | AssetClassesEnum.CFD | AssetClassesEnum.Swap | AssetClassesEnum.CDS,
    MaskUnderlying: AssetClassesEnum.FX | AssetClassesEnum.Crypto | AssetClassesEnum.Equity | AssetClassesEnum.Index | AssetClassesEnum.Commodity | AssetClassesEnum.ETF | AssetClassesEnum.MutualFund | AssetClassesEnum.Bond
}

export const AssetClassesEnumMap: [string, AssetClassesEnum][] = Object.entries(AssetClassesEnum).filter(([key, value]) => typeof value === 'number' && value > 0) as [string, AssetClassesEnum][];
export const AssetClassesEnumNames: Map<AssetClassesEnum, string> = new Map(AssetClassesEnumMap.map(([key, value]) => [value, key]));

export const AssetClassesEnumToString = (value: AssetClassesEnum) => {
    
    const ret = [];
    for (let [key, val] of AssetClassesEnumMap) {
        if (val & value) {
            ret.push(i18n.t(`enum.AssetClassEnum.${key}`));
        }
    }
    
    return ret.join(' ');
}


export interface Asset extends LyraNamed, ExposureLimit {
    name: string;
    altName: string;
    description: string;
    symbol: string;
    assetClassId: AssetClassesEnum;
    decimals: number;
    displayDecimals: number;
    enabled: boolean;
}
