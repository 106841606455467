import ApexCharts, { ApexOptions } from 'apexcharts';
import React, { Component, RefObject } from 'react';

declare global {
    interface Window {
        ApexCharts: typeof ApexCharts;
    }
}

window.ApexCharts = ApexCharts;

interface ChartsProps {
    type: string;
    width?: any;
    height?: any;
    series: ApexAxisChartSeries;
    options: ApexOptions;
}


export default class MyApexChart extends Component<ChartsProps> {
    private chartRef: RefObject<HTMLDivElement> | null;
    private chart: ApexCharts | null;

    constructor(props: ChartsProps) {
        super(props);
        this.chartRef = React.createRef<HTMLDivElement>();
        this.chart = null;
    }

    render() {
        const { ...props } = this.props;
        return <div ref={this.chartRef} {...props} />;
    }

    componentDidMount() {
        const current = this.chartRef?.current;
        if (current) {
            this.chart = new ApexCharts(current, this.getConfig());
            this.chart.render();
        }
    }

    getConfig() {
        const { type, height, width, series, options } = this.props;
        const newOptions = {
            chart: {
                type,
                height,
                width,
            },
            series,
        };

        return this.extend(options, newOptions);
    }

    isObject(item: any) {
        return item && typeof item === 'object' && !Array.isArray(item) && item != null;
    }

    extend(target: any, source: any) {
        if (typeof Object.assign !== 'function') {
            (function () {
                Object.assign = function (target: any) {
                    if (target === undefined || target === null) {
                        throw new TypeError('Cannot convert undefined or null to object');
                    }

                    let output = Object(target);
                    for (let index = 1; index < arguments.length; index++) {
                        let source = arguments[index];
                        if (source !== undefined && source !== null) {
                            for (let nextKey in source) {
                                if (source.hasOwnProperty(nextKey)) {
                                    output[nextKey] = source[nextKey];
                                }
                            }
                        }
                    }
                    return output;
                };
            })();
        }

        let output = Object.assign({}, target);
        if (this.isObject(target) && this.isObject(source)) {
            Object.keys(source).forEach((key) => {
                if (this.isObject(source[key])) {
                    if (!(key in target)) {
                        Object.assign(output, {
                            [key]: source[key],
                        });
                    } else {
                        output[key] = this.extend(target[key], source[key]);
                    }
                } else {
                    Object.assign(output, {
                        [key]: source[key],
                    });
                }
            });
        }
        return output;
    }

    componentDidUpdate(prevProps: ChartsProps) {
        const { options, series } = this.props;
        const prevOptions = JSON.stringify(prevProps.options);
        const prevSeries = JSON.stringify(prevProps.series);
        const currentOptions = JSON.stringify(options);
        const currentSeries = JSON.stringify(series);

        if (!this.chart)
            return;

        if (prevOptions !== currentOptions || prevSeries !== currentSeries) {
            if (prevSeries === currentSeries) {
                this.chart.updateOptions(this.getConfig(), false, false, false);
            } else if (prevOptions === currentOptions) {
                this.chart.updateSeries(series);
            } else {
                this.chart.updateOptions(this.getConfig(), false, false, false);
            }
        }
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.destroy();
        }
    }
}