import React from 'react';

import { observer } from "mobx-react-lite";
import useAlertViewData, { AlertsViewInternalProps } from './useAlertsViewData';
import { Alert } from '../../../models';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';


interface AlertRowProps {
    alert: Alert;
    symbol: string;
    refreshAlerts: CallableFunction;
}

const AlertRowBlock: React.FC<AlertRowProps> = observer(({ alert, symbol, refreshAlerts }) => {
    const sideClass = classNames('item side inline-form', alert.SideClass);
    const renderer = useAlertViewData({ symbol, refreshAlerts });
    const { t } = useTranslation();

    return (
        <div className={sideClass}>
            <div className='ticker left'>
                {renderer.ticker.render(alert)}
            </div>
            <div className='data'>
                <div className='data-2 data-item'>
                    <div className='cell price-content'>
                        <div className="header">{renderer.trigger_price.title}</div>
                        {renderer.trigger_price.render(alert)}
                    </div>
                </div>
                <div className='data- data-item'>
                    <div className='cell validity-content'>
                        <div className="header">{t('portfolio.alerts.validity')}</div>
                        {renderer.good_until.render(alert)}
                    </div>
                </div>
            </div>
            {renderer.actions.render(alert)}
        </div>
    )
})

export const AlertsViewBlocks: React.FC<AlertsViewInternalProps> = observer(({ alerts, symbol, refreshAlerts }) => {
    return <>
        <div className='positions responsive-data-table data-table portfolio-table'>
            {alerts.map(alert => <AlertRowBlock alert={alert} key={alert.id} symbol={symbol} refreshAlerts={refreshAlerts} />)}
        </div>
    </>
});
