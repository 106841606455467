import React from 'react';
import moment from "moment";
import { BridgedModel } from "./BridgedModel";
import { PairInfo } from "./PairInfo";
import { UnitValueField } from '../components/fields';
import { renderToString } from 'react-dom/server';

export enum GoodUntilTypes {
    GTC = 0,
    GTD = 1,
    GTT = 2,
}

export function getGoodUntilOptions(t: CallableFunction) {
    return [
        { value: GoodUntilTypes.GTC, label: t('enum.good_until_full.GTC') },
        { value: GoodUntilTypes.GTD, label: t('enum.good_until_full.GTD') },
        { value: GoodUntilTypes.GTT, label: t('enum.good_until_full.GTT') },
    ]
}

export function getGoodUntilShortOptions(t: CallableFunction) {
    return [
        { value: GoodUntilTypes.GTC, label: t('enum.good_until_short.GTC') },
        { value: GoodUntilTypes.GTD, label: t('enum.good_until_short.GTD') },
        { value: GoodUntilTypes.GTT, label: t('enum.good_until_short.GTT') },
    ]
}

class Identity {

}

export enum OrderSide {
    Unknown = 'Unknown',
    Buy = 'Buy',
    Sell = 'Sell'
}

export enum OrderPositionAssociation {
    Unknown = 'Unknown',
    OpenPosition = 'OpenPosition',
    ClosePosition = 'ClosePosition',
    PositionStopLoss = 'PositionStopLoss',
    PositionTakeProfit = 'PositionTakeProfit'
}


export enum TriggerStatus {
    Unknown = 0,
    Ticket = 16,
    Pending = 512,
    Executing = 1024,
    Completed = 16384,
    Cancelled = 33554432
}

export const TriggerStatusMap: [string, TriggerStatus][] = Object.entries(TriggerStatus).filter(([key, value]) => typeof value === 'number' && value > 0) as [string, TriggerStatus][];
export const TriggerStatusNames: Map<TriggerStatus, string> = new Map(TriggerStatusMap.map(([key, value]) => [value, key]));


export class Triggerable extends BridgedModel {
    id: number = 0;
    accountId: number = 0;
    cancellationReason: string;
    createdAt: Date;
    createdByUserId?: number;
    executedAt: Date | null;
    executionPrice: number;
    goodUntil: Date | null;
    isExecutable: boolean;
    lastUpdatedAt: Date | null;
    pairId: string = '';
    pairInfo?: PairInfo;
    persistentStatus: TriggerStatus = TriggerStatus.Unknown;
    side: OrderSide = OrderSide.Unknown;
    token: string = '';
    triggerPrice: number = 0;
    identity?: Identity | null = null;

    constructor(accountSymbol: string, accountDigits: number, data: Object) {
        super(accountSymbol, accountDigits, data);
    }

    init() {
        this.goodUntil = this.goodUntil ? new Date(this.goodUntil) : null;
        this.createdAt = new Date(this.createdAt);
        this.lastUpdatedAt = this.lastUpdatedAt ? new Date(this.lastUpdatedAt) : null;
        this.executedAt = this.executedAt ? new Date(this.executedAt) : null;
    }

    get isBuy() {
        return this.side === OrderSide.Buy;
    }

    get TriggerPrice() {
        return {
            main: { value: this.triggerPrice * this.BridgeRate, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.triggerPrice, unit: this.bridgeSymbol, digits: this.bridgePairDigits },
        }
    }

    get SideName() {
        return `enum.order_side.${this.side}`;
    }

    get SideClass() {
        return this.side === OrderSide.Buy ? 'side-Long side-Buy' : 'side-Short side-Sell';
    }

    get goodUntilType() {
        if (!this.goodUntil) {
            return GoodUntilTypes.GTC;
        }
        const mDate = moment(this.goodUntil);
        if (this.goodUntil.getFullYear() === 2100) {
            return GoodUntilTypes.GTC;
        }
        const eod = moment().endOf('day');
        if (eod.isSame(mDate, 'minute')) {
            return GoodUntilTypes.GTD;
        }
        return GoodUntilTypes.GTT;
    }

    get TriggerPriceDisplay() {
        return renderToString(<UnitValueField value={this.triggerPrice} unit={this.accountSymbol} precision={this.accountDigits} />)
    }

    get ExecutionPriceDisplay() {
        return renderToString(<UnitValueField value={this.executionPrice} unit={this.accountSymbol} precision={this.accountDigits} />)
    }

    get PersistentStatusName(): string {
        return TriggerStatusNames.get(this.persistentStatus) || 'Unknown';
    }
}