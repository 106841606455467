export enum TechIndicator {
    None = 0,
    ADX = 1 << 0, // Average Directional Index
    BBands = 1 << 1, // Bollinger Bands
    EMA = 1 << 2, // Exponential Moving Average
    MACD = 1 << 3, // Moving Average Convergence Divergence
    // MACD_Slope = 1 << 4, // Moving Average Convergence Divergence Regression Slope
    // PercentB = 1 << 5, // %B Indicator
    // PivotPointsHL = 1 << 6, // Pivot Points (High/Low)
    ROC = 1 << 7, //Price Rate of Change
    RSI = 1 << 8, // Relative Strength Index
    RVOL = 1 << 9, // Relative Volume Indicator
    SMA = 1 << 10, // Simple Moving Average
    Stoch = 1 << 11,// Stochastic Oscillator    
    Volume = 1 << 12,
  }
  
export const TechIndicatorsMap: [string, TechIndicator][] = Object.entries(TechIndicator).filter(([key, value]) => typeof value === 'number' && value > 0) as [string, TechIndicator][];
export const TechIndicatorsNames = new Map(TechIndicatorsMap.map(([key, value]) => [value, key]));

export const TechIndicatorsToArray = (value: TechIndicator) => {
    const ret = [];
    for (let [key, val] of TechIndicatorsMap) {
        if (val & value) {
            ret.push(val);
        }
    }
    
    return ret;
}

export const ArrayToTechIndicators = (values: TechIndicator[]) => {
    let ret = 0;
    for (let val of values) {
        ret |= val;
    }
    return ret;
}