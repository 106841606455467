import React from "react";
import { useTranslation } from "react-i18next";
import { AssetClassesEnumToString } from "src/models";

export const LogoAssetField: React.FC<{ assetId: string, name: string }> = ({ assetId, name }) => {
    return <span className="flex">
        <div className="logo-wrapper">
            <span className="logo-crop">
                <img className="pair-logo" src={`/api/logoimage/asset?assetId=${assetId}`} />
            </span>
        </div>
        <span>{name}</span>
    </span>
};

export const LogoPairField: React.FC<{ pairId: string }> = ({ pairId }) => {
    return <span className="flex">
        <div className="logo-wrapper">
            <span className="logo-crop">
                <img className="pair-logo" src={`/api/logoimage/pair?pairId=${pairId}`} />
            </span>
        </div>
        <span>{pairId}</span>
    </span>
};

export const LogoAssetClassField: React.FC<{ token: string }> = ({ token }) => {
    const { t } = useTranslation();
    const name = AssetClassesEnumToString(parseInt(token));
    return <LogoCategoryField token={token} name={name!} type="AssetClass" />
};

export const LogoCategoryField: React.FC<{ token: string, type: string, name: string }> = ({ token, type, name }) => {
    return <span className="flex">
        <div className="logo-wrapper">
            <span className="logo-crop">
                <img className="pair-logo" src={`/api/logoimage/category?type=${type}&token=${token}`} />
            </span>
        </div>
        <span>{name}</span>
    </span>
};

export const LogoField: React.FC<{ type: string, id: string, name?: string, category?: string }> = ({ type, id, name, category }) => {
    if (type === 'pair') {
        return <LogoPairField pairId={id} />
    } else if (type === 'asset') {
        return <LogoAssetField assetId={id} name={name!} />
    } else if (type === 'category') {
        return <LogoCategoryField token={id} name={name!} type={category!} />
    } else if (type === 'assetClass') {
        return <LogoAssetClassField token={id} />
    }
    console.error('Unknown type: ' + type);
    return <>---</>
};

