import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import './PairsGrid.scss';

import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { AppStateContext } from '../../../../../appContext';
import { OrderSide, PairInfo } from '../../../../../models';
import { logoImageService } from '../../../../../services';
import Loading from '../../../../Loading';
import PairActions from '../../../../PairActions';
import { TooltipItem } from '../../../../TootlipItem';
import { PairInfoTab } from '../PairInfoView/PairInfoView';


interface PairGridRowProps {
  pair: PairInfo;
  selectedPair: PairInfo | null;
  onInfo: (tab: string) => void;
  onAction: CallableFunction;
  markets: any[];
  onSelectPair: CallableFunction;
  showDirection: OrderSide | boolean;
  tab: PairInfoTab | boolean;
  showDescription: boolean;
  showChange: boolean;
}

const PairGridRow = observer(({ pair, selectedPair, onInfo, onAction, markets, onSelectPair, showDirection, tab, showDescription, showChange }: PairGridRowProps) => {

  const context = useContext(AppStateContext);
  const quotes = context.quotes.values;
  const { t } = useTranslation();
  const isSelected = selectedPair ? selectedPair.id === pair.id : false;

  const pairClass = classNames('pair', {
    'selected': isSelected
  });
  const quote = quotes.get(pair.id);
  pair.updateQuote(quote);

  const iconUrl = logoImageService.getPairLogoUrl(pair.id);
  const buyActionClass = classNames('btn btn-action buy', { isActive: showDirection === OrderSide.Buy && isSelected });
  const sellActionClass = classNames('btn btn-action sell', { isActive: showDirection === OrderSide.Sell && isSelected });

  const tooltipContent = <div>
    <b>{pair.id}</b>
    <div>{pair.dispName}</div>
  </div>

  return (
    <tr className={pairClass} key={pair.id} onClick={() => onSelectPair(pair)}>
      <td className='img column-icon'><div className='logo-wrapper'>
        <TooltipItem text={tooltipContent} id={'grid-' + pair.id}>
          <img src={iconUrl} className='pair-logo' alt={pair.id}></img>
        </TooltipItem>
      </div></td>
      <td className='text cell-ticker column-ticker'><span>{pair.id}</span></td>
      {showChange && <td className={pair.changeClass + ' text-number text-end text-nowrap'}>{pair.changeDisplay} ({pair.changePercent}%)</td>}
      <td className={pair.lastBidChangeClass + ' text-end text-nowrap text-number'} onClick={() => onAction(pair, 'sell', pair.bid)}>{pair.bidDisplay}</td>
      <td className={'text-start text-nowrap join-right'}><span className='suffix-value'><span className='suffix'>{pair.quoteSymbol}</span></span></td>
      {showChange && <td className='text-start'><button className={sellActionClass} onClick={() => onAction(pair, 'sell', pair.bid)}>{t('buttons.sell_order').toUpperCase()}</button></td>}
      <td className={pair.lastAskChangeClass + ' text-end text-nowrap  text-number'} onClick={() => onAction(pair, 'buy', pair.ask)}>{pair.askDisplay}</td>
      <td className={'text-start text-nowrap join-right'}><span className='suffix-value'><span className='suffix'>{pair.quoteSymbol}</span></span></td>
      {showChange && <td className='text-start'><button className={buyActionClass} onClick={() => onAction(pair, 'buy', pair.ask)}>{t('buttons.buy_order').toUpperCase()}</button></td>}
      {showDescription && <td className='cell-description text'><span>{pair.dispName}</span></td>}
      <td className='text-nowrap'>
        <PairActions pair={pair} tab={tab} isSelected={isSelected} onInfo={onInfo}></PairActions>
      </td>
    </tr>
  );
});

interface GridPaginatorProps {
  totalCount: number;
  page: number;
  itemsOnPage: number;
  onChangePage: (selectedItem: { selected: number; }) => void;
}

const GridPaginator = ({ totalCount, page, itemsOnPage, onChangePage }: GridPaginatorProps) => {
  const pageCount = Math.ceil(totalCount / itemsOnPage);
  return <ReactPaginate
    breakLabel="..."
    forcePage={page}
    nextLabel={'»'}
    onPageChange={onChangePage}
    pageRangeDisplayed={5}
    pageCount={pageCount}
    previousLabel={'«'}
    renderOnZeroPageCount={null}
    containerClassName='pagination float-end'
    pageClassName='page-item'
    nextClassName='page-item'
    nextLinkClassName='page-link'
    previousClassName='page-item'
    previousLinkClassName='page-link'
    pageLinkClassName='page-link'
    activeClassName='active'
  />
}

interface PairsGridProps {
  pairs: PairInfo[];
  selected: PairInfo | null;
  onChangePage: (selectedItem: { selected: number; }) => void;
  onSelect: CallableFunction;
  onInfo: (tab: string) => void;
  onAction: CallableFunction;
  markets: any[];
  showDirection: OrderSide | boolean;
  page: number;
  totalCount: number;
  itemsOnPage: number;
  tab: PairInfoTab | boolean;
  isLoading: boolean;
}

const PairsGrid = observer(({ onChangePage, pairs, selected, onSelect, onInfo, onAction, markets, showDirection, page, totalCount, itemsOnPage, tab, isLoading }: PairsGridProps) => {
  const [selectedPair, setSelectedPair] = useState(selected);
  const paginatorVisible = totalCount > itemsOnPage;
  const { t } = useTranslation();
  const { isAdaptive, isTablet } = useContext(AppStateContext);
  const showChange = !isAdaptive || isTablet;
  const showDescription = !isAdaptive;

  useEffect(() => {
    setSelectedPair(selected);
  }, [selected]);

  const selectPair = (pair: PairInfo) => {
    setSelectedPair(pair);
    onSelect(pair);
  };


  return (
    <div className='tradingPaneContent'>
      {isLoading && <div className='spin-container'><Loading /></div>}
      <table className='table-grid' id='pairs-grid'>
        <colgroup>
          <col className='column-icon' />
          <col className='column-ticker' />
          {showChange && <col className='column-change' />}
          <col className='column-price' />
          <col className='column-currency' />
          {showChange && <col className='column-button' />}
          <col className='column-price' />
          <col className='column-currency' />
          {showChange && <col className='column-button' />}
          {showDescription && <col className='column-description' />}
          <col className='column-actions' />
        </colgroup>
        <thead>
          <tr>
            <th ></th>
            <th>{t('trading.tabs.market')}</th>
            {showChange && <th className='text-end'>{t('trading.tabs.change')}</th>}
            <th className='text-end'>{t('trading.tabs.sell')}</th>
            {showChange && <th className='text-end'></th>}
            <th className='text-end'></th>
            <th className='text-end'>{t('trading.tabs.buy')}</th>
            {showChange && <th className='text-end'></th>}
            <th className='text-end'></th>
            {showDescription && <th className='cell-description'>{t('trading.tabs.description')}</th>}
            <th className='text-end'></th>
          </tr>
        </thead>
        <tbody>
          {pairs.map(pair =>
            <PairGridRow
              tab={tab}
              showDirection={showDirection}
              selectedPair={selectedPair}
              key={pair.id}
              onSelectPair={selectPair}
              pair={pair}
              onInfo={onInfo}
              onAction={onAction}
              markets={markets}
              showDescription={showDescription}
              showChange={showChange}
            />)}
        </tbody>
      </table>
      {paginatorVisible && <GridPaginator onChangePage={onChangePage} page={page} itemsOnPage={itemsOnPage} totalCount={totalCount}></GridPaginator>}
    </div>
  );
});

export default PairsGrid;