import { Button, Divider, Input, InputRef, Select, Space } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';


export const useConfidence = (): [JSX.Element, number] => {
    const { t } = useTranslation();
    const [threshold, setThreshold] = useState<number>(0.95);
    const [items, setItems] = useState([50, 90, 95, 99]);

    const [newValue, setNewValue] = useState('');
    const inputRef = useRef<InputRef>(null);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewValue(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        const value = parseFloat(newValue);
        if (isNaN(value) || items.includes(value)) {
            return;
        }
        e.preventDefault();
        setItems([...items, parseFloat(newValue)]);
        setNewValue('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const component = <>
        <label style={{ marginBottom: '5px'}} htmlFor="confidence">{t('var.fields.confidence')}</label>
        <Select
            className='number-select'
            popupClassName='number-select'
            style={{ width: '100%'}}
            options={items.map(item => ({ value: item / 100, label: item + '%' }))}
            value={threshold}
            onChange={setThreshold}
            // dropdownRender={(menu) => (
            //     <>
            //         {menu}
            //         <Divider style={{ margin: '8px 0' }} />
            //         <Space style={{ padding: '0 8px 4px' }}>
            //             <Input
            //                 placeholder={t('var.fields.customValue')}
            //                 ref={inputRef}
            //                 value={newValue}
            //                 onChange={onNameChange}
            //                 onKeyDown={(e) => e.stopPropagation()}
            //             />
            //             <Button type="text" icon={<PlusOutlined />} onClick={addItem} />
            //         </Space>
            //     </>
            // )}
        />
    </>

    return [component, threshold];
};