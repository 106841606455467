import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Container, Nav, NavItem } from 'reactstrap';
import { AppStateContext, MenuItem } from '../../appContext';
import Header from '../header/Header';
import SideBar from "../sidebar/SideBar";
import './Layout.scss';
import MobileMenuOverlay from './MobileMenuOverlay';


const BottomMenu = ({ menu }: { menu: MenuItem[] }) => {
    return <Nav>
        {menu.map((item: MenuItem) => <NavItem key={item.key}>
            <NavLink
                onClick={() => item.action && item.action()}
                to={item.path}
            >
                {item.title}
            </NavLink>
        </NavItem>)}
    </Nav>
}

export const MainLayout = observer(({ children }: { children: React.ReactNode }) => {

    const context = useContext(AppStateContext);
    const isAdaptive = context.isAdaptive;


    const location = useLocation();

    useEffect(() => {
        context.closeMenu();
    }, [location]);
    /*if (!isDesktop) {
        return (<>
            {context.isMenuOpen && <MobileMenuOverlay onClose={() => context.closeMenu()} />}
            <Container fluid className='min-vh-100 d-flex flex-column all-page'>
                <Header isAdaptive={true} onMenu={() => context.toggleMenu()} />
                <div className="main-content">
                    {children}
                </div>
                <div className="bottom-navigation-block">
                    {context.bottomMenu && <BottomMenu menu={context.bottomMenu} />}
                </div>
            </Container>
        </>
        );
    }*/

    return (
        <>
            {context.isMenuOpen && isAdaptive && <MobileMenuOverlay onClose={() => context.closeMenu()} />}
            <Container fluid className='min-vh-100 d-flex flex-column all-page'>
                <Header isAdaptive={isAdaptive} onMenu={() => context.toggleMenu()} />
                <div className="flex-grow-1 page-wrapper">
                    {!isAdaptive && <div className="left-sidebar-block d-flex flex-column">
                        <SideBar />
                    </div>}
                    <div className="main-content">
                        {children}
                    </div>
                    {isAdaptive && <div className="bottom-navigation-block">
                        {context.bottomMenu && <BottomMenu menu={context.bottomMenu} />}
                    </div>}
                </div>
            </Container>
        </>
    )
});