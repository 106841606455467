import { SMA } from "technicalindicators";
import { MAInput } from "technicalindicators/declarations/moving_averages/SMA";
import { CandlestickVolumeData } from "../Chart";
import { BaseLineIndicator } from "./BaseIndicator";


export class RVolIndicator extends BaseLineIndicator {
    color = '#00FF88';
    symbol = null;
    updateData(data: CandlestickVolumeData[]): void {

        const smaInput: MAInput = {
            values: data.map((item: CandlestickVolumeData) => item.volume),
            period: 14
        }

        const sma = new SMA(smaInput);
        this.data = data.map((item: CandlestickVolumeData, index: number) => {
            const value = sma.nextValue(item.volume);

            return {
                time: item.time,
                value: value ? item.volume / value : 0
            };
        });

        if (this.data && this.series) {
            this.series.setData(this.data);
        }
    }
}

