import { CanvasRenderingTarget2D } from 'fancy-canvas';
import {
	AutoscaleInfo,
	BarData,
	Coordinate,
	DataChangedScope,
	ISeriesPrimitive,
	ISeriesPrimitivePaneRenderer,
	ISeriesPrimitivePaneView,
	LineData,
	Logical,
	SeriesAttachedParameter,
	SeriesDataItemTypeMap,
	SeriesType,
	Time,
} from 'lightweight-charts';
import { PluginBase } from '../plugin-base';
import { cloneReadonly } from '../../helpers/simple-clone';
import { ClosestTimeIndexFinder } from '../../helpers/closest-index';
import { UpperLowerInRange } from '../../helpers/min-max-in-range';
import { SD, SMA } from 'technicalindicators';

interface BandRendererData {
	x: Coordinate | number;
	upper: Coordinate | number;
	lower: Coordinate | number;
	mid: Coordinate | number;
}

class BandsIndicatorPaneRenderer implements ISeriesPrimitivePaneRenderer {
	_viewData: BandViewData;
	constructor(data: BandViewData) {
		this._viewData = data;
	}
	draw() { }
	drawBackground(target: CanvasRenderingTarget2D) {
		const points: BandRendererData[] = this._viewData.data;
		if (points.length === 0) {
			return;
		}

		target.useBitmapCoordinateSpace(scope => {
			const ctx = scope.context;
			ctx.scale(scope.horizontalPixelRatio, scope.verticalPixelRatio);

			ctx.strokeStyle = this._viewData.options.lineColor;
			ctx.lineWidth = this._viewData.options.lineWidth;
			ctx.beginPath();
			const region = new Path2D();
			const lines = new Path2D();
			region.moveTo(points[0].x, points[0].upper);
			lines.moveTo(points[0].x, points[0].upper);
			for (const point of points) {
				region.lineTo(point.x, point.upper);
				lines.lineTo(point.x, point.upper);
			}
			const end = points.length - 1;
			region.lineTo(points[end].x, points[end].lower);
			lines.moveTo(points[end].x, points[end].lower);
			for (let i = points.length - 2; i >= 0; i--) {
				region.lineTo(points[i].x, points[i].lower);
				lines.lineTo(points[i].x, points[i].lower);
			}
			region.lineTo(points[0].x, points[0].upper);
			region.closePath();
			ctx.stroke(lines);
			ctx.fillStyle = this._viewData.options.fillColor;
			ctx.fill(region);

			// draw mid line
			ctx.strokeStyle = 'orange';  
			ctx.lineWidth = 1;
			ctx.beginPath();
			ctx.moveTo(points[0].x, points[0].mid);
			for (const point of points) {
				ctx.lineTo(point.x, point.mid);
			}
			ctx.stroke();
		});
	}
}

interface BandViewData {
	data: BandRendererData[];
	options: Required<BandsIndicatorOptions>;
}

class BBandsIndicatorPaneView implements ISeriesPrimitivePaneView {
	_source: BBandsIndicator;
	_data: BandViewData;

	constructor(source: BBandsIndicator) {
		this._source = source;
		this._data = {
			data: [],
			options: this._source._options,
		};
	}

	update() {
		const series = this._source.series;
		const timeScale = this._source.chart.timeScale();
		this._data.data = this._source._bandsData.map(d => {
			return {
				x: timeScale.timeToCoordinate(d.time) ?? -100,
				upper: series.priceToCoordinate(d.upper) ?? -100,
				lower: series.priceToCoordinate(d.lower) ?? -100,
				mid: series.priceToCoordinate(d.mid) ?? -100,
			};
		});
	}

	renderer() {
		return new BandsIndicatorPaneRenderer(this._data);
	}
}

export interface BandData {
	time: Time;
	upper: number;
	lower: number;
	mid: number;
}

function extractPrice(
	dataPoint: SeriesDataItemTypeMap[SeriesType]
): number | undefined {
	if ((dataPoint as BarData).close) return (dataPoint as BarData).close;
	if ((dataPoint as LineData).value) return (dataPoint as LineData).value;
	return undefined;
}

export interface BandsIndicatorOptions {
	lineColor?: string;
	fillColor?: string;
	lineWidth?: number;
	period?: number;
	multiplier?: number;
}

const defaults: Required<BandsIndicatorOptions> = {
	lineColor: 'rgb(25, 200, 100)',
	fillColor: 'rgba(25, 200, 100, 0.25)',
	lineWidth: 1,
	period: 20,
	multiplier: 2,
};

export class BBandsIndicator extends PluginBase implements ISeriesPrimitive<Time> {
	_paneViews: BBandsIndicatorPaneView[];
	_seriesData: SeriesDataItemTypeMap[SeriesType][] = [];
	_bandsData: BandData[] = [];
	_options: Required<BandsIndicatorOptions>;
	_timeIndices: ClosestTimeIndexFinder<{ time: number }>;
	_upperLower: UpperLowerInRange<BandData>;

	constructor(options: BandsIndicatorOptions = {}) {
		super();
		this._options = { ...defaults, ...options };
		this._paneViews = [new BBandsIndicatorPaneView(this)];
		this._timeIndices = new ClosestTimeIndexFinder([]);
		this._upperLower = new UpperLowerInRange([]);
	}

	updateAllViews() {
		this._paneViews.forEach(pw => pw.update());
	}

	paneViews() {
		return this._paneViews;
	}

	attached(p: SeriesAttachedParameter<Time>): void {
		super.attached(p);
		this.dataUpdated('full');
	}

	get(time: number) {
		return this._bandsData[this._timeIndices.findClosestIndex(time, 'right')];
	}

	dataUpdated(scope: DataChangedScope) {
		// plugin base has fired a data changed event
		this._seriesData = cloneReadonly(this.series.data());
		this.calculateBands();
		if (scope === 'full') {
			this._timeIndices = new ClosestTimeIndexFinder(
				this._seriesData as { time: number }[]
			);
		}
	}

	_minValue: number = Number.POSITIVE_INFINITY;
	_maxValue: number = Number.NEGATIVE_INFINITY;
	calculateBands() {

		const bandData: BandData[] = new Array(this._seriesData.length);
		const prices = this._seriesData.map(extractPrice).filter(p => p !== undefined) as number[];
		const multiplier = this._options.multiplier;

		const sma = new SMA({ period: this._options.period, values: prices });
		const stdDev = new SD({ period: this._options.period, values: prices });

		let index = 0;
		for (const d of this._seriesData) {
			const price = extractPrice(d);
			if (price === undefined) return;

			const nSMA = sma.nextValue(price) || price;
			const nSD = stdDev.nextValue(price) || 0;
			const upperBand = nSMA + (multiplier * nSD);
			const lowerBand = nSMA - (multiplier * nSD);

			bandData[index] = {
				upper: upperBand,
				lower: lowerBand,
				mid: nSMA,
				time: d.time,
			};
			index += 1;
		}

		bandData.length = index;
		this._bandsData = bandData;
		this._upperLower = new UpperLowerInRange(this._bandsData, this._options.multiplier);
	}

	autoscaleInfo(startTimePoint: Logical, endTimePoint: Logical): AutoscaleInfo | null {
		const ts = this.chart.timeScale();
		const startTime = (ts.coordinateToTime(
			ts.logicalToCoordinate(startTimePoint) ?? 0
		) ?? 0) as number;
		const endTime = (ts.coordinateToTime(
			ts.logicalToCoordinate(endTimePoint) ?? 5000000000
		) ?? 5000000000) as number;

		if (!startTime || !endTime || this._bandsData.length < this._options.period) {
			return null;
		}

		const startIndex = this._timeIndices.findClosestIndex(startTime, 'left');
		const endIndex = this._timeIndices.findClosestIndex(endTime, 'right');
		const range = this._upperLower.getMinMax(startIndex, endIndex);
		return {
			priceRange: {
				minValue: range.lower,
				maxValue: range.upper,
			},
		};
	}
}
