import { ADX } from "technicalindicators";
import { OhlcVolumeData } from "../Chart";
import { LineIndicator } from "./BaseIndicator";


export class ADXIndicator extends LineIndicator {

    symbol = null;
    updateData(data: OhlcVolumeData[]): void {

        const adxInput = {
            high: new Array<number>(),
            low: new Array<number>(),
            close: new Array<number>(),
            period: 14,
        }

        data.forEach((item: OhlcVolumeData) => {
            adxInput.high.push(item.high);
            adxInput.low.push(item.low);
            adxInput.close.push(item.close);
        });

        const adx = new ADX(adxInput);

        this.data = data.map((item: OhlcVolumeData) => {
            const value = adx.nextValue({
                high: item.high,
                low: item.low,
                close: item.close
            } as never);

            return {
                time: item.time,
                value: value?.adx
            };
        });

        if (this.data && this.series) {
            this.series.setData(this.data);
        }
    }
}

