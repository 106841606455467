import { ApexOptions } from 'apexcharts';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { AppStateContext } from '../../../appContext';
import { infoIcon } from '../../../assets/icons';
import { formatNumber } from '../../../utils';
import { AssetClassesEnumToString, AssetClassesEnumNames } from 'src/models';



interface PNLInfoProps {
    param: string;
}

type PlotData = { label: string, value: number };

export const PNLInfo: React.FC<PNLInfoProps> = observer(({ param }) => {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState<any>([{ data: [] }]);
    const [chartOptions, setChartOptions] = useState<any>({});
    const context = useContext(AppStateContext);
    const symbol = context.account.symbol;
    const digits = context.account.displayDecimals;
    let aggregate = true;
    let trim = false;

    const data: PlotData[] = useMemo(() => {
        let all: PlotData[] = [];
        switch (param) {
            case 'positions':
                all = context.balance.positions.map(p => { return { label: p.pairId, value: p.unrealizedPnL } });
                aggregate = false;
                trim = true;
                break;
            case 'sides':
                all = context.balance.positions.map(p => { return { label: p.side, value: p.unrealizedPnL } });
                break;
            case 'pairs':
                all = context.balance.positions.map(p => { return { label: p.pairId, value: p.unrealizedPnL } });
                trim = true;
                break;
            case 'assetClasses':
                all = context.balance.positions.map(p => { return { label: p.pairInfo ? AssetClassesEnumToString(p.pairInfo.assetClassId) : 'N/A', value: p.unrealizedPnL } });
                break;
            default:
                all = [];
        }

        if (aggregate) {
            all = all.reduce((acc: any, cur: any) => {
                const index = acc.findIndex((e: any) => e.label === cur.label);
                if (index === -1) {
                    acc.push({ label: cur.label, value: cur.value });
                } else {
                    acc[index].value += cur.value;
                }
                return acc;
            }, []);
        }

        if (trim && all.length > 5) {
            const trimmedData: PlotData[] = all.slice(0, 3);
            const trimmedSum = trimmedData.map((item)=>item.value).reduce((partialSum, a) => partialSum + a, 0);
            trimmedData.push({ label: t('header.other'), value: trimmedSum });
            return trimmedData;
        }

        return all;
    }, [context.balance.sentAtUtc, param]);

    useEffect(() => {
        const series = [{
            data: data.map((d: any) => d.value)
        }];

        const options: ApexOptions = {
            dataLabels: {
                enabled: true,
                formatter: (value: any) => formatNumber(value, digits) + ' ' + symbol
            },
            chart: {
                id: "pnl-info-bar",
                background: 'transparent',
                animations: {
                    speed: 200
                },
                toolbar: {
                    show: false
                },
            },
            theme: {
                palette: 'palette1',
                mode: 'dark',
            },
            plotOptions: {
                bar: {
                    distributed: true
                }
            },
            legend: {
                show: false
            },

            tooltip: {
                intersect: false,
                x: {
                    formatter: function (value: any) {
                        return value;
                    },
                },
                y: {
                    formatter: function (value: any) {
                        return formatNumber(value, digits) + ' ' + symbol;
                    },
                    title: {
                        formatter: (seriesName: string) => '',
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value: number) {
                        return formatNumber(value, 0) + ' ' + symbol;
                    }
                },
            },
            xaxis: {
                categories: data.map((d: any) => d.label)
            },
        }

        setChartOptions(options);
        setChartData(series);
    }, [data]);

    return (
        <>
            <Chart type="bar" series={chartData} options={chartOptions} width="100%" height="350" />
        </>
    )

});

export const PNLDropdown: React.FC<{}> = ({ }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const options = [
        { value: 'positions', label: t('header.positions') },
        { value: 'sides', label: t('header.sides') },
        { value: 'pairs', label: t('header.pairs') },
        { value: 'assetClasses', label: t('header.assetClasses') },
    ];
    const [param, setParam] = useState(options[0]);
    return (
        <div className='pnl-info'>
            <Dropdown isOpen={isOpen} toggle={(event: any) => setIsOpen(!isOpen)} direction='start'>
                <DropdownToggle>
                    {infoIcon}
                </DropdownToggle>
                <DropdownMenu container={'balance-container'} className='pnl-info-content'>
                    <Select
                        classNamePrefix='select'
                        options={options}
                        value={param}
                        onChange={(value) => setParam(value!)}
                    />
                    {isOpen && <PNLInfo param={param.value} />}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
