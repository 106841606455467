import type { TimeRangePickerProps } from 'antd';
import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;

type DateRangeType = [Dayjs, Dayjs];

export const useDateRange = (): [JSX.Element, DateRangeType, Dispatch<SetStateAction<DateRangeType>>] => {
    const { t } = useTranslation();
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: t('date_presets.today'), value: [dayjs().startOf('day'), dayjs().endOf('day')] },
        { label: t('date_presets.last_7_days'), value: [dayjs().add(-7, 'd'), dayjs().endOf('day')] },
        { label: t('date_presets.last_14_days'), value: [dayjs().add(-14, 'd'), dayjs().endOf('day')] },
        { label: t('date_presets.last_30_days'), value: [dayjs().add(-30, 'd'), dayjs().endOf('day')] },
        { label: t('date_presets.last_90_days'), value: [dayjs().add(-90, 'd'), dayjs().endOf('day')] },
    ];

    const [dateRange, setDateRange] = useState<DateRangeType>([dayjs().startOf('day'), dayjs().endOf('day')]);

    const selectComponent = <>
        <div><label htmlFor="dates">{t('var.fields.dates')}</label></div>
        <RangePicker
            style={{ width: '100%' }}
            allowClear={false}
            value={dateRange}
            presets={rangePresets}
            onChange={(dates: any) => setDateRange(dates)}
        /></>

    return [selectComponent, dateRange, setDateRange];
};