import React from 'react';

import { PositionRowProps, PositionsViewInternalProps, PositionsViewProps } from '../usePositionViewData';
import { OpenPositionsViewBlocks } from './OpenPositionsViewBlocks';
import { OpenPositionsViewTable } from './OpenPositionsViewTable';
import { useShowTransactionsDialog } from '../useShowTransactionsDialog';
import { Position } from '../../../../models';
import { useShowRelatedOrderDialog } from '../useShowRelatedOrderDialog';


export interface OpenPositionsViewInternalProps extends PositionsViewInternalProps {
    currency: string;
    positions: Position[];
    showTransaction: CallableFunction;
    showOrder: CallableFunction;
}

interface OpenPositionsViewProps extends PositionsViewProps {
}

export interface OpenPositionRowProps extends PositionRowProps {
}

export const OpenPositionsView: React.FC<OpenPositionsViewProps> = (props) => {
    const { transactionDialog, showPositionTransactions } = useShowTransactionsDialog();
    const { orderDialog, showOrder, } = useShowRelatedOrderDialog();

    const view = props.fullView ?
        <OpenPositionsViewTable showTransaction={showPositionTransactions} showOrder={showOrder} {...props} /> :
        <OpenPositionsViewBlocks showTransaction={showPositionTransactions} showOrder={showOrder} {...props} />
    return <>
        {transactionDialog}
        {orderDialog}
        {view}
    </>

};