import React from 'react';

import { useClosedPositionsData } from '../../../../services/hooks';
import Loading from '../../../Loading';
import { ClosedPositionsViewBlocks } from '../../../common/Positions/Closed/ClosedPositionsViewBlocks';
import { useShowRelatedOrderDialog } from '../../../common/Positions/useShowRelatedOrderDialog';
import { useShowTransactionsDialog } from '../../../common/Positions/useShowTransactionsDialog';
import './Positions.scss';
import { PorfolioTabPropsContextType } from '../../PortfolioPage/PortfolioPage';


const ClosedPositions: React.FC<PorfolioTabPropsContextType> = (params) => {
    const { currency, ...rest } = params;
    const { data: positions, loading } = useClosedPositionsData(rest);
    const { transactionDialog, showPositionTransactions } = useShowTransactionsDialog();
    const { orderDialog, showOrder, } = useShowRelatedOrderDialog();

    if (loading || !positions) {
        return <Loading />
    }
    return (
        <>
            {transactionDialog}
            {orderDialog}
            <ClosedPositionsViewBlocks positions={positions} currency={currency} showTransaction={showPositionTransactions} showOrder={showOrder} />
        </>
    );
};

export default ClosedPositions;
