import React from 'react';

import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { Transaction } from '../../../models';
import { ColumnDefinition, DataTable } from '../../DataTable';
import useTransactionsViewData, { TransactionsViewInternalProps } from './useTransactionsViewData';


export const TransactionsViewTable: React.FC<TransactionsViewInternalProps> = observer(({ transactions, showOrder, showPosition, symbol }) => {
    const { t } = useTranslation();
    const renderer = useTransactionsViewData({symbol, showOrder, showPosition});
    const columns = [
        {
            name: 'token',
            className: 'text-nowrap',
            title: renderer.token.title,
            render: (transactions: Transaction) => renderer.token.render(transactions)
        },
        {
            name: 'effective_date',
            className: 'text-end',
            title: renderer.effective_date.title,
            render: (transactions: Transaction) => renderer.effective_date.render(transactions)
        },
        {
            name: 'change',
            className: 'text-end',
            title: renderer.change.title,
            render: (transactions: Transaction) => renderer.change.render(transactions)
        },
        {
            name: 'reserve',
            className: 'text-end',
            title: renderer.reserve.title,
            render: (transactions: Transaction) => renderer.reserve.render(transactions)
        },
        {
            name: 'type',
            className: 'ps-3',
            title: renderer.type.title,
            render: (transactions: Transaction) => renderer.type.render(transactions)
        },
        
        {
            name: 'description',
            className: 'ps-3',
            title: renderer.description.title,
            render: (transactions: Transaction) => renderer.description.render(transactions)
        },
        {
            name: 'order',
            title: renderer.order.title,
            render: (transactions: Transaction) => renderer.order.render(transactions)
        },
        {
            name: 'position',
            title: renderer.position.title,
            render: (transactions: Transaction) => renderer.position.render(transactions)
        },
    ] as ColumnDefinition<Transaction>[];

    const itemProps = () => {
        return {
            className: 'item side-Short',
        }
    }
    return <>
        <DataTable itemProps={itemProps} data={transactions} columns={columns} className='table-grid transactions data-table table-auto' />
    </>
});
