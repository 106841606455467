import React, { useContext, useState } from 'react';

import { TransactionsViewBlocks } from './TransactionsViewBlocks';
import { TransactionsViewTable } from './TransactionsViewTable';
import { Dialog } from 'primereact/dialog';
import { PositionInfoDialog } from '../../pages/PortfolioPage/PositionInfoDialog';
import { OrderInfoDialog } from '../../pages/PortfolioPage/OrderInfoDialog';
import { Transaction } from '../../../models';
import { AppStateContext } from '../../../appContext';
import { observer } from 'mobx-react';


export interface TransactionsViewProps {
    transactions: Transaction[];
}

export const TransactionsView: React.FC<TransactionsViewProps> = observer(({ transactions }) => {

    const [positionId, setPositionId] = useState<number | undefined>(undefined);
    const [orderId, setOrderId] = useState<number | undefined>(undefined);
    const context = useContext(AppStateContext);
    const fullView = !context.isAdaptive;

    const view = fullView ?
        <TransactionsViewTable transactions={transactions} showOrder={setOrderId} showPosition={setPositionId} symbol={context.account.symbol} /> :
        <TransactionsViewBlocks transactions={transactions} showOrder={setOrderId} showPosition={setPositionId} symbol={context.account.symbol} />

    return <>
        <Dialog style={{ width: '70vw' }} header="Position" visible={!!positionId} onHide={() => setPositionId(undefined)}>
            <PositionInfoDialog positionId={positionId}></PositionInfoDialog>
        </Dialog>
        <Dialog style={{ width: '70vw' }} header="Order" visible={!!orderId} onHide={() => setOrderId(undefined)}>
            <OrderInfoDialog orderId={orderId}></OrderInfoDialog>
        </Dialog>
        {view}
    </>
});