import { useState, useEffect } from 'react';
import { SearchFilter, assetsService } from '../../../../../services';
import { AssetClassesEnum } from 'src/models';

export interface SearchPairInfo {
    id: string;
    dispName: string;
    exchangeName: string;
    exchangeCountry: string;
    exchangeMic: string;
    exchangeDescription: string;
    exchangeId: number;
    typeDescription: string;
    industries: string;
    industry: string;
    assetClassId: AssetClassesEnum;
    baseId: string;
    baseSymbol: string;
    quoteId: string;
    quoteSymbol: string;
    sector: string;
    type: string;
}

const useSearchData = ({ search, filters }: { search: string, filters: SearchFilter }) => {
    const [results, setReslts] = useState<SearchPairInfo[]>([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (search.length >= 3) {
            setIsLoading(true);
            assetsService.SearchPairs(search, filters, 1000)
                .then((data) => {
                    setReslts(data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        }
    }, [search, filters]);

    return { results, error, isLoading };
};

export default useSearchData;