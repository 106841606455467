import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavbarBrand } from 'reactstrap';
import { AppStateContext } from '../../appContext';
import logo from '../../assets/logo.png';
import Balance from './Balance/Balance';
import Languages from './Languages';
import UserMenu from './user-menu/UserMenu';

import { moreIcon } from '../../assets/icons';
import './Header.scss';

type HeaderProps = {
    isAdaptive: boolean;
    onMenu?: () => void;
}

const Header: React.FC<HeaderProps> = observer(({ isAdaptive, onMenu }) => {
    const { connectionReady } = useContext(AppStateContext);
    const connectionStatus = connectionReady ? 'connected' : 'disconnected';
    const connectionStatusClass = classNames('navbar-expand-sm navbar-toggleable-sm main-header signal-status', 'signal-status-' + connectionStatus);
    const fixed = isAdaptive ? undefined : 'top';

    return (
        <header>
            <Navbar className={connectionStatusClass} fixed={fixed} container={false}>
                {!isAdaptive && <NavbarBrand tag={Link} to="/">
                    <img
                        alt="logo"
                        src={logo}
                        className='logo'
                    />
                    BrokerName
                </NavbarBrand>}
                <div className='d-flex'>
                    <Balance hideConfig={isAdaptive} />
                    {!isAdaptive && <Languages />}
                    {!isAdaptive && <UserMenu showFunding={true} />}
                    {isAdaptive && <button className='btn menu-toggle' onClick={() => onMenu!()}>{moreIcon}</button>}
                </div>
            </Navbar>
        </header>
    );
});

export default Header;