import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import './CallDialog.css';


interface CallDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const CallDialog: React.FC<CallDialogProps> = ({ isOpen, onClose }) => {
    return (
        <Dialog style={{ width: '50vw', height: '50vh' }} header="Call support" visible={isOpen} onHide={() => onClose()}>
            <div className={`chat-container ${isOpen ? 'open' : ''}`}>
                <div className="chat-body">
                    Call <a href="tel:+1234567890">123-456-7890</a>
                </div>
                
            </div>
        </Dialog>

    );
};

export default CallDialog;