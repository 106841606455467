import React from "react";
import { DateString, formatDate } from "../../utils";
import { GoodUntilTypes, getGoodUntilShortOptions } from "../../models";
import { useTranslation } from "react-i18next";

export const GoodUntilField = ({ value, goodUntilType }: { value: DateString | null, goodUntilType: number }) => {
    const { t } = useTranslation();
    const goodUntilShortOptions = getGoodUntilShortOptions(t);
    const goodUntilDisplay = value ? formatDate(value) : '';

    return <div className="text-date">{goodUntilShortOptions[goodUntilType].label} {goodUntilType === GoodUntilTypes.GTT && <>{goodUntilDisplay}</>}</div>
}