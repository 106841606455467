import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { Order, Position } from '../../../models';
import { TransactionDialog } from '../../pages/PortfolioPage/TransactionsDialog';

export const useShowTransactionsDialog = () => {
    const [displayPositionTransaction, setDisplayPositionTransaction] = useState<Position | undefined>(undefined);
    const [displayOrderTransaction, setDisplayOrderTransaction] = useState<Order | undefined>(undefined);
    
    const showPositionTransactions = (position: Position) => {
        console.log(position);
        
        setDisplayPositionTransaction(position);
    }
    const showOrderTransactions = (order: Order) => {
        setDisplayOrderTransaction(order);
    }

    const hideDialog = () => {
        setDisplayPositionTransaction(undefined);
        setDisplayOrderTransaction(undefined);
    }

    const transactionDialog = <>
        {(displayPositionTransaction || displayOrderTransaction) && <Dialog style={{ width: '70vw' }} visible={true} header="Transactions" onHide={() => hideDialog()}>
            <TransactionDialog position={displayPositionTransaction} order={displayOrderTransaction} ></TransactionDialog>
        </Dialog>}
    </>

    return {
        transactionDialog,
        showPositionTransactions,
        showOrderTransactions,
    };
};