import { RSI } from "technicalindicators";
import { RSIInput } from "technicalindicators/declarations/oscillators/RSI";
import { OhlcVolumeData } from "../Chart";
import { LineIndicator } from "./BaseIndicator";


export class RSIIndicator extends LineIndicator {
    color = '#00FFFF';
    symbol = null;
    updateData(data: OhlcVolumeData[]): void {

        const input: RSIInput = {
            values: data.map((item: OhlcVolumeData) => item.close),
            period: 14
        }

        const indicator = new RSI(input);

        this.data = data.map((item: OhlcVolumeData) => {
            const value = indicator.nextValue(item.close);

            return {
                time: item.time,
                value: value
            };
        });

        if (this.data && this.series) {
            this.series.setData(this.data);
        }
    }
}

