import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { AppStateContext } from '../../../appContext';
import { moreIcon } from '../../../assets/icons';
import { formatNumber } from '../../../utils';
import { UnitValueField } from '../../fields/UnitValueField';
import "./Balance.scss";
import { PNLDropdown } from './PnLInfo';


interface BalanceItem {
    key: string;
    type: string;
}

interface BalanceState {
    isOpen: boolean;
}

interface BalanceProps extends WithTranslation {
    hideConfig?: boolean;
}

interface BalanceSelectorProps {
    items: BalanceItem[];
}


interface BalanceItemComponentProps {
    item: BalanceItem,
    value: any,
    connectionReady: boolean,
    symbol: string,
    hiddenBalanceItems: Set<string>,
    decimals: number,
    isAlwaysVisible: boolean;
}

export const BalanceItemComponent: React.FC<BalanceItemComponentProps> = observer(({ item, value, connectionReady, symbol, hiddenBalanceItems, decimals, isAlwaysVisible }) => {
    const { t } = useTranslation();
    const { key, type } = item;

    const name = t(`header.${key}`);
    if (hiddenBalanceItems.has(key) && !isAlwaysVisible) {
        return <></>;
    }

    let className = '';
    let unit = '';
    switch (key) {
        case 'unrealizedPnl':
            className = value > 0 ? "green" : "red";
            break;
        case 'marginIndicator':
            if (value === null) {
                className = 'green';
                value = 'N/A';
            } else if (value < 1) {
                className = 'blinking-red';
            } else if (value < 1.1) {
                className = "red";
            } else if (value < 1.5) {
                className = "yellow";
            } else {
                className = 'green';
            }

            if (typeof value === 'number') {
                value *= 100;
                if (value > 500) {
                    value = '>500';
                }
                decimals = 0
            }
            break;
    }

    if (typeof value === 'number') {
        value = formatNumber(value, decimals);
    }

    if (type === 'percent') {
        unit = '%';
    } else if (type === 'currency') {
        unit = symbol;
    }

    let extra = null;

    if (key === 'unrealizedPnl' && !isAlwaysVisible) {
        extra = <PNLDropdown />
    }

    return <div className={"balance-item " + className} key={key}>
        <div className='label'>{name}{extra}</div>
        <UnitValueField value={connectionReady ? value : '---'} unit={unit} />
    </div>
});


export const BalanceSelector: React.FC<BalanceSelectorProps> = observer(({ items }) => {
    const context = React.useContext(AppStateContext);
    const { hiddenBalanceItems, account, balance, connectionReady } = context;

    const toggleBalanceItem = (e: any, item: BalanceItem) => {
        context.toggleHiddenBalanceItems(item.key);
    }

    return <>{items.map((item) => {
        return <button className="balance-item-drop dropdown-item" key={item.key} onClick={(e) => toggleBalanceItem(e, item)} >
            <div className='visibility'>
                <Input type='checkbox' checked={!hiddenBalanceItems.has(item.key)} onChange={(event) => { }} />
            </div>
            <div className='info'>
                <BalanceItemComponent connectionReady={connectionReady} item={item} value={balance.getBalance(item.key)} symbol={account.symbol} hiddenBalanceItems={hiddenBalanceItems} decimals={account.displayDecimals} isAlwaysVisible={true} />
            </div>
        </button>
    })}</>
});


const balanceItems: BalanceItem[] = [
    { key: 'availableToTrade', type: 'currency' },
    { key: 'netEquity', type: 'currency' },
    { key: 'cash', type: 'currency' },
    { key: 'unrealizedPnl', type: 'currency' },
    { key: 'totalMargin', type: 'currency' },
    { key: 'marginIndicator', type: 'percent' },
    { key: 'totalValue', type: 'currency' },
]

export const mobileBalanceItems: BalanceItem[] = [
    { key: 'unrealizedPnl', type: 'currency' },
    { key: 'marginIndicator', type: 'percent' },
    { key: 'availableToTrade', type: 'currency' },
    { key: 'cash', type: 'currency' },
    { key: 'netEquity', type: 'currency' },
    { key: 'totalValue', type: 'currency' },
    { key: 'totalMargin', type: 'currency' },
]

const Balance = observer(class Balance extends Component<BalanceProps, BalanceState> {

    static contextType = AppStateContext;
    context!: ContextType<typeof AppStateContext>;

    constructor(props: BalanceProps) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    static displayName = Balance.name;


    toggleOpen(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if ((event.target as Element).closest('.dropdown-menu')) {
            return
        }
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { isAuthenticated, balance, account, connectionReady, hiddenBalanceItems, isAdaptive } = this.context;
        const currencyCode = account.denominatedIn;

        if (!isAuthenticated || !currencyCode) {
            return <></>;
        }
        const items = isAdaptive ? mobileBalanceItems : balanceItems;

        return (
            <div className={'Balance ' + (connectionReady ? 'balance-ready' : 'balance-receiving')} id="balance-container">
                <div className='items'>
                    {items.map((item: BalanceItem) =>
                        <BalanceItemComponent connectionReady={connectionReady} key={item.key} item={item} value={balance.getBalance(item.key)} symbol={account.symbol} hiddenBalanceItems={hiddenBalanceItems} decimals={account.displayDecimals} isAlwaysVisible={false} />
                    )}
                </div>
                {!this.props.hideConfig && <Dropdown isOpen={this.state.isOpen} toggle={(event: any) => this.toggleOpen(event)} direction='start' className='balance-dropdown'>
                    <DropdownToggle>
                        {moreIcon}
                    </DropdownToggle>
                    <DropdownMenu>
                        <BalanceSelector items={items} />
                    </DropdownMenu>
                </Dropdown>}
            </div>
        )
    }
})

Balance.contextType = AppStateContext;
export default withTranslation()(Balance);