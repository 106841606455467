import React, { Component, memo } from 'react';
import { commService, quotesService } from '../../../../../services';
import { debounce } from '../../../../../utils';
import { CandlestickVolumeData, HistoryFreq, QuotesChartComponent } from '../../../../Chart/Chart';

import { BarsInfo, CandlestickData, Time } from 'lightweight-charts';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PairInfo, TechIndicator, TechIndicatorsMap } from '../../../../../models';
import { OhlcData } from '../../../../../models/Quote';
import { IndicatorsDialog } from './IndicatorsDialog/IndicatorsDialog';
import './Trading.scss';

export const VisibleFreq = {
  Min1: '1m',
  Min5: '5m',
  Min15: '15m',
  Hr1: '1H',
  // Hr2: '2H',
  Hr4: '4H',
  // Hr8: '8H',
  Day1: '1D',
  Week1: 'W',
  Month1: 'M',
}


export type HistoryFreqKey = keyof typeof VisibleFreq;

interface TradingPageProps extends WithTranslation {
  pair: PairInfo;
  barCount: number;
}

interface TradingPageState {
  data: any[];
  frequency: HistoryFreqKey;
  indicators: TechIndicator;
  barCount: number;
  fitContent: boolean;
  isLoading: boolean;
  range: { from: number; to: number; };
}

class Trading extends Component<TradingPageProps, TradingPageState> {
  static displayName = Trading.name;
  currentRange: { from: number; to: number; };
  chartRef: React.RefObject<QuotesChartComponent>;
  debouncedUpdateRange: (...args: any) => void;

  constructor(props: TradingPageProps) {
    super(props);
    const currentUtcTimestamp = Date.now();
    const sixtyMinutesAgoTimestamp = currentUtcTimestamp - 60 * 60 * 1000;
    this.currentRange = { from: currentUtcTimestamp, to: 0 };

    const storedIndicators = localStorage.getItem('indicators');

    this.state = {
      data: [],
      frequency: localStorage.getItem('historyFrequency') as HistoryFreqKey || 'Min1',
      barCount: this.props.barCount,
      fitContent: true,
      indicators: storedIndicators ? JSON.parse(storedIndicators) : TechIndicator.Volume,
      isLoading: true,
      range: { from: sixtyMinutesAgoTimestamp, to: currentUtcTimestamp }
    }
    this.chartRef = React.createRef();
    this.onTimeRangeChanged = this.onTimeRangeChanged.bind(this);
    this.debouncedUpdateRange = debounce(this.updateRange.bind(this), 500);
    this.onOhlcReceived = this.onOhlcReceived.bind(this);
    this.setIndicators = this.setIndicators.bind(this);
  }

  handleResize() {
    this.chartRef.current!.handleResize();
  }

  async getPartial(from: number, to: number) {
    const dateRangeTo = new Date(to).toISOString();
    const dateRangeFrom = new Date(from).toISOString();
    var primeRequest = {
      PairId: this.props.pair.id,
      GoingBackTo: dateRangeFrom,
      MostRecent: dateRangeTo,
      Frequency: this.state.frequency,
      TypeOf: "Mid"
    };

    const data = await quotesService.GetHistoricalData(primeRequest);
    return data;
  }

  appendData(newData: CandlestickData[], append: boolean) {
    let { data } = this.state;
    if (newData.length === 0) {
      return;
    }
    const fitContent = data.length === 0;

    let ret: CandlestickData[] = [];
    if (append) {
      data = [...newData, ...data];
      let last = 0;
      // Sort items and remove duplicates.
      data = data.sort((a, b) => a.time - b.time);
      data.forEach((item) => {
        if (item.time > last) {
          ret.push(item);
          last = item.time;
        }
      });
    } else {
      ret = newData;
    }
    this.setState({ data: ret, fitContent });
  }

  onOhlcReceived(updatedData: OhlcData) {
    const { data } = this.state;
    if (this.props.pair.id !== updatedData.pairId) {
      return;
    }

    const date = new Date(updatedData.period);

    const newObj = {
      time: date.getTime() / 1000 - quotesService.timeZoneOffset,
      open: updatedData.open.mid,
      high: updatedData.high.mid,
      low: updatedData.low.mid,
      close: updatedData.close.mid
    } as CandlestickVolumeData;

    if (data.length === 0) {
      data.push(newObj);
    } else {
      const lastElement = data[data.length - 1];
      if (lastElement.time === newObj.time) {
        lastElement.high = Math.max(newObj.high, lastElement.high);
        lastElement.low = Math.min(newObj.low, lastElement.low);
        lastElement.close = newObj.close;
      }
      else {
        data.push(newObj);
      }
    }

    this.setState({ data });
    this.chartRef.current!.updateData(newObj);
  }

  resetData() {
    const { frequency, barCount } = this.state;
    const nowTS = Date.now();
    const from = nowTS - barCount * HistoryFreq[frequency] * 1000;
    this.currentRange.from = nowTS;
    this.setState({ range: { from, to: nowTS }, data: [], fitContent: true });
  }

  async loadData(force = false) {
    const { range, data, frequency } = this.state;

    if (range.from < this.currentRange.from || force || data.length === 0) {
      this.setState({ isLoading: true });
      const data = await this.getPartial(range.from, this.currentRange.from);
      if (data.length === 0 && frequency === 'Min1') {
        this.setState({ frequency: 'Day1' });
        return;
      }
      this.currentRange.from = range.from;
      this.appendData(data, !force);
      this.setState({ isLoading: false });
    }
    // const nowTS = Date.now();
    // if (range.to > this.currentRange.to && range.to < nowTS) {
    //   const part = await this.getPartial(this.currentRange.to, range.to);
    //   this.currentRange.to = range.to;
    //   this.appendData(part, true);
    // }
  }

  updateRange(barsRange: BarsInfo<Time>) {
    if (!barsRange || !barsRange.barsBefore) {
      return;
    }

    if (barsRange.barsBefore === 0) {
      return;
    }

    const nowTS = Date.now();
    const singleBar = HistoryFreq[this.state.frequency];
    const fromMs = Math.floor(barsRange.from as number + barsRange.barsBefore * singleBar + quotesService.timeZoneOffset) * 1000;
    const toMs = Math.min(Math.ceil(barsRange.to as number + barsRange.barsBefore * singleBar + quotesService.timeZoneOffset) * 1000, nowTS);
    this.setState({ range: { from: fromMs, to: toMs } });
  }

  onTimeRangeChanged(range: BarsInfo<Time> | null) {
    this.debouncedUpdateRange(range);
  }

  async componentDidMount() {

    commService.quoteHub.on("OHLC", this.onOhlcReceived);
    this.resetData();
    // TODO: Subscribe using frequency?
    commService.quoteHub.send("SubscribeTo", { PairIds: [this.props.pair.id], Type: "OHLC" });
  }

  componentWillUnmount() {
    commService.quoteHub.send("UnsubscribeFromFull", { PairIds: [this.props.pair.id], Type: "OHLC" });
    commService.quoteHub.off("OHLC", this.onOhlcReceived);
  }

  async componentDidUpdate(prevProps: TradingPageProps, prevState: TradingPageState) {
    if (prevProps.pair.id !== this.props.pair.id) {
      if (prevProps.pair) {
        commService.quoteHub.send("UnsubscribeFromFull", { PairIds: [this.props.pair.id], Type: "OHLC" });
      }
      commService.quoteHub.send("SubscribeTo", { PairIds: [this.props.pair.id], Type: "OHLC" });
      this.resetData();
    }
    if (this.state.data !== prevState.data) {
      this.chartRef.current!.setData(this.state.data);
      if (this.state.fitContent) {
        this.chartRef.current!.fitContent();
      }
    }
    if (this.state.range !== prevState.range) {
      this.loadData();
    }

    if (this.state.frequency !== prevState.frequency) {
      this.resetData();
      localStorage.setItem('historyFrequency', this.state.frequency);
    }
    if (this.state.indicators !== prevState.indicators) {
      localStorage.setItem('indicators', JSON.stringify(this.state.indicators));
    }
  }

  toggleFreq(frequency: HistoryFreqKey) {
    // TODO: recalculate range to avoid additional request.
    this.setState({ frequency });
  }

  toggleIndicator(indicator: TechIndicator) {
    this.setState((state) => ({ indicators: state.indicators & indicator ? state.indicators ^ indicator : state.indicators | indicator }));
  }

  setIndicators(newIndicators: TechIndicator) {
    this.setState({ indicators: newIndicators });
  }

  render() {
    const { pair } = this.props;
    const { frequency, isLoading, indicators } = this.state;
    if (!pair) {
      return <></>;
    }

    return (<div className='chart-wrapper-container'>
      <ul className='chart-buttons chart-freaquency'>
        {Object.entries(VisibleFreq).map(([key, value]) =>
          <li key={key} className={frequency === key ? 'active' : ''} onClick={() => this.toggleFreq(key as HistoryFreqKey)}>{value}</li>)}
      </ul>
      <div className='chart-buttons chart-indicators'>
        <ul className='active-indicators'>
          {TechIndicatorsMap.map(([key, value]) =>
            (indicators & value) !== 0 && <li key={key} onClick={() => this.toggleIndicator(value as TechIndicator)}>{key}</li>)
          }
        </ul>
        <IndicatorsDialog indicators={indicators} onChange={this.setIndicators} key="indicators-dialog" />
      </div>
      <QuotesChartComponent {...this.props} frequency={frequency} ref={this.chartRef} isLoading={isLoading} onTimeRangeChanged={this.onTimeRangeChanged} indicators={indicators} />
    </div>
    );
  }
}

export default withTranslation()(Trading);
