import { ApexOptions } from "apexcharts";
import { observer } from "mobx-react-lite";
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from "primereact/utils";
import React, { useContext, useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { Button, Col, Collapse, Container, Row } from "reactstrap";
import { AppStateContext } from "../../../../appContext";
import { arrowDownIcon, arrowUpIcon } from "../../../../assets/icons";
import { PairInfo, PositionSide, VaRMethod } from "../../../../models";
import { assetsService } from "../../../../services";
import { ElementOption, StringOption, TOption, useEnumOptions, usePerformScenarioAnalysisData, useRiskFreeRatesData } from "../../../../services/hooks";
import { useStoredParam } from "../../../../services/hooks/useStoredParam";
import { formatNumber } from "../../../../utils";
import { LogoPairField, UnitValueField } from "../../../fields";
import Loading from "../../../Loading";
import { useMonteCarloOptions } from "../filters/mcOptions";
import { RatioValue } from "../RatioValue";


const PortfolioMetricsNames = ['vaR', 'cVaR', 'volatility', 'sharpe', 'sortino'];
type PortfolioMetricsKeys = 'vaR' | 'cVaR' | 'volatility' | 'sharpe' | 'sortino';


const { Option, SingleValue } = components;
const IconOption = (props: any) => (
    <Option {...props}>
        <LogoPairField pairId={props.data.value} />
    </Option>
);

const IconValue = (props: any) => {
    console.log(props);
    return <SingleValue {...props}>
        <LogoPairField pairId={props.data.value} />
    </SingleValue>
}


// TODO: simplify state management
export const MarginalAnalysisTab: React.FC<{}> = observer((params) => {
    const { t } = useTranslation();
    const [pairId, setPairId] = useStoredParam('pairId');
    const [notionalValue, setNotionalValue] = useStoredParam<number>('notional', 1);
    const [sideValue, setSideValue] = useStoredParam('side', 'Long');

    const { account } = useContext(AppStateContext);
    const symbol = account.symbol;
    const digits = account.displayDecimals;

    const [showAdditionalParams, setShowAdditionalParams] = useState(false);
    const [pair, setPair] = useState<StringOption | undefined>();
    const [notional, setNotional] = useState<number>(notionalValue!);

    const sideOptions = useEnumOptions<PositionSide>(PositionSide, 'PositionSide');
    const methodOptions = useEnumOptions(VaRMethod, 'VaRMethod');
    const windowOptions = [6, 12, 18, 24, 36].map(item => ({ value: item, label: item.toString() }));
    const confidenceOptions = [0.5, 0.9, 0.95, 0.99].map(item => ({ value: item, label: item * 100 + '%' }));

    const [side, setSide] = useState<TOption<PositionSide>>(sideOptions.find(item => item.value === sideValue)!);
    const [method, setMethod] = useState(methodOptions[1]);
    const [window, setWindow] = useState(windowOptions[1]);
    const [confidence, setConfidence] = useState(confidenceOptions[2]);
    const [pairOptions] = useState<Array<StringOption>>([]);
    const [chartData, setChartData] = useState<any>([{ data: [] }]);
    const [chartOptions, setChartOptions] = useState<any>({});
    const [monteCarloComponent, numberOfPaths, horizon] = useMonteCarloOptions();

    const [risk, setRisk] = useState<ElementOption | undefined>();
    const [riskOptions, setRiskOptions] = useState<Array<ElementOption>>([]);
    const [metric, setMetric] = useState<PortfolioMetricsKeys>('vaR');

    const { data: riskRatesData } = useRiskFreeRatesData();
    const scoreMapping: Array<[number, string]> = [ 
        [0, 'score-very'], 
        [2, 'score-medium'], 
        [Number.MAX_VALUE, 'score-good']
    ];

    useEffect(() => {
        if (riskRatesData && riskRatesData.length > 0) {
            const riskOptions = riskRatesData?.map(item => ({ value: item.yield, disabled: false, label: <><span className="text-number">{(item.yield * 100).toFixed(4)}%</span> {item.country} ({item.symbol})</> }));
            setRiskOptions(riskOptions);
            setRisk(riskOptions[0]);
        }
    }, [riskRatesData]);

    useEffect(() => {
        setNotionalValue(notional!);
    }, [notional, setNotionalValue]);

    useEffect(() => {
        setSideValue(side.value);
    }, [side.value, setSideValue]);

    useEffect(() => {
        if (pairId) {
            setPair({ value: pairId, label: pairId, disabled: false, });
        }
    }, [pairId]);

    const loadOptions = (
        inputValue: string,
        callback: (options: StringOption[]) => void
    ) => {
        //   callback(filterColors(inputValue));
        if (!inputValue) {
            assetsService.GetPairListInfo(-1).then((data) => {
                const options = data.items.map((item: PairInfo) => { return { value: item.id, label: item.id, disabled: false } });
                callback(options);
            });
        } else if (inputValue.length >= 3) {
            assetsService.SearchPairs(inputValue, {}, 20)
                .then((data) => {
                    const options = data.map((item: PairInfo) => { return { value: item.id, label: item.id, disabled: false } });
                    callback(options);
                });
        }
    };

    const varParams = { Method: method.value, Window: window.value, Confidence: confidence.value, MonteCarloNumPaths: numberOfPaths, Horizon: horizon };
    const sharpinoParams = { Window: window.value, RiskFreeRate: risk?.value };

    const { data, loading } = usePerformScenarioAnalysisData(pair?.value, side.value, notional, varParams, sharpinoParams);

    useEffect(() => {

        const formatters = {
            'vaR': (value: number) => formatNumber(value, digits) + ' ' + symbol,
            'cVaR': (value: number) => formatNumber(value, digits) + ' ' + symbol,
            'volatility': (value: number) => formatNumber(value * 100, 2) + '%',
            'sharpe': (value: number) => formatNumber(value, digits),
            'sortino': (value: number) => formatNumber(value, digits),
        }

        if (!data) {
            return;
        }

        const series = [{
            data: [data?.current[metric], data?.expected[metric]]
        }];

        const options: ApexOptions = {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '1rem',
                },
                formatter: (value: any) => formatters[metric](value)
            },
            chart: {
                id: "pnl-info-bar",
                background: 'transparent',
                animations: {
                    speed: 200
                },
                toolbar: {
                    show: false
                },
            },
            theme: {
                palette: 'palette1',
                mode: 'dark',
            },
            plotOptions: {
                bar: {
                    distributed: true
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                style: {
                    fontSize: '1rem',
                },
                intersect: false,
                x: {
                    formatter: function (value: any) {
                        return value;
                    },
                },
                y: {
                    formatter: formatters[metric],
                    title: {
                        formatter: (seriesName: string) => '',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: '1rem',
                    },
                    formatter: formatters[metric],
                },
            },
            xaxis: {
                categories: ['Current', 'Expected'],
                labels: {
                    style: {
                        fontSize: '1rem',
                    },
                },
            },
        }

        setChartOptions(options);
        setChartData(series);
    }, [data, metric, digits, symbol]);

    const renderer = {
        'vaR': (value: number) => <UnitValueField value={value} unit={symbol} precision={digits} />,
        'cVaR': (value: number) => <UnitValueField value={value} unit={symbol} precision={digits} />,
        'volatility': (value: number) => <UnitValueField value={value * 100} unit={'%'} precision={digits} />,
        'sharpe': (value: number) => <RatioValue value={value} scoreMapping={scoreMapping} />,
        'sortino': (value: number) => <RatioValue value={value} scoreMapping={scoreMapping} />,
    }

    return (
        <Container className='MarginalAnalysisTab'>
            <Row>
                <Col className="filters-container">
                    <div style={{ width: '180px' }}>
                        <label htmlFor="pair">{t('marginalAnalysis.fields.pair')}</label>
                        {/* <Input value={pairId} onChange={(e) => setPairId(e.target.value)} /> */}
                        <AsyncSelect
                            classNamePrefix='select'
                            value={pair}
                            options={pairOptions}
                            isClearable={true}
                            placeholder={t('portfolio.common.select_pair')}
                            onChange={(e: StringOption) => { setPair(e); setPairId(e ? e.value : ''); }}
                            components={{ Option: IconOption, SingleValue: IconValue }}
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                        />
                    </div>
                    <div style={{ width: '130px' }}>
                        <label htmlFor="pair">{t('marginalAnalysis.fields.notional')}</label>
                        <div>
                            <InputNumber
                                maxFractionDigits={2}
                                value={notional}
                                showButtons={true}
                                step={0.1}
                                onChange={(e) => setNotional(e.value as number)}
                                inputClassName={classNames('form-control')}
                            />
                        </div>
                    </div>

                    <div style={{ width: '100px' }}>
                        <label htmlFor="side">{t('marginalAnalysis.fields.side')}</label>
                        <Select
                            classNamePrefix='select'
                            inputId="side"
                            options={sideOptions}
                            value={side}
                            onChange={setSide}
                        />
                    </div>
                    <div>
                        {pair && <Link to={"/trading/" + encodeURIComponent(pair?.value) + '?' + new URLSearchParams({ direction: side.value === PositionSide.Long ? 'Buy' : 'Sell', qty: notional?.toString() })}>
                            <Button color="primary">{t('marginalAnalysis.fields.order')}</Button>
                        </Link>}
                    </div>
                    {/* <div>
                        <Button color="primary" onClick={() => console.log('Calculate')}>{t('var.fields.calculate')}</Button>
                    </div> */}
                </Col>
                <Col className="extra-column">
                    <div>
                        <Button color="secondary" className="btn-icon" onClick={() => setShowAdditionalParams(!showAdditionalParams)}>
                            {t('marginalAnalysis.fields.additionalParams')} {showAdditionalParams ? arrowUpIcon : arrowDownIcon}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Collapse isOpen={showAdditionalParams}>
                <Row>
                    <Col className="filters-container">
                        <div>
                            <label htmlFor="method">{t('var.fields.method')}</label>
                            <Select
                                classNamePrefix='select'
                                inputId="method"
                                options={methodOptions}
                                value={method}
                                onChange={setMethod}
                            />
                        </div>
                        <div>
                            <label htmlFor="window">{t('var.fields.window')}</label>
                            <Select
                                className="number-select"
                                classNamePrefix='select'
                                inputId="window"
                                options={windowOptions}
                                value={window}
                                onChange={setWindow} />
                        </div>
                        <div style={{ width: '100px' }}>
                            <label htmlFor="confidence">{t('var.fields.confidence')}</label>
                            <Select
                                className="number-select"
                                classNamePrefix='select'
                                inputId="confidence"
                                options={confidenceOptions}
                                value={confidence}
                                onChange={setConfidence}
                            />
                        </div>
                        <div style={{ width: '320px' }}>
                            <label htmlFor="risk">{t('var.fields.riskFreeRate')}</label>
                            <CreatableSelect
                                getNewOptionData={(inputValue, optionLabel) => {
                                    return {
                                        value: parseFloat(inputValue) / 100,
                                        disabled: false,
                                        label: <span className="text-number">{inputValue}%</span>,
                                    }
                                }}
                                className="number-select-fixed"
                                classNamePrefix='select'
                                inputId="risk"
                                options={riskOptions}
                                value={risk}
                                onChange={setRisk}
                            />
                        </div>
                        {method.value === VaRMethod.MonteCarlo && monteCarloComponent}
                    </Col>
                </Row>
            </Collapse>

            <Row>
                <Col className="text-center score-container" md={8}>
                    {loading && <Loading />}
                    <table className="table-grid data-table margin-analysis-table table-fixed action-table" >
                        <thead>
                            <tr>
                                <th>{t('marginalAnalysis.fields.metric')}</th>
                                <th className="text-end">{t('marginalAnalysis.fields.current')}</th>
                                <th className="text-end">{t('marginalAnalysis.fields.expected')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {PortfolioMetricsNames.map((metricName: PortfolioMetricsKeys) => (
                                <tr onClick={() => setMetric(metricName)} className={metricName === metric ? 'selected' : ''} key={metricName}>
                                    <td>{t(`marginalAnalysis.metrics.${metricName}`)}</td>
                                    <td className="text-end">{data?.current[metricName] && renderer[metricName](data?.current[metricName])}</td>
                                    <td className="text-end">{data?.expected[metricName] && renderer[metricName](data?.expected[metricName])}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
                <Col md={4}>
                    <Chart type="bar" series={chartData} options={chartOptions} width="100%" height="350" />

                </Col>
            </Row>

            <div className="typography">
                <h2>Understanding Scenario Analysis</h2>
                <p>Scenario Analysis is a powerful tool that allows traders to perform what-if analyses on their portfolios. By simulating hypothetical trades, users can assess the potential impact of new positions on key financial metrics. This feature helps in making informed decisions by understanding how a prospective trade could affect the overall risk and return profile of the portfolio.</p>
                <h3>Key Features:</h3>
                <ol>
                    <li><b>Hypothetical Trade Specification</b>:</li>
                    <ul>
                        <li><b>Trading Pair</b>: Select the trading pair for the hypothetical trade (e.g., BTC/USD, ETH/EUR).</li>
                        <li><b>Side</b>: Choose whether the position is long or short.</li>
                        <li><b>Notional</b>: Specify the notional value of the hypothetical trade.</li>
                    </ul>
                    <li><b>Metrics for Comparison</b>: Users can choose from a set of financial metrics to compare the current portfolio with the hypothetical scenario. The available metrics include:</li>
                    <ul>
                        <ul>
                            <li>Value at Risk (VaR)</li>
                            <li>Conditional Value at Risk (CVaR)</li>
                            <li>Portfolio Volatility</li>
                            <li>Sharpe Ratio</li>
                            <li>Sortino Ratio</li>
                        </ul>
                    </ul>
                    <li><b>Comparative Analysis</b>:</li>
                    <ul>
                        <li>The system calculates the selected metrics for two scenarios:</li>
                        <ul>
                            <li><b>Current Portfolio</b>: The metrics for the existing portfolio without the hypothetical trade.</li>
                            <li><b>Adjusted Portfolio</b>: The metrics for the portfolio with the hypothetical trade included.</li>
                        </ul>
                        <li>Results are displayed in a bar chart for easy comparison, allowing users to visually assess the impact of the hypothetical trade.</li>
                    </ul>
                </ol>
                <h3>How to Use Scenario Analysis:</h3>
                <ol>
                    <li><b>Specify the Hypothetical Trade</b>:</li>
                    <ul>
                        <li>Choose the trading pair, side (long or short), and the notional value for the trade you are considering.</li>
                    </ul>
                    <li><b>Select Metrics for Comparison</b>:</li>
                    <ul>
                        <li>Select the financial metrics you want to compare. This selection helps in focusing on the specific aspects of risk and return that are most relevant to your trading strategy.</li>
                    </ul>
                    <li><b>Analyze the Results</b>:</li>
                    <ul>
                        <li>View the bar chart comparing the metrics for the current portfolio and the adjusted portfolio.</li>
                        <li>Assess how the hypothetical trade affects each metric to make an informed decision about whether to proceed with the trade.</li>
                    </ul>
                </ol>
                <h3>Detailed Metric Descriptions:</h3>
                <p>For detailed explanations of the metrics used in Scenario Analysis, please refer to the individual pages dedicated to each metric:</p>
                <ul>
                    <li><b>Value at Risk (VaR)</b>: <Link to="/risk/var">Understanding VaR</Link></li>
                    <li><b>Conditional Value at Risk (CVaR)</b>: <Link to="/risk/cvar">Understanding CVaR</Link></li>
                    <li><b>Portfolio Volatility</b>: <Link to="/risk/var">Understanding Portfolio Volatility</Link></li>
                    <li><b>Sharpe & Sortino Ratio</b>: <Link to="/risk/sharpino">Understanding Sharpe and Sortino Ratio</Link></li>
                </ul>
                <p>By using Scenario Analysis, you can better understand the potential implications of new trades and strategically manage your portfolio to optimize for desired outcomes.</p>
            </div>
        </Container >)
});