import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { appState } from "../../../appContext";
import { Position } from "../../../models";
import { OrderService, logoImageService } from "../../../services";
import { DateField, NumberField, UnitValueField } from "../../fields";
import Loading from "../../Loading";


interface PositionInfoProps {
    position: Position
}

const ClosedPositionInfo: React.FC<PositionInfoProps> = ({ position }) => {
    const { t } = useTranslation();
    const sideName = position.side;
    const iconUrl = logoImageService.getPairLogoUrl(position.pairId);
    const symbolAccount = appState.account.symbol;
    const digits = appState.account.displayDecimals;

    const data = [
        {
            label: t('portfolio.positions.token'),
            value: position.token
        },
        {
            label: t('portfolio.positions.pair_id'),
            value: <div><span className='logo-wrapper'><img className="pair-logo" src={iconUrl} alt={position.pairId}></img></span> <span>{position.pairId}</span></div>
        },
        {
            label: t('portfolio.positions.side'),
            value: sideName
        },
        {
            label: t('portfolio.positions.status'),
            value: position.status
        },
        {
            label: t('portfolio.positions.notional'),
            value: <NumberField value={position.notional} />
        },
        {
            label: t('portfolio.positions.realized'),
            value: <UnitValueField value={position.realizedPnl} unit={symbolAccount} precision={digits} />
        },
        {
            label: t('portfolio.positions.entrance_cost'),
            value: <UnitValueField value={position.entranceCost} unit={symbolAccount} precision={digits} />
        },
        {
            label: t('portfolio.positions.margin_ratio'),
            value: <><UnitValueField value={position.marginRatio * 100} unit={'%'} precision={digits} /><span className="text-number">   (1:{Math.round(1 / position.marginRatio)})</span></>
        },
        {
            label: t('portfolio.positions.created_at'),
            value: <DateField value={position.createdAt} format={"medium"} />
        },
        {
            label: t('portfolio.positions.updated_at'),
            value: <DateField value={position.lastUpdatedAt} format={"medium"} />
        },
        {
            label: t('portfolio.positions.closed_at'),
            value: <DateField value={position.closedAt} format={"medium"} />
        },
    ];

    if (position.bridgeForward) {
        data.push(
            {
                label: t('portfolio.positions.bridge_opening'),
                value: <NumberField value={position.bridgeOpening} />
            },
        );
    }

    return <table width="100%" className="info-table">
        <tbody>
            {data.map((item, index) => <tr key={index}>
                <th>{item.label}</th>
                <td>{item.value}</td>
            </tr>
            )}
        </tbody>
    </table>
};

const OpenPositionInfo: React.FC<PositionInfoProps> = ({ position }) => {
    const { t } = useTranslation();
    const sideName = position.side;
    const iconUrl = logoImageService.getPairLogoUrl(position.pairId);
    const symbolAccount = appState.account.symbol;
    const digits = appState.account.displayDecimals;

    const data = [
        {
            label: t('portfolio.positions.token'),
            value: position.token
        },
        {
            label: t('portfolio.positions.pair_id'),
            value: <div><span className='logo-wrapper'><img className="pair-logo" src={iconUrl} alt={position.pairId}></img></span> <span>{position.pairId}</span></div>
        },
        {
            label: t('portfolio.positions.side'),
            value: sideName
        },
        {
            label: t('portfolio.positions.status'),
            value: position.status
        },
        {
            label: t('portfolio.positions.notional'),
            value: <NumberField value={position.notional} />
        },
        {
            label: t('portfolio.positions.current_margin'),
            value: <UnitValueField value={position.currentMargin} unit={symbolAccount} precision={digits} />
        },
        {
            label: t('portfolio.positions.current_value'),
            value: <UnitValueField value={position.currentValue} unit={symbolAccount} precision={digits} />
        },
        {
            label: t('portfolio.positions.unrealized'),
            value: <UnitValueField value={position.unrealizedPnL} unit={symbolAccount} precision={digits} />
        },
        {
            label: t('portfolio.positions.entrance_cost'),
            value: <UnitValueField value={position.entranceCost} unit={symbolAccount} precision={digits} />
        },
        {
            label: t('portfolio.positions.margin_ratio'),
            value: <><UnitValueField value={position.marginRatio * 100} unit={'%'} precision={digits} /><span className="text-number">   (1:{Math.round(1 / position.marginRatio)})</span></>
        },
        {
            label: t('portfolio.positions.created_at'),
            value: <DateField value={position.createdAt} format={"medium"} />
        },
    ];

    if (position.bridgeForward) {
        data.push(
            {
                label: t('portfolio.positions.bridge_opening'),
                value: <NumberField value={position.bridgeOpening} />
            },
        );
    }

    return <table width="100%" className="info-table">
        <tbody>
            {data.map((item, index) => <tr key={index}>
                <th>{item.label}</th>
                <td>{item.value}</td>
            </tr>
            )}
        </tbody>
    </table>
};

interface PositionInfoDialogProps {
    positionId?: number;
}

export const PositionInfoDialog: React.FC<PositionInfoDialogProps> = ({ positionId }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [position, setPosition] = useState<Position | null>(null);
    const { account } = appState;

    useEffect(() => {
        const loadData = async () => {
            setPosition(await OrderService.instance.getPosition(positionId!, account));
            setIsLoading(false);
        }

        if (positionId) {
            setIsLoading(true);
            loadData();
        }
    }, [positionId, account]);

    return <div className="pending-dialog-content">
        {isLoading && <Loading />}
        {position && <div>
            {position.status === "Closed"
                ? <ClosedPositionInfo position={position} />
                : <OpenPositionInfo position={position} />}
        </div>}
    </div>
};
