import { ConfigProvider } from "antd";
import React, { PropsWithChildren, createContext } from 'react';
import { lyraDarkTheme } from "../theme";

type ColorModeContextType = {
    mode: string;
    setMode: (mode: string) => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
    {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {


    return (
        <ConfigProvider
            theme={lyraDarkTheme}
        >
            {children}
        </ConfigProvider>
    );
};
