import { Col, Row } from 'antd';
import { ApexOptions } from 'apexcharts';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { SSRatiosData } from 'src/models';
import { formatNumber, formatNumberFix, formatNumberTrim } from 'src/utils';

interface DetailsProps {
    data: SSRatiosData;
}

const baseOptions: ApexOptions = {
    legend: {
        position: 'bottom',
    },
    chart: {
        toolbar: { show: false },
        background: '#202024',
    },
    theme: { mode: "dark", palette: "palette6" },
    tooltip: {
        style: {
            fontSize: '1rem',
        },
    },
    dataLabels: {
        style: {
            fontSize: '1rem'
        },
        formatter: (val: number, opts?: any) => {
            return formatNumberFix(val, 3)
        },
    },
    plotOptions: {
        bar: {
            distributed: true,
            horizontal: false,
            dataLabels: {
                hideOverflowingLabels: true,
                total: {
                    enabled: true,
                    style: {
                        fontSize: '1rem'
                    }
                }
            }
        },
    },
    yaxis: {
        labels: {
            style: {
                fontSize: '1rem'
            },
            formatter: (val: number, opts?: any) => {
                return formatNumberFix(val, 3)
            },
        }
    }
}

export const SharpinoDetails: React.FC<DetailsProps> = ({ data }) => {
    const { t } = useTranslation();

    const series = [
        {
            name: t('var.fields.sharpeContributions'),
            data: data.sharpeContributions ? Object.values(data.sharpeContributions) : [],
        },
        {
            name: t('var.fields.sortinoContributions'),
            data: data.sortinoContributions ? Object.values(data.sortinoContributions) : [],
        },
    ]

    const chartOptions = useMemo(() => {
        const labels = data.sharpeContributions ? Object.keys(data.sharpeContributions) : [];
        const opts = {
            labels: labels,
            title: {
                text: t('var.fields.adjusted'),
            },
            ...baseOptions,
        };
        return opts;
    }, [data, t]);

    return (
        <>
            <Row>
                <Col md={24}>
                    <ReactApexChart
                        options={chartOptions}
                        series={series}
                        type="bar"
                        height={336}
                    />
                </Col>
            </Row>
        </>)
};
