import classNames from 'classnames';
import React, { useContext } from 'react';

import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../../../appContext';
import { DateField } from '../../../fields';
import usePositionViewData from '../usePositionViewData';
import { ClosedPositionRowProps, ClosedPositionsViewInternalProps } from './ClosedPositionsView';


const ClosedPositionTableRow: React.FC<ClosedPositionRowProps> = observer(({ currency, position, showTransaction, showOrder }) => {
    const { side } = position;
    const isDirect = currency === 'account';
    const pair = position.pairInfo;
    const context = useContext(AppStateContext);

    const sideClass = classNames('item', `side-${side}`);
    const symbolAccount = context.account.symbol;
    const currencySymbol = isDirect ? symbolAccount : position.bridgeSymbol;

    const columns = usePositionViewData({ isDirect, showTransaction, showOrder });

    return (
        <tr className={sideClass}>
            <td className='img'>
                {columns.logo.render(position)}
            </td>
            <td className='cell-ticker'>
                {columns.cell_ticker.render(position)}
            </td>
            <td className='text-end cell-side'>
                {columns.side.render(position)}
            </td>

            <td className='text-end combined-left'>
                {columns.notional.render(position)}
            </td>
            <td className='cell-unit'>
                {pair?.baseSymbol}
            </td>

            <td className='text-end combined-left'>
                {columns.realized.render(position, { hideUnit: true })}
            </td>
            <td className='cell-unit'>
                {symbolAccount}
            </td>

            <td className='text-end combined-left'>
                <div className="value">{columns.opening_value.render(position)}<span className='divider'>/</span></div>
            </td>
            <td className='combined-right'>
                <div className="value">{columns.opening_price.render(position, { hideUnit: true })}</div>
            </td>

            <td className='text-end combined-left'>
                <div className="value">{columns.closing_value.render(position)}<span className='divider'>/</span></div>
            </td>
            <td className='combined-right'>
                {columns.closing_price.render(position, { hideUnit: true })}
            </td>

            <td className='text-end'>
                <DateField value={position.createdAt} />
            </td>
            <td className='text-end last-data'>
                <DateField value={position.closedAt} />
            </td>
            <td>
                {columns.actions.render(position)}
            </td>
        </tr>
    )
});


export const ClosedPositionsViewTable: React.FC<ClosedPositionsViewInternalProps> = observer((props) => {
    const { t } = useTranslation();
    const { positions, ...rest } = props;

    return (
        <>
            <table width="100%" className='table-grid positions data-table  table-auto'>
                <colgroup>
                    <col className='cell-logo' />
                    <col className='cell-ticker' />
                    <col className='cell-side' />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col className='cell-actions cell-actions-small' />
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th className='left cell-ticker'>{t('portfolio.positions.ticker')}</th>
                        <th className='text-end cell-side'></th>
                        <th className='text-end combined-left'>{t('portfolio.positions.notional')}</th>
                        <th className='cell-unit'></th>

                        <th className='text-end combined-left'>{t('portfolio.positions.realized')}</th>
                        <th className='cell-unit'></th>

                        <th className='text-end combined-left'>{t('portfolio.positions.opening_value')} <span className='divider'>/ </span></th>
                        <th className='combined-right'>{t('portfolio.positions.price')}</th>
                        <th className='text-end combined-left'>{t('portfolio.positions.closing_value')} <span className='divider'>/ </span> </th>
                        <th className='combined-right'>{t('portfolio.positions.price')} </th>
                        <th className='text-end'>{t('portfolio.positions.date_opened')}</th>
                        <th className='text-end last-data'>{t('portfolio.positions.date_closed')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {positions && positions.map(position => <ClosedPositionTableRow position={position} key={position.id} {...rest} />)}
                </tbody>
            </table>
        </>
    );
});
