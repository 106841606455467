import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { AppStateContext } from 'src/appContext';
import { supportedLanguages } from '../../i18n';
import classNames from 'classnames';


const Languages = observer(() => {
  const context = useContext(AppStateContext);
  const { language: currentLanguage } = context;

  const changeLanguage = (language: string) => {
    context.switchLanguage(language);
  };

  return (
    <UncontrolledDropdown className='language-menu'>
      <DropdownToggle>
        {supportedLanguages[currentLanguage as keyof typeof supportedLanguages].flag}
      </DropdownToggle>
      <DropdownMenu>
        {Object.keys(supportedLanguages).map((language: keyof typeof supportedLanguages) => (
          <DropdownItem key={language} onClick={() => changeLanguage(language)} className={classNames({ active: language === currentLanguage })}>
            {supportedLanguages[language].flag} {supportedLanguages[language].name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
});

export default Languages;
