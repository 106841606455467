import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { infoIcon, moreHorizontalIcon, transactionIcon } from '../../../assets/icons';
import { Transaction } from '../../../models';
import { formatCurrency } from '../../../utils';
import { DateField, EnumField } from '../../fields';
import { TooltipItem } from '../../TootlipItem';



export interface TransactionsViewInternalProps {
    transactions: Transaction[];
    showOrder: CallableFunction;
    showPosition: CallableFunction;
    symbol: string;
}

export interface TransactionsViewProps extends TransactionsViewInternalProps {
    fullView: boolean;
}

export interface TransactionRowProps {
    transaction: Transaction;
    currency?: string;
}

const useTransactionViewData = ({ symbol, showPosition, showOrder }: { symbol: string, showPosition: CallableFunction, showOrder: CallableFunction }) => {
    const { t } = useTranslation();

    return {
        token: {
            title: t('portfolio.transactions.token'),
            render: (transaction: Transaction, params = {}) => transaction.token
        },
        ticker: {
            title: t('portfolio.transactions.token'),
            render: (transaction: Transaction, params = {}) => {
                const tooltipContent = <div>
                    <div><b>Token:</b> {transaction.token}</div>
                    <div><b>Created at:</b> <DateField value={transaction.createdAt} format='medium' /></div>
                    <div>{transaction.description}</div>
                </div>
                return <><div className="total">
                    <span className='side'>{transaction.token}</span>
                    <div className='info-icon'>
                        <TooltipItem text={tooltipContent} id={transaction.id} prefix="tt-open-order" customClass='info-tooltip'>
                            {infoIcon}
                        </TooltipItem>
                    </div>
                </div>
                    <div className='name'>{transaction.type}</div></>
            }
        },
        effective_date: {
            title: t('portfolio.transactions.effective_date'),
            render: (transaction: Transaction, params = {}) => <DateField value={transaction.effectiveDate} format='medium' />
        },
        change: {
            title: t('portfolio.transactions.change'),
            render: (transaction: Transaction, params = {}) => formatCurrency(transaction.accountChange, symbol)
        },
        reserve: {
            title: t('portfolio.transactions.reserve'),
            render: (transaction: Transaction, params = {}) => formatCurrency(transaction.reserveChange, symbol)
        },
        type: {
            title: t('portfolio.transactions.type'),
            render: (transaction: Transaction, params = {}) => <EnumField value={transaction.type} enum="TransactionType" translate={t} />
        },
        description: {
            title: t('portfolio.transactions.description'),
            render: (transaction: Transaction, params = {}) => <div className="combined">
                <div className='description'>{transaction.description}</div>
                {transaction.description.length > 70 &&
                    <TooltipItem prefix="trx" text={transaction.description} id={transaction.id} position="bottom">
                        <div className='show-more'>{moreHorizontalIcon}</div>
                    </TooltipItem>}
            </div>
        },
        full_description: {
            title: t('portfolio.transactions.description'),
            render: (transaction: Transaction, params = {}) => <div className='full-description'>{transaction.description}</div>
        },
        position: {
            title: t('portfolio.transactions.position'),
            render: (transaction: Transaction, params = {}) => transaction.positionId && <Button className="icon" onClick={() => showPosition(transaction.positionId)}>{transactionIcon}</Button>
        },
        order: {
            title: t('portfolio.transactions.order'),
            render: (transaction: Transaction, params = {}) => transaction.orderId && <Button className="icon" onClick={() => showOrder(transaction.orderId)}>{transactionIcon}</Button>
        },
    }
};

export default useTransactionViewData;