import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AppStateContext } from '../../../appContext';
import { arrowDownIcon, arrowUpIcon, closeIcon } from '../../../assets/icons';
import { OrderSide, PairInfo } from '../../../models';
import { AssetCategory, AssetService, assetsService, favCategory } from '../../../services';
import Loading from '../../Loading';
import './Main.scss';
import MarketsExplorer from './components/MarketsExplorer/MarketsExplorer';
import PairInfoView, { PairInfoTab } from './components/PairInfoView/PairInfoView';
import PairsGrid from './components/PairsGrid/PairsGrid';
import Trading from './components/Trading/Trading';
import { getFontSize } from 'src/utils';


interface MainPageProps extends WithTranslation {

}

interface MainPageState {
  categories: AssetCategory[];
  pairs: PairInfo[];
  category: AssetCategory;
  pair: PairInfo | null;
  clientReady: boolean;
  totalCount: number;
  page: number;
  isGridLoading: boolean;
  itemsOnPage: number;
  accountId: number;
}


const MainPage = observer(class Main extends Component<MainPageProps, MainPageState> {
  static displayName = Main.name;
  isInitialized: boolean;
  itemsOnPage: number;
  gridRef: React.RefObject<HTMLDivElement>;
  context!: ContextType<typeof AppStateContext>;
  resizeHandler: () => void;
  timer: any;

  constructor(props: MainPageProps) {
    super(props);
    this.isInitialized = false;
    this.state = {
      categories: [],
      pairs: [],
      category: favCategory,
      pair: null,
      clientReady: false,
      totalCount: 0,
      page: 0,
      itemsOnPage: 0,
      accountId: 0,
      isGridLoading: true
    }
    this.gridRef = React.createRef();
    this.resizeHandler = this.handleResize.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.timer = setTimeout(() => this.handleResize(), 100);
    this.context.setTitle(this.props.t('menu.trading'));
  }

  componentDidUpdate(prevProps: MainPageProps, prevState: MainPageState) {
    if (this.context.clientReady && !this.state.clientReady) {
      this.setState({ clientReady: true }, () => {
        this.loadInitData();
      });
    }

    if (this.context.account.id !== this.state.accountId) {
      console.log('account changed');
      this.setState({ accountId: this.context.account.id }, () => {
        this.loadMarketData();
      });
    }
  }

  componentWillUnmount() {
    this.context.quotes.changeSubscription([], this.state.pairs.map(item => item.id));
    window.removeEventListener('resize', this.resizeHandler);
  }

  async loadInitData() {
    if (this.isInitialized) {
      return;
    }
    const categories = await assetsService.GetPairCategoriesTree();
    
    this.isInitialized = true;
    this.setState({ categories: categories });
  }

  async loadMarketData() {
    const { category, page } = this.state;
    const unsubscribe = this.state.pairs.map(item => item.id);
    if (!category) {
      this.context.quotes.changeSubscription([], unsubscribe);
      this.setState({ isGridLoading: false });
      return;
    }
    try {
      const { items, totalCount } = await assetsService.GetPairListInfo(category.assetClassId, category.id, page * this.state.itemsOnPage, this.state.itemsOnPage);
      const pairIds = items.map((item: PairInfo) => item.id);
      const newState = { pairs: items, totalCount, isGridLoading: false, pair: null } as any;
      if (items && items.length) {
        newState.pair = items[0];
      }
      this.setState(newState);
      this.context.quotes.changeSubscription(pairIds, unsubscribe);
    } catch (err) {
      console.error(err);
      this.setState({ pairs: [], totalCount: 0, isGridLoading: false });
    }
  }

  openSidebar(tab: PairInfoTab | boolean) {
    if (this.context.sideBarOpen === tab) {
      this.context.setSideBar(false);
    } else {
      this.context.setSideBar(tab);
    }
  }

  toggleFafovorite(pair: PairInfo) {
    this.context.toggleFavorite(pair);
  }

  onAction(pair: PairInfo, event: string, price: number) {
    if (event === 'buy') {
      this.context.setSideBar(PairInfoTab.Order);
      this.context.setDirection(OrderSide.Buy);
    } else if (event === 'sell') {
      this.context.setSideBar(PairInfoTab.Order);
      this.context.setDirection(OrderSide.Sell);
    }
  }

  render() {
    const { pair } = this.state;
    const { clientReady, sideBarOpen, chartOpen, direction, account } = this.context;

    if (!clientReady || !this.context.account) {
      return <Loading>{this.props.t('auth.notConnected')}</Loading>
    }

    const sidebarClass = classNames('sidebar', { 'is-open': sideBarOpen });
    const mainClass = classNames('main', { 'main-content-shifted': sideBarOpen, 'graph-open': chartOpen });
    const toggleIcon = chartOpen ? arrowDownIcon : arrowUpIcon;
    const accountId = account.id;

    return (
      <div className='MainPage' key={accountId}>

        <div className="wrapper">
          <div>
            <div className={mainClass}>
              <div className='tree-pane'>
                {this.treePaneContent()}
              </div>
              <div className='grid-pane' ref={this.gridRef}>
                <div >{this.gridPaneContent()}</div>
              </div>
              <div className='graph-pane'>
                <button onClick={() => this.toggleChart()} className='toggler'>{toggleIcon}</button>
                {this.graphPaneContent()}
              </div>
            </div>
          </div>
          <div className={sidebarClass}>
            {sideBarOpen && <div className='content full-height'>
              <button onClick={() => this.openSidebar(false)} className="close-button">{closeIcon}</button>
              <PairInfoView pair={pair} tab={sideBarOpen} direction={direction} key={pair?.id}></PairInfoView>
            </div>}
          </div>
        </div>
      </div>
    )
  }

  handleResize() {
    if (!this.gridRef.current) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.handleResize(), 100);
      return;
    }

    const pxRate = getFontSize() / 16;

    const pagerSize = 25 * pxRate;
    const headerSize = 17 * pxRate;
    const rowSize = (30 + 7) * pxRate;
    const itemsFit = this.gridRef.current?.clientHeight ? Math.floor((this.gridRef.current?.clientHeight - pagerSize - headerSize) / rowSize) : 0;

    if (itemsFit !== this.state.itemsOnPage && itemsFit > 0) {
      this.setState({ itemsOnPage: itemsFit }, () => { this.loadMarketData() });
    }
  }

  toggleChart() {
    this.context.toggleChart();
    setTimeout(() => this.handleResize(), 100);
  }

  handleSelectPair(pair: PairInfo) {
    this.setState({ pair });
  };

  onChangePage(event: { selected: number }) {
    if (event.selected !== this.state.page) {
      this.setState({
        page: event.selected,
        isGridLoading: true,
      }, () => { this.loadMarketData() });
    }
  }

  gridPaneContent() {
    const { pairs, pair, page, totalCount, isGridLoading, itemsOnPage } = this.state;
    const { markets, sideBarOpen, direction } = this.context;
    let showDirection: OrderSide | boolean = false;
    if (sideBarOpen === 'order' && direction) {
      showDirection = direction;
    }

    if (itemsOnPage === 0) {
      return <Loading></Loading>
    }

    return <PairsGrid
      onChangePage={(event: { selected: number }) => this.onChangePage(event)} page={page}
      itemsOnPage={itemsOnPage}
      totalCount={totalCount}
      markets={markets}
      showDirection={showDirection}
      pairs={pairs}
      selected={pair}
      onAction={(pair: PairInfo, action: string, price = 0) => this.onAction(pair, action, price)}
      onInfo={(info: PairInfoTab) => this.openSidebar(info)}
      onSelect={(pair: PairInfo) => this.handleSelectPair(pair)}
      tab={sideBarOpen}
      isLoading={isGridLoading}
    />
  }

  handleUpdateCategory(filterData: { selectedCategory: AssetCategory }) {
    this.setState({
      category: filterData.selectedCategory,
      isGridLoading: true,
      page: 0,
    }, () => { this.loadMarketData() });
  };

  treePaneContent() {
    const { categories, category } = this.state;
    return <MarketsExplorer key="markets" categories={categories} category={category} handleUpdate={(value) => this.handleUpdateCategory(value)}></MarketsExplorer>
  }

  graphPaneContent() {
    const { pair } = this.state;
    const { chartOpen, isMobile } = this.context;

    if (!pair) {
      return <></>
    }

    return (
      <>
        <div className='content'>
          {chartOpen &&
            <>
              <a onClick={() => this.toggleChart()} className="close-button">{closeIcon}</a>
              <Trading pair={pair} barCount={isMobile ? 30 : 120} />
            </>
          }
        </div>
      </>
    );
  }
})

MainPage.contextType = AppStateContext;
export default withTranslation()(MainPage);