import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrowRightIcon } from '../../../../../assets/icons';
import { AssetCategory, logoImageService } from '../../../../../services';
import { TooltipItem } from '../../../../TootlipItem';
import './CategoryTree.scss';

interface CategoryTreeProps {
  categories: AssetCategory[],
  onCategorySelect: CallableFunction,
  selected: AssetCategory,
}

const CategoryTree = ({ categories, onCategorySelect, selected }: CategoryTreeProps) => {
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(selected);
  const [animation, setAnimation] = useState<boolean | string>(false);
  const { t } = useTranslation();

  const toggleCategory = (e: any, keyId: string) => {
    e.stopPropagation();
    setTimeout(() => {
      if (expandedCategories.includes(keyId)) {
        setExpandedCategories(expandedCategories.filter(id => id !== keyId));
      } else {
        setExpandedCategories([...expandedCategories, keyId]);
      }
    }, 0);
    setAnimation(keyId);
    setTimeout(() => { setAnimation(false) }, 300);
  };

  const selectCategory = (category: AssetCategory) => {
    setSelectedCategory(category);
    onCategorySelect(category);
  };

  const renderCategory = (category: AssetCategory, level: number) => {
    const isExpanded = expandedCategories.includes(`${category.assetClassId}-${category.id}`);

    const hasChildren = category.children && category.children.length > 0;
    const iconUrl = logoImageService.getCategoryLogoUrl(category);
    const keyId = `${category.assetClassId}-${category.id}`;

    const categoryClass = classNames('category', {
      'has-children': hasChildren,
      'selected': selectedCategory && selectedCategory.id === category.id && selectedCategory.assetClassId === category.assetClassId
    });
    const arrowClass = classNames('toggle-button', { 'expanded': isExpanded });
    const subCategoryClass = classNames('subcategory', { 'expanded': isExpanded, 'animate': animation == keyId })

    return (
      <li key={keyId} className={`category-level-${level} category-${category.assetClassId}-${category.id}`}>
        <div className={categoryClass} onClick={() => selectCategory(category)}>
          <span className='icon'> {category.icon ? category.icon : <img src={iconUrl} alt={category.name} />}</span>
          <TooltipItem text={category.name} id={keyId} position="right">
            <span className='name' title=""> {t(`enum.AssetClassEnum.${category.name}`, category.name)}</span>
          </TooltipItem>
          {hasChildren && (
            <button className={arrowClass} onClick={(e: any) => toggleCategory(e, keyId)}>
              {arrowRightIcon}
            </button>
          )}
        </div>
        <ul className={subCategoryClass}>
          {(isExpanded || animation == keyId) && category.children && (
            <>{category.children.map(child => renderCategory(child, level + 1))}</>
          )}
        </ul>
      </li>
    );
  };

  return (
    <>
      <ul className='categories-tree'>
        {categories.map(category => renderCategory(category, 1))}
      </ul>
    </>
  );
};

export default CategoryTree;