import { notificationIcon } from "../assets/icons";
import { showNotification } from "../common/notifications";
import i18n from "../i18n";
import { IGenericNotification, NotificationType } from "../models/Notifications";
import { roundSmart } from "../utils";


class GenericNotificationFormatter {
    constructor(protected notification: IGenericNotification) { }

    get message() {
        return i18n.t(`notifications.generic.${this.notification.type}`, { ...this.params });
    }

    get icon() {
        return notificationIcon;
    }

    get date() {
        return this.notification.sentAtUtc;
    }

    get params() {
        return this.notification as any;
    }

    static format(notification: IGenericNotification) {
        const formatter = new this(notification);
        return { message: formatter.message, icon: formatter.icon, date: formatter.date };
    }
}

class OrderNotificationFormatter extends GenericNotificationFormatter {
    get params() {
        return this.notification.order as any;
    }
}

class PositionNotificationFormatter extends GenericNotificationFormatter {
    get params() {
        return this.notification.position as any;
    }
}

class AlertNotificationFormatter extends GenericNotificationFormatter {
    get params() {
        return { alert: this.notification.alert as any };
    }
}

class MarginCallNotificationFormatter extends GenericNotificationFormatter {
    get params() {
        const all = this.notification.marginCall as any
        return { ...all, level: roundSmart(this.notification.marginCall?.marginIndicator! * 100) + '%' };
    }
}

export class NotificationService {
    static get instance() { return notificationService }

    async showNotification(notification: IGenericNotification) {
        const { message, icon, date } = this.format(notification);
        showNotification(message, icon, 'info', date);
    }

    format(notification: IGenericNotification) {
        let formatter = null;
        switch (notification.type) {
            case NotificationType.OrderExecutedPositionOpened:
            case NotificationType.OrderExecutedPositionClosed:
            case NotificationType.OrderExecutedPositionClosedSLTP:
                formatter = PositionNotificationFormatter;
                break;
            case NotificationType.OrderSubmitted:
            case NotificationType.OrderExpired:
            case NotificationType.OrderCancelled:
                formatter = OrderNotificationFormatter;
                break;
            case NotificationType.AlertFired:
            case NotificationType.AlertExpired:
                formatter = AlertNotificationFormatter;
                break;
            case NotificationType.MarginCallIssued:
                formatter = MarginCallNotificationFormatter;
                break;
            default:
                formatter = GenericNotificationFormatter;
                break;
        }

        return formatter.format(notification);
    }
}

export const notificationService = new NotificationService();