import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import { commService, quotesService } from '../services';

export class Quote {
    ask = 0;
    askChange = 0;
    askDir = 'Same';
    at = new Date();
    bid = 0;
    bidChange = 0;
    bidDir = 'Same';
    mid = 0;
    midChange = 0;
    midDir = 'Same';
    pairId = '';
    constructor(quoteData: any) {
        makeAutoObservable(this);
        Object.assign(this, quoteData);
    }
}

export class Quotes {
    _subscriptions = new Map();
    values = observable.map({});
    _subscribed = false;

    constructor() {
        makeObservable(this, {
            values: observable,
            onQuotesReceived: action
        });
    }

    ensureSubscriptions() {
        if (!this._subscribed && commService.quoteHub) {
            commService.quoteHub.on("BatchQuote", (data) => this.onQuotesReceived(data));
            this._subscribed = true;
        }
    }

    onQuotesReceived = (data: any) => {
        const { quotes } = data;

        for (const quote of quotes) {
            if (this.values.has(quote.pairId)) {
                this.values.delete(quote.pairId)
            }
            this.values.set(quote.pairId, new Quote(quote));
        }
    }

    updateSubscriptions(addPairIds: string[], removePairIds: string[]) {
        console.log('updateSubscriptions', addPairIds, removePairIds);
        this.ensureSubscriptions();
        if (removePairIds.length > 0) {
            quotesService.UnsubscribeFromFull(removePairIds);
        }
        if (addPairIds.length > 0) {
            quotesService.SubscribeTo(addPairIds);
        }
    }

    changeSubscription(subscribePairIds: string[], unsubscribePairIds: string[]) {
        const addPairIds = this.subscribe(subscribePairIds.filter(pairId => !this._subscriptions.has(pairId) && unsubscribePairIds.indexOf(pairId) === -1));
        const removePairIds = this.unsubscribe(unsubscribePairIds.filter(pairId => this._subscriptions.has(pairId) && subscribePairIds.indexOf(pairId) === -1));
        this.updateSubscriptions(addPairIds, removePairIds);
    }

    protected subscribe(pairIds: string[]) {
        const added: string[] = []
        pairIds.forEach(pairId => {
            const cnt = this._subscriptions.get(pairId) || 0;
            if (cnt) {
                this._subscriptions.set(pairId, cnt + 1);
            } else {
                this._subscriptions.set(pairId, 1);
                added.push(pairId);
            }
        });
        return added;
    }

    protected unsubscribe(pairIds: string[]) {
        const removed: string[] = [];
        pairIds.forEach(pairId => {
            const cnt = this._subscriptions.get(pairId);
            if (cnt) {
                this._subscriptions.set(pairId, cnt - 1);
            }
            if (cnt === 1) {
                this._subscriptions.delete(pairId);
                removed.push(pairId);
            }
        });
        return removed;
    }
}


export interface ValueData {
    mid: number;
}


export interface OhlcData {
    period: number;
    pairId: string;

    open: ValueData;
    high: ValueData;
    low: ValueData;
    close: ValueData;
}