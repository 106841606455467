import { useEffect, useState } from 'react';
import { showErrorNotification } from '../../common/notifications';

type HookResponse<T> = {
    data: T | undefined;
    loading: boolean;
    error: string | null;
};

export function useData<T>(fetchData: () => Promise<T>): HookResponse<T> {
    const [data, setData] = useState<T | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                setLoading(true);
                const data = await fetchData();
                setData(data);
            } catch (error) {
                setError(error.message);
                showErrorNotification(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDataAsync();
    }, [fetchData]);

    return { data, loading, error };
};

export default useData;