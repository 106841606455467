import { action, computed, observable, makeObservable } from "mobx";
import { BridgedModel } from "./BridgedModel";


export enum PositionSide
{
    Long = 'Long',
    Short = 'Short'
}


export class Position extends BridgedModel {

    bridgeOpening: number;
    bridgeClosing: number;

    accountId = 0;
    //beingClosed = false;
    //bodCost = 0;
    quoteBridge = 0;
    quoteMain = 0;
    closedAt = null;
    compexity = null;
    createdAt = null;
    createdByUserId = 0;
    currentMargin = 0;
    currentValue = 0;
    entranceCost = 0;
    notional = 0;
    pairId = '';
    id = 0;
    lastUpdatedAt = null;
    marginRatio = 0;
    marginRatioShort = 0;
    marginRatioLong = 0;
    realizedPnl = 0;
    unrealizedPnL = 0;
    mae = 0;
    mfe = 0;
    //spreadWideningOnClose = 0;
    side: PositionSide = PositionSide.Short;
    status = '';
    token = '';
    totalStopLoss = 0;
    totalTakeProfit = 0;
    constructor(data: Object, accountSymbol: string, accountDigits: number) {
        super(accountSymbol, accountDigits, data);
        Object.assign(this, data);
        makeObservable(this, {
            update: action,
            OpeningValue: computed,
            OpeningPrice: computed,
            CurrentValue: computed,
            UnrealizedPnL: computed,
            RealizedPnL: computed,
            Margin: computed,
            TotalStopLoss: computed,
            TotalTakeProfit: computed,
            changeClass: computed,
            unPnlClass: computed,
            pnlClass: computed,
            closingCost: computed,

            //beingClosed: observable,
            //bodCost: observable,
            quoteBridge: observable,
            quoteMain: observable,
            closedAt: observable,
            compexity: observable,
            createdAt: observable,
            createdByUserId: observable,
            currentMargin: observable,
            currentValue: observable,
            entranceCost: observable,
            notional: observable,
            pairId: observable,
            id: observable,
            lastUpdatedAt: observable,
            marginRatioShort: observable,
            marginRatioLong: observable,
            realizedPnl: observable,
            unrealizedPnL: observable,
            mae: observable,
            mfe: observable,
            //spreadWideningOnClose: observable,
            side: observable,
            status: observable,
            token: observable,
            totalStopLoss: observable,
            totalTakeProfit: observable,
        });
    }

    update(newData: Object) {
        Object.assign(this, newData);
    }

    get closingCost() {
        return this.entranceCost + this.realizedPnl;
    }

    // Values in pair currency
    get OpeningValue() {
        return {
            main: { value: this.entranceCost, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.entranceCost / this.BridgeOpeningRate, unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get OpeningPrice() {
        return {
            main: { value: this.entranceCost / this.notional, unit: this.accountSymbol, digits: this.pairInfo?.pairDecimals },
            alt: { value: this.entranceCost / this.notional / this.BridgeOpeningRate, unit: this.bridgeSymbol, digits: this.bridgePairDigits }
        }
    }

    get ClosingValue() {
        return {
            main: { value: this.closingCost, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.closingCost / this.BridgeClosingRate, unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get ClosingPrice() {
        return {
            main: { value: this.closingCost / this.notional, unit: this.accountSymbol, digits: this.pairInfo?.pairDecimals },
            alt: { value: this.closingCost / this.notional / this.BridgeClosingRate , unit: this.bridgeSymbol, digits: this.bridgePairDigits }
        }
    }

    get CurrentValue() {
        return {
            main: { value: this.currentValue, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.currentValue / this.BridgeCurrentRate, unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get CurrentPrice() {
        return {
            main: { value: this.currentValue / this.notional, unit: this.accountSymbol, digits: this.pairInfo?.pairDecimals },
            alt: { value: this.currentValue / this.notional / this.BridgeCurrentRate, unit: this.bridgeSymbol, digits: this.bridgePairDigits }
        }
    }

    // Values in account currency
    get UnrealizedPnL() {
        return {
            main: { value: this.unrealizedPnL, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.unrealizedPnL / this.BridgeCurrentRate, unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get RealizedPnL() {
        return {
            main: { value: this.realizedPnl, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.realizedPnl / (this.bridgeClosing ?? 1), unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get Margin() {
        return {
            main: { value: this.currentMargin, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.currentMargin, unit: this.accountSymbol, digits: this.accountDigits }
        }
    }

    get TotalStopLoss() {
        return {
            main: { value: this.totalStopLoss, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.totalStopLoss / this.BridgeCurrentRate, unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get TotalTakeProfit() {
        return {
            main: { value: this.totalTakeProfit, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.totalTakeProfit / this.BridgeCurrentRate, unit: this.bridgeSymbol, digits: this.bridgeCostDigits }
        }
    }

    get MAE() {
        return {
            main: { value: this.mae, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.mae, unit: this.bridgeSymbol, digits: this.accountDigits }
        }
    }

    get MFE() {
        return {
            main: { value: this.mfe, unit: this.accountSymbol, digits: this.accountDigits },
            alt: { value: this.mfe, unit: this.bridgeSymbol, digits: this.accountDigits }
        }
    }

    get BridgeOpeningRate() {
        if (this.isBridge) {
            return this.bridgeOpening;
        }
        return 1;
    }

    get BridgeClosingRate() {
        if (this.isBridge) {
            return this.bridgeClosing;
        }
        return 1;
    }

    get changeClass() {
        return this.diffClass(-this.unrealizedPnL);
    }

    get unPnlClass() {
        return this.diffClass(-this.unrealizedPnL);
    }

    get pnlClass() {
        return this.diffClass(-this.realizedPnl);
    }

    diffClass(diff: number) {
        if (diff > 0) {
            return 'Rising';
        } else if (diff < 0) {
            return 'Falling';
        } else {
            return 'Same';
        }
    }
}
