import { Account, Alert, Order, OrderPositionAssociation, Position, Transaction } from "../models";
import BaseCommService from "./BaseCommService";
import { Pagination } from "./hooks/usePaginationData";
import dayjs, { Dayjs } from 'dayjs';

type TransactionHistoryParams = {
    orderId?: number;
    positionId?: number;
    skip?: number;
    take?: number;
    dateFrom?: Date,
    dateTo?: Date
}


export enum SortableProperty {
    None = 0,
    PairId = 1,
    Side = 2,
    CreatedAt = 3,
    PositionRealizedPnL = 4, //position only
    PositionOpeningValue = 5, //position only
    PositionClosedAt = 6, //position only
    OrderType = 7, //order only
    OrderTriggerPrice = 8, //order only
    OrderValidity = 9, //order only
    OrderExecutedAt = 10, //order only

    // local
    PositionUnrealizedPnl = 11,
    PositionCurrentValue = 12,
    PositionMargin = 13,
}

export enum SortDirection {
    Asc = 0,
    Desc = 1
}


export interface PortfolioRequestDataParams {
    dateRange?: Dayjs[];
    pairId?: string;
    filters?: any;
    sort?: SortableProperty;
    sortAsc?: boolean;
    updateAt?: Date;
}

interface RequesParams extends PortfolioRequestDataParams{
    pagination?: Pagination;
}

export class OrderService extends BaseCommService {
    static get instance() { return orderService }
    get hub() {
        return this._connecction.appHub;
    }

    static createRequest(params: RequesParams) {
        const request = { } as any;
        if (params.pagination) {
            request.skip = params.pagination.page * params.pagination.pageSize;
            request.take = params.pagination.pageSize;
        }
        if (params.dateRange) {
            request.dateFrom = params.dateRange[0];
            request.dateTo = params.dateRange[1];
        }
        if (params.pairId) {
            request.pairId = params.pairId;
        }
        if (params.sort) {
            request.sortBy = { property: params.sort, direction: params.sortAsc ? SortDirection.Asc : SortDirection.Desc };
        }
        return request;
    }

    async SubmitOrder(orderReq: any) {
        return this._invokeMethod("SubmitOrder", orderReq);
    }

    async UpdateOrder(order: Order, data: any) {
        const request = {
            StopLossTotal: data.totalStopLoss,
            TakeProfitTotal: data.totalTakeProfit,
            TriggerPrice: data.triggerPrice,
            Qty: data.qty,
            GoodUntil: data.goodUntil,
            Id: order.id
        }
        return this._invokeMethod("UpdateOrder", request);
    }

    async CancelOrder(order: Order) {
        return this._invokeMethod("CancelOrder", { orderId: order.id });
    }

    async GetPendingOrders(account: Account, params: any) {
        const orders = await this._invokeMethod("GetPendingOrders", params, 'orders');
        return [orders.map((item: Object) => new Order(item, account.symbol, account.displayDecimals)), orders.length];
    }

    async GetClosedOrders(account: Account, params: any) {
        const result = await this._invokeMethod("GetClosedOrders", params);
        const orders = result['orders'];
        return [orders.map((item: Object) => new Order(item, account.symbol, account.displayDecimals)), result['count']];
    }

    async GetOrdersForPosition(positionId: number, account: Account, referenceType?: OrderPositionAssociation) {
        const request: any = { positionId };
        if (referenceType) {
            request.referenceType = referenceType;
        }
        const result = await this._invokeMethod("GetOrdersForPosition", request);
        const orders = result['orders'];
        return [orders.map((item: Object) => new Order(item, account.symbol, account.displayDecimals)), result['count']];
    }

    async GetClosedPositions(account: Account, params: any) {
        const result = await this._invokeMethod("GetClosedPositions", params);
        const positions = result['positions'];
        return [positions.map((item: Object) => new Position(item, account.symbol, account.displayDecimals)), result['count']];
    }

    async GetTransactionHistory({ orderId, positionId, skip, take, dateFrom, dateTo }: TransactionHistoryParams) {
        const request: any = {};
        if (orderId) {
            request.orderId = orderId;
        }
        if (positionId) {
            request.positionId = positionId;
        }
        if (skip !== undefined) {
            request.skip = skip;
        }
        if (take) {
            request.take = take;
        }
        if (dateFrom) {
            request.dateFrom = dateFrom;
        }
        if (dateTo) {
            request.dateTo = dateTo;
        }
        const result = await this._invokeMethod("GetTransactionHistory", request);
        const transactions = result['transactions'];
        return [transactions.map((item: Object) => new Transaction(item)), result['count']];
    }

    async UpdatePosition(position: Position, data: any) {
        const request = {
            Id: position.id,
            TotalStopLoss: data.totalStopLoss,
            TotalTakeProfit: data.totalTakeProfit,
        }
        return this._invokeMethod("UpdatePosition", request);
    }

    async ClosePosition(position: Position) {
        return this._invokeMethod("ClosePosition", { positionId: position.id });
    }

    async AlertCreate(alertReq: any) {
        return this._invokeMethod("AlertCreate", alertReq);
    }

    async AlertList(account: Account, request: any): Promise<[Alert[], number]> {
        const alerts = await this._invokeMethod("AlertList", request, 'alerts');
        return [alerts.map((item: Object) => new Alert(item, account.symbol, account.displayDecimals)), alerts.length];
    }

    async AlertCancel(alert: Alert) {
        return this._invokeMethod("AlertCancel", { AlertId: alert.id });
    }

    async AlertUpdate(alert: Alert, data: any) {
        const request = {
            Id: alert.id,
            TriggerPrice: data.triggerPrice,
            GoodUntil: data.goodUntil,
        }
        return this._invokeMethod("AlertUpdate", request);
    }

    async getPosition(id: number, account: Account) {
        let position = await this._invokeMethod("GetPosition", { id: id }, 'position');
        return new Position(position, account.symbol, account.displayDecimals);
    }

    async getOrder(id: number | undefined, account: Account) {
        if (!id) return undefined;
        let order = await this._invokeMethod("GetOrder", { id: id }, 'order');
        return new Order(order, account.symbol, account.displayDecimals);
    }

    async AddAccountFavorites(pairIds: string[]) {
        return this._invokeMethod("AddAccountFavorites", { pairIds });
    }

    async RemoveAccountFavorites(pairIds: string[]) {
        return this._invokeMethod("RemoveAccountFavorites", { pairIds });
    }

}

export const orderService = new OrderService();