import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { TooltipItem } from "./components/TootlipItem";
import { UnitValueField } from "./components/fields/UnitValueField";
import { TimeRangePickerProps } from "antd";
import dayjs from 'dayjs';
import i18n from "./i18n";
import { appState } from "./appContext";
import { AssetClassesEnum, AssetClassesEnumToString } from "./models";

export const shortDateFormat = 'DD.MM HH:mm';
export const mediumtDateFormat = 'DD.MM.YY HH:mm:ss';
export const fullDateFormat = 'DD.MM.YYYY HH:mm:ss';
export const agoFormat = 'DD.MM.YY HH:mm:ss';

export type DateString = string | Date | undefined | null;
export type DateStyle = "short" | "full" | "long" | "medium" | undefined;

export const formatDate = (date: DateString, format = shortDateFormat) => {
    if (!date) {
        return '---';
    }
    return moment(date).format(format);
}

export const formatLocalDate = (date: DateString, dateStyle: DateStyle = 'short', timeStyle: DateStyle = 'short', name = '') => {
    if (!date) {
        return '---';
    }
    const formats = {
        short: 'L',
        medium: 'L LTS',
        long: 'LLL LTS',
        full: 'LLL LTS',
    }
    const format = formats[dateStyle];
    // const dt = typeof date === 'string' ? new Date(date) : date;
    // const localDateFormated = new Intl.DateTimeFormat(appState.language, { dateStyle, timeStyle, }).format(dt);
    const dt = dayjs(date).tz(appState.timezone);
    const utc = dt.utc();
    const localDateFormated = dt.format(format);
    const extenedInfo = <div className="date-info">
        <div className="date-item"><b>{i18n.t('common.date.local')}: </b>{dt.format('LLL')}</div>
        <div className="date-item"><b>{i18n.t('common.date.utc')}: </b>{utc.format('LLL')}</div>
    </div>
    return <TooltipItem prefix={name} text={extenedInfo}>{localDateFormated}</TooltipItem>;
}

export const formatNumber = (number: number, digits = 3) => {
    if (number === null || number === undefined) {
        return '---';
    }
    return new Intl.NumberFormat(appState.language, { maximumFractionDigits: digits, minimumFractionDigits: digits }).format(number);
}

export const formatNumberTrim = (number: number, digits = 3) => {
    return new Intl.NumberFormat(appState.language, { maximumFractionDigits: digits }).format(number);
}

export const formatNumberFix = (value: number, digits = 3) => {
    return new Intl.NumberFormat(appState.language, { maximumFractionDigits: digits, minimumFractionDigits: digits }).format(value);
}

export const formatCurrency = (number: number | null | undefined, symbol: string, digits = 2, className = '') => {
    if (number === null || number === undefined) {
        return <UnitValueField value={''} unit={''} className={className} />;
    }

    return <UnitValueField value={number} precision={digits} unit={symbol} className={className} />
}

export const getCurrencySymbolPosition = function () {
    const currency = 'USD';
    const formatter = new Intl.NumberFormat(appState.language, {
        style: "currency",
        currency: currency,
        currencyDisplay: "code"
    });
    const parts = formatter.formatToParts(0);
    const currencyIndex = parts.findIndex(part => part.value === currency);

    return currencyIndex === 0 ? 'before' : 'after';
};

type SwitchValueProps = { value: any, direct: any, hideUnit?: boolean, className?: string };
export const SwitchValue = ({ value, direct, hideUnit, className }: SwitchValueProps) => {
    const val = direct ? value.main : value.alt;
    const displayUnit = hideUnit === true ? '' : val.unit;
    return formatCurrency(val.value, displayUnit, val.digits, className);
}

export const debounce = (func: CallableFunction, delay: number) => {
    let timeoutId: any;
    return (...args: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const withParams = (Component: React.ComponentType<any>) => {
    return (props: any) => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

export const optionTrasform = (value: any, rawValue: any) => {
    if (rawValue && typeof rawValue === 'object')
        return rawValue.value;
    else
        return value;
}

export const round = (a: number, dec = 0) => {
    const pow = Math.pow(10, dec);
    return Math.round(a * pow) / pow;
}

export const roundSmart = (a: number) => {
    if (a > 100000) {
        return round(a / 10000, 0) * 10000;
    } else if (a > 10000) {
        return round(a / 1000, 0) * 1000;
    } else if (a > 1000) {
        return round(a / 100, 0) * 100;
    } else if (a > 100) {
        return round(a, 0);
    } else if (a > 10) {
        return round(a, 1);
    } else {
        return round(a, 2);
    }
}

export const usePagination = (itemsOnPage: number = 30) => {
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const onPage = (selected: number) => {
        setPage(selected);
    }

    return {
        setTotalCount,
        totalCount,
        setPage,
        page,
        onChangePage: onPage,
        itemsOnPage
    }
}

/**
 * Returns the computed font size of the given element in pixels.
 * If no element is provided, it returns the font size of the document element.
 *
 * @param {HTMLElement | undefined} element - The element to get the font size from.
 * @return {number} The computed font size in pixels.
 */
export const getFontSize = (element: HTMLElement | undefined = undefined) => {
    const size = window.getComputedStyle(element || document.documentElement, null).getPropertyValue("font-size");
    const pxSize = parseInt(size);
    return pxSize;
}


export const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Last Hour', value: [dayjs().add(-1, 'h'), dayjs()] },
    { label: 'Last Day', value: [dayjs().add(-1, 'd'), dayjs()] },
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
];

export const CategoryLabelFormatters = {
    pair: (name: string) => name,
    asset: (name: string) => name,
    assetClass: (name: string) => AssetClassesEnumToString(parseInt(name) as AssetClassesEnum),
}
