import { ReactElement } from "react";
import { favIcon } from "../assets/icons";
import { Alert, AssetClassesEnum, Order, PairInfo, Position } from "../models";
import { ExtendedPairInfo } from "../models/ExtendedPairInfo";
import BaseCommService from "./BaseCommService";

export interface AssetCategory {
  id: number;
  assetClassId: AssetClassesEnum; // TODO: check type
  type: string;
  token: string;
  name: string;
  children?: AssetCategory[];
  icon?: ReactElement;
}

type SearchMetadataResult = {
  categories: { sector: string, industry: string }[];
  exchanges: { name: string, id: number, micCode: string }[];
  subtypes: string[];
}

export type SearchMetadata = {
  categories: Map<string, string[]>;
  exchanges: { name: string, id: number, micCode: string }[];
  subtypes: string[];
}

export type SearchFilter = {
  assetClassId?: AssetClassesEnum;
  industry?: string;
  sector?: string;
  exchange?: number;
  subtype?: string;
}

export const favCategory: AssetCategory = { id: 0, name: 'Favorites', children: [], assetClassId: -1, icon: favIcon, type: 'category', token: 'favorites' };

export class AssetService extends BaseCommService {
  
  static pairInfoExtendedCache = new Map();
  static pairInfoCache = new Map();

  static get instance() { return assetsService }
  get hub() {
    return this._connecction.appHub;
  }

  async GetPairCategoriesTree() {
    const categories = await this._invokeMethod("GetPairCategoriesTree", {}, 'tree');
    categories.splice(0, 0, favCategory);
    return categories;
  }

  // TODO: cache for account
  async GetPairExtendedInfo(pairId: string): Promise<ExtendedPairInfo> {
    if (AssetService.pairInfoExtendedCache.has(pairId)) {
      return AssetService.pairInfoExtendedCache.get(pairId);
    }
    const data = await this._invokeMethod("GetPairExtendedInfo", { pairId });
    const info = new ExtendedPairInfo(data);
    AssetService.pairInfoExtendedCache.set(pairId, info);
    return info;
  }

  async GetPairInfo(pairId: string | undefined) {
    if (!pairId) {
      return null;
    }
    const result = await this.GetPairInfoByIds([pairId]);
    return result ? result[0] : null;
  }

  async GetAsset(assetId: string | undefined) {
    if (!assetId) {
      return null;
    }
    const result = await this._invokeMethod("GetAsset", { AssetId: assetId }, 'asset');
    return result;
  }

  async GetPairInfoByIds(pairIds: string[]) {
    if (pairIds.length === 0) {
      return [];
    }

    // try to get all from cache
    const cacheResult = pairIds.map(id => AssetService.pairInfoCache.get(id)).filter(item => item);
    if (cacheResult.length === pairIds.length) {
      return cacheResult;
    }
    const notCached = pairIds.filter(id => !AssetService.pairInfoCache.has(id));

    // get not cached from server
    const result = await this._invokeMethod("GetPairListInfo", { PairIds: notCached }, 'result');
    const retResult = result.map((item: string) => new PairInfo(item))

    // add to cache
    retResult.forEach((item: PairInfo) => AssetService.pairInfoCache.set(item.id, item));

    // add cached to result
    retResult.push(...cacheResult.map((item: string) => new PairInfo(item)));
    return retResult;
  }

  async GetPairListInfo(assetClassId: AssetClassesEnum, categoryId: number = 0, skip = 0, take = 20): Promise<{ items: PairInfo[], totalCount: number }> {
    const reqObj: any = {
      Skip: skip,
      Take: take ? take : 10,
    }

    if (assetClassId === AssetClassesEnum.Favorites) {
      // Favorites
    } else if (categoryId) {
      reqObj['PairCategoryId'] = categoryId;
      reqObj['AssetClassId'] = assetClassId;
    } else {
      reqObj['AssetClassId'] = assetClassId;
    }

    const result = await this._invokeMethod("GetPairListInfo", reqObj);
    return { items: result.result.map((item: object) => new PairInfo(item)), totalCount: result.count };
  }

  async SearchPairs(search: string, filter: SearchFilter, limit = 15) {
    const reqObj: any = {
      Filter: search,
      Take: limit,
    }
    if (filter.assetClassId) {
      reqObj.AssetClassId = filter.assetClassId;
    }
    if (filter.industry) {
      reqObj.Industry = filter.industry;
    }
    if (filter.sector) {
      reqObj.Sector = filter.sector;
    }
    if (filter.exchange) {
      reqObj.ExchangeId = filter.exchange;
    }
    if (filter.subtype) {
      reqObj.Subtype = filter.subtype;
    }

    return await this._invokeMethod("SearchPairs", reqObj, 'items');
  }

  async GetSearchMetadata(): Promise<SearchMetadata> {
    const result: SearchMetadataResult = await this._invokeMethod("GetSearchMetadata", {});
    const map = new Map<string, string[]>();
    result.categories.forEach(item => {
      if (!map.has(item.sector)) {
        map.set(item.sector, []);
      }
      let values = map.get(item.sector);
      values!.push(item.industry);
    });
    return { categories: map, exchanges: result.exchanges, subtypes: result.subtypes };
  }

  async ExtendPairInfo(items: Array<Order | Position | Alert | ExtendedPairInfo | any>, pair?: PairInfo): Promise<Array<Order | Position | Alert | ExtendedPairInfo | any>> {
    const filterNeccessary = (item: Order | Position | Alert) => {
      return item && item.pairId && !item.pairInfo && (!pair || item.pairId !== pair.id);
    }
    const bridgePairIds = items.filter(item => item.bridgePairId && !item.bridgePairInfo).map(item => item.bridgePairId);
    const pairIds = items.filter(filterNeccessary).map(item => item.pairId);
    bridgePairIds.push(...pairIds);

    const allPairs = new Map<string, PairInfo>((await assetsService.GetPairInfoByIds(bridgePairIds)).map((item: PairInfo) => [item.id, item]));
    if (pair) {
      allPairs.set(pair.id, pair);
    }
    items.forEach(item => {
      if (item.bridgePairId && !item.bridgePairInfo) {
        const bridgePair = allPairs.get(item.bridgePairId);
        item.bridgePairInfo = bridgePair;
      }
      if (!item.pairInfo) {
        const pairInfo = allPairs.get(item.pairId);
        item.pairInfo = pairInfo;
      }
    });
    return items;
  }
}

export const assetsService = new AssetService();