import React from 'react';
import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AreaLinkRoutes from './components/header/area-link/AreaLinkRoutes';
import Main from "./components/pages/Main/Main";
import NewsPage from './components/pages/NewsPage/NewsPage';
import PortfolioPage from './components/pages/PortfolioPage/PortfolioPage';
import { OpenPositionsTab } from './components/pages/PortfolioPage/tabs/OpenPositionsTab';
import PendingOrdersTab from './components/pages/PortfolioPage/tabs/PendingOrdersTab';
import StyleGuidePage from './components/pages/StyleGuide/StyleGuide';
import TradingPage from './components/pages/TradingPage/TradingPage';

import AlertsTab from './components/pages/PortfolioPage/tabs/AlertsTab';
import ClosedOrdersTable from './components/pages/PortfolioPage/tabs/ClosedOrdersTab';
import ClosedPositionsTab from './components/pages/PortfolioPage/tabs/ClosedPositionsTab';
import TransactionsTable from './components/pages/PortfolioPage/tabs/TransactionsTab';

import RisksPage from './components/pages/RiskPage/RisksPage';
import { ExposureTab } from './components/pages/RiskPage/tabs/ExposureTab';
import { HistoricalTab } from './components/pages/RiskPage/tabs/HistoricalTab';
import { MarginalAnalysisTab } from './components/pages/RiskPage/tabs/MarginalAnalysisTab';
import { SharpinoTab } from './components/pages/RiskPage/tabs/SharpinoTab/SharpinoTab';
import { SummaryDrawdownTab } from './components/pages/RiskPage/tabs/SummaryDrawdownTab';
import { SummaryTab } from './components/pages/RiskPage/tabs/VaRTab/VaRTab';
import { VarSubvalue } from './models';

interface AppRoute {
  path: string;
  requireAuth?: boolean;
  element: JSX.Element;
  children?: AppRoute[];
}


const AppRoutes: AppRoute[] = [
  {
    path: '/main',
    requireAuth: true,
    element: <Main />,
  },
  {
    path: '/trading/:pairId',
    requireAuth: true,
    element: <TradingPage />
  },
  {
    path: '/portfolio',
    requireAuth: true,
    element: <PortfolioPage />,
    children: [
      {
        path: "positions",
        element: <OpenPositionsTab />,
      },
      {
        path: "orders",
        element: <PendingOrdersTab />,
      },
      {
        path: "closed_positions",
        element: <ClosedPositionsTab />,
      },
      {
        path: "closed_orders",
        element: <ClosedOrdersTable />,
      },
      {
        path: "transactions",
        element: <TransactionsTable />,
      },
      {
        path: "alerts",
        element: <AlertsTab />,
      },
      {
        path: "*",
        element: <h2>Not found</h2>,
      }
    ]
  },
  {
    path: '/risk',
    requireAuth: true,
    element: <RisksPage />,
    children: [
      {
        path: "var",
        element: <SummaryTab subvalue={VarSubvalue.VaR} />,
      },
      {
        path: "cvar",
        element: <SummaryTab subvalue={VarSubvalue.CVaR} />,
      },
      {
        path: "exposure",
        element: <ExposureTab />,
      },
      {
        path: "drawdown",
        element: <SummaryDrawdownTab />,
      },
      {
        path: "sharpino",
        element: <SharpinoTab />,
      },
      {
        path: "historical",
        element: <HistoricalTab />,
      },
      {
        path: "marginal",
        element: <MarginalAnalysisTab />,
      },
      {
        path: "*",
        element: <h2>Not found</h2>,
      }
    ]
  },
  {
    path: '/news/:newsItemId',
    requireAuth: true,
    element: <NewsPage />
  },
  {
    path: '/news',
    requireAuth: true,
    element: <NewsPage />
  },
  {
    path: '/styles',
    element: <StyleGuidePage />
  },
  ...AreaLinkRoutes,
  ...ApiAuthorzationRoutes,

];

export default AppRoutes;
