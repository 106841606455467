import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';


export function useStoredParam<T = string>(key: string, defaultValue: T | undefined = undefined): [T | undefined, (value: T | undefined) => void] {
    const params = useParams();
    const storedValueFromParams = params[key] as T | undefined;
    const storedValueFromLocalStorage = localStorage.getItem(key);
    const [searchParams] = useSearchParams();
    const storedValueFromQuery = searchParams.get(key) as T | undefined;

    let defValue = defaultValue;
    if (storedValueFromParams) {
        defValue = storedValueFromParams;
    } else if (storedValueFromQuery) {
        defValue = storedValueFromQuery;
    } else if (storedValueFromLocalStorage) {
        defValue = JSON.parse(storedValueFromLocalStorage) as T;
    }

    const [storedValue, setStoredValue] = useState<T | undefined>(defValue);

    useEffect(() => {
        if (storedValueFromParams) {
            setStoredValue(storedValueFromParams);
        } else if (storedValueFromQuery) {
            setStoredValue(storedValueFromQuery);
        } else if (storedValueFromLocalStorage) {
            setStoredValue(JSON.parse(storedValueFromLocalStorage) as T);
        }
    }, [key, defaultValue, searchParams, storedValueFromParams, storedValueFromLocalStorage, storedValueFromQuery]);

    const setValue = (value: T | undefined) => {
        if (value !== undefined) {
            localStorage.setItem(key, JSON.stringify(value));
        }
        setStoredValue(value);
    };

    return [storedValue, setValue];
};
