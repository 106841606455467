import { observer } from "mobx-react-lite";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { saveIcon } from '../../../../assets/icons';
import { useTransactionsData } from '../../../../services/hooks';
import Loading from '../../../Loading';
import { TablePaginator } from '../../../TablePaginator';
import StatementDialog from "../../../common/StatementDialog/StatementDialog";
import { TransactionsView } from '../../../common/Transactions/TransactionsView';
import { useOutletContext } from "react-router";
import { PorfolioTabPropsContextType } from "../PortfolioPage";


const TransactionsTable: React.FC<{}> = observer(() => {
    const { t } = useTranslation();
    const {currency, ...rest} = useOutletContext<PorfolioTabPropsContextType>();
    const { data: transactions, loading, totalCount, pagination, setPage } = useTransactionsData(rest);
    const [isStatementDialogOpen, setStatementDialogOpen] = React.useState(false);


    if (loading || !transactions) {
        return <Loading />
    }

    return (
        <>
            <StatementDialog isOpen={isStatementDialogOpen} onClose={() => setStatementDialogOpen(false)} />
            <TransactionsView transactions={transactions} />
            <TablePaginator onPage={setPage} page={pagination.page} itemsOnPage={pagination.pageSize} totalCount={totalCount} />
            <Button color="primary" className="btn-icon btn-icon" onClick={() => setStatementDialogOpen(true)}>{saveIcon} {t('portfolio.statement.buttons.download')}</Button>
        </>
    )

});

export default TransactionsTable;
