import moment from 'moment';
import React from 'react';
import { ToastOptions, TypeOptions, toast } from 'react-toastify';


export const showNotification = (message: any, icon: React.JSX.Element | null = null, type: TypeOptions = 'info', date: Date | null = null) => {
    const dt = moment(date ? date : new Date()).fromNow();
    const internal = <div className='container'><div className='header' dangerouslySetInnerHTML={{__html:message}}></div><div className='body'>{dt}</div></div>
    const options: ToastOptions = { type, hideProgressBar: true };

    if (icon !== null) {
        options.icon = icon;
    }
    toast(internal, options);
}

export const showErrorNotification = (message: string, date = null) => {
    const dt = moment(date ? date : new Date()).fromNow();
    const internal = <div className='container'><div className='header'>{message}</div><div className='body'>{dt}</div></div>
    toast.error(internal, { hideProgressBar: true });
}