import { NewsItem } from "../models/NewsItem";
import BaseCommService from "./BaseCommService";

export class NewsService extends BaseCommService {
    static get instance() { return newsService }
    _cache = new Map();
    get hub() {
        return this._connecction.quoteHub;
    }
    
    async GetGetMarketNews(symbols: string[]) {
        const key = JSON.stringify(symbols);
        if (this._cache.has(key)) {
            return this._cache.get(key);
        }

        const request = {
            Symbols: symbols
        };
        const newsList = await this._invokeMethod("GetMarketNews", request, 'news');
        const ret = newsList.map((item: Object) => new NewsItem(item));
        this._cache.set(key, ret);
        return ret;
      }
}


export const newsService = new NewsService();