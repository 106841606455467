import React, { Component, Suspense } from 'react';
import {
  Navigate,
  Route, Routes, useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { AppStateContext, appState } from './appContext';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import authService from './components/api-authorization/AuthorizeService';
import { accountService, commService } from './services';
import { getCurrencySymbolPosition } from './utils';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { ToastContainer } from 'react-toastify';

import classNames from 'classnames';
import 'primereact/resources/primereact.min.css';
import { ColorModeContextProvider } from './contexts/color-mode';
import Loading from './components/Loading';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/de'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'



export const withRouter = (Component: typeof React.Component) => {
  const Wrapper = (props: any) => {
    const history = useNavigate();
    const location = useLocation();
    const params = useParams();
    return <Component history={history} location={location} params={params} {...props} />
  }
  return Wrapper;
}

interface AppProps {
  location: Location;
}

interface AppState {
  isMobile: boolean;
  isTablet: boolean;
}

class App extends Component<AppProps, AppState> {
  static displayName = App.name;
  private _subscription: number;

  constructor(props: AppProps) {
    super(props);
    this.state = {
      isMobile: false,
      isTablet: false,
    }
    this._subscription = authService.subscribe(() => this.populateState());
  }

  componentDidMount() {
    this.populateState();
    window.addEventListener('resize', this.handleResize.bind(this));
    this.handleResize()
    this.setupDayJS();
  }

  setupDayJS() {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(localizedFormat);
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  handleResize() {
    const isMobile = window.matchMedia("(max-width: 780px)").matches;
    const isTablet = window.matchMedia("(max-width: 1200px)").matches && !isMobile;
    if (this.state.isMobile !== isMobile) {
      this.setState({ isMobile: isMobile });
      appState.setIsMobile(isMobile);
    }
    if (this.state.isTablet !== isTablet) {
      this.setState({ isTablet: isTablet });
      appState.setIsTablet(isTablet);
    }
  }

  async populateState() {
    const [user] = await Promise.all([authService.getUser()])
    const isAuthenticated = !!user;
    appState.updateUser(user);

    if (isAuthenticated) {
      this.startSignal();
    }
  }

  async connectionStateChanged(isReady: boolean) {
    appState.updateConnectionState(isReady);

    if (isReady) {
      const [account, balance] = await Promise.all([accountService.GetAccountInfo(), accountService.GetPortfolioInfo()]);
      appState.updateAccount(account);
      appState.updateBalance(balance);
    }
  }

  onPortfolioStatus(data: any) {
    // TODO: convert data
    appState.updateBalance(data);
  }

  onPendingOrderList(data: any) {
    // TODO: convert data
    appState.updatePendingOrders(data);
  }

  onGenericNotification(gn: any) {
    // TODO: convert data
    appState.onGenericNotification(gn);
  }

  onConnectionClose(hub: signalR.HubConnection, error?: Error) {
    appState.setError(error);
  }

  async startSignal() {
    if (this.props.location.pathname.startsWith('/authentication')) {
      return;
    }

    commService.subscribe((isReady: boolean) => this.connectionStateChanged(isReady));
    const { appHub, quoteHub } = await commService.ensureInitialized();

    appHub.on("PortfolioStatus", (data) => this.onPortfolioStatus(data));
    appHub.on("PendingOrderList", (data) => this.onPendingOrderList(data));
    appHub.on("GenericNotification", (data) => this.onGenericNotification(data));

    appHub.onclose((error) => this.onConnectionClose(appHub, error))
    quoteHub.onclose((error) => this.onConnectionClose(quoteHub, error))

    try {
      await commService.start();
    } catch (error) {
      if (error.errorType === "FailedToNegotiateWithServerError" && error.message.includes("Status code '403'")) {
        authService.signOut({ state: { returnUrl: ApplicationPaths.Login } });
      }
    }
  }

  render() {
    const symbolPosition = getCurrencySymbolPosition();
    const isAdaptive = this.state.isMobile || this.state.isTablet;
    return (
      <Suspense fallback={<Loading />}>
        <div className={classNames('symbol-position-' + symbolPosition, { isAdaptive: isAdaptive, isMobile: this.state.isMobile, isTablet: this.state.isTablet })}>
          <ToastContainer />
          <ConfirmDialog />
          <ColorModeContextProvider>
            <AppStateContext.Provider value={appState}>
              <Routes>
                {AppRoutes.map((route, index) => {
                  const { element, requireAuth, children, ...rest } = route;
                  return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} >
                    {children?.map((child, index) => <Route key={index} path={child.path} element={child.element} />)}
                  </Route>;
                })}
                <Route path="/" element={<Navigate to={isAdaptive ? "/portfolio/positions" : "/main"} />} />
              </Routes>
            </AppStateContext.Provider>
          </ColorModeContextProvider>
        </div>
      </Suspense>
    );
  }
}

export default withRouter(App);