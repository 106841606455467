import classNames from 'classnames';
import { ErrorMessage, Field, Form } from 'formik';
import { observer } from 'mobx-react';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect } from 'react';
import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next';
import TimePicker from 'react-time-picker';
import { Button } from 'reactstrap';
import { appState } from '../../../../../appContext';
import { ExtendedPairInfo, GoodUntilTypes, OrderSide, OrderType, PairInfo, QtyType, getGoodUntilOptions } from '../../../../../models';
import { formatCurrency, formatNumber, roundSmart } from '../../../../../utils';
import { UnitValueField } from '../../../../fields/UnitValueField';
import { MultiPriceInput } from './MultiPriceInput/MultiPriceInput';
import MySelect from './MySelect/MySelect';
import { OrderFormModel } from './OrderFormModel';
import { Link } from 'react-router-dom';
import { analysesIcon } from '../../../../../assets/icons';


interface OrderActionButtonProps {
    direction: OrderSide;
    value: string;
    headingValue: string;
    action: CallableFunction;
    disabled: boolean;
    pair: PairInfo;
}

export const OrderActionButton: React.FC<OrderActionButtonProps> = ({ direction, value, headingValue, action, disabled, pair }) => {
    const { t } = useTranslation();
    const title = direction === OrderSide.Buy ? t('buttons.buy') : t('buttons.sell');
    const headingName = direction === OrderSide.Buy ? t('buttons.bid') : t('buttons.ask');
    const directionClass = direction === OrderSide.Buy ? 'buy' : 'sell';

    return (
        <Button disabled={disabled} className={directionClass} onClick={() => action(direction)} type="submit" name="orderSide">
            <div className='header'>
                <div className='left'>{headingName}</div>
                <div className='right text-number'><span >{headingValue}</span> <span className='suffix'>{pair.baseSymbol}</span></div>
            </div>
            <div className='title'>{title}</div>
            <div className='text-number value suffix-value unit-field'><span className='val'>{value}</span><span className=''>{pair.quoteSymbol}</span></div>
        </Button>
    )
};



interface FeeInfoProps {
    model: OrderFormModel;
    precision: number;
    unit: string;
}

const FeeInfo: React.FC<FeeInfoProps> = ({ precision, unit, model }) => {
    const { t } = useTranslation();
    if (!model.extendedInfo) {
        return <></>
    }
    return <div className={classNames('form-row', 'info-container')}>
        <h4 className='info-title'>{t('trading.orderForm.order_fees')}</h4>
        <div className='info'>
            <div className='label'>{t('trading.orderForm.fixed_fee')}:</div>
            <div className='value'><UnitValueField value={model.orderFeeFixed!} precision={precision} unit={unit} /></div>
        </div>
        <div className='info'>
            <div className='label'>{t('trading.orderForm.floating_fee')}:</div>
            <div className='value'><UnitValueField value={model.orderFeeFloating!} precision={2} unit={'%'} /></div>
        </div>
        <div className='info'>
            <div className='label'>{t('trading.orderForm.floating_fee_total')}:</div>
            <div className='value'><UnitValueField value={model.orderFeeFloatingTotal!} precision={precision} unit={unit} /></div>
        </div>
        <div className='info'>
            <div className='label'>{t('trading.orderForm.charge_type')}:</div>
            <div className=''>{model.chargeTiming}</div>
        </div>
    </div>
}

interface OrderFormInternalProps {
    direction?: OrderSide;
    symbol: string;
    extendedInfo?: ExtendedPairInfo;
    setFieldTouched: CallableFunction;
    availableMargin: number;
    model: OrderFormModel;
    pair: PairInfo;
    values: any;
    touched: any;
    errors: any;
    handleSubmit: CallableFunction;
    isValid: boolean;
    setValues: CallableFunction;
    handleChange: CallableFunction;
    setFieldValue: CallableFunction;
}

export const OrderFormInternal: React.FC<OrderFormInternalProps> = observer(({ direction, symbol, extendedInfo, setFieldTouched, availableMargin, model, pair, values, touched, errors, isValid, handleChange, setFieldValue }) => {
    model.pair = pair;
    model.values = values;
    model.extendedInfo = extendedInfo;
    const { account, balance } = appState;

    const askValueDisplay = pair.askDisplay;
    const bidValueDisplay = pair.bidDisplay;
    const askClass = classNames('ask price', pair.askClass, { active: values.side === OrderSide.Buy });
    const bidClass = classNames('bid price', pair.bidClass, { active: values.side === OrderSide.Sell });
    const spread = pair.spread;
    const spreadUnit = pair.spreadUnit;
    const askValue = pair.ask;
    const bidValue = pair.bid;
    const bidPriceDisplay = model.bidPriceDisplay;
    const askPriceDisplay = model.askPriceDisplay;
    const totalQty = formatNumber(model.totalQty, 0);
    const priceStep = 1 / Math.pow(10, pair.pairDecimals - 1);
    const qtyStep = values.qtyType === QtyType.Lots ? 1 : pair.lotSize / 100;
    const qtyDigits = 5;

    const marginRequired = model.marginRequired ? formatNumber(model.marginRequired, 2) : '---';
    const currencySymbol = account.symbol;
    const marginRequiredTotal = model.marginRequiredTotal ? formatCurrency(model.marginRequiredTotal, currencySymbol, account.displayDecimals) : '---';
    const { t } = useTranslation();
    const isActionActive = isValid && extendedInfo && pair.ask > 0 && pair.bid > 0;

    // Calculate initial values or react on change of available margin.
    useEffect(() => {
        setFieldValue('availableMargin', availableMargin);
        setFieldValue('marginRequired', model.marginRequiredTotal);
    }, [availableMargin, model.marginRequiredTotal]);

    const priceToleranceOptionsFX = [
        { value: '', label: t('enum.price_tolerance.market') },
        { value: '1', label: '1 pip' },
        { value: '2', label: '2 pips' },
        { value: '3', label: '3 pips' },
        { value: '4', label: '4 pips' },
        { value: '5', label: '5 pips' },
        { value: '0', label: t('enum.price_tolerance.fill_or_kill') }
    ]
    const priceToleranceOptionsOther = [
        { value: '', label: t('enum.price_tolerance.market') },
        { value: '1', label: '1 bp' },
        { value: '2', label: '2 bp' },
        { value: '3', label: '3 bp' },
        { value: '4', label: '4 bp' },
        { value: '5', label: '5 bp' },
        { value: '6', label: '6 bp' },
        { value: '7', label: '7 bp' },
        { value: '8', label: '8 bp' },
        { value: '9', label: '9 bp' },
        { value: '10', label: '10 bp' },
        { value: '0', label: t('enum.price_tolerance.fill_or_kill') }
    ]

    const priceToleranceOptions = pair.isFX ? priceToleranceOptionsFX : priceToleranceOptionsOther;
    const priceToleranceLabel = t('trading.orderForm.price_tolerance');
    const goodUntilOptions = getGoodUntilOptions(t);

    const handleChangeQtyType = (event: any) => {
        handleChange(event);
        const qtyNewValue = event.target.value === QtyType.Qty ? values.qty * pair.lotSize : values.qty / pair.lotSize;
        setFieldValue('qty', qtyNewValue);
    }

    const quickQty = [10, 25, 50, 75];
    const qtyMax = extendedInfo ? model.getMaxQty(balance.availableToTrade) : 0;

    const handleQuickQty = (value: number) => {
        setFieldValue('qty', roundSmart(value * qtyMax / 100));
        setFieldValue('qtyType', QtyType.Qty);
        setFieldTouched('qty');
    }

    const handleShortcuts = (e: any) => {
        const key = e.key.toUpperCase();
        if (key === 'K') {
            setFieldValue('qty', parseFloat(e.target.value) * 1000);
        }
        if (key === 'M') {
            setFieldValue('qty', parseFloat(e.target.value) * 1000000);
        }
    }

    const handlePriceClick = (direction: OrderSide, price: number) => {
        setFieldValue('price', price);
        setFieldValue('side', direction);
        appState.setDirection(direction);
    }

    return <Form>
        <div className={classNames('currentPrice', values.side)}>
            <div className={bidClass} onClick={() => handlePriceClick(OrderSide.Sell, bidValue)}>
                <div className='value'>{bidValueDisplay}</div>
                <div className='label'>{t('trading.orderForm.bid')}</div>
            </div>
            <div className='lot'>
                {spread} {spreadUnit}
            </div>
            <div className={askClass} onClick={() => handlePriceClick(OrderSide.Buy, askValue)}>
                <div className='value'>{askValueDisplay}</div>
                <div className='label'>{t('trading.orderForm.ask')}</div>
            </div>
        </div>
        <div className='orderType tabs'>
            <div className={classNames('tab', { active: values.orderType === OrderType.Market })} onClick={() => { setFieldValue('orderType', OrderType.Market); }}>{t('trading.orderForm.market')}</div>
            <div className={classNames('tab', { active: values.orderType === OrderType.Limit })} onClick={() => { setFieldValue('orderType', OrderType.Limit); }}>{t('trading.orderForm.limit')}</div>
            <div className={classNames('tab', { active: values.orderType === OrderType.Stop })} onClick={() => { setFieldValue('orderType', OrderType.Stop); }}>{t('trading.orderForm.stop')}</div>
            <Field name="orderType" type="hidden" onChange={handleChange}></Field>
        </div>

        <div className='inner'>
            <div className="form-row">
                <div className='cols'>
                    <div className="c1">
                        <div className="btn-group" role="group">
                            <Field className="btn-check" id="qtyType1" name="qtyType" type="radio" value={QtyType.Qty} onChange={handleChangeQtyType}></Field>
                            <label className="btn" htmlFor="qtyType1">{t('trading.orderForm.qty')}</label>
                            <Field className="btn-check" id="qtyType2" name="qtyType" type="radio" value={QtyType.Lots} onChange={handleChangeQtyType}></Field>
                            <label className="btn" htmlFor="qtyType2">{t('trading.orderForm.lots')}</label>
                        </div>
                    </div>
                    <div className="c2">
                        {/* <Field className="form-control" id="qty" name="qty" type="number" value={values.qty} onChange={handleChange} autoComplete="off"></Field> */}
                        <InputNumber
                            maxFractionDigits={qtyDigits}
                            step={qtyStep}
                            showButtons
                            inputId="qty"
                            name="qty"
                            onKeyUp={(e) => handleShortcuts(e)}
                            value={values.qty}
                            onChange={(e: any) => { setFieldValue('qty', e.value) }}
                            inputClassName={classNames('form-control', { 'p-invalid': !!errors.qty })}
                            pt={{
                                input: {
                                    root: { autoComplete: 'off' }
                                } as any
                            }}
                        />
                    </div>
                    <ErrorMessage name="qty" className="c3 invalid-feedback" component="div"></ErrorMessage>
                </div>
                <div className="qty-max">
                    {quickQty.map(value => {
                        return <button key={value} className="btn btn-link" type="button" onClick={() => handleQuickQty(value)}>{value}%</button>
                    })}
                </div>
            </div>

            <div className={classNames('form-row', { 'hidden': values.orderType !== OrderType.Market })}>
                <MySelect
                    className="priceTolerance"
                    options={priceToleranceOptions}
                    name="priceTolerance"
                    label={priceToleranceLabel}
                    placeholder={priceToleranceLabel}
                    value={values.priceTolerance}
                    onSetValue={setFieldValue}
                    onTouched={setFieldTouched}
                    error={errors.priceTolerance}
                    touched={touched.priceTolerance}
                />
            </div>

            <div className={classNames('form-row', { 'hidden': values.orderType === OrderType.Market })}>
                <label htmlFor="price" className="form-label">{t('trading.orderForm.trigger_price')}</label>
                <div>
                    <InputNumber
                        minFractionDigits={pair.pairDecimals}
                        showButtons
                        inputId="price"
                        name="price"
                        step={priceStep}
                        value={values.price}
                        onChange={(e) => { setFieldValue('price', e.value) }}
                        inputClassName={classNames('form-control', { 'p-invalid': !!errors.price })}
                    />
                </div>

                {/* <Field id="price" className="form-control" name="price" type="number" value={values.price} onChange={handleChange} step={priceStep}></Field> */}
                <ErrorMessage name="price" className="invalid-feedback" component="div"></ErrorMessage>
            </div>


            <div className={classNames('form-row good-until-type-row', { 'hidden': values.orderType === OrderType.Market })}>
                <MySelect
                    className="goodUntilType"
                    options={goodUntilOptions}
                    name="goodUntilType"
                    label="Good until"
                    placeholder="Good until"
                    value={values.goodUntilType}
                    onSetValue={setFieldValue}
                    onTouched={setFieldTouched}
                    error={errors.goodUntilType}
                    touched={touched.goodUntilType}
                />
            </div>

            <div className={classNames('form-row was-validated good-until-row', { 'hidden': values.orderType === OrderType.Market || values.goodUntilType.value !== GoodUntilTypes.GTT })}>
                <div className='cols'>
                    <DatePicker calendarIcon={null} className='c1' onChange={(value) => { setFieldTouched('goodUntilDate'); setFieldValue('goodUntilDate', value) }} value={values.goodUntilDate} name="goodUntilDate" id="goodUntilDate" />
                    <TimePicker clockIcon={null} className='c2' onChange={(value) => { setFieldTouched('goodUntilTime'); setFieldValue('goodUntilTime', value) }} value={values.goodUntilTime} name="goodUntilTime" id="goodUntilTime" />
                    <div className='c3 '>
                        <ErrorMessage component="div" className='invalid-feedback' name="goodUntilDate"></ErrorMessage>
                        <ErrorMessage component="div" className='invalid-feedback' name="goodUntilTime"></ErrorMessage>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-check form-switch">
                    <label className="form-check-label" htmlFor="takeProfit">{t('trading.orderForm.take_profit')}</label>
                    <Field className="form-check-input" id="takeProfit" name="takeProfit" type="checkbox"></Field>
                </div>

                <div className={classNames('take-profit', { 'hidden': !values.takeProfit })}>
                    {model.extendedInfo && <Field as={MultiPriceInput} decimals={pair.pairDecimals} symbol={symbol} margin={model.marginRequiredTotal} id="takeProfitTotal" price={pair.bid} qty={values.qty} name="takeProfitTotal" type="number" value={values.takeProfitTotal} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} errors={errors}></Field>}
                    <ErrorMessage name="takeProfitTotal" className="invalid-feedback" component="div"></ErrorMessage>
                </div>
            </div>

            <div className="form-row">
                <div className="form-check form-switch">
                    <label className="form-check-label" htmlFor="stopLoss">{t('trading.orderForm.stop_loss')}</label>
                    <Field className="form-check-input" id="stopLoss" name="stopLoss" type="checkbox"></Field>
                </div>

                <div className={classNames('stop-loss', { 'hidden': !values.stopLoss })}>
                    {model.extendedInfo && <Field as={MultiPriceInput} decimals={pair.pairDecimals} symbol={symbol} margin={-model.marginRequiredTotal!} price={pair.bid} id="stopLossTotal" qty={values.qty} name="stopLossTotal" type="number" value={values.stopLossTotal} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} errors={errors}></Field>}
                    <ErrorMessage name="stopLossTotal" className="invalid-feedback" component="div"></ErrorMessage>
                </div>
            </div>

            <div className={classNames('actions', 'form-row', { 'single': values.side })}>
                {(!values.side || values.side === OrderSide.Sell) && <OrderActionButton direction={OrderSide.Sell} value={bidPriceDisplay} headingValue={totalQty} action={(submiter: CallableFunction) => { setFieldValue("side", submiter); }} disabled={!isActionActive} pair={pair}></OrderActionButton>}
                {(!values.side || values.side === OrderSide.Buy) && <OrderActionButton direction={OrderSide.Buy} value={askPriceDisplay} headingValue={totalQty} action={(submiter: CallableFunction) => { setFieldValue("side", submiter); }} disabled={!isActionActive} pair={pair}></OrderActionButton>}
            </div>

            <div className={classNames('form-row', 'info-container')}>
                <div className='info'>
                    <div className='label'>{t('trading.orderForm.margin_required')}:</div>
                    <div className='value'><div className='suffix-value'><span className='val'>{marginRequired}</span><span className="suffix">%</span></div></div>
                </div>
                <div className={classNames('info', { 'invalid-feedback-inline': errors.marginRequired })}>
                    <div className='label'>{t('trading.orderForm.margin_required_total')}:</div>
                    <div className='value'>{marginRequiredTotal}</div>
                </div>
                {!!model.marginRequiredTotal && errors.marginRequired && <div className='invalid-feedback-inline'>{errors.marginRequired}</div>}
                <div className='info'>
                    <div className='label'>{t('trading.orderForm.available_margin')}:</div>
                    <div className='value'>{formatCurrency(availableMargin, currencySymbol)}</div>
                </div>
            </div>
            <FeeInfo model={model} precision={account.displayDecimals} unit={symbol}></FeeInfo>
            <Link
                to={'/risk/marginal?' + new URLSearchParams({pairId: pair.id, notional: values.qty, side: values.side === 'Buy' ? 'Long' : 'Short'}).toString()}
                className='link btn btn-secondary btn-icon'>
                {t('trading.orderForm.see_marginal')} {analysesIcon}
            </Link>
        </div>
    </Form>
});