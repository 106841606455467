import classNames from 'classnames';
import { ErrorMessage, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import 'react-clock/dist/Clock.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { Button } from 'reactstrap';
import * as yup from 'yup';
import { AppStateContext } from '../../../../../appContext';
import { alertIcon } from '../../../../../assets/icons';
import { getGoodUntil } from '../../../../../common/order-utils';
import { GoodUntilTypes, getGoodUntilOptions } from '../../../../../models/Triggerable';
import { logoImageService, orderService } from '../../../../../services';
import { optionTrasform, round } from '../../../../../utils';
import MySelect from '../OrderForm/MySelect/MySelect';
import './AlertForm.css';

import { InputNumber } from 'primereact/inputnumber';
import { WithTranslation, withTranslation } from 'react-i18next';
import { showErrorNotification, showNotification } from '../../../../../common/notifications';
import i18n from '../../../../../i18n';
import { PairDetailsProps } from '../PairInfoView/PairInfoView';

interface AlertFormProps extends PairDetailsProps, WithTranslation {
}

const AlertForm = observer(class AlertForm extends Component<AlertFormProps> {
    static displayName = AlertForm.name;
    static contextType = AppStateContext;
    context!: ContextType<typeof AppStateContext>;

    schema: yup.ObjectSchema<{ price: number; goodUntilType: number; goodUntilDate: {} | null | undefined; goodUntilTime: {} | null | undefined; }, yup.AnyObject, { price: undefined; goodUntilType: undefined; goodUntilDate: undefined; goodUntilTime: undefined; }, "">;

    constructor(props: AlertFormProps) {
        super(props);

        this.schema = yup.object({
            price: yup.number().required(),
            goodUntilType: yup.mixed<GoodUntilTypes>().oneOf(Object.values(GoodUntilTypes) as number[]).required().transform(optionTrasform),
            goodUntilDate: yup.mixed().nullable()
                .when('goodUntilType', {
                    is: (value: number) => value === GoodUntilTypes.GTT,
                    then: () => yup.date().required(), // min(new Date())
                }),
            goodUntilTime: yup.mixed().nullable()
                .when('goodUntilType', {
                    is: (value: number) => value === GoodUntilTypes.GTT,
                    then: () => yup.mixed().required(),
                }),
        });
    }

    async onSubmit(values: any) {
        values = this.schema.cast(values, { assert: false });
        const alertRequest = {
            AlertDraft: {
                PairId: this.props.pair.id,
                TriggerPrice: values.price,
                GoodUntil: getGoodUntil(values)
            }
        };
        try {
            const response = await orderService.AlertCreate(alertRequest);
            console.table(response);
            showNotification(i18n.t('notifications.alert.created'));
        } catch (error) {
            showErrorNotification(error.message);
        }
    }

    render() {
        const { pair, extendedInfo, t } = this.props;

        const quotes = this.context.quotes.values;
        const quote = quotes.get(pair.id);
        pair.updateQuote(quote);
        const goodUntilOptions = getGoodUntilOptions(t);
        const priceStep = 1 / Math.pow(10, pair.pairDecimals - 1);

        const iconUrl = logoImageService.getPairLogoUrl(pair.id);
        return (
            <div className='AlertForm'>
                <h2 className=''>{alertIcon} <span>{t('alert.alert')}</span></h2>
                <h3><span className='logo-wrapper'><img className="pair-logo" src={iconUrl}></img></span> <span>{pair.id}</span></h3>
                <div className='pair-description'>{pair.dispName} {extendedInfo && extendedInfo.subtype !== null && <span>({extendedInfo.subtype})</span>}</div>
                <Formik
                    validationSchema={this.schema}
                    onSubmit={(values) => this.onSubmit(values)}
                    enableReinitialize={false}
                    initialValues={{
                        PairId: pair.id,
                        price: round(pair.mid, pair.pairDecimals),
                        goodUntilType: goodUntilOptions[0],
                        goodUntilDate: new Date(),
                        goodUntilTime: new Date(),
                    }}
                    validateOnMount={true}
                >
                    {({ values, errors, touched, setFieldValue, setFieldTouched, handleChange, isValid, handleSubmit }) => {
                        const direction = values.price > pair.mid ? 'bid' : 'ask';
                        const askClass = classNames('ask price', pair.askClass, {active: values.price > pair.mid});
                        const bidClass = classNames('bid price', pair.bidClass, {active: values.price < pair.mid});
                    
                        return <form onSubmit={handleSubmit}>
                            <div className="currentPrice">
                                <div className={bidClass}>
                                    <div className='value'>{pair.bidDisplay}</div>
                                    <div className='label'>{t('trading.orderForm.bid')}</div>
                                </div>
                                <div className='lot'>
                                    {pair.spread} {pair.spreadUnit}
                                </div>
                                <div className={askClass}>
                                    <div className='value'>{pair.askDisplay}</div>
                                    <div className='label'>{t('trading.orderForm.ask')}</div>
                                </div>
                            </div>
                            <div className='inner'>
                                <div className={classNames('form-row')}>
                                    <label htmlFor="price" className={classNames('form-label', 'price-direction-' + direction)} dangerouslySetInnerHTML={{ __html: t(`alert.${direction}_price_label`) }}></label>
                                    <InputNumber
                                        minFractionDigits={pair.pairDecimals}
                                        showButtons
                                        inputId="price"
                                        name="price"
                                        step={priceStep}
                                        value={values.price}
                                        onChange={(e) => { setFieldValue('price', e.value) }}
                                        inputClassName={classNames('form-control', { 'p-invalid': !!errors.price })}
                                    />

                                    <ErrorMessage name="price" className="invalid-feedback" component="div"></ErrorMessage>
                                </div>

                                <div className={classNames('form-row')}>
                                    <MySelect
                                        options={goodUntilOptions}
                                        name="goodUntilType"
                                        label={t('alert.good_until')}
                                        placeholder={t('alert.good_until')}
                                        value={values.goodUntilType}
                                        onSetValue={setFieldValue}
                                        onTouched={setFieldTouched}
                                        error={errors.goodUntilType}
                                        touched={touched.goodUntilType}
                                    />
                                </div>

                                <div className={classNames('form-row was-validated', { 'hidden': values.goodUntilType.value !== GoodUntilTypes.GTT })}>
                                    <div className='cols'>
                                        <DatePicker calendarIcon={null} className='c1' onChange={(value) => { setFieldTouched('goodUntilDate'); setFieldValue('goodUntilDate', value) }} value={values.goodUntilDate} name="goodUntilDate" id="goodUntilDate" />
                                        <TimePicker clockIcon={null} className='c2' onChange={(value) => { setFieldTouched('goodUntilTime'); setFieldValue('goodUntilTime', value) }} value={values.goodUntilTime} name="goodUntilTime" id="goodUntilTime" />
                                        <div className='c3 '>
                                            <ErrorMessage component="div" className='invalid-feedback' name="goodUntilDate"></ErrorMessage>
                                            <ErrorMessage component="div" className='invalid-feedback' name="goodUntilTime"></ErrorMessage>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-row single text-center'>
                                    <Button color="primary" disabled={!isValid} className='w-100'>
                                        {t('alert.create_alert')}
                                    </Button>
                                </div>
                            </div>

                            {/* <div className='debug text-danger'>Errors: {JSON.stringify(errors)}</div> */}
                            {/* <div className='debug'>Values: {JSON.stringify(this.schema.cast(values, { assert: false }))}</div> */}
                            {/* <div className='debug'>Touched: {JSON.stringify(props.touched)}</div> */}
                        </form>;
                    }}

                </Formik>

            </div>
        );
    }
})

AlertForm.contextType = AppStateContext;
export default withTranslation()(AlertForm);