import { HistogramSeriesPartialOptions, IChartApi, ISeriesApi, MouseEventParams, SingleValueData } from "lightweight-charts";
import { CandlestickVolumeData } from "../Chart";
import { BaseIndicator, TooltipOptions } from "./BaseIndicator";
import { formatNumber } from "src/utils";


export class VolumeIndicator extends BaseIndicator {
    data: any;
    series: ISeriesApi<"Histogram">;
    priceScaleId: string = '';

    update(item: CandlestickVolumeData): void {
        this.series.update({
            time: item.time,
            value: item.volume,
            color: item.open < item.close ? '#198754' : '#dc3545'
        });
    }

    updateData(data: CandlestickVolumeData[]): void {
        this.data = data.map((item: CandlestickVolumeData) => {
            return {
                time: item.time,
                value: item.volume,
                color: item.open < item.close ? '#198754' : '#dc3545'
            };
        }).filter(item => item.value !== null);

        if (this.data && this.series) {
            this.series.setData(this.data);
        }
    }

    attach(chart: IChartApi, mainSeries: ISeriesApi<"Candlestick">): void {
        super.attach(chart, mainSeries);

        this.series = this.chart.addHistogramSeries({
            color: 'red',
            priceFormat: {
                type: 'volume',
            },
            lastValueVisible: false,
            priceScaleId: '', // set as an overlay by setting a blank priceScaleId

        } as HistogramSeriesPartialOptions);
        this.series.priceScale().applyOptions({
            scaleMargins: {
                top: 0.8,
                bottom: 0.0,
            },
        });

        if (this.data) {
            this.series.setData(this.data);
        }
    }

    detach() {
        this.chart?.removeSeries(this.series);
    }

    getTooltip(param: MouseEventParams, options: TooltipOptions): string {
        const volumeData = param.seriesData.get(this.series!) as SingleValueData;
        const value = formatNumber(volumeData?.value, undefined);
        const baseSymbol = options.baseSymbol;
        return `<span class="value suffix-value"><span class="val number text-number">${value}</span><span class="suffix">${baseSymbol}</span>`;
    }
}
