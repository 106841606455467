import React, { useState } from 'react';
import CategoryTree from './CategoryTree';
import SearchWidget from '../SearchWidget/SearchWidget';
import { AssetCategory } from '../../../../../services';
import classNames from 'classnames';
import { searchIcon } from '../../../../../assets/icons';

interface MarketsExplorerProps {
    categories: AssetCategory[];
    category: AssetCategory;
    handleUpdate: (state: { selectedCategory: AssetCategory }) => void;
}

const MarketsExplorer: React.FC<MarketsExplorerProps> = (props) => {
    const [selectedCategory, setSelectedCategory] = useState(props.category);
    const [isOpen, setOpen] = useState(false);

    const handleCategorySelect = (category: any) => {
        setSelectedCategory(category);
        props.handleUpdate({ selectedCategory: category });
    };

    return (<>
        <button className='btn toggler' onClick={() => setOpen(!isOpen)}>{searchIcon}</button>
        <div className={classNames('content', { isOpen })}>
            <SearchWidget categories={props.categories} />
            <CategoryTree
                selected={selectedCategory}
                categories={props.categories}
                onCategorySelect={(category: AssetCategory) => handleCategorySelect(category)}
            />
        </div>
    </>
    );
};

export default MarketsExplorer;
