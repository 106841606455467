
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Col, Container, Row } from 'reactstrap';
import Loading from '../../Loading';

import { confirmDialog } from 'primereact/confirmdialog';
import { toast } from 'react-toastify';
import { showNotification } from '../../../common/notifications';
import { Order, TriggerStatus } from '../../../models';
import { GenericNotification, NotificationType } from '../../../models/Notifications';
import { NotificationService } from '../../../services';
import { useTitle } from '../../../services/hooks/useTitle';
import { withParams } from '../../../utils';
import './StyleGuide.css';

const mcNotification = new GenericNotification({
    "accountId": 1,
    "type": "MarginCallIssued",
    "alert": null,
    "order": null,
    "position": null,
    "marginCall": {
        "accountId": 1,
        "policyId": 1,
        "cash": 148457.688848464,
        "status": "Pending",
        "kind": "Liquidation",
        "positionCount": 9,
        "unrealizedPnl": 14217.9414505195,
        "totalMargin": 159686.4889121787,
        "totalValue": 3253762.4341989183,
        "netEquity": 162675.6302989835,
        "availableToTrade": 2989.1413868048,
        "marginIndicator": 0.90187188121372541,
        "issuedOn": "2024-03-24T12:52:09.7965833Z",
        "userNotifiedOn": "0001-01-01T00:00:00",
        "token": "MC/0000-5B00-0004",
        "id": 8
    },
    "marginCallPolicy": null,
    "customerServiceMessage": null,
    "orderId": null,
    "orderToken": null,
    "status": null,
    "reason": null,
    "positionId": null,
    "positionToken": null,
    "sentAtUtc": "2024-03-24T12:52:09.7989614Z"
}, '$', 2);

const order = new Order({
    persistentStatus: TriggerStatus.Completed,
    pairId: 'BTC/USD',
    executionPrice: 20000,
    lastUpdatedAt: new Date()
}, '$', 2);

const orderNotification = new GenericNotification({
    "accountId": 1,
    "type": NotificationType.OrderSubmitted,
    "alert": null,
    "order": order,
    "position": null,
    "marginCall": null,
    "marginCallPolicy": null,
    "customerServiceMessage": null,
    "orderId": null,
    "orderToken": null,
    "status": null,
    "reason": null,
    "positionId": null,
    "positionToken": null,
    "sentAtUtc": "2024-03-24T12:52:09.7989614Z"
}, '$', 2);

const orderExpiredNotification = new GenericNotification({
    "accountId": 1,
    "type": NotificationType.OrderExpired,
    "alert": null,
    "order": order,
    "position": null,
    "marginCall": null,
    "marginCallPolicy": null,
    "customerServiceMessage": null,
    "orderId": null,
    "orderToken": null,
    "status": null,
    "reason": null,
    "positionId": null,
    "positionToken": null,
    "sentAtUtc": "2024-03-24T12:52:09.7989614Z"
}, '$', 2);

const alertFiredNotification = new GenericNotification({
    "accountId": 1,
    "type": NotificationType.AlertFired,
    "alert": {
        "bridgeForward": null,
        "bridgePairId": null,
        "bridgeRate": null,
        "id": 164,
        "accountId": 1,
        "cancellationReason": "Unknown",
        "createdAt": "2024-03-31T13:48:15.886Z",
        "createdByUserId": 2,
        "executedAt": "2024-03-31T13:48:48.494Z",
        "executionPrice": 65508.9,
        "goodUntil": "2100-01-01T00:00:00.000Z",
        "isExecutable": false,
        "lastUpdatedAt": "2024-03-31T13:48:48.494Z",
        "pairId": "BTC/EUR",
        "persistentStatus": TriggerStatus.Completed,
        "side": "Buy",
        "token": "A/0000-6A00-0002",
        "triggerPrice": 65516.3,
        "identity": null,
        "inQuoteCurrency": true,
        "discriminator": "Alert",
        "pair": null,
        "beingProcessed": true
    },
    "order": order,
    "position": null,
    "marginCall": null,
    "marginCallPolicy": null,
    "customerServiceMessage": null,
    "orderId": null,
    "orderToken": null,
    "status": null,
    "reason": null,
    "positionId": null,
    "positionToken": null,
    "sentAtUtc": "2024-03-24T12:52:09.7989614Z"
}, '$', 2);


export const SGToasts = () => {

    const showToast = (type: string) => {
        if (type === 'orderCreated') {
            NotificationService.instance.showNotification(orderNotification);
        } if (type === 'orderExpired') {
            NotificationService.instance.showNotification(orderExpiredNotification);
        } else if (type === 'position') {
            // showPositionNotification('Postion closed');
        } else if (type === 'alert') {
            NotificationService.instance.showNotification(alertFiredNotification);
        } else if (type === 'info') {
            showNotification('Order created');
        } else if (type === 'mc') {
            NotificationService.instance.showNotification(mcNotification);
        }
    };
    const types = ['orderCreated', 'orderExpired', 'position', 'info', 'mc', 'alert'];

    return (
        <div className="d-flex">
            {types.map((type) =>
                <div key={type} className="pe-3" >
                    <button className="btn btn-primary" type="button" onClick={() => showToast(type)}>Toast {type}</button>
                </div>
            )}
        </div>
    );
};

export const SGConfiramtion = () => {

    const showConfirmation = async () => {
        confirmDialog({
            message: 'Are you sure you want to delete?',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            icon: 'pi pi-exclamation-triangle',
            accept: () => toast.info("Accepted"),
            reject: () => toast.error("Canceled"),
        });
    }

    return (
        <div className="d-flex">
            <div className="pe-3" >
                <button className="btn btn-primary" type="button" onClick={() => showConfirmation()}>Confirm</button>
            </div>
        </div>
    );
}

interface StyleGuidePageProps {

}

const StyleGuidePage: React.FC<StyleGuidePageProps> = () => {
    const [loading, setLoading] = useState(true);
    useTitle('Style guide');

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    if (loading) {
        return <Loading />;
    }

    const reactElement = <span style={{ color: 'red' }}>React Element</span>;
    const rendered = renderToString(reactElement)

    return (
        <Container fluid >
            <Row>
                <Col>
                    <div dangerouslySetInnerHTML={{ __html: rendered }} />
                    <h1>Style Guide</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Toasts</h2>
                    <SGToasts />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Dialogs</h2>
                    <SGConfiramtion />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Buttons and Checkboxes</h2>
                    <div className='checkbox-wrapper'>
                        <label className='checkbox-label' htmlFor='custom-checkbox'>Take-profit</label>
                        <input className='checkbox-input' type='checkbox' id='custom-checkbox'></input>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default withParams(StyleGuidePage);