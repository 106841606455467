import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeIcon, deleteIcon, editIcon, infoIcon, saveIcon } from '../../../assets/icons';
import { AlertFormValues, cancelAlert, submitChanges } from '../../../common/alert-utils';
import { Alert, GoodUntilTypes, getGoodUntilShortOptions } from '../../../models';
import { SwitchValue } from '../../../utils';
import { NavLink } from 'react-router-dom';
import { logoImageService } from '../../../services';
import { DateField } from '../../fields';
import { TooltipItem } from '../../TootlipItem';
import SelectField from '../../fields/SelectField';
import { DateTimePickerField } from '../../fields/DateTimePickerField';
import { PairField } from '../../fields/PairField';



export interface AlertsViewInternalProps {
    alerts: Alert[];
    refreshAlerts: CallableFunction;
    symbol: string;
    currency?: string;
}

export interface AlertsViewProps extends AlertsViewInternalProps {
    fullView: boolean;
}

export interface AlertRowProps {
    alert: Alert;
    symbol?: string;
    currency?: string;
}

const useAlertViewData = ({ symbol, refreshAlerts, currency }: { currency?: string, symbol: string, refreshAlerts: CallableFunction }) => {
    const { t } = useTranslation();
    const [editingRow, setEditingRow] = useState<Number | undefined>();
    const { values, resetForm } = useFormikContext<AlertFormValues>()
    const isDirect = currency === 'account' || !!editingRow;
    const goodUntilShortOptions = getGoodUntilShortOptions(t);

    const setEdit = (alert: Alert | undefined) => {
        setEditingRow(alert?.id);
        if (alert) {
            resetForm({
                values: {
                    triggerPrice: alert.triggerPrice,
                    goodUntilType: alert.goodUntilType,
                    goodUntil: alert.goodUntil && alert.goodUntilType === GoodUntilTypes.GTT ? alert.goodUntil : moment().add(1, 'day').toDate(),
                }
            });
        }
    }
    return {
        logo: {
            title: null,
            render: (alert: Alert, params = {}) => {
                const iconUrl = logoImageService.getPairLogoUrl(alert.pairId);
                return <div className='logo-wrapper'><img src={iconUrl} className='pair-logo'></img></div>
            }
        },
        cell_ticker: {
            title: null,
            render: (alert: Alert, params = {}) => {
                return <>
                    <NavLink className='pair-id' to={"/trading/" + encodeURIComponent(alert.pairId)}>{alert.pairId}</NavLink>
                    <span className='small-info'>{alert.token}</span>
                </>
            }
        },
        ticker: {
            title: t('portfolio.alerts.ticker'),
            render: (alert: Alert, params = {}) => {
                const tooltipContent = <div>
                    <div><b>Pair:</b> {alert.pairId}</div>
                    <div><b>Token:</b> {alert.token}</div>
                    <div><b>Created at:</b> <DateField value={alert.createdAt} format='medium' /></div>
                </div>
                return <>
                <div className='item-info'>
                        <PairField pairId={alert.pairId} withLogo={true} withName={true} fullName={false} />
                        <div className='info-icon'>
                            <TooltipItem text={tooltipContent} id={alert.id} prefix="tt-open-pos" customClass='info-tooltip'>
                                {infoIcon}
                            </TooltipItem>
                        </div>
                    </div>
                    <span className='side'>{t(alert.SideName)}</span>
                </>
            }
        },
        trigger_price: {
            title: t('portfolio.alerts.price'),
            render: (alert: Alert, params = {}) => {
                const isEditing = editingRow === alert.id;
                if (isEditing) {
                    return <div className='text-number value suffix-value unit-field'><Field className="form-control" name="triggerPrice" type="number" /> <span className='suffix'>{alert.bridgeSymbol}</span></div>
                }
                console.log(alert.TriggerPrice);
                
                return <SwitchValue value={alert.TriggerPrice} direct={isDirect} {...params} />
            }
        },
        current_price: {
            title: t('portfolio.alerts.current_price'),
            render: (alert: Alert, params = {}) => {
                return <div className='text-number'>{alert.isBuy ? alert.pairInfo?.bidDisplay : alert.pairInfo?.askDisplay}</div>
            }
        },
        currency_unit: {
            render: (alert: Alert, params = {}) => {
                return symbol;
            }
        },
        created_at: {
            title: t('portfolio.alerts.created_at'),
            render: (alert: Alert, params = {}) => <DateField value={alert.createdAt} />
        },
        good_until: {
            title: t('portfolio.alerts.validity'),
            render: (alert: Alert, params = {}) => {
                const isEditing = editingRow === alert.id;
                if (isEditing) {
                    return <div className='multi-input'>
                        <Field component={SelectField} classNamePrefix='select' name="goodUntilType" menuPlacement="bottom" options={goodUntilShortOptions} placeholder="Valid to" />
                        {values.goodUntilType === GoodUntilTypes.GTT && <DateTimePickerField name="goodUntil" />}
                    </div>
                } else {
                    if (alert.goodUntilType === GoodUntilTypes.GTT) {
                        return <DateField value={alert.goodUntil} />
                    } else {
                        return <>{goodUntilShortOptions[alert.goodUntilType].label}</>
                    }
                }
            }
        },
        actions: {
            title: t('portfolio.alerts.actions'),
            render: (alert: Alert, params = {}) => {
                const isEditing = editingRow === alert.id;
                return <div className='actions'>
                    {!isEditing && <button className="icon" onClick={() => setEdit(alert)}>{editIcon}</button>}
                    {!isEditing && <button className="icon" onClick={() => cancelAlert(alert, refreshAlerts)}>{deleteIcon}</button>}

                    {isEditing && <button className="icon" onClick={() => setEdit(undefined)}>{closeIcon}</button>}
                    {isEditing && <button className="icon" onClick={() => submitChanges(alert, setEditingRow, refreshAlerts!, values)}>{saveIcon}</button>}
                </div>
            }
        },
    }
};

export default useAlertViewData;