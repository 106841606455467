import React from 'react';
import ReactPaginate from 'react-paginate';

type TablePaginatorProps = { totalCount: number, page: number, itemsOnPage: number, onPage: (page: number) => void }

export const TablePaginator = ({ totalCount, page, itemsOnPage, onPage }: TablePaginatorProps) => {
  const pageCount = Math.ceil(totalCount / itemsOnPage);
  if (pageCount <= 1) {
    return <></>
  }
  return <ReactPaginate
    breakLabel="..."
    initialPage={page}
    nextLabel={'»'}
    onPageChange={({ selected }) => onPage(selected)}
    pageRangeDisplayed={5}
    pageCount={pageCount}
    previousLabel={'«'}
    renderOnZeroPageCount={null}
    containerClassName='pagination float-end'
    pageClassName='page-item'
    nextClassName='page-item'
    nextLinkClassName='page-link'
    previousClassName='page-item'
    previousLinkClassName='page-link'
    pageLinkClassName='page-link'
    activeClassName='active'
  />
}