import React, { useContext } from 'react';

import { AppStateContext } from '../../../../appContext';
import { useShowTransactionsDialog } from '../../Positions/useShowTransactionsDialog';
import { OrdersViewInternalProps } from '../useOrderViewData';
import { ClosedOrdersViewBlocks } from './ClosedOrdersViewBlocks';
import { ClosedOrdersViewTable } from './ClosedOrdersViewTable';
import { Formik } from 'formik';

export const ClosedOrdersView: React.FC<OrdersViewInternalProps> = (props) => {
    const context = useContext(AppStateContext);
    const fullView = !context.isAdaptive;
    const { transactionDialog, showOrderTransactions } = useShowTransactionsDialog();

    const view = fullView ?
        <ClosedOrdersViewTable {...props} showTransaction={showOrderTransactions} /> :
        <ClosedOrdersViewBlocks {...props} showTransaction={showOrderTransactions} />
    return <>
        {transactionDialog}
        <Formik initialValues={{}} onSubmit={() => { }}>
            {view}
        </Formik>
    </>
};