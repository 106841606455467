import { Popover } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeIcon, doOutIcon, editIcon, infoIcon, orderIcon, saveIcon, transactionIcon } from '../../../assets/icons';
import { cancelOpenPosition, submitPositionChange } from '../../../common/positions-utils';
import { OrderPositionAssociation, Position } from '../../../models';
import { SwitchValue, formatNumberTrim } from '../../../utils';
import ActionButton from '../../TooltipButton';
import { TooltipItem } from '../../TootlipItem';
import { DateField, UnitValueField } from '../../fields';
import { PairField } from '../../fields/PairField';


export interface PositionsViewInternalProps {
    currency: string;
    positions: Position[];
}

export interface PositionsViewProps extends PositionsViewInternalProps {
    fullView: boolean;
}

export interface PositionRowProps {
    position: Position;
    currency?: string;
    showTransaction: CallableFunction;
    showOrder: CallableFunction;
}

const usePositionViewData = ({ isDirect, showTransaction, showOrder }: { isDirect: boolean, showTransaction: CallableFunction, showOrder: CallableFunction }) => {
    const { t } = useTranslation();
    const [editingRow, setEditingRow] = useState<Number | undefined>();
    const [totalStopLossValue, setTotalStopLossValue] = useState<number>();
    const [totalTakeProfitValue, setTotalTakeProfitValue] = useState<number>();
    const setEdit = (position: Position | undefined) => {
        setEditingRow(position?.id);
        if (position) {
            setTotalStopLossValue(position.totalStopLoss);
            setTotalTakeProfitValue(position.totalTakeProfit);
        }
    }

    const getTooltip = (position: Position) =>
        <div>
            <div><b>Pair:</b> {position.pairId}</div>
            <div><b>Token:</b> {position.token}</div>
            <div><b>Created at:</b> <DateField value={position.createdAt} format='medium' /></div>
        </div>


    return {
        logo: {
            title: null,
            render: (position: Position, params = {}) => {
                return <PairField pairId={position.pairId} withLogo={true} withName={false} />
            }
        },
        cell_ticker: {
            title: null,
            render: (position: Position, params = {}) => {
                const tooltip = getTooltip(position);
                return <div className='item'>
                    <div className='pair-info'>
                        <PairField pairId={position.pairId} withLogo={false} withName={true} fullName={true} />
                        <div className='info-icon'>
                            <TooltipItem text={tooltip} customClass='info-tooltip'>
                                {infoIcon}
                            </TooltipItem>
                        </div>
                    </div>
                    <span className='small-info'>{position.token}</span>
                </div>
            }
        },
        ticker: {
            title: null,
            render: (position: Position, params = {}) => {
                const pair = position.pairInfo;
                const sideName = position.side === 'Long' ? 'Long' : 'Short';
                const notional = formatNumberTrim(position.notional);

                return (<>
                    <div className='item-info'>
                        <PairField pairId={position.pairId} withLogo={true} withName={true} fullName={false} />
                        <div className='info-icon'>
                            <TooltipItem text={getTooltip(position)} id={position.id} prefix="tt-open-pos" customClass='info-tooltip'>
                                {infoIcon}
                            </TooltipItem>
                        </div>
                    </div>
                    <div className="total">
                        <div className='suffix-value text-number'><span className='val'>{notional}</span><span className='suffix'>{pair?.baseSymbol}</span></div>
                    </div>
                    <div className='side'>{t(`enum.PositionSide.${sideName}`)}</div>
                </>
                )
            }
        },
        notional: {
            title: t('portfolio.positions.notional'),
            render: (position: Position, params = {}) => {
                return <UnitValueField value={position.notional} />
            }
        },
        side: {
            title: t('portfolio.positions.side'),
            render: (position: Position, params = {}) => {
                const sideName = position.side === 'Long' ? 'Long' : 'Short';
                return <div className='side'>{sideName}</div>
            }
        },
        unrealized: {
            title: t('portfolio.positions.unrealized'),
            render: (position: Position, params = {}) => {
                const pnlClass = classNames(position.unPnlClass);
                return <SwitchValue className={pnlClass} value={position.UnrealizedPnL} direct={isDirect} {...params} />
            }
        },
        realized: {
            title: t('portfolio.positions.realized'),
            render: (position: Position, params = {}) => {
                const pnlClass = classNames(position.pnlClass);
                return <SwitchValue className={pnlClass} value={position.RealizedPnL} direct={isDirect} {...params} />
            }
        },
        margin: {
            title: t('portfolio.positions.margin'),
            render: (position: Position, params = {}) => {
                return <div className='value number'><SwitchValue value={position.Margin} direct={isDirect} {...params} /></div>
            }
        },
        opening_value: {
            title: t('portfolio.positions.opening_value'),
            render: (position: Position, params = {}) => {
                return <SwitchValue value={position.OpeningValue} direct={isDirect} {...params} />
            }
        },
        opening_price: {
            title: t('portfolio.positions.opening_price'),
            render: (position: Position, params = {}) => {
                return <SwitchValue value={position.OpeningPrice} direct={isDirect} {...params} />
            }
        },
        current_price: {
            title: t('portfolio.positions.price'),
            render: (position: Position, params = {}) => {
                const content = (
                    <ul>
                        <li><label>{t('portfolio.positions.mae')}: </label><UnitValueField value={position.mae} unit={position.accountSymbol} precision={position.accountDigits} /></li>
                        <li><label>{t('portfolio.positions.mfe')}: </label><UnitValueField value={position.mfe} unit={position.accountSymbol} precision={position.accountDigits} /></li>
                    </ul>
                );
                return <Popover content={content}> <SwitchValue value={position.CurrentPrice} direct={isDirect} {...params} /></Popover>
            }
        },
        current_value: {
            title: t('portfolio.positions.current_value'),
            render: (position: Position, params = {}) => {
                const changeClass = classNames(position.changeClass);
                return <SwitchValue className={changeClass} value={position.CurrentValue} direct={isDirect} {...params} />
            }
        },
        closing_price: {
            title: t('portfolio.positions.price'),
            render: (position: Position, params = {}) => {
                const changeClass = classNames(position.changeClass);
                return <SwitchValue className={changeClass} value={position.ClosingPrice} direct={isDirect} {...params} />
            }
        },
        closing_value: {
            title: t('portfolio.positions.closing_value'),
            render: (position: Position, params = {}) => {
                const changeClass = classNames(position.changeClass);
                return <SwitchValue className={changeClass} value={position.ClosingValue} direct={isDirect} {...params} />
            }
        },
        total_stop_loss: {
            title: t('portfolio.positions.total_stop_loss'),
            render: (position: Position, params = {}) => {
                const isEditing = editingRow === position.id;
                if (!isEditing && position.totalStopLoss) {
                    return <SwitchValue value={position.TotalStopLoss} direct={isDirect} {...params} />
                }

                if (isEditing) {
                    return <div className='inline-form-group text-number'>{<input type="number" name="totalStopLoss" className="form-control" defaultValue={totalStopLossValue} onChange={(e: any) => setTotalStopLossValue(e.target.value)} />}</div>
                }
                return null;
            }
        },
        total_take_profit: {
            title: t('portfolio.positions.total_take_profit'),
            render: (position: Position, params = {}) => {
                const isEditing = editingRow === position.id;
                if (!isEditing && position.totalTakeProfit) {
                    return <SwitchValue value={position.TotalTakeProfit} direct={isDirect} {...params} />
                }
                if (isEditing) {
                    return <div className='inline-form-group text-number'>{<input type="number" name="totalTakeProfit" className="form-control" defaultValue={totalTakeProfitValue} onChange={(e: any) => setTotalTakeProfitValue(e.target.value)} />}</div>
                }
                return null;
            }
        },
        closed_at: {
            title: t('portfolio.positions.closed_at'),
            render: (position: Position, params = {}) => {
                return <DateField value={position.closedAt} />
            }
        },
        created_at: {
            title: t('portfolio.positions.created_at'),
            render: (position: Position, params = {}) => {
                return <DateField value={position.createdAt} />
            }
        },
        actions: {
            title: null,
            render: (position: Position, params = {}) => {
                return <div className='actions'>
                    <ActionButton onClick={() => showTransaction(position)} tooltip={"Transactions"} id={position.id} prefix="trx">{transactionIcon}</ActionButton>
                    <ActionButton onClick={() => showOrder(position, OrderPositionAssociation.OpenPosition)} tooltip={"Opening Order"} id={position.id} prefix="o-order">{orderIcon}</ActionButton>
                    <ActionButton onClick={() => showOrder(position, OrderPositionAssociation.ClosePosition)} tooltip={"Closing Order"} id={position.id} prefix="c-order">{orderIcon}</ActionButton>
                </div>
            }
        },
        edit_actions: {
            title: null,
            render: (position: Position, params = {}) => {
                const isEditing = editingRow === position.id;
                return <div className='actions'>
                    {!isEditing &&
                        <>
                            <ActionButton onClick={() => setEdit(position)} tooltip={"Edit"} id={position.id} prefix="edit">{editIcon}</ActionButton>
                            <ActionButton onClick={() => cancelOpenPosition(position)} tooltip={"Close position"} id={position.id} prefix="cancel">{doOutIcon}</ActionButton>
                            <ActionButton onClick={() => showTransaction(position)} tooltip={"Transactions"} id={position.id} prefix="trx">{transactionIcon}</ActionButton>
                            <ActionButton onClick={() => showOrder(position)} tooltip={"Opening order"} id={position.id} prefix="order">{orderIcon}</ActionButton>
                        </>}

                    {isEditing &&
                        <>
                            <ActionButton onClick={() => setEdit(undefined)} tooltip={"Close Position"} id={position.id} prefix="close">{closeIcon}</ActionButton>
                            <ActionButton onClick={() => submitPositionChange(position, totalStopLossValue as never as string, totalTakeProfitValue as never as string, setEdit)} tooltip={"Save"} id={position.id} prefix="save">{saveIcon}</ActionButton>
                        </>}

                </div>
            }
        },
    }
};

export default usePositionViewData;