import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { AccountMetric, MetricAccessor, MetricPosition } from '../../../models';
import PositionMetrics from './charts/PositionMetrics';
import SimpleMetrics from './charts/SimpleMetrics';


interface MetricsTableProps {
    data: AccountMetric[];
    selectedMetrics: number[];
}

const MetricsTable: React.FC<MetricsTableProps> = ({ data, selectedMetrics }) => {
    const { t } = useTranslation();
    const [zeroScale, setZeroScale] = useState(false);

    const allMetricsNames = selectedMetrics.map((metric) => MetricAccessor[metric]);

    const simpleMetrics = allMetricsNames.filter(metric => !metric.startsWith('IP'));
    const positionSelectedNames = allMetricsNames.filter(metric => metric.startsWith('IP')).map(metric => metric.replace('IP', '')) as Array<keyof typeof MetricPosition>;

    return (
        <>
            {simpleMetrics.length > 0 &&
                <SimpleMetrics
                    data={data}
                    selectedMetrics={simpleMetrics}
                    zeroScale={zeroScale} />
            }
            {positionSelectedNames.map((metric, idx) => (
                <PositionMetrics
                    key={metric}
                    idx={idx}
                    metric={metric}
                    data={data}
                    zeroScale={zeroScale}
                />
            ))}
            <Row>
                <Col sm={2}>
                    <div className='nav-tabs'>
                        <div className="form-check form-switch nav-link">
                            <label htmlFor='currencyToggler' className={classNames({ active: zeroScale })}>{t('var.chart.zero')}</label>
                            <input onChange={(e) => setZeroScale(!e.target.checked)} className="form-check-input" id="currencyToggler" name="currencyToggler" type="checkbox" checked={!zeroScale} />
                            <label htmlFor='currencyToggler' className={classNames({ active: !zeroScale })}>{t('var.chart.min')}</label>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default MetricsTable;