import { OhlcData } from "../models/Quote";
import { CommunicationService, commService } from "./CommunicationService";

export class QuotesService {
    _connecction: CommunicationService;
    timeZoneOffset: number;
    constructor() {
        this._connecction = commService;
        this.timeZoneOffset = new Date().getTimezoneOffset() * 60;
    }

    static get instance() { return quotesService }

    async UnsubscribeFromFull(pairIds: string[]) {
        const subscribeRequest = {
            PairIds: pairIds,
            Type: "Full"
        };
        commService.quoteHub!.send("UnsubscribeFromFull", subscribeRequest);
    }

    async SubscribeTo(pairIds: string[]) {
        const subscribeRequest = {
            PairIds: pairIds,
            Type: "Full"
        };
        commService.quoteHub!.send("SubscribeTo", subscribeRequest);
    }

    async GetHistoricalData(primeRequest: any) {
        var respObj = await commService.quoteHub.invoke("GetHistoricalData", primeRequest);
        const data = respObj.result.map((item: OhlcData) => ({ ...item, time: item.period - this.timeZoneOffset }));
        return data;
    }

    async GetTechIndicatorData(primeRequest: any) {
        var respObj = await commService.quoteHub.invoke("GetTechIndicatorData", primeRequest);
        const data = respObj.result.map((item: OhlcData) => ({ ...item, time: item.period - this.timeZoneOffset }));
        return data;
    }
}


export const quotesService = new QuotesService();