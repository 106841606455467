import classNames from 'classnames';
import React from 'react';

import { observer } from "mobx-react-lite";
import { Order } from '../../../../models';
import useOrderViewData, { OrdersViewInternalProps } from '../useOrderViewData';


interface OrderRowProps {
    order: Order;
    currency: string;
    showTransaction: CallableFunction;
}

const ClosedOrderRowBlock: React.FC<OrderRowProps> = observer(({ currency, order, showTransaction }) => {
    const sideClass = classNames('item side inline-form', order.SideClass);
    const { renderer } = useOrderViewData({ currency, showTransaction });

    return (
        <div className={sideClass}>
            <div className='ticker left'>
                {renderer.ticker.render(order)}
            </div>
            <div className='data'>
                <div className='data-2 data-item'>
                    <div className='cell'>
                        <div className="header">{renderer.qty.title}</div>
                        {renderer.qty.render(order)}
                    </div>
                    <div className='cell'>
                        <div className="header">{renderer.execution_price.title}</div>
                        <div className='value number'>{renderer.execution_price.render(order)}</div>
                    </div>
                </div>
                <div className='data-3 data-item'>
                    <div className='cell'>
                        <div className="header">{renderer.status.title}</div>
                        {renderer.status.render(order)}
                    </div>
                    <div className='cell'>
                        <div className="header">{renderer.executed_at.title}</div>
                        {renderer.executed_at.render(order)}
                    </div>
                </div>
            </div>
            {renderer.closed_actions.render(order)}
        </div>
    )
})


export const ClosedOrdersViewBlocks: React.FC<OrdersViewInternalProps> = observer(({ currency, orders, showTransaction }) => {
    return <div className='orders Closed-orders responsive-data-table data-table portfolio-table'>
        {orders.map(order => <ClosedOrderRowBlock order={order} key={order.id} currency={currency} showTransaction={showTransaction!} />)}
    </div>
});
