import React, { Component, ContextType } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Loading from '../../Loading';
import { Col, Container, Row } from 'reactstrap';

import { AppStateContext } from '../../../appContext';
import { withParams } from '../../../utils';
import { newsService } from '../../../services';
import { observer } from 'mobx-react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';

import "./NewsPage.scss"
import { NewsItem } from '../../../models';

interface ShowNewsItemProps {
    isActive: boolean;
    item: NewsItem;
    showNews: CallableFunction;
}

export const NewsItemView: React.FC<ShowNewsItemProps> = ({ isActive, item, showNews }) => {
    const { title, publishedAt, tags } = item;
    const publishedAtDisplay = moment(publishedAt).fromNow();
    const itemClass = classNames('item', { active: isActive });

    return (
        <div className={itemClass}>
            <h4 className='title' onClick={() => showNews(item)}>{title}</h4>
            {tags.length > 0 && <div className='tags'>{tags.map((tag: string) => <div key={tag} className='tag'>{tag}</div>)}</div>}
            <div className='date'>{publishedAtDisplay}</div>
        </div>
    );
};

interface FullNewsItemProps {
    isActive?: boolean;
    item: NewsItem;
    baseClassName: string;
}

export const FullNewsItem: React.FC<FullNewsItemProps> = ({ isActive, item, baseClassName }) => {
    const { snippet, title, text, publishedAt, imageUrl, url } = item;
    const isFullArticle = !!text;
    const body = text ? text : snippet;
    const publishedAtDisplay = moment(publishedAt).fromNow();
    const itemClass = classNames(baseClassName, { active: isActive });
    const { t } = useTranslation();

    return <div className={itemClass}>
        <div className='header'>
            <h1 className='title'>{title}</h1>
            <div className='date'>{publishedAtDisplay}</div>
        </div>
        <div className='body'>
            {imageUrl && <div className='image'><img alt={snippet} src={imageUrl} /></div>}
            {isFullArticle && body}
            {!isFullArticle &&
                <div className=''>
                    {snippet}
                    <div className='read-more'>
                        <a href={url} target='_blank' >{t('news.read_full')}</a>
                    </div>
                </div>}
        </div>
    </div>
};

interface NewsPageProps extends WithTranslation {
    params: any;
}

interface NewsPageState {
    items: NewsItem[];
    loading: boolean;
    active: NewsItem | null;
    newsItemId: string;
}


export const NewsPage = observer(class NewsPage extends Component<NewsPageProps, NewsPageState> {
    static displayName = NewsPage.name;
    context!: ContextType<typeof AppStateContext>;

    constructor(props: NewsPageProps) {
        super(props);
        this.state = {
            items: [],
            loading: true,
            active: null,
            newsItemId: props.params.newsItemId ? props.params.newsItemId : null,
        };
    }

    async componentDidMount() {
        if (this.context.connectionReady) {
            this.loadData();
        }
    }

    async componentDidUpdate(prevProps: NewsPageProps, prevState: NewsPageState) {
        if (this.context.connectionReady && this.state.loading) {
            this.loadData();
        }
        if (this.state.active) {
            this.context.setTitle(this.props.t('menu.news'), this.state.active.title);
        } else {
            this.context.setTitle(this.props.t('menu.news'));
        }
    }

    async loadData() {
        const items = await newsService.GetGetMarketNews([]);
        this.setState({ loading: false, items: items });
    }

    showNewsSideBar(newsItem: NewsItem) {
        this.setState({ active: newsItem });
    }

    render() {
        const { connectionReady } = this.context;
        const { loading, items, active } = this.state;
        if (!connectionReady || loading) {
            return <Loading />
        }
        const containerClass = classNames('NewsPage', { withActive: !!active });

        return (
            <Container fluid className={containerClass}>
                <Row>
                    {/* <Col md={1} className='tags-sidebar'>
                        Sidbar with tags
                    </Col> */}
                    {active && <Col>
                        <FullNewsItem baseClassName='FullNewsItem' item={active}></FullNewsItem>
                    </Col>}
                    <Col md={active ? 3 : 6} className='news-list'>
                        {items.map(item => <NewsItemView key={item.id} item={item} isActive={active === item} showNews={(item: NewsItem) => this.showNewsSideBar(item)} />)}
                    </Col>
                </Row>
            </Container>
        );
    }
});

NewsPage.contextType = AppStateContext;

export default withTranslation()(withParams(NewsPage));