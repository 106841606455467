import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import Translate from "src/components/common/Translate/Translate";

export const CVaRDescription: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <h2>{t('ConditionalValueAtRisk.title')}</h2>
            <p><Translate i18nKey="ConditionalValueAtRisk.description" /></p>
            <p><Translate i18nKey="ConditionalValueAtRisk.methodsOfCalculation" /></p>
            <ol>
                <li><Translate i18nKey="ConditionalValueAtRisk.methods.varianceCovarianceMethod" /></li>
                <li><Translate i18nKey="ConditionalValueAtRisk.methods.historicalSimulationMethod" /></li>
                <li><Translate i18nKey="ConditionalValueAtRisk.methods.monteCarloSimulationMethod" /></li>
            </ol>
            <p><Trans i18nKey="ConditionalValueAtRisk.details">
                For a detailed explanation of these methods, refer to the <Link to={'/risk/var'}>Value at Risk (VaR) page.</Link>
            </Trans></p>
            <p><Translate i18nKey="ConditionalValueAtRisk.example" /></p>

            <h2>{t('ConditionalValueAtRisk.mctr.title')}</h2>
            <Translate i18nKey="ConditionalValueAtRisk.mctr.description" component="p" isMulty={true} />
        </>
    )
};
