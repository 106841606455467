import React from "react";
import { Order, Position } from "../../../models";
import { useTransactionsData } from "../../../services/hooks";
import Loading from "../../Loading";
import { TablePaginator } from "../../TablePaginator";
import { TransactionsView } from "../../common/Transactions/TransactionsView";


interface TransactionDialogProps {
    position?: Position;
    order?: Order;
}

export const TransactionDialog: React.FC<TransactionDialogProps> = ({ position, order }) => {
    const { data: transactions, loading, totalCount, pagination, setPage } = useTransactionsData({ orderId: order?.id, positionId: position?.id });

    if (loading || !transactions) {
        return <Loading />
    }

    return <>
        <TransactionsView transactions={transactions} />
        <TablePaginator onPage={setPage} page={pagination.page} itemsOnPage={pagination.pageSize} totalCount={totalCount} />
    </>

};
