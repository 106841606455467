import classNames from 'classnames';
import React from 'react';

import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../models';
import { ColumnDefinition, DataTable } from '../../../DataTable';
import useOrderViewData, { OrdersViewInternalProps } from '../useOrderViewData';


export const ClosedOrdersViewTable: React.FC<OrdersViewInternalProps> = observer(({ currency, orders, refreshOrders, showTransaction }) => {
    const { t } = useTranslation();
    const { renderer, editingRow, setEdit } = useOrderViewData({ currency, refreshOrders, showTransaction });
    const columns = [
        {
            name: 'logo',
            className: 'cell-logo',
            render: (order: Order) => renderer.logo.render(order)
        },
        {
            name: 'tiker',
            className: 'cell-ticker',
            title: t('portfolio.orders.ticker'),
            render: (order: Order) => renderer.cell_ticker.render(order)
        },
        {
            name: 'side',
            className: 'cell-side',
            title: t('portfolio.orders.side'),
            render: (order: Order) => renderer.side.render(order)
        },
        {
            name: 'qty',
            className: 'cell-qty text-end combined-left',
            title: renderer.qty.title,
            render: (order: Order) => renderer.qty.render(order, {hideUnit: true})
        },
        {
            name: 'qty_unit',
            className: 'cell-unit',
            render: (order: Order) => order.pairInfo?.baseSymbol
        },
        {
            name: 'trigger_price',
            className: 'text-end combined-left',
            title: renderer.trigger_price.title,
            render: (order: Order) => renderer.trigger_price.render(order, {hideUnit: true})
        },
        {
            name: 'trigger_price_unit',
            className: 'cell-unit',
            render: (order: Order) => renderer.trigger_price_unit.render(order)
        },
        {
            name: 'status',
            className: '',
            title: renderer.status.title,
            render: (order: Order) => renderer.status.render(order)
        },
        {
            name: 'execution_price',
            className: 'text-end combined-left',
            title: renderer.execution_price.title,
            render: (order: Order) => renderer.execution_price.render(order, {hideUnit: true})
        },
        {
            name: 'execution_price_unit',
            className: 'cell-unit',
            render: (order: Order) => renderer.execution_price_unit.render(order)
        },
        {
            name: 'created_at',
            className: '',
            title: renderer.created_at.title,
            render: (order: Order) => renderer.created_at.render(order)
        },
        {
            name: 'executed_at',
            className: '',
            title: renderer.executed_at.title,
            render: (order: Order) => renderer.executed_at.render(order)
        },
        {
            name: 'actions',
            render: (order: Order) => renderer.closed_actions.render(order)
        },
    ] as ColumnDefinition<Order>[];

    const itemProps = (order: Order, index: number) => {
        return {
            className: classNames('item', order.SideClass),
        }
    }

    return <>
        <DataTable itemProps={itemProps} data={orders} columns={columns} className='table-grid  orders data-table  table-auto' />
    </>
});
