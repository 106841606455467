
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React from 'react';


export class TooltipItem extends React.Component<any> {
    position: TooltipPlacement = "bottom";
    constructor(props: any) {
        super(props);
        this.position = this.props.position ? this.props.position : "bottom";
    }

    render() {
        if (!this.props.text) {
            return this.props.children;
        }

        return (
            <Popover rootClassName={this.props.customClass} content={this.props.text} placement={this.position}>
                {this.props.children}
            </Popover>
        );
    }
}
