import * as signalR from '@microsoft/signalr'
import authService from '../components/api-authorization/AuthorizeService';


export class CommunicationService {
    _callbacks: { callback: any, subscription: number }[] = [];
    _nextSubscriptionId = 0;
    _isReady = false;
    _error: Error | null = null;

    _appHubConn?: signalR.HubConnection;
    _quoteHubConn?: signalR.HubConnection;


    updateState(ready: boolean, err: Error | null) {
        this._isReady = ready;
        this._error = err;
        this.notifySubscribers();
    }

    getState() {
        return this._isReady;
    }

    subscribe(callback: CallableFunction) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index as number, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback(this._isReady, this._error);
        }
    }

    async ensureInitialized(): Promise<{ appHub: signalR.HubConnection, quoteHub: signalR.HubConnection }> {
        if (this._appHubConn && this._quoteHubConn) {
            return { appHub: this._appHubConn!, quoteHub: this._quoteHubConn! };
        }

        const token = await authService.getAccessToken();

        this._appHubConn = new signalR.HubConnectionBuilder()
            .withUrl("/apphub", { accessTokenFactory: () => token as string })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this._quoteHubConn = new signalR.HubConnectionBuilder()
            .withUrl("/quotehub", { accessTokenFactory: () => token as string })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        return { appHub: this._appHubConn, quoteHub: this._quoteHubConn };
    }

    async start() {
        await this.ensureInitialized();
        try {

            if (this._quoteHubConn!.state !== signalR.HubConnectionState.Connected) {
                await this._quoteHubConn!.start();
            }
            if (this._appHubConn!.state !== signalR.HubConnectionState.Connected) {
                await this._appHubConn!.start();
            }
            console.log("SignalR Connected.");
            this.updateState(true, null);
        } catch (err) {
            this.updateState(false, err);
            console.error("SignalR error.");
            console.error(err);
            throw err;
        }

        return { appHub: this._appHubConn, quoteHub: this._quoteHubConn };
    };

    get appHub(): signalR.HubConnection {
        return this._appHubConn!;
    }

    get quoteHub(): signalR.HubConnection {
        return this._quoteHubConn!;
    }
}

export const commService = new CommunicationService();