import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppStateContext } from '../../appContext';
import { logoImageService } from '../../services';

interface PairProps {
    pairId: string;
    withLogo?: boolean;
    withName?: boolean;
    fullName?: boolean;
}

export const PairField: React.FC<PairProps> = ({ pairId, withLogo, withName, fullName }) => {
    const context = useContext(AppStateContext);
    const accCurrency = context.account.denominatedIn;
    const [baseCurrency, quoteCurrency] = pairId.split('/');
    const iconUrl = logoImageService.getPairLogoUrl(pairId);

    const displayCurrency = fullName || quoteCurrency !== accCurrency ? pairId : baseCurrency;

    return (<div className='pair-id'>
        {withLogo && <div className='logo-wrapper'><img src={iconUrl} className='pair-logo' alt={pairId} /></div>}
        {withName && <NavLink className='pair-link' to={"/trading/" + encodeURIComponent(pairId)}>{displayCurrency}</NavLink>}
    </div>
    );
};
