import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { InputGroup } from "reactstrap";
import { round } from "../../../../../../utils";
import './MultiPriceInput.css';
import { InputNumber } from "primereact/inputnumber";

interface MultiPriceInputProps {
    symbol: string;
    onChange: CallableFunction;
    margin: number;
    values: any;
    recomendedPrice: number;
    price: number;
    decimals: number;
    qty: number;
    setFieldValue: CallableFunction;
    name: string;
    setFieldTouched: CallableFunction;
    errors: any;
}

export const MultiPriceInput: React.FC<MultiPriceInputProps> = ({ symbol, decimals, onChange, margin, values, recomendedPrice, price, qty, setFieldValue, name, setFieldTouched, errors }) => {
    let [priceValue, setPriceValue] = useState<number>();
    let [percent, setPercent] = useState<number>();
    let [maxValue, setMaxValue] = useState<number>();
    let [trackValue, setTrackValue] = useState('margin');
    let [enteredValue, setEnteredValue] = useState<string>();

    const getValues = (type: string, value?: string) => {
        const thisValue = value ? parseFloat(value) : 0;

        if (type === 'price') {
            priceValue = thisValue;
            maxValue = round((priceValue - price) * qty, decimals);
            percent = round(maxValue / margin * 100, 2);
        } else if (type === 'percent') {
            percent = thisValue;
            maxValue = round(percent * margin / 100, 2);
            priceValue = round(price + maxValue / qty, decimals);
        } else if (type === 'maxValue') {
            priceValue = round(thisValue / qty + price, decimals);
            maxValue = thisValue;
            percent = round(maxValue / margin * 100, 2);
        } else if (type === 'margin') {
            maxValue = round(margin / 2, 2);
            percent = round(maxValue / margin * 100, 2)
            priceValue = round(price + maxValue / qty, decimals);
        }

        return { priceValue, percent, maxValue };
    }


    const changeValue = (type: string, value?: string) => {
        setEnteredValue(value);
        ({ priceValue, percent, maxValue } = getValues(type, value));
        setTrackValue(type);

        setPriceValue(priceValue);
        setPercent(percent);
        setMaxValue(maxValue);

        setFieldTouched(name);
        setFieldValue(name, maxValue);
    }

    useEffect(() => {
        changeValue(trackValue, enteredValue);
    }, []);


    ({ priceValue, percent, maxValue } = getValues(trackValue, enteredValue));
    const hasErrors = errors.hasOwnProperty(name);
    const priceClass = classNames('form-control price-trigger', { 'active': trackValue === 'price', 'is-invalid': hasErrors });
    const percentClass = classNames('form-control', { 'active': trackValue === 'percent', 'is-invalid': hasErrors });
    const maxValueClass = classNames('form-control color-control', { 'active': trackValue === 'maxValue', 'is-invalid': hasErrors });

    return (<div className="multiprice">
        <div className="labels">
            <label className='form-label price-trigger' htmlFor={name + "-price"}>Trigger</label>
            <label className='form-label' htmlFor={name + "-percent"}>% of margin</label>
            <label className='form-label price-total' htmlFor={name + "-maxValue"}>Total {symbol}</label>
        </div>
        <InputGroup>
            <input className={priceClass} type="number" id={name + "-price"} step="1" value={priceValue + ""} onChange={(event) => changeValue('price', event.target.value)} />
            <span className="divider"></span>
            <input type="number" className={percentClass} id={name + "-percent"} value={percent + ""} onChange={(event: any) => changeValue('percent', event.target.value)} />
            <span className="divider"></span>
            <input className={maxValueClass} id={name + "-maxValue"} type="number" step="1" value={maxValue + ""} onChange={(event) => changeValue('maxValue', event.target.value)} />
        </InputGroup>
        {/* <div>{trackValue}</div> */}
    </div>)
};