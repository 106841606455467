import classNames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';

import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../../../appContext';
import { DateField } from '../../../fields';
import usePositionViewData from '../usePositionViewData';
import { OpenPositionRowProps, OpenPositionsViewInternalProps } from './OpenPositionsView';

const PositionTableRow: React.FC<OpenPositionRowProps> = observer(({ currency, position, showTransaction, showOrder }) => {
    const { side } = position;
    const context = useContext(AppStateContext);
    const symbolAccount = context.account.symbol;
    const isDirect = currency === 'account';
    const currencySymbol = isDirect ? symbolAccount : position.bridgeSymbol;
    const pair = position.pairInfo;

    const sideClass = classNames('item inline-form', `side-${side}`);
    const columns = usePositionViewData({ isDirect, showTransaction, showOrder });

    return (
        <tr className={sideClass}>
            <td className='img'>
                {columns.logo.render(position)}
            </td>

            <td className='cell-ticker'>
                {columns.cell_ticker.render(position)}
            </td>
            <td className='text-end cell-side'>
                {columns.side.render(position)}
            </td>

            <td className='text-end text-number combined-left'>
                {columns.notional.render(position)}
            </td>
            <td className='cell-unit'>
                {pair?.baseSymbol}
            </td>

            <td className='text-end combined-left'>
                {columns.unrealized.render(position, { hideUnit: true })}
            </td>
            <td className='cell-unit'>
                {currencySymbol}
            </td>

            <td className='text-end combined-left'>
                <div className='with-divider'>
                    {columns.opening_value.render(position)}
                </div>
            </td>
            <td className='combined-right'>
                {columns.opening_price.render(position)}
            </td>

            <td className='text-end combined-left'>
                <div className='with-divider'>
                    {columns.current_value.render(position)}
                </div>
            </td>
            <td className='combined-right'>
                {columns.current_price.render(position)}
            </td>

            <td className='text-end combined-left'>
                {columns.margin.render(position, { hideUnit: true })}
            </td>
            <td className='cell-unit'>
                {symbolAccount}
            </td>

            <td className='text-end combined-left'>
                {columns.total_stop_loss.render(position, { hideUnit: true })}
            </td>
            <td className='cell-unit'>
                {!!position.totalStopLoss && currencySymbol}
            </td>

            <td className='text-end combined-left'>
                {columns.total_take_profit.render(position, { hideUnit: true })}
            </td>
            <td className='cell-unit'>
                {!!position.totalTakeProfit && currencySymbol}
            </td>

            <td className='text-end'>
                <DateField value={position.createdAt} />
            </td>
            <td>
                {columns.edit_actions.render(position)}
            </td>
        </tr>
    )
});

export const OpenPositionsViewTable: React.FC<OpenPositionsViewInternalProps> = observer((props) => {
    const { t } = useTranslation();
    const { positions, ...rest } = props;
    return (
        <table className='table-grid positions data-table table-auto'>
            <colgroup>
                <col className='cell-logo' />
                <col className='cell-ticker' />
                <col className='cell-side' />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className='cell-date' />
                <col className='cell-actions' />
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th className='left cell-ticker'>{t('portfolio.positions.ticker')}</th>
                    <th className='text-end cell-side'></th>

                    <th className='text-end combined-left'>{t('portfolio.positions.notional')}</th>
                    <th className='cell-unit'></th>

                    <th className='text-end combined-left'>{t('portfolio.positions.unrealized')}</th>
                    <th className='cell-unit'></th>

                    <th className='text-end combined-left'>{t('portfolio.positions.opening_value')} <span className='divider'>/ </span></th>
                    <th className='combined-right'>{t('portfolio.positions.price')}</th>

                    <th className='text-end combined-left'>{t('portfolio.positions.current_value')} <span className='divider'>/ </span></th>
                    <th className='combined-right'>{t('portfolio.positions.price')}  </th>

                    <th className='text-end combined-left'>{t('portfolio.positions.margin')}</th>
                    <th className='cell-unit'></th>

                    <th className='text-end combined-left'>{t('portfolio.positions.stop_loss')}</th>
                    <th className='cell-unit'></th>

                    <th className='text-end combined-left'>{t('portfolio.positions.take_profit')}</th>
                    <th className='cell-unit'></th>

                    <th className='text-end'>{t('portfolio.positions.created_at')} </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {positions.map(position => <PositionTableRow position={position} key={position.id} {...rest} />)}
            </tbody>
        </table>
    );
});
