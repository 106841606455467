import React from 'react'
import { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import { MainLayout } from '../Layouts/MainLayout'

interface AuthorizeRouteProps {
  path: string;
  element: React.ReactNode;
}

interface AuthorizeRouteState {
  ready: boolean;
  authenticated: boolean;
}

export default class AuthorizeRoute extends Component<AuthorizeRouteProps, AuthorizeRouteState> {
  private _subscription: number
  constructor(props: AuthorizeRouteProps) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;
    var link = document.createElement("a");
    link.href = document.location.href;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
      return <></>;
    } else {
      const { element } = this.props;
      return authenticated ? <MainLayout>{element}</MainLayout> : <Navigate replace to={redirectUrl} />;
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
