import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../appContext';
import { closeIcon } from '../../assets/icons';
import { BalanceSelector, mobileBalanceItems } from '../header/Balance/Balance';
import SideBar from '../sidebar/SideBar';

interface MobileMenuOverlayProps {
    onClose: () => void;
}

const MobileMenuOverlay: React.FC<MobileMenuOverlayProps> = ({ onClose }) => {
    const context = useContext(AppStateContext);
    const { currency, account } = context;
    const { t } = useTranslation();

    const toggleCurrency = (checked: boolean) => {
        context.setCurrency(checked ? 'quote' : 'account');
    }
    const isDirect = currency === 'account';

    return (
        <div className='menu-overlay'>
            <div className='top-header'>
                <button className="close-button btn" onClick={onClose}>
                    {closeIcon}
                </button>
            </div>
            <div className='overlay-content'>
                <div className='Balance'>
                    <BalanceSelector items={mobileBalanceItems} />
                </div>
                <div className='container-fluid'>
                    <div className='nav-tabs'>
                        <div className="nav-item d-flex align-items-baseline inline-form">
                            <label>{t('portfolio.common.display_currency_values')}: </label>
                            <div className="form-check form-switch nav-link">
                                <label htmlFor='currencyToggler' className={classNames({ active: isDirect })}>{account.denominatedIn}</label>
                                <input onChange={(e) => toggleCurrency(e.target.checked)} className="form-check-input" id="currencyToggler" name="currencyToggler" type="checkbox" checked={!isDirect} />
                                <label htmlFor='currencyToggler' className={classNames({ active: !isDirect })}>{t('portfolio.common.quote_currency')}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu">
                    <SideBar />
                </div>

            </div>
        </div>
    );
};

export default MobileMenuOverlay;