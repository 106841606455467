import React, { useContext } from 'react';

import { Form, Formik } from 'formik';
import { AppStateContext } from '../../../../appContext';
import { OrdersViewInternalProps } from '../useOrderViewData';
import { PendingOrdersViewBlocks } from './PendingOrdersViewBlocks';
import { PendingOrdersViewTable } from './PendingOrdersViewTable';


export const PendingOrdersView: React.FC<OrdersViewInternalProps> = (props) => {
    const context = useContext(AppStateContext);
    const fullView = !context.isAdaptive;

    const view = fullView ?
        <PendingOrdersViewTable {...props} /> :
        <PendingOrdersViewBlocks {...props} />
    return <>
        <Formik initialValues={{}} onSubmit={() => { }}>
            <Form>
                {view}
            </Form>
        </Formik>
    </>

};