import { Triggerable } from "./Triggerable";

export class Alert extends Triggerable {
    inQuoteCurrency: boolean = false;
    constructor(data: Object, accountSymbol: string, accountDigits: number) {
        super(accountSymbol, accountDigits, data);
        Object.assign(this, data);
        this.init();
    }
}
