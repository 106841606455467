import React, { Component, ContextType } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, NavLink, UncontrolledDropdown } from 'reactstrap';
import LoginMenu from '../login-menu/LoginMenu';

import { observer } from 'mobx-react';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AppStateContext } from '../../../appContext';
import { notificationsIcon, settingsIcon, userCollapseIcon, userExpandIcon } from '../../../assets/icons';
import { IGenericNotification } from '../../../models/Notifications';
import { notificationService } from '../../../services';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import FundingDialog from '../../common/FundingDialog/FundingDialog';
import { AccountsMenu } from '../accounts-menu/AccountsMenu';
import "./UserMenu.scss";


interface UserMenuProps extends WithTranslation {
    showFunding?: boolean;
}

interface UserMenuState {
    userIsOpen: boolean;
    notificationsIsOpen: boolean;
    isFundingDialogOpen: boolean;
}


interface NotificationProps {
    notification: IGenericNotification;
}

const NotificationComponent: React.FC<NotificationProps> = observer(({ notification }) => {
    const { message, icon, date } = notificationService.format(notification);

    return (
        <li>
            <div>{icon}</div>
            <div>
                <div className='notification-title' dangerouslySetInnerHTML={{ __html: message as any }} />
                <div className='notification-date'>{moment(date).fromNow()}</div>
            </div>
        </li>
    );
});

const UserMenu = observer(class UserMenu extends Component<UserMenuProps, UserMenuState> {
    context!: ContextType<typeof AppStateContext>;

    constructor(props: UserMenuProps) {
        super(props);
        this.state = {
            userIsOpen: false,
            notificationsIsOpen: false,
            isFundingDialogOpen: false
        }
    }

    static displayName = UserMenu.name;

    toggleOpen(event: any) {
        if (event.target.closest('.dropdown-menu')) {
            return
        }
        this.setState({ userIsOpen: !this.state.userIsOpen });
    }

    toggleNotificationsOpen(event: any) {
        if (event.target.closest('.dropdown-menu')) {
            return
        }
        this.setState({ notificationsIsOpen: !this.state.notificationsIsOpen });
        this.context.markAsRead();
    }

    render() {

        const { connectionReady, isAuthenticated, notifications } = this.context;
        const profilePath = `${ApplicationPaths.Profile}`;
        const fundingBtnName = this.props.t('header.funding');
        const profileLink = this.props.t('header.profile');
        const unseen = notifications.filter((obj) => !obj.seenAt).length;

        return (
            <div className='user-menu'>
                <FundingDialog isOpen={this.state.isFundingDialogOpen} onClose={() => this.setState({ isFundingDialogOpen: false })} />
                {this.props.showFunding && <Link className="" to='#' onClick={() => this.setState({ isFundingDialogOpen: true })}>
                    <button className='btn btn-primary'>{fundingBtnName}</button>
                </Link>}
                <div className='actions'>
                    <UncontrolledDropdown isOpen={this.state.notificationsIsOpen} toggle={(event: any) => this.toggleNotificationsOpen(event)}>
                        <DropdownToggle>
                            <div className='notifications-wrapper'>
                                {notificationsIcon}
                                {(unseen > 0) && (
                                    <div className='notifications-number-block'>
                                        {unseen > 99
                                            ? <span className='ellipsis'>...</span>
                                            : <span className='notifications-number'>{unseen}</span>
                                        }
                                    </div>
                                )}
                            </div>
                        </DropdownToggle>
                        <DropdownMenu>
                            <ul className='notification-list'>
                                {notifications.map((item, idx) =>
                                    <NotificationComponent notification={item} key={idx} />
                                )}
                            </ul>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown>
                        <DropdownToggle className="settings-icon">
                            {settingsIcon}
                        </DropdownToggle>
                        <DropdownMenu className='settings-menu'>
                            <DropdownItem>
                                <NavLink className="profile-link" tag={Link} to={profilePath}>{profileLink}</NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown isOpen={this.state.userIsOpen} toggle={(event: any) => this.toggleOpen(event)}>
                        <DropdownToggle className="user-icon">
                            {this.state.userIsOpen ? userCollapseIcon : userExpandIcon}
                        </DropdownToggle>
                        <DropdownMenu>
                            {isAuthenticated && <AccountsMenu />}
                            <LoginMenu />
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        );
    }
})

UserMenu.contextType = AppStateContext;
export default withTranslation()(UserMenu);