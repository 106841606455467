import { orderService } from "../services";
import { confirmDialog } from 'primereact/confirmdialog';
import { getGoodUntil } from "./order-utils";
import { deleteIcon } from "../assets/icons";
import { showErrorNotification, showNotification } from "./notifications";
import { Alert, GoodUntilTypes } from "../models";
import i18n from "../i18n";

export const cancelAlertProcess = async (alert: Alert, refreshData: CallableFunction) => {
    try {
        const ret = await orderService.AlertCancel(alert);
        if (ret.cancelledSuccessfully) {
            refreshData();
        }
        showNotification("Alert deleted");
    } catch (error) {
        showErrorNotification(error.message);
    }
}

export const cancelAlert = async (alert: Alert, refreshData: CallableFunction) => {
    confirmDialog({
        message: 'Are you sure you want to delete?',
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        icon: deleteIcon,
        accept: () => cancelAlertProcess(alert, refreshData),
    });
}

export type AlertFormValues = {
    triggerPrice: number;
    goodUntilType: GoodUntilTypes;
    goodUntil: Date | null,
}


export const submitChanges = async (alert: Alert, setEditing: CallableFunction, refreshData: CallableFunction, values: AlertFormValues) => {
    try {
        const data = {
            triggerPrice: values.triggerPrice,
            goodUntil: getGoodUntil({ goodUntilType: values.goodUntilType, goodUntilDate: values.goodUntil }),
        }
        const result = await orderService.AlertUpdate(alert, data);
        setEditing(false);
        refreshData();
        showNotification(i18n.t("notifications.alert.updated"));
    } catch (error) {
        showErrorNotification(error.message);
    }
}