
export enum AggregationType {
    ByPair = 'pair',
    ByAsset = 'asset',
    ByAssetClass = 'assetClass'
}

export type CategoryExposureTypes = "long" | "short" | "netted";

export interface CategoryExposure {
    categoryId: string;

    long: Exposure;
    short: Exposure;
    netted: Exposure;

    limitShort?: number;
    limitLong?: number;
    limitNetted?: number;
}

export interface Exposure {
    totalNotional: number;
    totalValue: number;
    openPositionCount: number;
    cumulativeUnrealizedPnl: number;
}

export type ExposureType = keyof Exposure;

export interface FullExposureReport {
    ByPair?: ExposureReport;
    ByAsset?: ExposureReport;
    ByAssetClass?: ExposureReport;
}

export interface ExposureReport {
    denominatedInAssetId: string;
    displayDecimals: number;
    exposureDecimals: number;
    aggregation: AggregationType;
    categories: CategoryExposure[];
    preparedOn: string;
}