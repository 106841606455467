import { EMA, SMA } from "technicalindicators";
import { MAInput } from "technicalindicators/declarations/moving_averages/SMA";
import { OhlcVolumeData } from "../Chart";
import { LineIndicator } from "./BaseIndicator";


export class EMAIndicator extends LineIndicator {
    priceScaleId = 'right';
    color = '#00FF00';
    updateData(data: OhlcVolumeData[]): void {

        const input: MAInput = {
            values: data.map((item: OhlcVolumeData) => item.close),
            period: 14
        }

        const indicator = new EMA(input);

        this.data = data.map((item: OhlcVolumeData) => {
            const value = indicator.nextValue(item.close);

            return {
                time: item.time,
                value: value
            };
        });

        if (this.data && this.series) {
            this.series.setData(this.data);
        }
    }
}

