
export enum VaRMethod {
    HistoricalSimulation = 'HistoricalSimulation',
    VarianceCovariance = 'VarianceCovariance',
    MonteCarlo = 'MonteCarlo',
}


export enum MetricPeriodicity {
    // None = 0,
    Intraday = 1,
    Daily = 2,
    Monthly = 4
}

export class MetricPosition {
    Id: number;
    Token: string;
    PairId: string;

    AvailableToTrade?: number;
    NetEquity?: number;
    Cash?: number;
    UnrealizedPnl?: number;
    TotalMargin?: number;
    MarginIndicator?: number;
    TotalValue?: number;
    VaR?: number;
}

export class AccountMetric {
    Positions?: MetricPosition[];

    AlignedTime?: string;
    AvailableToTrade?: number;
    NetEquity?: number;
    Cash?: number;
    UnrealizedPnl?: number;
    TotalMargin?: number;
    MarginIndicator?: number;
    TotalValue?: number;
    VaR?: number;
}

export enum AvailableMetrics {
    AvailableToTrade = 1 << 0,
    NetEquity = 1 << 1,
    Cash = 1 << 2,
    UnrealizedPnl = 1 << 3,
    TotalMargin = 1 << 4,
    MarginIndicator = 1 << 5,
    TotalValue = 1 << 6,
    PositionCount = 1 << 7,
    VaR = 1 << 8,
    CVaR = 1 << 25,
    SharpeRatio = 1 << 10,
    SortinoRatio = 1 << 17,
    Volatility = 1 << 12,
    ROI = 1 << 9,
    MaximumDrawdown = 1 << 11,
    WinRate = 1 << 13,
    AvgWinToAvgLossRatio = 1 << 14,
    EquityCurve = 1 << 15,
    CAGR = 1 << 16,
    // IndvidualPositions = 1 << 18, //includes Id, Token, PairId,
    IPMarginRatio = (1 << 19) | (1 << 18),
    IPCurrentMargin = (1 << 20) | (1 << 18),
    IPCurrentValue = (1 << 21) | (1 << 18),
    IPUnrealizedPnL = (1 << 22) | (1 << 18),
    IPQuoteMain = (1 << 23) | (1 << 18),
    IPQuoteBridge = (1 << 24) | (1 << 18),
    IPMAE = (1 << 27) | (1 << 18),
    IPMFE = (1 << 28) | (1 << 18),

    // ExposureReport = 1 << 26
}

export const MetricAccessor = Object.fromEntries(Object.entries(AvailableMetrics).map((pair) => [pair[1], pair[0]]));


export const ChartMetrics =
    AvailableMetrics.AvailableToTrade |
    // AvailableMetrics.IndvidualPositions |
    AvailableMetrics.NetEquity |
    AvailableMetrics.Cash |
    AvailableMetrics.UnrealizedPnl |
    AvailableMetrics.TotalMargin |
    AvailableMetrics.MarginIndicator |
    AvailableMetrics.TotalValue |
    AvailableMetrics.PositionCount |
    AvailableMetrics.VaR |
    AvailableMetrics.CVaR |
    AvailableMetrics.SharpeRatio |
    AvailableMetrics.SortinoRatio |
    AvailableMetrics.Volatility |
    AvailableMetrics.IPMarginRatio |
    AvailableMetrics.IPCurrentMargin |
    AvailableMetrics.IPCurrentValue |
    AvailableMetrics.IPUnrealizedPnL |
    AvailableMetrics.IPQuoteMain |
    AvailableMetrics.IPQuoteBridge | 
    AvailableMetrics.IPMAE |
    AvailableMetrics.IPMFE
;


export class DrawdownDetails {
    drawdown: number;
    startDate: Date;
    troughDate: Date;
    recoveryStartDate: Date | null;
    recoveryEndDate: Date | null;
    recoveryPeriod: number;
}

export class DrawdownData {
    maxDrawdown: number;
    drawdowns: DrawdownDetails[];
    frequency: number;
    totalSeverity: number;
    dateRange: [Date, Date];
}

export type SSRatiosData = {
    sharpeRatio?: number;
    shortinoRatio?: number;
    sharpeContributions?: {[key: string]: number}
    sortinoContributions?: {[key: string]: number}
}

export enum VarSubvalue {
    VaR = 'vaR',
    CVaR = 'cVaR'
};

export type CalculateVarData = {
    vaR?: number;
    cVaR?: number;
    volatility?: number;
    warnings?: string;
    mctrCVaR?: number[];
    mctrVaR?: number[];
    weights?: number[];
    pairIds?: string[];
}

export interface RateItem {
    symbol: string;
    country: string;
    yield: number;
}

export interface PortfolioMetrics {
    vaR: number;
    cVaR: number;
    volatility: number;
    sharpe: number;
    sortino: number;
}
