import React from 'react';
import AreaAction from './AreaAction'

const AreaLinkRoutes = [
  {
    path: '/crm',
    element: areaAction('/Identity/Account/Manage')
  }
];

function areaAction(link: string) {
  return <AreaAction link={link}></AreaAction>;
}

export default AreaLinkRoutes;
