import { Account, AccountMetric, AggregationType, CalculateVarData, DrawdownData, ExposureReport, MetricPeriodicity, PortfolioMetrics, PositionSide, RateItem, SSRatiosData, VaRMethod } from "../models";
import BaseCommService from "./BaseCommService";


export class AccountService extends BaseCommService {
    static get instance() { return accountService }
    get hub() {
        return this._connecction.appHub;
    }

    async GetAccountInfo() {
        return this._invokeMethod("GetAccountInfo", {});
    }

    async GetPortfolioInfo() {
        return this._invokeMethod("GetPortfolioInfo", {}, 'openPositions');
    }

    async CalculateAccountVar(method: VaRMethod, window: number, confidence: number, numberOfPaths: number, horizon: number, includeMCTR: boolean): Promise<CalculateVarData> {
        return this._invokeMethod("CalculateAccountVar", { Method: method, Window: window, Confidence: confidence, MonteCarloNumPaths: numberOfPaths, Horizon: horizon, IncludeMCTR: includeMCTR });
    }

    async GetAccountMetricsHistory(goingBackTo: Date, mostRecent: Date, metrics: number, periodicity: MetricPeriodicity): Promise<AccountMetric[]> {
        if (!metrics) {
            return [];
        }
        return this._invokeMethod("GetAccountMetricsHistory", { GoingBackTo: goingBackTo.toISOString(), MostRecent: mostRecent.toISOString(), Metrics: metrics, Periodicity: periodicity }, 'metrics');
    }

    async GetAccountDrawdown(goingBackTo: Date, mostRecent: Date, periodicity: MetricPeriodicity, threshold: number): Promise<DrawdownData> {
        return this._invokeMethod("GetAccountDrawdown", { GoingBackTo: goingBackTo.toISOString(), MostRecent: mostRecent.toISOString(), Periodicity: periodicity, Threshold: threshold }, 'drawdown');
    }

    async GetAccountSSRatios(window: number, riskFreeRate: number | undefined, includeRRA: boolean) {
        if (!window || !riskFreeRate) {
            return null;
        }
        return this._invokeMethod("GetAccountSSRatios", { Window: window, RiskFreeRate: riskFreeRate, IncludeRRA: includeRRA }) as Promise<SSRatiosData>;
    }

    async GetAccountExposure(aggType: AggregationType) {
        return this._invokeMethod("GetAccountExposure", { AggType: 'by' + aggType }, 'report') as Promise<ExposureReport>;
    }

    async GetAccountExposureHistory(goingBackTo: Date, mostRecent: Date, periodicity: MetricPeriodicity, aggType: AggregationType) {
        return this._invokeMethod("GetAccountExposureHistory", { GoingBackTo: goingBackTo.toISOString(), MostRecent: mostRecent.toISOString(), Periodicity: periodicity, AggType: 'by' + aggType }, 'reports') as Promise<ExposureReport[]>;
    }

    async GetRiskFreeRates(): Promise<RateItem[]> {
        return this._invokeMethod("GetRiskFreeRates", {}, 'yields') as Promise<RateItem[]>;
    }

    async PerformScenarioAnalysis(pairId: string | undefined, side: PositionSide, notional: number, vaR: any, sharpeAndSortino: any): Promise<{ current: PortfolioMetrics, expected: PortfolioMetrics } | null> {
        if (!pairId || !side || !notional || !vaR || !sharpeAndSortino || isNaN(notional) || isNaN(sharpeAndSortino.RiskFreeRate)) {
            return null;
        }

        return this._invokeMethod("PerformScenarioAnalysis", { PairId: pairId, Side: side, Notional: Number(notional), VaR: vaR, SharpeAndSortino: sharpeAndSortino });
    }

    async SwitchAccount(token: string) {
        return this._invokeMethod("SwitchConnection", { NewAccountToken: token });
    }
}


export const accountService = new AccountService();