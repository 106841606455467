import { Override } from "./Override";
import { PairInfo } from "./PairInfo";

enum LendingType {
    SwapInterest = 'SwapInterest',
    MarginInterest = 'MarginInterest',
    StockBorrowInterest = 'StockBorrowInterest',
    IdleCash = 'IdleCash'
}

export type Lending = {
    assetId: string;
    effectiveFrom: Date;
    lendingType: LendingType;
    basicRate: number;
    fromAmount1: number;
    rate1: number;
    fromAmount2: number;
    rate2: number;
    fromAmount3: number;
    rate3: number;
}

export type LendingInfo = {
    long: number;
    short: number;
    marginInterest: number;
    stockBorrowInterest: number;
    idleCash?: Lending;
}

export class ExtendedPairInfo {
    constructor(data: Object) {
        Object.assign(this, data);
    }

    public get isBridge(): boolean {
        return !!this.bridgePairId;
    }

    pairId: string;
    bridgePairId: string;
    bridgePairInfo: PairInfo;
    bridgeForward: boolean;
    baseSymbol: string;
    quoteSymbol: string;
    marginRatioLong: number;
    marginRatioShort: number;
    marginLeverageShort: number;
    marginLeverageLong: number;
    exchangeMic: string;
    exchangeName: string;
    exchangeLEI: string;
    exchangeAcro: string;
    exchangeDescription: string;
    exchangeEntity: string;
    exchangeCity: string;
    exchangeCountryName: string;
    exchangeCountryCode: string;
    exchangeWebsite: string;
    exchangeTimezone: string;
    exchangeTimezoneOffset: number;
    marketSchedule: any;
    lendToday: Lending[];
    lendTomorrow: Lending[];
    subtype: string;
    longDescription: string;
    effOverride: Override;

    assetSector?: string;
    assetIndustry?: string;
    assetDescription?: string;
    assetType?: string;
    assetEmployeeCt?: string;
    assetWebsite?: string;
    assetCEO?: string;
    assetAddress?: string;
    assetCity?: string;
    assetState?: string;
    assetZip?: string;
    assetCountry?: string;

    private extractLandingInfo(baseId: string, quoteId: string, lendings: Lending[]): LendingInfo {
        let marginInterest = 0;
        let baseSwapRate = 0;
        let quoteSwapRate = 0;
        let stockBorrowInterest = 0;
        let idleCash = undefined;
        lendings.filter(l => l).forEach(l => {
            if (l.assetId === quoteId && l.lendingType === LendingType.SwapInterest) {
                quoteSwapRate = l.basicRate;
            }
            if (l.assetId === baseId && l.lendingType === LendingType.SwapInterest) {
                baseSwapRate = l.basicRate;
            }
            if (l.assetId === quoteId && l.lendingType === LendingType.MarginInterest) {
                marginInterest = l.basicRate;
            }
            if (l.assetId === quoteId && l.lendingType === LendingType.StockBorrowInterest) {
                stockBorrowInterest = l.basicRate;
            }
            if (l.assetId === quoteId && l.lendingType === LendingType.IdleCash) {
                idleCash = l;
            }
        });

        const long = (baseSwapRate - quoteSwapRate) * 100;
        const short = (quoteSwapRate - baseSwapRate) * 100;

        return { long, short, marginInterest, stockBorrowInterest, idleCash };
    }

    TodayLandingInfo(baseId: string, quoteId: string): LendingInfo {
        return this.extractLandingInfo(baseId, quoteId, this.lendToday);
    }

    TomorrowLandingInfo(baseId: string, quoteId: string): LendingInfo {
        return this.extractLandingInfo(baseId, quoteId, this.lendTomorrow);
    }
}