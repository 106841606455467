import { Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VaRMethod } from '../../../../models';
import { useEnumOptions } from '../../../../services/hooks';


export const useMethod = (): [JSX.Element, VaRMethod] => {
    const { t } = useTranslation();
    const methodOptions = useEnumOptions<VaRMethod>(VaRMethod, 'VaRMethod');

    const [method, setMethod] = useState<VaRMethod>(VaRMethod.VarianceCovariance);

    const component = <>
        <label style={{ marginBottom: '5px'}} htmlFor="method">{t('var.fields.method')}</label>
        <Select
            style={{ width: '100%' }}
            options={methodOptions}
            value={method}
            onChange={setMethod}
        />
    </>

    return [component, method];
};