import { useState, useEffect, SetStateAction } from 'react';
import { AssetCategory, SearchFilter, SearchMetadata, assetsService } from '../../../../../services';
import { showErrorNotification } from '../../../../../common/notifications';
import { SearchPairInfo } from './useSearchData';
import { useTranslation } from 'react-i18next';

interface SearchMetadataProps {
    results: SearchPairInfo[];
    categories: AssetCategory[];
    filters: SearchFilter;
    setFilters: React.Dispatch<SetStateAction<SearchFilter>>;
}

interface OptionValue {
    value: number | string;
    label: string;
}

interface MetaOptions {
    assetClassOptions: OptionValue[];
    sectorOptions: OptionValue[];
    industryOptions: OptionValue[];
    exchangeOptions: OptionValue[];
    subtypeOptions: OptionValue[];
}

let localCache: any = null;

const useSearchMetaData = ({ results, categories, filters, setFilters }: SearchMetadataProps) => {
    const [searchData, setSearchData] = useState<SearchMetadata | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [options, setOptions] = useState<MetaOptions>({ assetClassOptions: [], sectorOptions: [], industryOptions: [], exchangeOptions: [], subtypeOptions: [] });
    const { t } = useTranslation();

    useEffect(() => {
        if (localCache) {
            setSearchData(localCache);
            setLoading(false);
            return;
        }

        setLoading(true);
        assetsService.GetSearchMetadata()
            .then((data) => {
                setSearchData(data);
                localCache = data;
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
                showErrorNotification(error);
            });
    }, []);

    useEffect(() => {
        if (!searchData) return;

        const exchanges = new Set<number>();
        const industries = new Set<string>();
        const sectors = new Set<string>();
        const types = new Set<string>();

        results.forEach(item => {
            if (item.exchangeId) {
                exchanges.add(item.exchangeId);
            }
            if (item.industry) {
                industries.add(item.industry);
            }
            if (item.sector) {
                sectors.add(item.sector);
            }
            if (item.type) {
                types.add(item.type);
            }
        });

        const newMeta = {
            sectorOptions: filters.sector ? options.sectorOptions : Array.from(searchData.categories.keys()).sort().filter(item => sectors.has(item)).map(item => {
                return {
                    value: item,
                    label: item,
                }
            }) || [],
            industryOptions: filters.industry ? options.industryOptions : Array.from(industries.keys()).sort().map(item => {
                return {
                    value: item,
                    label: item,
                }
            }) || [],
            exchangeOptions: filters.exchange ? options.exchangeOptions : searchData.exchanges.filter(item => exchanges.has(item.id)).map(item => {
                return {
                    value: item.id,
                    label: `${item.name} (${item.micCode})`,
                }
            }),
            subtypeOptions: filters.subtype ? options.subtypeOptions : searchData.subtypes.filter(item => types.has(item)).map(item => {
                return {
                    value: item,
                    label: item,
                }
            }),
        }
        setOptions((prev) => {
            return {
                ...prev,
                ...newMeta
            }
        });

    }, [results, searchData]);

    useEffect(() => {
        if (!searchData) return;

        const ac = categories.map(item => {
            return {
                value: item.assetClassId,
                label: t(`enum.asset_class.${item.name}`),
            }
        }).filter(item => item.value !== -1);
        setOptions((prev) => {
            return {
                ...prev,
                assetClassOptions: ac,
            }
        });
    }, [results, categories, searchData]);

    const handleFilter = (type: string, event: any) => {
        setFilters((prev) => {
            return {
                ...prev,
                [type]: event?.value,
            }
        });
    }

    return { searchData, loading, error, options, handleFilter };
};

export default useSearchMetaData;