import React, { useContext, useEffect } from 'react';
import { Col, Container, Nav, NavItem, Row } from 'reactstrap';


import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppStateContext } from '../../../appContext';
import { useStoredParam } from '../../../services/hooks/useStoredParam';
import Loading from '../../Loading';
import "./RisksPage.scss";


interface TabItem {
    name: string;
    key: string;
}

const RisksPage: React.FC<{}> = observer(() => {
    const { clientReady } = useContext(AppStateContext);
    const { t } = useTranslation();
    const [subpage, setSubpage] = useStoredParam('subpage', 'exposure');
    const navigate = useNavigate();

    const location = useLocation();
    const paramSubpage = location.pathname.split('/')[2];

    // navigate to the stored page if we went to this page from outside without full subpage path
    useEffect(() => {
        if (!paramSubpage && paramSubpage !== subpage) {
            navigate(`/risk/${subpage}`);
        }
    }, [subpage, paramSubpage, setSubpage, navigate]);


    const tabs: TabItem[] = [
        { name: t('var.tabs.exposure'), key: 'exposure' },
        { name: t('var.tabs.var'), key: 'var' },
        { name: t('var.tabs.cvar'), key: 'cvar' },
        { name: t('var.tabs.drawdown'), key: 'drawdown' },
        { name: t('var.tabs.sharpino'), key: 'sharpino' },
        { name: t('var.tabs.historical'), key: 'historical' },
        { name: t('var.tabs.marginal'), key: 'marginal' },
    ];

    const toggleTab = (tabName: string) => {
        setSubpage(tabName);
    };

    if (!clientReady) {
        return <Loading />
    }

    return (
        <Container fluid className='VaRPage'>
            <Row>
                <Col>
                    <Nav tabs>
                        {tabs.map((tab) => (
                            <NavItem key={tab.name}>
                                <NavLink
                                    to={`${tab.key}`}
                                    className={'nav-link'}
                                    onClick={() => toggleTab(tab.key)}
                                >
                                    {tab.name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    );
});

export default RisksPage;