import { Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MetricPeriodicity } from '../../../../models';
import { useEnumNumericOptions } from '../../../../services/hooks';


export const usePeriodicity = (): [JSX.Element, MetricPeriodicity] => {
    const { t } = useTranslation();
    const periodicityOptions = useEnumNumericOptions(MetricPeriodicity, 'MetricPeriodicity');

    const [periodicity, setPeriodicity] = useState<MetricPeriodicity>(MetricPeriodicity.Intraday);

    const component = <>
        <label htmlFor="periodicity">{t('var.fields.periodicity')}</label>
        <Select
            style={{ width: '100%' }}
            options={periodicityOptions}
            value={periodicity}
            onChange={setPeriodicity}
        />
    </>

    return [component, periodicity];
};