import { ApexOptions } from "apexcharts";
import React, { useMemo, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { CategoryExposure, CategoryExposureTypes, ExposureType } from "../../../../models";
import { formatNumberFix, formatNumberTrim } from "../../../../utils";

type ExposureChartViewProps = {
    serie: any,
    othersData: CategoryExposure[],
    labels: Array<string>,
    name: CategoryExposureTypes,
    activeStat: ExposureType,
    onSelectItem: CallableFunction,
    selection: string,
    displayDecimals: number,
    denominatedInAssetId: string
};

export const ExposureBarView: React.FC<ExposureChartViewProps> = React.memo(({ serie, labels, displayDecimals, denominatedInAssetId, name, activeStat, othersData }) => {
    const currency = denominatedInAssetId;
    const digits = displayDecimals;
    const fixedDecimals = 0;

    const tooltipFormatters = {
        totalValue: function (value: number, percentValue: string, opts: any) {
            return percentValue + '%';
        },
        openPositionCount: function (value: number, percentValue: string, opts: any) { return percentValue + '%'; },
        cumulativeUnrealizedPnl: function (value: number, percentValue: string, opts: any) { return percentValue + '%'; },
    }

    const dataFormatters = {
        totalValue: function (dataValue: number) { return formatNumberFix(dataValue / 1000, fixedDecimals) + "K " + currency },
        openPositionCount: function (dataValue: number) { return formatNumberTrim(dataValue, digits); },
        cumulativeUnrealizedPnl: function (dataValue: number) { return formatNumberFix(dataValue / 1000, fixedDecimals) + "K " + currency },
    }
    const activeTootipFormatter = tooltipFormatters[activeStat as keyof typeof tooltipFormatters];
    const activeDataFormatter = dataFormatters[activeStat as keyof typeof tooltipFormatters];

    const options: ApexOptions = {
        labels: labels,
        legend: {
            position: 'bottom',
        },
        theme: { mode: "dark", palette: "palette6" },
        tooltip: {
            style: {
                fontSize: '1rem',
            },
            y: {
                formatter: ((val: number, opts?: any) => {
                    const percentValue = formatNumberTrim(val / serie.total * 100, 2);
                    return activeTootipFormatter(val, percentValue, opts)
                }),
                title: {
                    formatter: function () {
                        return ''
                    }
                }
            }
        },
        dataLabels: {
            style: {
                fontSize: '1rem'
            },
            formatter: function (value: number, { seriesIndex, w }) {
                if (value) {
                    return activeDataFormatter(value);
                }
                return '';
            }
        },
        plotOptions: {
            bar: {
                distributed: true,
                horizontal: false,
                dataLabels: {
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '1rem'
                        }
                    }
                }
            },
        },
        yaxis: {
            labels: {
                formatter: (val: number, opts?: any) => {
                    return formatNumberTrim(val / 1000, digits) + "K"
                },
                style: {
                    fontSize: '1rem'
                }
            }
        }
    }

    // const prevSelection = useRef('');

    const chartOptions = useMemo(() => {
        const opts = {
            ...options, chart: {
                toolbar: { show: false },
                // group: "realtime",
                id: `${name}-${activeStat}-bar`,
                background: '#202024',
                events: {
                    // dataPointMouseEnter: (e: any, chart?: any, options?: any) => { onSelectItem(options.w.config.chart.id, options.dataPointIndex); },
                    // dataPointMouseLeave: (e: any, chart?: any, options?: any) => { onSelectItem(options.w.config.chart.id, null); },
                    // dataPointSelection: (e: any, chart?: any, options?: any) => {
                    //     onSelectItem(options.w.config.chart.id, options.selectedDataPoints[0].length ? options.dataPointIndex : null);
                    // },
                },

            }
        };
        return opts;
    }, [name, serie, labels, activeStat]);

    const chartRef = useRef<{ chart: any }>(null);
    // if (chartRef.current) {
    //     if (prevSelection.current != ''){
    //         (chartRef.current.chart as ApexCharts).hideSeries(prevSelection.current);
    //         (chartRef.current.chart as ApexCharts).showSeries(prevSelection.current);
    //     }
    //     if (selection) {
    //         (chartRef.current.chart as ApexCharts).hideSeries(selection);

    //     }
    //     prevSelection.current = selection || '';
    // }

    const series = [{
        data: serie.data
    }];

    return (
        <ReactApexChart
            ref={chartRef as never}
            options={chartOptions}
            series={series}
            type="bar"
            height={288}
        />
    )
});

ExposureBarView.displayName = 'ExposureBarView';