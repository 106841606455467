import { BandData, BBandsIndicator } from "src/lightweight-charts/plugins/bbands-indicator/bbands-indicator";
import { BaseIndicator, TooltipOptions } from "./BaseIndicator";
import { IChartApi, ISeriesApi, MouseEventParams } from "lightweight-charts";
import { formatNumber } from "src/utils";

export class BandsIndicator extends BaseIndicator {
    plugin: BBandsIndicator;
    constructor() {
        super();
        this.plugin = new BBandsIndicator();
    }

    attach(chart: IChartApi, mainSeries: ISeriesApi<"Candlestick">): void {
        super.attach(chart, mainSeries);
        mainSeries.attachPrimitive(this.plugin);
    }

    detach() {
        this.mainSeries.detachPrimitive(this.plugin);
    }

    getTooltip(param: MouseEventParams, options: TooltipOptions): string | null {
        const valueData = this.plugin.get(param.time as number);
        if (!valueData) return null;
        
        const lower = formatNumber(valueData.lower, options.digits);
        const mid = formatNumber(valueData.mid, options.digits);
        const upper = formatNumber(valueData.upper, options.digits);
        const symbol = this.symbol ? options[this.symbol as keyof TooltipOptions] : '&nbsp;';
        return `<span class="value suffix-value"><span class="val number text-number">${lower} / ${mid} / ${upper}</span><span class="suffix">${symbol}</span>`;
    }
}
