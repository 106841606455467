import { ThemeConfig, } from "antd";

export const lyraDarkTheme: ThemeConfig = {
    "token": {
        "colorBgBase": "#202024",
        "borderRadius": 7,
        "colorLinkActive": "#ff8f28",
        "colorLinkHover": "#ffffff",
        "colorLink": "#ffffff",
        "colorPrimary": "#ff8f28",
        "colorInfo": "#ff8f28",
        "colorTextBase": "#ffffff",
        "colorBgLayout": "#0a0911",
        "colorPrimaryBg": "#0a0911",
        colorPrimaryHover: "var(--bs-body-color)",
        colorBorder: "var(--secondary-dark-3)",
    },
    "components": {
        "Table": {
            "rowHoverBg": "var(--hover-dark)",
            "rowSelectedBg": "var(--hover-dark)",
            "rowSelectedHoverBg": "var(--hover-dark)",
            "headerBg": "var(--secondary-dark)",
            "fontFamily": "var(--small-text-2)",
            "colorBgContainer": "var(--primary-dark)",
            "borderColor": "transparent",
        },
        "Menu": {
            "colorPrimary": "rgb(255, 143, 40)",
            "colorText": "rgb(255, 255, 255)",
            "itemBorderRadius": 7,
            "fontSize": 16,
            "iconSize": 20,
            "itemHeight": 32,
            "itemPaddingInline": 3,
            "itemMarginBlock": 0,
            "itemMarginInline": 0,
            "controlItemBgActive": "rgb(32, 32, 36)",
            "subMenuItemBg": "rgba(0, 0, 0, 0)",
            "collapsedIconSize": 40,
            "collapsedWidth": 96,
        },
        "Tooltip": {
            "colorBgSpotlight": "var(--white)",
            "colorText": "var(--primary-dark)"
        },
        "Dropdown": {
            "controlItemBgActiveHover": "var(--hover-dark)"
        },
        "Pagination": {
            "itemSize": 25,
            "marginXS": 0,
        },
        "DatePicker": {
            hoverBorderColor: "var(--bs-body-color)",
            activeBorderColor: "var(--bs-body-color)",
        },
        "Select": {
            colorPrimary: "var(--bs-body-color)",
        },
    }
}