enum TransactionType
{
    Unknown = 'Unknown',
    Order = 'Order',
    Adjustment = 'Adjustment',
    MarginInterest = 'MarginInterest',
    SwapInterest = 'SwapInterest',
    Fee = 'Fee',
    PnL = 'PnL',
    StockBorrowInterest = 'StockBorrowInterest',
    IdleCash = 'IdleCash',
    MarginAdjustment = 'MarginAdjustment',
    Capital = 'Capital',
    Markup  = 'Markup',
}


enum TransactionVisibility
{
    Visible = 'Visible',
    Hidden = 'Hidden'
}

export class Identity {

}

export class Transaction  {
    accountChange: number = 0;
    accountId: number = 0;
    createdByUserId: number = 0;
    createdAt: Date;
    description: string;
    effectiveDate: Date;
    hidden: TransactionVisibility;
    id: number;
    lastUpdatedAt: Date;
    orderId: number;
    positionId: number;
    reserveChange: number = 0;
    token: string;
    type: TransactionType;
    identity?: Identity;

    constructor(transaction: Object) {
        Object.assign(this, transaction);
    }
}