import React from 'react';

export interface ColumnDefinition<T> {
    name: string;
    key?: string;
    title?: string;
    className?: string;
    render?: (item: T, index: number) => React.ReactNode;
};

interface DataTableProps<T> {
    data: T[];
    columns: ColumnDefinition<T>[];
    className?: string;
    itemProps?: (item: T, index: number) => React.HTMLAttributes<HTMLTableRowElement>;
}

type DataTableI<T = any> = React.FC<DataTableProps<T>>

export const DataTable: DataTableI = <T,>({ data, columns, className, itemProps }: DataTableProps<T>) => {
    return (
        <table className={className}>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={column.name} className={column.className}>{column.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => {
                    const props = itemProps ? itemProps(item, index) : {};
                    return <tr key={index} {...props}>
                        {columns.map((column, colIndex) => (
                            <td key={`${index}-${colIndex}`} className={column.className}>{column.render ? column.render(item, index) : item[column.key as never]}</td>
                        ))}
                    </tr>
                })}
            </tbody>
        </table>
    );
};