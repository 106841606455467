import { Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ApexOptions } from 'apexcharts';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { LogoPairField, NumberField, PairField, UnitValueField } from 'src/components/fields';
import Loading from 'src/components/Loading';
import { CalculateVarData, VarSubvalue } from 'src/models';
import { formatNumberTrim } from 'src/utils';

interface MCTRProps {
    subvalue: VarSubvalue;
    data: CalculateVarData;
}

interface MCTRData {
    key: number;
    pairId: string;
    mctr: number;
    weight: number;
}

const baseOptions: ApexOptions = {
    legend: {
        position: 'bottom',
    },
    chart: {
        toolbar: { show: false },
        background: '#202024',
    },
    theme: { mode: "dark", palette: "palette6" },
    tooltip: {
        style: {
            fontSize: '1rem',
        },
    },
    dataLabels: {
        style: {
            fontSize: '1rem'
        },
        formatter: (val: number, opts?: any) => {
            return formatNumberTrim(val / 1000, 1) + "K"
        },
    },
    plotOptions: {
        bar: {
            distributed: true,
            horizontal: false,
            dataLabels: {
                hideOverflowingLabels: true,
                total: {
                    enabled: true,
                    style: {
                        fontSize: '1rem'
                    }
                }
            }
        },
    },
    yaxis: {
        labels: {
            style: {
                fontSize: '1rem'
            },
            formatter: (val: number, opts?: any) => {
                return formatNumberTrim(val / 1000, 1) + "K"
            },
        }
    }
}

export const MCTR: React.FC<MCTRProps> = ({ subvalue, data }) => {
    const { t } = useTranslation();

    const mtcr = subvalue === VarSubvalue.VaR ? data.mctrVaR : data.mctrCVaR;
    const weights = data.weights;
    const labels = data.pairIds

    const chartOptions = useMemo(() => {
        const opts = {
            labels: labels,
            title: {
                text: t('var.fields.mctr'),
            },
            ...baseOptions,
        };
        return opts;
    }, [labels, t]);

    const pieOptions = useMemo(() => {
        const opts = {
            title: {
                text: t('var.fields.weights'),
            },
            labels: labels,
            ...baseOptions,
            dataLabels: {
                enabled: true,
                formatter: function (val: number, opts?: any) {
                    return formatNumberTrim(val, 2) + "%"
                },
            },
            tooltip: {
                style: {
                    fontSize: '1rem',
                },
                y: {
                    formatter: ((val: number, opts?: any) => {
                        return formatNumberTrim(val * 100, 2) + "%";
                    })
                },
            },
        };
        return opts;
    }, [labels, t]);


    const series = [{
        data: mtcr || [], name: 'MCTR'
    }];


    if (!mtcr || !labels || !weights) {
        return <Loading />
    }

    const dataSource = labels.map((label, index) => {
        return {
            key: index,
            pairId: label,
            mctr: mtcr[index],
            weight: weights[index],
        } as MCTRData
    })

    const columns = [
        {
          title: t('var.fields.pair'),
          dataIndex: 'pairId',
          key: 'pairId',
          render: (value: any) => <PairField pairId={value} withLogo={true} withName={true} fullName={false} />
        },
        {
          title: t('var.fields.mctr'),
          dataIndex: 'mctr',
          key: 'mctr',
          render: (value: any) => <NumberField value={value} digits={2} />
        },
        {
          title: t('var.fields.weight'),
          dataIndex: 'weight',
          key: 'weight',
          render: (value: any) => <UnitValueField value={value * 100} unit="%" />
        },
      ];

    return (
        <>
        <Row>
            <Col md={11}>
                <ReactApexChart
                    options={chartOptions}
                    series={series}
                    type="bar"
                    height={336}
                />
            </Col>
            <Col md={2}></Col>
            <Col md={11}>
                <ReactApexChart
                    options={pieOptions}
                    series={weights || []}
                    type="donut"
                    height={370}
                />
            </Col>
        </Row>
        <Row style={{ marginBottom: '24px' }}>
            <Col>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Col>
        </Row>
        
        </>)
};
