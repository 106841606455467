import { ErrorMessage, Field } from "formik";
import React from "react";
import Select, { Props } from "react-select";
import './MySelect.scss';


interface MySelectProps extends Props {
    name: string;
    label: string;
    onTouched: CallableFunction;
    onSetValue: CallableFunction;
    error: any;
    touched: any;
}

class MySelect extends React.Component<MySelectProps> {
    handleChange = (value: any) => {
        this.props.onSetValue(this.props.name, value);
    };

    handleBlur = () => {
        this.props.onTouched(this.props.name);
    };

    formatOptionLabel(val: any, options: any) {
        const { label } = val;
        const { context } = options;
        if (context === 'value') {
            return `${this.props.label}: ${label}`;
        }
        return label;
    };

    render() {
        return (
            <>
                <Select
                    classNamePrefix='select'
                    isClearable={this.props.isClearable}
                    name={this.props.name}
                    getOptionLabel={this.props.getOptionLabel}
                    getOptionValue={this.props.getOptionValue}
                    formatOptionLabel={(val, options) => this.formatOptionLabel(val, options)}
                    isMulti={this.props.isMulti}
                    options={this.props.options}
                    styles={this.props.styles}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    // menuIsOpen={true}
                    className={"creation-form__select-input " + (this.props.className || "")}
                />
                <ErrorMessage name={this.props.name}></ErrorMessage>
            </>
        );
    }
}

export default MySelect;