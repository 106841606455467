import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../../../appContext';
import { AccountMetric, MetricPosition } from '../../../../models';
import { formatNumber } from '../../../../utils';
import MyApexChart from '../../../ApexChart';

interface PositionsMetricsProps {
    data: AccountMetric[];
    metric: string;
    zeroScale: boolean;
    idx: number;
}

type MetricOptionsType = {
    [key: string]: {
        formatter: (value: number) => string;
        color?: string;
        label: string;
        step?: number;
    };
};


const PositionMetrics: React.FC<PositionsMetricsProps> = ({ data, metric, zeroScale, idx }) => {
    const { account } = useContext(AppStateContext);
    const { t } = useTranslation();
    const symbol = account.symbol;

    const currencyFormatter = (value: number) => formatNumber(value, 0) + ' ' + symbol;
    const percentFormatter = (value: number) => formatNumber(value * 100, 2) + '%';

    const MetricSimpleOptions: MetricOptionsType = {
        'AvailableToTrade': {
            formatter: currencyFormatter,
            color: '#fd7f6f',
            label: t('enum.AvailableMetrics.AvailableToTrade'),
        },
        'NetEquity': {
            formatter: currencyFormatter,
            color: '#7eb0d5',
            label: t('enum.AvailableMetrics.NetEquity'),
        },
        'Cash': {
            formatter: currencyFormatter,
            color: '#b2e061',
            label: t('enum.AvailableMetrics.Cash'),
        },
        'UnrealizedPnl': {
            formatter: currencyFormatter,
            color: '#bd7ebe',
            label: t('enum.AvailableMetrics.UnrealizedPnl'),
        },
        'TotalMargin': {
            formatter: currencyFormatter,
            color: '#ffb55a',
            label: t('enum.AvailableMetrics.TotalMargin'),
        },
        'MarginIndicator': {
            formatter: percentFormatter,
            color: '#ffee65',
            label: t('enum.AvailableMetrics.MarginIndicator'),
        },
        'TotalValue': {
            formatter: currencyFormatter,
            color: '#beb9db',
            label: t('enum.AvailableMetrics.TotalValue'),
        },
        'VaR': {
            formatter: currencyFormatter,
            color: '#fdcce5',
            label: t('enum.AvailableMetrics.VaR'),
        },
        'PositionCount': {
            formatter: (value: number) => value ? value.toFixed(0) : '0',
            color: '#8bd3c7',
            label: t('enum.AvailableMetrics.PositionCount'),
            step: 1,
        },
        // position metrics

        // 'IndvidualPositions': {
        //     formatter: (value: number) => 'IndvidualPositions',
        //     color: "#b3d4ff",
        //     label: t('enum.AvailableMetrics.IndvidualPositions'),
        // },
        IPMarginRatio: {
            formatter: percentFormatter,
            label: t('enum.AvailableMetrics.IPMarginRatio'),
        },
        IPCurrentMargin: {
            formatter: currencyFormatter,
            label: t('enum.AvailableMetrics.IPCurrentMargin'),
        },
        IPCurrentValue: {
            formatter: currencyFormatter,
            label: t('enum.AvailableMetrics.IPCurrentValue'),
        },
        IPUnrealizedPnL: {
            formatter: currencyFormatter,
            label: t('enum.AvailableMetrics.IPUnrealizedPnL'),
        },
        IPQuoteMain: {
            formatter: currencyFormatter,
            label: t('enum.AvailableMetrics.IPQuoteMain'),
        },
        IPQuoteBridge: {
            formatter: currencyFormatter,
            label: t('enum.AvailableMetrics.IPQuoteBridge'),
        },
        IPMAE: {
            formatter: currencyFormatter,
            label: t('enum.AvailableMetrics.IPMAE'),
        },
        IPMFE: {
            formatter: currencyFormatter,
            label: t('enum.AvailableMetrics.IPMFE'),
        },

        // Not implemented yet
        ROI: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        SharpeRatio: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        MaximumDrawdown: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        Volatility: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        WinRate: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        AvgWinToAvgLossRatio: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        EquityCurve: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        CAGR: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        },
        SortinoRatio: {
            formatter: function (value: number): string {
                throw new Error('Function not implemented.');
            },
            color: '',
            label: '',
            step: undefined
        }
    }
    const ipmetric = 'IP' + metric;

    const basicOptions = {
        chart: {
            id: 'chart-' + metric,
            toolbar: {
                show: false
            },
            group: 'metrics',
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        theme: {
            mode: 'dark',
        },
        xaxis: {
            type: 'datetime',
            style: {
                fontSize: '1rem',
            }
    },
        title: {
            text: t('enum.AvailableMetrics.IP' + metric),
            align: 'left',
            style: {
                fontWeight: 600,
                fontFamily: 'Neue Haas Grotesk Display Pro',
            }
        },
        yaxis: {
            stepSize: MetricSimpleOptions[ipmetric].step,
            title: {
                style: {
                    fontSize: '1rem',
                }
            },
            labels: {
                formatter: MetricSimpleOptions[ipmetric].formatter,
                style: {
                    fontSize: '1rem',
                }
            },
        },
        tooltip: {
            style: {
                fontSize: '1rem',
            }
        }

    } as ApexOptions;

    const [options, setOptions] = useState<ApexOptions>(basicOptions);
    const [series, setSeries] = useState<ApexAxisChartSeries>([]);


    useEffect(() => {

        const times = data.map(item => moment(item.AlignedTime).toDate().getTime());

        // Create series for position metrics

        // Create map of all positions from all data items
        const positionsMap: Map<number, MetricPosition> = new Map();
        if (data.length && data[0].Positions) {
            data.forEach(item => {
                item.Positions?.forEach(position => {
                    positionsMap.set(position.Id, position);
                });
            });

            // Create empty data for all position metrics
            type OptionalNumber = [number, number | null];
            const positionsData = new Map<number, OptionalNumber[]>();
            positionsMap.forEach((position, idx) => {
                if (!positionsData.has(position.Id)) {
                    const metricsData = times.map((item) => [item, null] as OptionalNumber);
                    positionsData.set(position.Id, metricsData);
                }
            });

            let maxValue = Number.MIN_VALUE;
            let minValue = Number.MAX_VALUE;

            // Fill data for all position metrics
            if (positionsMap.size) {
                data.forEach((item, index) => {
                    positionsMap.forEach((position) => {
                        const pos = item.Positions?.find(p => p.Id === position.Id);
                        const posData = positionsData.get(position.Id)!;
                        if (pos) {
                            posData[index][1] = pos[metric as never];
                        }

                        if (pos && pos[metric as never] !== null) {
                            maxValue = Math.max(maxValue, pos[metric as never]!);
                            minValue = Math.min(minValue, pos[metric as never]!);
                        }
                    });
                });
            }

            const posSeries: ApexAxisChartSeries = [];

            // Create series for position metrics
            positionsMap.forEach((position) => {
                const positionSerie = {
                    name: position.PairId,
                    data: positionsData.get(position.Id)!,
                };
                posSeries.push(positionSerie);
            });

            // Create yaxis for position metrics
            const newOptions = {
                ...options,
                yaxis: {
                    ...options.yaxis,
                    min: zeroScale ? 0 : minValue,
                    max: zeroScale ? undefined : maxValue,
                },
            } as ApexOptions;

            setSeries(posSeries);
            setOptions(newOptions);
        }

    }, [data, metric, zeroScale]);

    return (
        <div>
            {options && <MyApexChart
                options={options}
                series={series}
                type="line"
                height="350"
                width="100%"
            />}
        </div>
    );
};

export default PositionMetrics;