import React, { useState } from 'react';
import { TooltipItem } from './TootlipItem';

interface ButtonProps {
    children?: React.ReactNode;
    tooltip: string;
    onClick: CallableFunction;
    id: any;
    prefix?: string;
}

const ActionButton: React.FC<ButtonProps> = ({ children, onClick, tooltip, id, prefix }) => {
    return (

        <button
            className="icon"
            onClick={() => onClick()}
        >
            <TooltipItem prefix={`action-button-${prefix}`} text={tooltip} id={id}>
                {children}
            </TooltipItem>

        </button>
    );
};

export default ActionButton;