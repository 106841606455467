import { observer } from "mobx-react-lite";
import React, { useState } from 'react';
import { useAlertsData } from '../../../../services/hooks';
import Loading from '../../../Loading';
import { AlertsView } from '../../../common/Alerts/AlertsView';
import { TablePaginator } from "../../../TablePaginator";
import { useOutletContext } from "react-router";
import { PorfolioTabPropsContextType } from "../PortfolioPage";


const AlertsTab: React.FC<{}> = observer(() => {
    const [lastUpdate, setLastUpdate] = useState(new Date());
    const {currency, ...rest} = useOutletContext<PorfolioTabPropsContextType>();

    const { data: alerts, loading, pagination, setPage, totalCount } = useAlertsData({ ...rest, updateAt: lastUpdate });

    // TODO: move to invalidating resources
    const refreshAlerts = () => {
        setLastUpdate(new Date());
    }

    if (loading || !alerts) {
        return <Loading />
    }

    return <>
        <AlertsView alerts={alerts} refreshAlerts={refreshAlerts} currency={currency} />
        <TablePaginator onPage={setPage} page={pagination.page} itemsOnPage={pagination.pageSize} totalCount={totalCount} />
    </>
});

export default AlertsTab;
