import { Popover } from 'antd';
import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AppStateContext } from '../../../appContext';
import { closeIcon, deleteIcon, editIcon, infoIcon, saveIcon, transactionIcon } from '../../../assets/icons';
import { OrderFormValues, cancelOrder, submitChanges } from '../../../common/order-utils';
import { GoodUntilTypes, Order, OrderType, TriggerStatus, getGoodUntilShortOptions } from '../../../models';
import { logoImageService } from '../../../services';
import { SwitchValue, formatNumberTrim } from '../../../utils';
import { DateField, EnumField, UnitValueField } from '../../fields';
import { DateTimePickerField } from '../../fields/DateTimePickerField';
import { PairField } from '../../fields/PairField';
import SelectField from '../../fields/SelectField';
import { TooltipItem } from '../../TootlipItem';



export interface OrdersViewInternalProps {
    currency: string;
    orders: Order[];
    refreshOrders?: CallableFunction;
    showTransaction?: CallableFunction;
}

export interface OrderRowProps {
    order: Order;
    currency?: string;
    refreshOrders?: CallableFunction;
}

const useOrderViewData = ({ currency, refreshOrders, showTransaction }: { currency: string, refreshOrders?: CallableFunction, showTransaction?: CallableFunction }) => {
    const context = useContext(AppStateContext);
    const { t } = useTranslation();
    const [editingRow, setEditingRow] = useState<Number | undefined>();
    const { values, resetForm } = useFormikContext<OrderFormValues>()
    const symbolAccount = context.account.symbol;
    const userId = context.user.id;
    const isDirect = currency === 'account' || !!editingRow;
    const goodUntilShortOptions = getGoodUntilShortOptions(t);

    const setEdit = (order: Order | undefined) => {
        setEditingRow(order?.id);
        if (order) {
            resetForm({
                values: {
                    qty: order.qty,
                    triggerPrice: order.triggerPrice,
                    stopLossTotal: order.stopLossTotal,
                    takeProfitTotal: order.takeProfitTotal,
                    goodUntilType: order.goodUntilType,
                    goodUntil: order.goodUntil && order.goodUntilType === GoodUntilTypes.GTT ? order.goodUntil : moment().add(1, 'day').toDate(),
                }
            });
        }
    }

    const renderer = {
        logo: {
            title: null,
            render: (order: Order, params = {}) => {
                const iconUrl = logoImageService.getPairLogoUrl(order.pairId);
                return <div className='logo-wrapper'><img src={iconUrl} className='pair-logo'></img></div>
            }
        },
        token: {
            title: t('portfolio.orders.token'),
            render: (order: Order, params = {}) => order.token
        },
        cell_ticker: {
            title: null,
            render: (order: Order, params = {}) => {
                return <>
                    <NavLink className='pair-id' to={"/trading/" + encodeURIComponent(order.pairId)}>{order.pairId}</NavLink>
                    <span className='small-info'>{order.token}</span>
                </>
            }
        },
        ticker: {
            title: null,
            render: (order: Order, params = {}) => {
                const tooltipContent = <div>
                    <div><b>Pair:</b> {order.pairId}</div>
                    <div><b>{renderer.token.title}:</b> {order.token}</div>
                    <div><b>{renderer.created_at.title}:</b> <DateField value={order.createdAt} format='medium' /></div>
                    {!!order.triggerPrice && <div><b>{renderer.trigger_price.title}:</b> {renderer.trigger_price.render(order)}</div>}
                </div>
                return (<>
                    <div className='item-info'>
                        <PairField pairId={order.pairId} withLogo={true} withName={true} fullName={false} />
                        <div className='info-icon'>
                            <TooltipItem text={tooltipContent} id={order.id} prefix="tt-open-pos" customClass='info-tooltip'>
                                {infoIcon}
                            </TooltipItem>
                        </div>
                    </div>
                    <div className='side'>{t(order.SideName)}</div>
                    <div className='name'>{t(order.TypeName)}</div>
                </>
                )
            }
        },
        side: {
            title: t('portfolio.orders.side'),
            render: (order: Order, params = {}) => {
                return <div>
                    <div className='side '>{t(order.SideName)}</div>
                    <div className="total">
                        {t(order.TypeName)}
                    </div>
                </div>
            }
        },
        qty: {
            title: t('portfolio.orders.qty'),
            render: (order: Order, params = {}) => {
                if (editingRow === order.id) {
                    return <Field className="form-control" name="qty" type="number" />
                } else {
                    const qtyDisplay = formatNumberTrim(order.qty);
                    return <><UnitValueField value={qtyDisplay} unit={order.pairInfo?.baseSymbol} {...params} /></>
                }
            }
        },
        current_price: {
            title: t('portfolio.orders.current_price'),
            render: (order: Order, params = {}) => {
                return <div className='text-number'>{order.isBuy ? order.pairInfo?.bidDisplay : order.pairInfo?.askDisplay}</div>
            }
        },
        pair_unit: {
            render: (order: Order, params = {}) => {
                return symbolAccount;
            }
        },
        currency_unit: {
            render: (order: Order, params = {}) => {
                return isDirect ? symbolAccount : order.bridgeSymbol;
            }
        },
        execution_price: {
            title: t('portfolio.orders.execution_price'),
            render: (order: Order, params = {}) => {
                return <div className='text-number'>{order.executionPrice ? <SwitchValue value={order.ExecutionPrice} direct={isDirect} {...params} /> : '---'}</div>
            }
        },
        execution_price_unit: {
            render: (order: Order, params = {}) => {
                if (!order.executionPrice) {
                    return null;
                }
                return isDirect ? order.accountSymbol : order.bridgeSymbol;
            }
        },
        trigger_price: {
            title: t('portfolio.orders.trigger_price'),
            render: (order: Order, params = {}) => {
                const isEditing = editingRow === order.id;
                if (isEditing) {
                    return <Field className="form-control" name="triggerPrice" type="number" />
                }
                if (!order.triggerPrice) {
                    return null;
                }
                return order.type !== OrderType.Market ?
                    <UnitValueField value={order.triggerPrice} {...params} /> :
                    <UnitValueField value='---' />;
            }
        },
        trigger_price_unit: {
            render: (order: Order, params = {}) => {
                if (!order.triggerPrice) {
                    return null;
                }
                return order.accountSymbol;
            }
        },
        status: {
            title: t('portfolio.orders.status'),
            render: (order: Order, params = {}) => {
                return <div className={classNames('value order-status', 'order-status-' + order.PersistentStatusName)}>
                    <EnumField value={order.PersistentStatusName} enum={"TriggerStatus"} translate={t} />
                    {order.persistentStatus === TriggerStatus.Cancelled && <TooltipItem text={order.cancellationReason} customClass='info-tooltip'>
                        {infoIcon}
                    </TooltipItem>}
                </div>
            }
        },
        total_stop_loss: {
            title: t('portfolio.orders.total_stop_loss'),
            render: (order: Order, params = {}) => {
                const isEditing = editingRow === order.id;
                if (!isEditing) {
                    if (!order.stopLossTotal) return null;
                    return <SwitchValue value={order.TotalStopLoss} direct={isDirect} {...params} />
                } else {
                    return <Field className="form-control" type="number" name="stopLossTotal" />
                }
            }
        },
        total_take_profit: {
            title: t('portfolio.orders.total_take_profit'),
            render: (order: Order, params = {}) => {
                const isEditing = editingRow === order.id;
                if (!isEditing) {
                    if (!order.takeProfitTotal) return null;
                    return <SwitchValue value={order.TotalTakeProfit} direct={isDirect} {...params} />
                } else {
                    return <Field className="form-control" type="number" name="takeProfitTotal" />
                }
            }
        },
        created_at: {
            title: t('portfolio.orders.created_at'),
            render: (order: Order, params = {}) => <DateField value={order.createdAt} />
        },
        executed_at: {
            title: t('portfolio.orders.executed_at'),
            render: (order: Order, params = {}) => <DateField value={order.executedAt} />
        },
        good_until: {
            title: t('portfolio.orders.good_until'),
            render: (order: Order, params = {}) => {
                const isEditing = editingRow === order.id;
                if (isEditing) {
                    return <div className='multi-input'>
                        <Field component={SelectField} classNamePrefix='select' name="goodUntilType" menuPlacement="bottom" options={goodUntilShortOptions} placeholder="Valid to" />
                        {values.goodUntilType === GoodUntilTypes.GTT && <DateTimePickerField name="goodUntil" />}
                    </div>
                } else {
                    if (order.goodUntilType === GoodUntilTypes.GTT) {
                        return <DateField value={order.goodUntil} />
                    } else {
                        return <>{goodUntilShortOptions[order.goodUntilType].label}</>
                    }
                }
            }
        },
        actions: {
            title: t('portfolio.orders.actions'),
            render: (order: Order, params = {}) => {
                const isEditing = editingRow === order.id;
                const allowEditing = order.createdByUserId === userId;
                return <div className='actions'>
                    {!isEditing && allowEditing && <button className="icon" onClick={() => setEdit(order)}>{editIcon}</button>}
                    {!isEditing && allowEditing && <button className="icon" onClick={() => cancelOrder(order, refreshOrders!)}>{deleteIcon}</button>}

                    {!allowEditing && <Popover content={t('portfolio.orders.forbidden')}>{infoIcon}</Popover>}

                    {isEditing && <button className="icon" onClick={() => setEdit(undefined)}>{closeIcon}</button>}
                    {isEditing && <button className="icon" onClick={() => submitChanges(order, setEditingRow, refreshOrders!, values)}>{saveIcon}</button>}
                </div>
            }
        },
        closed_actions: {
            title: t('portfolio.orders.actions'),
            render: (order: Order, params = {}) => {
                return <div className='actions'>
                    <button className="icon" onClick={() => showTransaction && showTransaction(order)} type='button'>{transactionIcon}</button>
                </div>
            }
        },
    }

    return { renderer, editingRow, setEdit }
};

export default useOrderViewData;