import { observer } from "mobx-react-lite";
import React from 'react';
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";

import { useVARGetSSRatiosData } from "../../../../../services/hooks";
import Loading from "../../../../Loading";
import { RatioValue } from "../../RatioValue";
import { useRisk, useWindow } from "../../filters";
import { Button } from "antd";
import { arrowDownIcon, arrowUpIcon } from "src/assets/icons";
import { SharpinoDetails } from "./details";


export const SharpinoTab: React.FC<{}> = observer((params) => {
    const { t } = useTranslation();

    const [windowComponent, window] = useWindow();
    const [showDetails, setShowDetails] = React.useState(false);

    const [riskComponent, risk] = useRisk();
    const { loading, data } = useVARGetSSRatiosData(window, risk, showDetails);
    const scoreMapping: Array<[number, string]> = [ 
        [0, 'score-very-bad'], 
        [1, 'score-bad'], 
        [1.5, 'score-medium'], 
        [2, 'score-medium-good'], 
        [3, 'score-good'], 
        [Number.MAX_VALUE, 'score-very-good']
    ];

    return (
        <Container className='SharpinoTab score-rows'>
            <Row>
                <Col className="filters-container">
                    <div>
                        {windowComponent}
                    </div>
                    <div style={{ width: '320px' }}>
                        {riskComponent}
                    </div>
                </Col>
                <Col className="extra-column" >
                    <div>
                        <Button color="secondary" className="btn-icon" onClick={() => setShowDetails(!showDetails)}>{t('var.fields.adjusted')}
                            {showDetails ? arrowUpIcon : arrowDownIcon}
                        </Button>
                    </div>
                </Col>
            </Row>

            {loading && <Loading />}
            {<div className="score-container">
                <div className="score">
                    <div>
                        <label >{t('var.fields.sharpe')}:</label> <RatioValue scoreMapping={scoreMapping} value={data?.sharpeRatio} loading={loading} />
                    </div>
                    <div>
                        <label >{t('var.fields.sortino')}:</label> <RatioValue scoreMapping={scoreMapping} value={data?.shortinoRatio} loading={loading} />
                    </div>
                </div>
            </div>
            }
            {showDetails && data && <div>
                <SharpinoDetails data={data} />
            </div >}
            <div className="typography">
                <h2>Understanding Sharpe and Sortino Ratios: Tools for Assessing Risk-Adjusted Investment Performance</h2>
                <p>
                    The Sharpe and Sortino ratios are pivotal metrics used in evaluating the performance of investments relative to their associated risks. Each metric illuminates different aspects of investment performance, allowing investors to gauge the efficiency of their portfolio management strategies.
                </p>
                <p>
                    <b>Sharpe Ratio:</b> This metric measures the excess return per unit of volatility, providing a straightforward indicator of risk-adjusted performance relative to a risk-free asset. It is calculated by subtracting the risk-free rate from the returns of the investment and then dividing this by the standard deviation of the investment's returns. The Sharpe Ratio thus helps investors understand the additional return their investment generates per unit of total risk.
                </p>

                <h3>Interpretation of Sharpe Ratio Values:</h3>
                <ol>
                    <li>
                        <b className="score-very-bad">Below 0:</b> A Sharpe Ratio below zero indicates that the investment is underperforming even the risk-free rate, resulting in negative risk-adjusted returns. This suggests that the investment is not only risky but also failing to provide any additional return above a risk-free alternative.
                    </li>
                    <li>
                        <b className="score-bad">Between 0 and 1.0:</b> A Sharpe Ratio in this range reflects underperformance, where the investment yields positive returns but insufficient risk-adjusted returns. The portfolio may have some gains, but they do not adequately compensate for the risks taken, making it a less desirable investment.
                    </li>
                    <li>
                        <b className="score-medium">Between 1.0 and 1.5:</b> In this range, the portfolio demonstrates acceptable performance with good risk-adjusted returns. The investment provides returns that are proportional to the risk taken, but there is room for improvement in overall efficiency.
                    </li>
                    <li>
                        <b className="score-medium-good">Between 1.5 and 2.0:</b> A Sharpe Ratio between 1.5 and 1.99 suggests solid, above-average risk-adjusted returns. The portfolio effectively manages risk while generating satisfactory returns, appealing to most investors.
                    </li>
                    <li>
                        <b className="score-good">Between 2.0 and 3.0:</b> This range signals strong, well-balanced performance, where the investment delivers substantial returns in proportion to the risk taken. Such portfolios are highly efficient, making them attractive to most investors seeking consistent risk-adjusted growth.
                    </li>
                    <li>
                        <b className="score-very-good">Above 3.0:</b> A Sharpe Ratio of 3.0 or higher represents exceptional performance. The investment generates significantly high returns relative to the amount of risk, indicating superior portfolio management and an excellent balance of reward versus risk.
                    </li>
                </ol>

                <p>
                    <b>Sortino Ratio:</b> This ratio refines the Sharpe Ratio by focusing solely on downside volatility—only the variability of negative returns is considered. It's particularly useful for investors who are primarily concerned with downside risk. The Sortino Ratio is calculated in a similar manner to the Sharpe Ratio but divides the excess return over the risk-free rate by the downside deviation instead of total volatility.
                </p>

                <h3>Interpretation of Sortino Ratio Values:</h3>
                <ol>
                    <li>
                        <b className="score-very-bad">Below 0:</b> A Sortino Ratio below zero means that the portfolio’s returns do not compensate for the downside risk, indicating that the investment produces negative risk-adjusted returns when considering only the risk of losses. This is a clear sign of poor management of downside risk.
                    </li>
                    <li>
                        <b className="score-bad">Between 0 and 1.0:</b> In this range, the Sortino Ratio suggests that the investment is underperforming in terms of downside risk management. While some returns may be generated, they are insufficient to outweigh the risk of negative outcomes, making it a suboptimal choice for risk-averse investors.
                    </li>
                    <li>
                        <b className="score-medium">Between 1.0 and 1.5:</b> A Sortino Ratio between 1.0 and 1.49 is indicative of adequate returns relative to the downside risk. The portfolio manages negative volatility reasonably well, but there remains potential for improvement.
                    </li>
                    <li>
                        <b className="score-medium-good">Between 1.5 and 2.0:</b> In this range, the portfolio manages downside risk effectively, providing strong risk-adjusted returns. The portfolio minimizes the impact of losses, making it a sound choice for investors focused on limiting downside risk.
                    </li>
                    <li>
                        <b className="score-good">Between 2.0 and 3.0:</b> A Sortino Ratio within this range indicates robust management of downside risk, yielding substantial returns relative to the potential for loss. The portfolio is highly efficient in avoiding significant downturns while still producing attractive gains.
                    </li>
                    <li>
                        <b className="score-very-good">Above 3.0:</b> A Sortino Ratio of 3.0 or higher reflects outstanding downside risk management. The portfolio delivers exceptional returns while minimizing exposure to losses, making it an ideal investment for risk-averse individuals or those prioritizing downside protection.
                    </li>
                </ol>

                <h3>Parameters:</h3>
                <ul>
                    <li>
                        <b>Window</b> - a historical look-back period (e.g., 6 to 36 months) for calculating weighted returns for the portfolio. This period helps assess how the investment has fared under varying market conditions and contributes to a more nuanced understanding of performance stability.
                    </li>
                    <li>
                        <b>Risk-free rate</b>, which is the theoretical rate of return of an investment with zero risk, typically represented by government bond yields. This risk-free rate is crucial as it serves as the baseline for comparing the performance of riskier assets.
                    </li>
                </ul>

                <p>
                    <b>Benefits and Limitations:</b> The Sharpe Ratio is advantageous for comparing different investments on a risk-adjusted basis, but it does not distinguish between good and bad volatility. The Sortino Ratio focus specifically on downside risk, differentiating itself from the Sharpe Ratio by considering only negative volatility (downside deviation) instead of total volatility. This makes the Sortino Ratio particularly valuable for investors who are more concerned with the downside risk of their investments. Importantly, the Sortino Ratio does not require the assumption that returns are normally distributed. This aspect makes it useful for analyzing investments where the returns may be skewed or exhibit fat tails (i.e., they do not conform to a normal distribution). This characteristic is particularly advantageous when evaluating portfolios or strategies that aim to minimize losses rather than maximize total returns, as it more accurately reflects the risk of negative outcomes.
                </p>
                <p>
                    <b>Example:</b> For a portfolio with an annual return of 15%, a risk-free rate of 5%, and a standard deviation of returns at 5%, the Sharpe Ratio would be 2.0. This implies a highly efficient investment relative to its risk. If the downside deviation of this portfolio is 3%, the Sortino Ratio would be 3.33, demonstrating superior management of negative volatility and an enhanced focus on generating returns above the risk-free rate while minimizing downside risks.
                </p>
                <p>
                    By integrating both Sharpe and Sortino ratios into their analysis, investors can derive a comprehensive view of how effectively their investments are managed in terms of both overall and downside-specific risks, aiding them in crafting a balanced and strategic investment approach.
                </p>

                <h3>Risk-Adjusted Return Attribution</h3>
                <p>
                    Risk-adjusted return attribution breaks down a portfolio’s performance by analyzing the contribution of each asset or strategy relative to the risks they carry. By focusing on both the returns and the risks taken to achieve those returns, this approach allows investors to assess which assets or strategies contribute positively or negatively to the overall risk-adjusted performance. This insight helps identify efficient components of a portfolio, optimize asset allocation, and refine investment strategies to enhance long-term, risk-conscious returns.
                </p>
            </div>
        </Container >)
});