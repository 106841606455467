import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row, UncontrolledCollapse } from "reactstrap";
import { DrawdownData, MetricPeriodicity } from "../../../../models";
import { useDrawdownData, useEnumNumericOptions } from "../../../../services/hooks";
import { NumberField, UnitValueField } from "../../../fields";
import { useDateRange } from "../filters";
import { useTreshhold } from "../filters/treshhold";


export const DrawdownComponent: React.FC<{ drawdown?: DrawdownData, loading: boolean }> = ({ drawdown, loading }) => {
    const { t } = useTranslation();
    // const drawdownClass = drawdown.maxDrawdown < 0.1 ? 'score-good' : drawdown.maxDrawdown < 0.2 ? 'score-medium' : 'score-bad';
    // const severityClass = drawdown.totalSeverity < 0.1 ? 'score-good' : drawdown.totalSeverity < 0.2 ? 'score-medium' : 'score-bad';
    return (
        <>
            <div className="score">
                <div>
                    <label>{t('var.fields.maxDrawdown')}: </label><UnitValueField value={drawdown? drawdown?.maxDrawdown * 100 : null} unit="%" precision={2} loading={loading} />
                </div>
                <div>
                    <label>{t('var.fields.totatlSeverity')}: </label><UnitValueField value={drawdown? drawdown.totalSeverity * 100 : null} unit="%" precision={2} loading={loading} />
                </div>
                <div>
                    <label>{t('var.fields.frequency')}: </label><div><NumberField value={drawdown?.frequency} loading={loading} /></div>
                </div>
            </div>


            <Button color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
                {t('var.fields.drawdowns')}
            </Button>
            <UncontrolledCollapse toggler="#toggler">
                <table className="table-grid positions data-table table-auto drawdown-table">
                    <thead>
                        <tr>
                            <th>{t('var.fields.startDate')}</th>
                            <th>{t('var.fields.troughDate')}</th>
                            <th>{t('var.fields.recoveryStartDate')}</th>
                            <th>{t('var.fields.recoveryEndDate')}</th>
                            <th>{t('var.fields.recoveryPeriod')}</th>
                            <th>{t('var.fields.drawdown')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {drawdown?.drawdowns.map((item, index) => (
                            <tr key={index}>
                                <td>{moment(item.startDate).format('LLL')}</td>
                                <td>{moment(item.troughDate).format('LLL')}</td>
                                <td>{item.recoveryStartDate && moment(item.recoveryStartDate).format('LLL')}</td>
                                <td>{item.recoveryEndDate && moment(item.recoveryEndDate).format('LLL')}</td>
                                <td>{item.recoveryPeriod && item.recoveryPeriod}</td>
                                <td><UnitValueField value={item.drawdown * 100} unit="%" precision={2} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </UncontrolledCollapse>
        </>
    )
}

export const SummaryDrawdownTab: React.FC<{}> = observer((params) => {
    const { t } = useTranslation();

    const periodicityOptions = useEnumNumericOptions(MetricPeriodicity, 'MetricPeriodicity');

    const [periodicity] = useState(periodicityOptions[0]);
    const [selectComponent, dateRange] = useDateRange();
    const [thresholdComponent, threshold] = useTreshhold();

    const { loading, data } = useDrawdownData(dateRange, periodicity.value, threshold);

    return (
        <Container className='SummaryDrawdownTab'>
            <Row>
                <Col className="filters-container">
                    <div>
                        {thresholdComponent}
                    </div>
                    <div>
                        {selectComponent}
                    </div>
                    <div>
                        <label htmlFor="periodicity">{t('var.fields.periodicity')}</label>
                        <div className="field-value">{t('enum.MetricPeriodicity.Intraday')}</div>
                        {/* <Select
                            classNamePrefix='select'
                            inputId="periodicity"
                            options={periodicityOptions}
                            value={periodicity}
                            onChange={setPeriodicity}
                            isOptionDisabled={(option: { value: MetricPeriodicity; label: string }) => option.value != MetricPeriodicity.Intraday}
                        /> */}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className="text-center score-container score-rows">
                    {<DrawdownComponent drawdown={data} loading={loading} />}
                </Col>
            </Row>
            <div className="typography">
                <h2>Understanding Drawdown</h2>
                <p>
                    Drawdown is a measure of the decline from a historical peak in portfolio value over a specified time period. It is commonly used in financial analysis to assess the risk and stability of investment portfolios by identifying the largest single drop from peak to trough before a new peak is achieved. Drawdown provides crucial insights into the risk characteristics of an investment, indicating both the potential downside and the recovery period for an asset or portfolio. It is typically presented as a percentage or absolute value, representing the extent of the loss experienced.
                </p>
                <h3>Components:</h3>
                <ol>
                    <li>
                        <b>Max Drawdown:</b> This represents the largest single drop from peak to trough over the measurement period. It is a critical metric because it provides insight into the worst historical performance of the portfolio, which can be vital for assessing risk tolerance and potential financial exposure.
                    </li>
                    <li>
                        <b>Recovery Period:</b> This is the amount of time it took for the portfolio to recover from the deepest drawdown back to the original peak level. A longer recovery period might indicate that the asset or portfolio faces challenges in rebounding after significant losses, which could influence investment decisions, especially for those who require liquidity or have shorter investment horizons.
                    </li>
                    <li>
                        <b>Drawdown Dates:</b> Including the start date, trough date, and recovery end date for each drawdown. These dates help investors understand the timeline of drawdowns and subsequent recovery, providing a chronological context to the drawdown events.
                    </li>
                    <li>
                        <b>Frequency of Drawdowns:</b> This counts how often significant drawdowns occur within the specified time frame, offering insights into the volatility and stability of the portfolio.
                    </li>
                    <li>
                        <b>Total Severity:</b> This aggregates the severity of all drawdowns occurring during the period, providing a cumulative sense of how much the portfolio has declined. This can be useful in comparing the overall risk profile of different investments.
                    </li>
                </ol>

                <h3>Methods of Calculation:</h3>
                <ul>
                    <li>
                        The data is calculated by tracking the historical values of the portfolio and identifying the peaks and troughs over time. Each drawdown is measured from a peak to the subsequent trough, with recovery measured until the next peak.
                    </li>
                </ul>
                <h3>Parameters:</h3>
                <ul>
                    <li>
                        <b>Threshold for Significant Drawdown</b>: Only declines that meet a specified percentage threshold are considered in the analysis, ensuring that minor fluctuations are disregarded to focus on significant valuation changes.
                    </li>
                    <li>
                        <b>Time Span:</b> The period over which the drawdowns are measured can vary, typically ranging from several months to several years, depending on the user’s need for analysis.
                    </li>
                </ul>

                <p>
                    <b>Example</b>: Suppose a portfolio shows a max drawdown of <i>20%</i> with a recovery period of <i>90</i> days, occurring from <span>January 1</span> to <span>March 31</span>. This indicates that at its worst point, the portfolio was down <i>20%</i> from its peak, and it took three months to return to the peak level. This information is crucial for investors looking for insights into the resilience and risk associated with the portfolio.
                </p>
                <p>
                    By adjusting parameters like the threshold and time span, investors can tailor the drawdown analysis to reflect different risk tolerances and investment strategies.
                </p>
            </div>
        </Container >)
});