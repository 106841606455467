import React from 'react';
import { UnitValueField } from '../../fields';

export const RatioValue: React.FC<{ value: number | undefined, loading?: boolean, scoreMapping: Array<[number, string]> }> = ({ value, loading, scoreMapping }) => {
    let valClassName = '';
    for (const [entryValue, className] of scoreMapping) {
        valClassName = className;
        if (value && value < entryValue) {
            break;
        }
    }
    return <UnitValueField className={valClassName} value={value} precision={2} loading={loading} />
}
