import { observer } from "mobx-react-lite";
import React from 'react';
import { useClosedOrdersData } from '../../../../services/hooks';
import Loading from '../../../Loading';
import { TablePaginator } from '../../../TablePaginator';
import { ClosedOrdersView } from '../../../common/Orders/Closed/ClosedOrdersView';
import { SortableProperty } from "../../../../services";
import { PorfolioTabPropsContextType } from "../PortfolioPage";
import { useOutletContext } from "react-router";

export const ClosedOrdersSortColumns = [
    {
        key: SortableProperty.PairId,
        label: 'portfolio.orders.ticker'
    },
    {
        key: SortableProperty.OrderType,
        label: 'portfolio.orders.type'
    },
    {
        key: SortableProperty.Side,
        label: 'portfolio.orders.side'
    },
    {
        key: SortableProperty.OrderTriggerPrice,
        label: 'portfolio.orders.trigger_price'
    },
    {
        key: SortableProperty.OrderExecutedAt,
        label: 'portfolio.orders.executed_at'
    },
    {
        key: SortableProperty.CreatedAt,
        label: 'portfolio.orders.created_at'
    },
];

const ClosedOrdersTable: React.FC<{}> = observer(() => {
    const {currency, ...rest} = useOutletContext<PorfolioTabPropsContextType>();

    const { data: orders, loading, pagination, setPage, totalCount } = useClosedOrdersData(rest);

    if (loading || !orders) {
        return <Loading />
    }

    return <>
        <ClosedOrdersView currency={currency} orders={orders} />
        <TablePaginator onPage={setPage} page={pagination.page} itemsOnPage={pagination.pageSize} totalCount={totalCount} />
    </>
});

export default ClosedOrdersTable;
