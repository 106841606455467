import { makeAutoObservable } from "mobx";

import { Position } from "./Position";
import { AccountInterface } from "./Account";
import { assetsService } from "../services";

export class Balance {
    accountId = 0;
    availableToTrade = 0;
    equity = 0;
    cash = 0;
    positions: Position[] = [];
    unrealizedPnl = 0;
    totalMargin = 0;
    marginIndicator = 0;
    totalValue = 0;
    netEquity = 0;
    sentAtUtc: Date | null = null;
    constructor() {
        makeAutoObservable(this);
    }

    getBalance(key: string) {
        return this[key as keyof this];
    }

    update(newBalance: any, account: AccountInterface) {
        this.sentAtUtc = new Date(newBalance.sentAtUtc);
        this.availableToTrade = newBalance.availableToTrade;
        this.netEquity = newBalance.netEquity;
        this.cash = newBalance.cash;
        this.unrealizedPnl = newBalance.unrealizedPnl;
        this.totalMargin = newBalance.totalMargin;
        this.marginIndicator = newBalance.marginIndicator;
        this.totalValue = newBalance.totalValue;
        this.updatePositions(newBalance.positions, account);
    }

    updatePositions(positions: Position[], account: AccountInterface) {
        const positionsMap = new Map<number, Position>();
        this.positions.forEach((position: Position) => { positionsMap.set(position.id, position); });
        const updatedIds = new Set();

        positions.forEach((newPosition: Position) => {
            updatedIds.add(newPosition.id);
            if (positionsMap.has(newPosition.id)) {
                positionsMap.get(newPosition.id)!.update(newPosition);
            } else {
                this.positions.push(new Position(newPosition, account.symbol, account.displayDecimals));
            }
        });

        for (let i = this.positions.length - 1; i >= 0; i--) {
            const pos = this.positions[i];
            if (!updatedIds.has(pos.id)) {
                this.positions.splice(this.positions.findIndex(item => item.id === pos.id), 1);
            }
        }
        assetsService.ExtendPairInfo(this.positions);
    }

    updateAccount(account: AccountInterface) {
        this.positions.forEach(position=> {
            position.accountDigits = account.displayDecimals;
            position.accountSymbol = account.symbol;
        });
    }
}
