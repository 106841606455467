import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { appState } from "../../../appContext";
import { Order, PairInfo, TriggerStatus } from "../../../models";
import { OrderService, logoImageService } from "../../../services";
import { usePairData } from "../../../services/hooks/usePairData";
import { formatNumberTrim } from "../../../utils";
import Loading from "../../Loading";
import { DateField, EnumField, NumberField, UnitValueField } from "../../fields";
import { GoodUntilField } from "../../fields/GoodUntilField";


interface OrderInfoProps {
    order: Order;
    pair: PairInfo
}

const ClosedOrderInfo: React.FC<OrderInfoProps> = ({ order, pair }) => {
    const { t } = useTranslation();
    const sideName = t(order.SideName);
    const typeName = t(order.TypeName);
    const iconUrl = logoImageService.getPairLogoUrl(order.pairId);
    const qtyDisplay = formatNumberTrim(order.qty);
    const symbolAccount = appState.account.symbol;
    const digits = pair.pairDecimals;

    const data = [
        {
            label: t('portfolio.orders.token'),
            value: order.token
        },
        {
            label: t('portfolio.orders.pair_id'),
            value: <div><span className='logo-wrapper'><img className="pair-logo" src={iconUrl}></img></span> <span>{order.pairId}</span></div>
        },
        {
            label: t('portfolio.orders.side'),
            value: sideName
        },
        {
            label: t('portfolio.orders.type'),
            value: typeName
        },
        {
            label: t('portfolio.orders.status'),
            value: <EnumField value={order.PersistentStatusName} translate={t} enum="TriggerStatus" />
        },
        {
            label: t('portfolio.orders.quoted_price'),
            value: order.quotedPrice ? <UnitValueField value={order.quotedPrice} unit={symbolAccount} precision={digits} /> : '---'
        },
        {
            label: t('portfolio.orders.qty'),
            value: <UnitValueField value={qtyDisplay} unit={pair?.baseSymbol} />
        },
        {
            label: t('portfolio.orders.trigger_price'),
            value: order.triggerPrice ? <UnitValueField value={order.triggerPrice} unit={symbolAccount} precision={digits} /> : '---'
        },
        {
            label: t('portfolio.orders.reference_type'),
            value: order.referenceType
        },
        {
            label: t('portfolio.orders.execution_price'),
            value: <UnitValueField value={order.executionPrice} unit={symbolAccount} precision={digits} />
        },
        {
            label: t('portfolio.orders.created_at'),
            value: <DateField value={order.createdAt} format={"medium"} />
        },
        {
            label: t('portfolio.orders.executed_at'),
            value: <DateField value={order.executedAt} format={"medium"} />
        },
        {
            label: t('portfolio.orders.reserved_amount'),
            value: <UnitValueField value={order.reservedAmount} unit={symbolAccount} precision={digits} />
        },
        {
            label: t('portfolio.orders.margin_ratio'),
            value: <><UnitValueField value={order.marginRatio * 100} unit={'%'} precision={digits} /><span className="text-number">   (1:{Math.round(1 / order.marginRatio)})</span></>
        },
        {
            label: t('portfolio.orders.good_until'),
            value: <GoodUntilField value={order.goodUntil} goodUntilType={order.goodUntilType} />,
        },
    ];

    if (order.persistentStatus === TriggerStatus.Cancelled) {
        data.push(
            {
                label: t('portfolio.orders.cancellation_reason'),
                value: order.persistentStatus === TriggerStatus.Cancelled ? order.cancellationReason : '',
            }
        );
    }

    return <table width="100%" className="info-table">
        <tbody>
            {data.map((item, index) => <tr key={index}>
                <th>{item.label}</th>
                <td>{item.value}</td>
            </tr>
            )}
        </tbody>
    </table>
};

const OpenOrderInfo: React.FC<OrderInfoProps> = ({ order, pair }) => {
    const { t } = useTranslation();
    const sideName = t(order.SideName);
    const typeName = t(order.TypeName);
    const iconUrl = logoImageService.getPairLogoUrl(order.pairId);
    const qtyDisplay = formatNumberTrim(order.qty);
    const symbolAccount = appState.account.symbol;
    const digits = pair.pairDecimals;

    const data = [
        {
            label: t('portfolio.orders.token'),
            value: order.token
        },
        {
            label: t('portfolio.orders.pair_id'),
            value: <div><span className='logo-wrapper'><img className="pair-logo" src={iconUrl} alt={order.pairId} /></span> <span>{order.pairId}</span></div>
        },
        {
            label: t('portfolio.orders.side'),
            value: sideName
        },
        {
            label: t('portfolio.orders.type'),
            value: typeName
        },
        {
            label: t('portfolio.orders.status'),
            value: <EnumField value={order.PersistentStatusName} translate={t} enum="TriggerStatus" />
        },
        {
            label: t('portfolio.orders.quoted_price'),
            value: order.quotedPrice ? <UnitValueField value={order.quotedPrice} unit={symbolAccount} precision={digits} /> : '---'
        },
        {
            label: t('portfolio.orders.qty'),
            value: <UnitValueField value={qtyDisplay} unit={pair?.baseSymbol} />
        },
        {
            label: t('portfolio.orders.trigger_price'),
            value: order.triggerPrice ? <UnitValueField value={order.triggerPrice} unit={symbolAccount} precision={digits} /> : '---'
        },
        {
            label: t('portfolio.orders.reference_type'),
            value: order.referenceType
        },
        {
            label: t('portfolio.orders.created_at'),
            value: <DateField value={order.createdAt} format={"medium"} />
        },
        {
            label: t('portfolio.orders.reserved_amount'),
            value: <NumberField value={order.reservedAmount} />
        },
        {
            label: t('portfolio.orders.margin_ratio'),
            value: <NumberField value={order.marginRatio} />
        },
    ];


    return <table width="100%" className="info-table">
        <tbody>
            {data.map((item, index) => <tr key={index}>
                <th>{item.label}</th>
                <td>{item.value}</td>
            </tr>
            )}
        </tbody>
    </table>
};


interface OrderInfoDialogProps {
    orderId?: number;
    order?: Order;
}


export const OrderInfoDialog: React.FC<OrderInfoDialogProps> = ({ orderId, order }) => {
    const [isLoading, setIsLoading] = useState<boolean>(!order);
    const [loadOrder, setOrder] = useState<Order | undefined>(order);
    const { data: pair } = usePairData(loadOrder?.pairId);
    const { account } = appState;

    useEffect(() => {
        const loadData = async () => {
            setOrder(await OrderService.instance.getOrder(orderId!, account));
            setIsLoading(false);
        }

        if (orderId) {
            setIsLoading(true);
            loadData();
        }

        if (order) {
            setIsLoading(false);
            setOrder(order);
        }
    }, [orderId, order]);


    return <div className="pending-dialog-content">
        {isLoading && <Loading />}
        {loadOrder && pair && <div>
            {loadOrder.persistentStatus === TriggerStatus.Pending
                ? <OpenOrderInfo order={loadOrder} pair={pair} />
                : <ClosedOrderInfo order={loadOrder} pair={pair} />}
        </div>}
    </div>
};
