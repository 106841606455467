import classNames from 'classnames';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import 'react-clock/dist/Clock.css';
import 'react-date-picker/dist/DatePicker.css';
import { WithTranslation, withTranslation } from 'react-i18next';
import 'react-time-picker/dist/TimePicker.css';
import * as yup from 'yup';
import { AppStateContext } from '../../../../../appContext';
import { Quote } from '../../../../../models';
import { OrderType, QtyType } from '../../../../../models/Order';
import { GoodUntilTypes, getGoodUntilOptions } from '../../../../../models/Triggerable';
import { logoImageService } from '../../../../../services';
import { optionTrasform, round, roundSmart } from '../../../../../utils';
import Loading from '../../../../Loading';
import { PairDetailsProps } from '../PairInfoView/PairInfoView';
import { OrderFormInternal } from './InternalOrderForm';
import './OrderForm.scss';
import { OrderFormModel } from './OrderFormModel';


declare module 'yup' {
    interface NumberSchema {
        marginCheck(errorMessage: string): NumberSchema;
    }
}

interface OrderFormProps extends PairDetailsProps, WithTranslation {
    quote?: Quote;
}

const OrderForm = observer(class OrderForm extends Component<OrderFormProps> {
    static displayName = OrderForm.name;
    orderModel: OrderFormModel;
    formRef: React.RefObject<any>;
    context!: ContextType<typeof AppStateContext>;
    schema: yup.ObjectSchema<{ orderType: {} | undefined; price: number | undefined; qty: any; goodUntilType: number; goodUntilDate: {} | null | undefined; goodUntilTime: {} | null | undefined; takeProfit: boolean | undefined; takeProfitTotal: {} | undefined; stopLoss: boolean | undefined; stopLossTotal: {} | undefined; priceTolerance: string | null | undefined; availableMargin: number | undefined; marginRequired: number | undefined; }, yup.AnyObject, { orderType: undefined; price: undefined; qty: any; goodUntilType: undefined; goodUntilDate: undefined; goodUntilTime: undefined; takeProfit: undefined; takeProfitTotal: undefined; stopLoss: undefined; stopLossTotal: undefined; priceTolerance: undefined; availableMargin: undefined; marginRequired: undefined; }, "">;

    constructor(props: OrderFormProps) {
        super(props);
        this.orderModel = new OrderFormModel(this.props.pair, {}, this.props.t, this.props.extendedInfo, this.props.quote);

        yup.addMethod(yup.number, "marginCheck", function (errorMessage) {
            return this.test(`test-margin-available`, errorMessage, function (value) {
                const { path, createError } = this;
                if (this.parent.marginRequired > this.parent.availableMargin) {
                    return createError({ path, message: errorMessage })
                }
                return true;
            });
        });
        this.initSchema();
        this.formRef = React.createRef();
    }

    initSchema() {
        this.schema = yup.object({
            orderType: yup.mixed().oneOf(Object.values(OrderType)),
            price: yup.number().when('orderType', {
                is: (value: string) => value !== OrderType.Market,
                then: () => yup.number().required(),
            }),
            qty: yup.number()
                .required()
                .min(this.props.pair.orderMin, 'Minimum order is ${min}')
                .max(this.props.pair.orderMax ? this.props.pair.orderMax : Infinity, 'Maximum order is ${max}')
                .marginCheck("Insuficient funds"),
            goodUntilType: yup.mixed<GoodUntilTypes>().required().oneOf(Object.values(GoodUntilTypes) as number[]).transform(optionTrasform),
            goodUntilDate: yup.mixed().nullable()
                .when('goodUntilType', {
                    is: (value: number) => value === GoodUntilTypes.GTT,
                    then: () => yup.date().required(), // min(new Date())
                }),
            goodUntilTime: yup.mixed().nullable()
                .when('goodUntilType', {
                    is: (value: number) => value === GoodUntilTypes.GTT,
                    then: () => yup.mixed().required(),
                }),
            takeProfit: yup.boolean(),
            takeProfitTotal: yup.mixed().when('takeProfit', {
                is: true,
                then: () => yup.number().typeError('Enter valid take profit value').required(),
            }),
            stopLoss: yup.boolean(),
            stopLossTotal: yup.mixed().when('stopLoss', {
                is: true,
                then: () => yup.number().typeError('Enter valid stop loss value').required(),
            }),
            priceTolerance: yup.string().nullable().transform(optionTrasform),
            availableMargin: yup.number(),
            marginRequired: yup.number().lessThan(yup.ref('availableMargin'), 'Required margin must be less than available margin'),
        });
    }

    async onSubmit(values: any) {
        values = this.schema.cast(values, { assert: false });
        this.orderModel.submit(values);
        this.context.setSideBar(false);
    }

    getQty() {
        
        const searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has('qty')) {
            return Number(searchParams.get('qty'));
        }
        
        const { pair } = this.props;
        const availableMargin = this.context.balance.availableToTrade;
        const maxQty = this.orderModel.getMaxQty(availableMargin);
        const qty = Math.max(0, pair.lotSize < pair.orderMin ? pair.orderMin : pair.lotSize);
        return roundSmart(Math.min(maxQty * 0.9, qty));
    }

    componentDidUpdate(prevProps: OrderFormProps, prevState: any) {
        const formRefCurrent = this.formRef.current;

        if (formRefCurrent && (prevProps.pair !== this.props.pair || prevProps.direction !== this.props.direction || prevProps.extendedInfo !== this.props.extendedInfo)) {
            const { setFieldValue, setFieldTouched } = formRefCurrent;
            setFieldValue('qty', this.getQty());
            setFieldTouched('qty');
            setFieldValue('price', round(this.props.pair.mid, this.props.pair.pairDecimals));
            setFieldValue('side', this.props.direction);
            this.initSchema();
        }

        if (formRefCurrent) {
            formRefCurrent.validateForm();
        }
    }

    render() {
        const { pair, extendedInfo, direction, t } = this.props;

        const availableMargin = this.context.balance.availableToTrade;
        const symbol = this.context.account.symbol;
        const quotes = this.context.quotes.values;
        const quote = quotes.get(pair.id);
        const currentPrice = round(pair.mid, pair.pairDecimals);
        pair.updateQuote(quote);
        const goodUntilOptions = getGoodUntilOptions(t);

        const formValues = {
            orderType: OrderType.Market,
            price: currentPrice,
            qty: 0,
            priceTolerance: { value: '', label: t('enum.price_tolerance.market') },
            goodUntilType: goodUntilOptions[0],
            goodUntilDate: new Date(),
            goodUntilTime: new Date(),
            takeProfit: false,
            takeProfitTotal: '',
            stopLoss: false,
            stopLossTotal: '',
            qtyType: QtyType.Qty,
            availableMargin: availableMargin,
            marginRequired: 0,
            side: direction,
        };
        this.orderModel.values = formValues;
        this.orderModel.extendedInfo = extendedInfo;

        formValues.qty = this.getQty();

        if (!extendedInfo) {
            return <Loading />
        }

        const iconUrl = logoImageService.getPairLogoUrl(pair.id);
        return (
            <div className={classNames('OrderForm', direction)}>
                <h3><span className='logo-wrapper'><img className="pair-logo" alt="pair logo" src={iconUrl}></img></span> <span>{pair.id}</span></h3>
                <div className='pair-description'>{pair.dispName} {extendedInfo && extendedInfo.subtype !== null && <span>({extendedInfo.subtype})</span>}</div>
                <Formik innerRef={this.formRef}
                    validationSchema={this.schema}
                    onSubmit={(values) => this.onSubmit(values)}
                    enableReinitialize={false}
                    initialValues={formValues}
                    validateOnMount={true}
                    validateOnChange={true}
                >
                    {props => {
                        return <>
                            <OrderFormInternal
                                symbol={symbol}
                                direction={direction}
                                extendedInfo={extendedInfo}
                                availableMargin={availableMargin}
                                model={this.orderModel}
                                pair={pair}
                                {...props}
                            ></OrderFormInternal>
                            {/* <div className='debug text-danger'>Errors: {JSON.stringify(props.errors)}</div> */}
                            {/* <div className='debug'>Values: {JSON.stringify(this.schema.cast(props.values, { assert: false }))}</div> */}
                            {/* <div className='debug'>Touched: {JSON.stringify(props.touched)}</div> */}
                        </>;
                    }}

                </Formik>

            </div>
        );
    }
})

OrderForm.contextType = AppStateContext;
export default withTranslation()(OrderForm);