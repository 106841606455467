import { Formik } from 'formik';
import { observer } from "mobx-react-lite";
import React from 'react';
import { useClosedOrdersData } from '../../../../services/hooks';
import Loading from '../../../Loading';
import { ClosedOrdersViewBlocks } from '../../../common/Orders/Closed/ClosedOrdersViewBlocks';
import { useShowTransactionsDialog } from '../../../common/Positions/useShowTransactionsDialog';
import { PorfolioTabPropsContextType } from '../../PortfolioPage/PortfolioPage';
// import './Orders.css';



const ClosedOrders: React.FC<PorfolioTabPropsContextType> = observer((params) => {
    const { currency, ...rest } = params;

    const { data: orders, loading } = useClosedOrdersData(rest);
    const { transactionDialog, showPositionTransactions } = useShowTransactionsDialog();

    if (loading || !orders) {
        return <Loading />
    }
    return (
        <>
            {transactionDialog}
            <Formik onSubmit={() => { }} initialValues={{}}>
                <ClosedOrdersViewBlocks orders={orders} currency={currency!} showTransaction={showPositionTransactions} />
            </Formik >
        </>
    );
});

export default ClosedOrders;
