import React from 'react';
import classNames from "classnames";
import { infoIcon, linkIcon } from "../../../../../assets/icons";
import { logoImageService } from "../../../../../services";
import { formatNumber, formatNumberTrim } from "../../../../../utils";
import { useTranslation } from "react-i18next";
import { UnitValueField } from "../../../../fields/UnitValueField";
import "./PairDetails.scss"
import { PairDetailsProps } from '../PairInfoView/PairInfoView';
import Loading from '../../../../Loading';
import { NumberField } from '../../../../fields';
import { ExtendedPairInfo } from '../../../../../models';


const AssetDetailsRow: React.FC<{ keyName: string, value: string, type: string }> = ({ keyName, value, type }) => {
    const { t } = useTranslation();
    return (<div className='info' key={keyName}>
        {(type != 'inline' && type != 'link')  && <div className='label'>{t(`pair_details.${keyName}`)}:</div>}
        {type === 'link' && <a className='label' href={value} target='_blank'>{t(`pair_details.${keyName}`)} {linkIcon}</a>}
        {type !== 'link' && <div className={'value ' + type}>{value}</div>}
    </div>);
}

const AssetDetails: React.FC<{ extendedInfo: ExtendedPairInfo }> = ({ extendedInfo }) => {

    const keys = {
        'assetDescription': 'inline',
        'assetSector': 'text',
        'assetIndustry': 'text',
        'assetType': 'text',
        'assetEmployeeCt': 'number',
        'assetWebsite': 'link',
        'assetCEO': 'text',
        'assetCountry': 'text',
        'assetState': 'number',
        'assetCity': 'text',
        'assetAddress': 'text',
        'assetZip': 'number',
    };
    const { t } = useTranslation();

    const keysWithValues = Object.keys(keys).filter(key => {const value = extendedInfo[key as keyof ExtendedPairInfo]; return value && value !== '' && value !== 0 && value !== '0'});
    if (keysWithValues.length === 0) {
        return null;
    }

    return <div className='form-row info-container'>
        <h3>{t('pair_details.asset_details')}</h3>
        {keysWithValues.map(key =>
            <AssetDetailsRow key={key} keyName={key} value={extendedInfo[key as keyof ExtendedPairInfo]} type={keys[key as keyof typeof keys]} />
        )}</div>
}


export const PairDetails: React.FC<PairDetailsProps> = ({ pair, extendedInfo }) => {
    const { t } = useTranslation();
    if (!extendedInfo) {
        return <Loading />
    }
    const iconUrl = logoImageService.getPairLogoUrl(pair.id);
    const marginRatioShort = formatNumber(extendedInfo.marginRatioShort * 100, 2);
    const marginRatioLong = formatNumber(extendedInfo.marginRatioLong * 100, 2);
    const orderMin = formatNumberTrim(pair.orderMin, pair.lotDecimals);
    const orderMax = formatNumberTrim(pair.orderMax, pair.lotDecimals);

    const { long: todayRatesLong, short: todayRatesShort, marginInterest: todayMarginInterest, stockBorrowInterest: todayStockBorrowInterest, idleCash: todayIdleCash } = extendedInfo.TodayLandingInfo(pair.baseId, pair.quoteId);
    const { long: tomorrowRatesLong, short: tomorrowRatesShort, marginInterest: tomorrowMarginInterest, stockBorrowInterest: tomorrowStockBorrowInterest, idleCash: tomorrowIdleCash } = extendedInfo.TodayLandingInfo(pair.baseId, pair.quoteId);

    const ratesLabel = pair.isFX ? t('pair_details.rates_swap') : t('pair_details.rates_financing');

    return (
        <div className='PairDetails'>
            <h2 className=''>{infoIcon} <span>{t('pair_details.details')}</span></h2>
            <h3><span className='logo-wrapper'><img className="pair-logo" src={iconUrl}></img></span> <span>{pair.id}</span></h3>

            <div className={classNames('form-row', 'info-container')}>
                <div className='info'>
                    <div className='label'>{t('pair_details.lot_quantity')}:</div>
                    <div className='value'><div className='suffix-value'><span className='val'>{formatNumber(pair.lotSize, 0)}</span></div></div>
                </div>

                <div className='info'>
                    <div className='label'>{t('pair_details.margin_required')}:</div>
                </div>
                <table className="market-schedule">
                    <tbody>
                        <tr>
                            <td>{t('pair_details.short')}</td>
                            <td className="text-end"><div className='value'><div className='suffix-value'><span className='val'>{marginRatioShort}</span><span className="suffix">%</span></div></div></td>
                        </tr>
                        <tr>
                            <td>{t('pair_details.long')}</td>
                            <td className="text-end"><div className='value'><div className='suffix-value'><span className='val'>{marginRatioLong}</span><span className="suffix">%</span></div></div></td>
                        </tr>
                    </tbody>
                </table>

                <div className='info'>
                    <div className='label'>{t('pair_details.quoted_currency')}:</div>
                    <div className='value'><div className='suffix-value'><span className='val'>{pair.quoteId}</span><span className="suffix">{pair.quoteSymbol}</span></div></div>
                </div>
                {pair.orderMin > 0 && <div className='info'>
                    <div className='label'>{t('pair_details.min_position')}:</div>
                    <div className='value'><UnitValueField value={orderMin} unit={pair.baseSymbol} /></div>
                </div>}
                {pair.orderMax > 0 && <div className='info'>
                    <div className='label'>{t('pair_details.max_position')}:</div>
                    <div className='value'><UnitValueField value={orderMax} unit={pair.baseSymbol} /></div>
                </div>}
                <div className='info'>
                    <div className='label'>{ratesLabel}</div>
                </div>
                <table className="market-rates">
                    <tbody>
                        <tr>
                            <td>{t('pair_details.today')}:</td>
                            <td className="text-end">{t('pair_details.short')}</td>
                            <td className="text-end text-number"><UnitValueField value={todayRatesShort} unit='%' precision={2} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="text-end">{t('pair_details.long')}</td>
                            <td className="text-end "><UnitValueField value={todayRatesLong} unit='%' precision={2} /></td>
                        </tr>
                        <tr>
                            <td className="pb-2">{t('pair_details.margin_interest')}</td>
                            <td className="pb-2 text-end">{pair.quoteId}</td>
                            <td className="text-end pb-2"><UnitValueField value={todayMarginInterest} unit='%' precision={2} /></td>
                        </tr>
                        <tr>
                            <td>{t('pair_details.tomorrow')}:</td>
                            <td className="text-end">{t('pair_details.short')}</td>
                            <td className="text-end text-number"><UnitValueField value={tomorrowRatesShort} unit='%' precision={2} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="text-end">{t('pair_details.long')}</td>
                            <td className="text-end text-number"><UnitValueField value={tomorrowRatesLong} unit='%' precision={2} /></td>
                        </tr>
                        <tr>
                            <td className="pb-2">{t('pair_details.margin_interest')}</td>
                            <td className="pb-2 text-end">{pair.quoteId}</td>
                            <td className="text-end pb-2"><UnitValueField value={tomorrowMarginInterest} unit='%' precision={2} /></td>
                        </tr>
                        <tr>
                            <td className="pb-2">{t('pair_details.stock_borrow_interest')}</td>
                            <td className="pb-2 text-end">{pair.quoteId}</td>
                            <td className="text-end pb-2"><UnitValueField value={tomorrowStockBorrowInterest} unit='%' precision={2} /></td>
                        </tr>
                        {tomorrowIdleCash &&
                            <>
                                <tr>
                                    <td className="pb-2">{t('pair_details.idle_cash')}</td>
                                    <td className="pb-2 text-end"><NumberField value={tomorrowIdleCash.fromAmount1} /></td>
                                    <td className="text-end pb-2">
                                        <UnitValueField value={tomorrowIdleCash.rate1} unit='%' precision={2} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pb-2"></td>
                                    <td className="pb-2  text-end"><NumberField value={tomorrowIdleCash.fromAmount2} /></td>
                                    <td className="text-end pb-2">
                                        <UnitValueField value={tomorrowIdleCash.rate2} unit='%' precision={2} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pb-2"></td>
                                    <td className="pb-2  text-end"><NumberField value={tomorrowIdleCash.fromAmount3} /></td>
                                    <td className="text-end pb-2">
                                        <UnitValueField value={tomorrowIdleCash.rate3} unit='%' precision={2} />
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
                <div className='info'>
                    <div className='label'>{t('pair_details.market_open_schedule')}:</div>
                </div>
                <table className="market-schedule">
                    <tbody>
                        {Object.keys(extendedInfo.marketSchedule).map(key => <tr key={key}>
                            <td>{t(`enum.market_open_schedule.${key}`)}</td>
                            <td className="text-end">{t(`enum.market_status.${extendedInfo.marketSchedule[key]}`)}</td>
                        </tr>)
                        }
                    </tbody>
                </table>
            </div>
            <div className='pair-description'>{extendedInfo.longDescription}</div>
            <AssetDetails extendedInfo={extendedInfo} />
        </div>
    );
};
