import { LyraSequential } from "./Base";

export enum MarginPolicyCloseoutOptions {
  Unknown = 'Unknown',
  Full = 'Full',
  Partial = 'Partial'
}

export enum MarginPolicyCloseoutStrategy {
  Unknown = 'Unknown',
  MostMarginUsage = 'MostMarginUsage',
  LargestUnrealizedLosses = 'LargestUnrealizedLosses'
}


export enum MarginPolicyWorkflowType {
  Unknown = 'Unknown',
  Manual = 'Manual',
  Automatic = 'Automatic'
}

export class MarginCallPolicy implements LyraSequential {
  constructor(data: Object, public accountSymbol: string, public accountDigits: number) {
    Object.assign(this, data);
  }
  id: number;
  token: string;
  description: string;
  levelWarning?: number | null;
  levelCritical?: number | null;
  levelMC: number;
  autoCloseoutDelay: number;
  closeoutOptions: MarginPolicyCloseoutOptions;
  closeoutStrategy: MarginPolicyCloseoutStrategy;
  workflowType: MarginPolicyWorkflowType;
}
