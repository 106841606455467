import React from 'react';

import { observer } from "mobx-react-lite";

import { useOpenPositionsData } from '../../../../services/hooks';
import Loading from '../../../Loading';
import { OpenPositionsViewBlocks } from '../../../common/Positions/Open/OpenPositionsViewBlocks';
import { useShowRelatedOrderDialog } from '../../../common/Positions/useShowRelatedOrderDialog';
import { useShowTransactionsDialog } from '../../../common/Positions/useShowTransactionsDialog';
import { PorfolioTabPropsContextType } from '../../PortfolioPage/PortfolioPage';
import './Positions.scss';


const OpenPositions: React.FC<PorfolioTabPropsContextType> = observer((params) => {
    const { currency, ...rest } = params;
    const { data: positions, loading } = useOpenPositionsData(rest);
    const { transactionDialog, showPositionTransactions } = useShowTransactionsDialog();
    const { orderDialog, showOrder } = useShowRelatedOrderDialog();

    if (loading || !positions) {
        return <Loading />
    }

    return (
        <>
            {transactionDialog}
            {orderDialog}
            <OpenPositionsViewBlocks positions={positions} currency={currency} showTransaction={showPositionTransactions} showOrder={showOrder} />
        </>
    );
});

export default OpenPositions;
