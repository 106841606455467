import { Radio } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import { AggregationType, ExposureType } from "../../../../models";
import { useExposureData } from "../../../../services/hooks";
import { useAssetData } from "../../../../services/hooks/useAssetData";
import Loading from "../../../Loading";
import { ExposureCard } from "../exposure/exposureCard";
import ExposureHistoryPane from "../exposure/exposureHistoryPane";


export const ExposureTab: React.FC<{}> = observer((params) => {
    const { t } = useTranslation();
    // const aggOptions = useEnumOptions(AggregationType, 'AggregationType');
    // const [agg, setAgg] = useState(aggOptions[1]);
    const [activeStat] = useState<ExposureType>('totalValue');
    const [agg, setAgg] = useState<AggregationType>(AggregationType.ByPair);
    const [showHistory, setShowHistory] = useState(false);


    const [sorter, setSorter] = useState<{ order: string | null, field: string[] }>({ order: 'ascend', field: ['netted', 'totalValue'] });

    const { loading: isLoading, data } = useExposureData(agg as AggregationType);

    const { data: asset } = useAssetData(data?.denominatedInAssetId);
    if (!asset || !data) {
        return <Loading />
    }

    return (
        <Container className='ExposureTab' fluid={true}>
            <Row justify="space-between">
                <Col className="filters-container">
                    <Radio.Group onChange={(e) => setAgg(e.target.value)} value={agg}>
                        <Radio.Button value="pair">{t('enum.AggregationType.ByPair')}</Radio.Button>
                        <Radio.Button value="asset">{t('enum.AggregationType.ByAsset')}</Radio.Button>
                        <Radio.Button value="assetClass">{t('enum.AggregationType.ByAssetClass')}</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            {data && <Row justify="space-between" gutter={[16, 24]} className="exposure-cards">
                <Col sm={12} md={6} lg={4}>
                    <ExposureCard isLoading={isLoading} data={data} name="netted" activeStat={activeStat} assetType={agg} sorter={sorter} setSorter={setSorter} />
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <ExposureCard isLoading={isLoading} data={data} name="long" activeStat={activeStat} assetType={agg} sorter={sorter} setSorter={setSorter} />
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <ExposureCard isLoading={isLoading} data={data} name="short" activeStat={activeStat} assetType={agg} sorter={sorter} setSorter={setSorter} />
                </Col>
            </Row>}
            <Row>
                <Col>
                    <Button
                        color="primary"
                        id="toggler"
                        style={{
                            marginBottom: '1rem'
                        }}
                        onClick={() => setShowHistory(!showHistory)}
                    >
                        {t('exposure.history.title')}
                    </Button>
                    <Collapse isOpen={showHistory}>
                        <Card>
                            <CardBody>
                                {showHistory && <ExposureHistoryPane displayDecimals={data.exposureDecimals} denominatedInAssetId={data?.denominatedInAssetId} activeStat={activeStat} assetType={agg as AggregationType} />}
                            </CardBody>
                        </Card>
                    </Collapse>
                </Col>
            </Row>
            <div className="typography">
                <h2>Understanding Exposure</h2>
                <p>
                    Exposure in the context of trading refers to the amount of capital at risk in open positions. This metric is crucial for managing risk and making informed trading decisions. Exposure can be aggregated and analyzed by individual assets, asset classes, or trading pairs, and is presented both netted and separately for long and short positions.
                </p>
                <h3>Key Elements:</h3>
                <ol>
                    <li>
                        <b>Category:</b>This represents the individual asset, asset class, or trading pair. Each category is identified by its name and, where applicable, an icon.
                    </li>
                    <li>
                        <b>Total Value:</b> The total value of the open positions for the given category. This value helps to gauge the overall magnitude of exposure to specific assets or asset classes.
                    </li>
                    <li>
                        <b>Open Position:</b> The number of open positions within the category. This metric provides insight into the level of activity and commitment in the market.
                    </li>
                    <li>
                        <b>Cumulative Unrealized PnL:</b> The cumulative unrealized profit and loss (PnL) for the open positions. This figure indicates the potential gains or losses that have not yet been realized, providing an early warning of how current positions are performing.
                    </li>
                    <li>
                        <b>Total Notional:</b> The total notional value of the open positions, representing the aggregate face value of the trades. This value is crucial for understanding the scale of exposure and potential leverage.
                    </li>
                </ol>

                <h3>Aggregation Options:</h3>
                <ul>
                    <li>
                        <b>By Asset</b>: Exposure can be grouped by individual assets such as specific cryptocurrencies or stocks. This view allows for detailed analysis of exposure to each asset.

                    </li>
                    <li>
                        <b>By Asset Class:</b> Exposure can be aggregated by asset classes like cryptocurrencies, equities, or commodities. This helps in understanding the risk distribution across different types of assets.
                    </li>
                    <li>
                        <b>By Trading Pair:</b> For trading pairs, exposure can be examined for combinations like <b>BTC/USD</b> or <b>ETH/EUR</b>. This view is particularly useful for traders who operate in multiple currency pairs, providing clarity on exposure to each pair.
                    </li>
                </ul>

                <h3>Netted vs. Separate Positions:</h3>
                <ul>
                    <li>
                        <b>Netted Exposure:</b> This view shows the overall exposure by netting long and short positions. It provides a consolidated picture of the exposure, simplifying the assessment of risk.
                    </li>
                    <li>
                        <b>Separate Long and Short Positions:</b> Here, exposure is displayed separately for long and short positions. This detailed view helps in understanding the directional bets and potential risks associated with each type of position.
                    </li>
                </ul>

                <h2>Historical Trends</h2>
                <p>
                    In addition to the current exposure, you can also examine the historical trends of these metrics. By expanding this section, you can see how the key elements of your exposure have changed over time. This historical perspective allows for a deeper understanding of trends and patterns in your trading activity and risk management.
                </p>
            </div>
        </Container >)
});