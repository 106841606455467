import { PairInfo } from "./PairInfo";

export class BridgedModel {
    // set later
    public bridgePairInfo?: PairInfo;
    public pairInfo?: PairInfo;
    public bridgeForward: boolean;
    public bridgePairId: string;
    public quoteBridge: number;
    constructor(public accountSymbol: string, public accountDigits: number, data: any) {
        this.bridgeForward = data.bridgeForward;
        this.bridgePairId = data.bridgePairId;
        this.quoteBridge = data.quoteBridge;
    }

    public get isBridge(): boolean {
        return !!this.bridgePairId;
    }

    get bridgeSymbol() {
        return this.pairInfo?.quoteSymbol;
    }

    get bridgeCostDigits() {
        if (this.isBridge && this.bridgePairInfo) {
            return this.bridgePairInfo.costDecimals;
        }
        return this.accountDigits;
    }

    get bridgePairDigits() {
        return this.pairInfo?.pairDecimals;
    }

    get BridgeRate() {
        if (this.isBridge && this.quoteBridge !== undefined) {
            return this.quoteBridge;
        }
        if (this.isBridge && this.bridgePairInfo) {
            return this.bridgeForward ? this.bridgePairInfo.mid : 1 / this.bridgePairInfo.mid;
        }
        return 1;
    }

    get BridgeCurrentRate() {
        if (this.isBridge && this.quoteBridge !== undefined) {
            return this.quoteBridge;
        }
        if (this.isBridge && this.bridgePairInfo) {
            return this.bridgeForward ? this.bridgePairInfo.mid : 1 / this.bridgePairInfo.mid;
        }
        return 1;
    }
}